import styled from 'styled-components';

const WorkForSVCContainer = styled.div`
    display: grid;
    justify-content: center;

    .pe-header-logo{
        display: grid;
        justify-content: center;
        margin-top: 20px;
        img {
            width: 396px; // Keep this ratio at 1.97 to height
            height: 200px;
        }
    }

    .success-message{
        display: grid;
        justify-content: center;
        font-size: 18px;
        margin-top: 25px;
    }

    .pe-form-header{
        display: grid;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        margin-top: 15px;
    }

    .pe-form-required-note{
        display: grid;
        justify-content: center;
        font-size: 14px;
        color: red;
        margin-top: 15px;
    }

    .pe-info-box{
        display: grid;
        width: 480px;

        .input-box{
            display: grid;
            grid-template-columns: repeat(20, 1fr);
            margin-top: 10px;

            .text-box-label{
                display: grid;
                justify-content: end;
                align-items: center;
                grid-column: 1/8;
                padding-right: 5px;
            }

            .text-box-input{
                display: grid;
                grid-column: 8/20;
            }

            .warning-border{
                border: 2px solid red;
            }
        }

        .drop-down-box{
            display: grid;
            grid-template-columns: repeat(20, 1fr);
            margin-top: 10px;

            .drop-down-label{
                display: grid;
                justify-content: end;
                align-items: center;
                grid-column: 1/8;
                padding-right: 5px;
            }

            .drop-down-select{
                display: grid;
                grid-column: 8/20;
            }

            .warning-border{
                border: 2px solid red;
            }
        }

        .work-experience-header{
            display: grid;
            justify-content: center;
            margin-top: 20px;
            font-weight: bold;
        }

        .radio-button-box{
            display: grid;
            grid-template-columns: repeat(20, 1fr);
            margin-top: 15px;

            .radio-button-question{
                display: grid;
                justify-content: end;
                grid-column: 1/11;
            }

            .radio-button-yes{
                display: grid;
                justify-content: center;
                grid-column: 12/13;
            }

            .radio-button-label-yes{
                display: grid;
                justify-content: center;
                grid-column: 13/14;
            }

            .radio-button-no{
                display: grid;
                justify-content: center;
                grid-column: 15/16;
            }

            .radio-button-label-no{
                display: grid;
                justify-content: center;
                grid-column: 16/17;
            }
        }

        .text-area{
            display: grid;
            justify-content: center;
            margin-top: 20px;

            .text-area-label{
                display: grid;
                justify-content: center;
            }

            .text-area-input{
                display: grid;
                margin-top: 5px;
            }
        }
    }

    .captcha{
        display: grid;
        justify-content: center;
        margin-top: 15px;
    }

    .warning-border{
        border: 2px solid red;
    }

    .submit {
        display: grid;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 200px;
        
        .button {
            justify-self: center;
            width: 140px;
            height: 35px;
            background: rgb(220, 220, 220);
            border: 1px solid rgb(120, 120, 120);
            border-radius: 3px;
            display: grid;
            justify-content: center;
            align-items: center;
            cursor: default;
            
            :hover {
                background: rgb(200, 200, 200);
            }
        }
    }
`;

export default WorkForSVCContainer;