import styled from 'styled-components';

const EvenFlowContainer = styled.div`
    display: grid;
    margin-top: 10px;

    .top-boxes {
        display: grid;
        grid-template-columns: 3fr 3fr 2fr 2fr;
        grid-gap: 5px;
        width: 1600px;
        margin-left: 10px;
        border-bottom: 15px solid white;
    }

    .page-options {
        background: rgb(210, 200, 190);
    }

    .page-header {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background:  rgb(58, 64, 67);
        color: white;
        border-bottom: 2px solid white;
    }

    .filters-body {
        display: grid;
        grid-template-columns: 1fr;
        padding: 10px 0 15px;
    }

    .filter {
        display: grid;
        grid-template-columns: repeat(20, 1fr);
    }

    .options {
        padding: 10px 0;
    }

    .page-key, .table-layout-display {
        background: rgb(210, 200, 190);
    }

    .key {
        padding: 5px 0;
    }

    .key-pair {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        margin: 8px 0;

        .key-yellow, .key-red, .key-green, .key-blue, .key-projected {
            grid-column: 1/3;
            justify-self: end;
            align-self: center;
            margin-right: 8px;
            width: 15px;
            height: 15px;
        }

        .key-yellow { background: rgb(230, 230, 0);}
        .key-red { background: rgb(255, 35, 35);}
        .key-green { background: rgb(0, 180, 0);}
        .key-blue { background: rgb(0, 191, 255);}
        .key-projected { background: rgb(165, 165, 165);}

        .key-value {
            grid-column: 3/11;
        }
    }


    .table-title {
        width: calc(694px + (${props => props.numOfPhases} * 100px));
        display: grid;
        grid-template-columns: repeat(30, 1fr);
        font-size: 18px;
        padding: 3px 0;
        text-align: center;
        margin: 0 10px;
        background: rgb(58, 64, 67);
        color: white;

        // ADD THIS TO GET LOCKING HEADER
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        z-index: 4;
        // ********************************
        
        border-bottom: 3px solid white;

        .header-title {
            grid-column: 14/18;
            align-self: center;
            text-align: center;
        }
    }
    
    .no-lot-data-message-box{
        width: 900px;
        height: 200px;
        display: grid;
        align-items: center;
        text-align: center;
        font-size: 30px;
    }

    .grid-table {
        display: grid;
        box-sizing: border-box;
        width: calc(694px + (${props => props.numOfPhases} * 100px));
        margin: 0 10px 120px;
        border-right: 2px solid black;   // ADD THIS TO GET LOCKING HEADER
        border-bottom: 2px solid black;  // ADD THIS TO GET LOCKING HEADER
        border-left: 2px solid black;    // ADD THIS TO GET LOCKING HEADER
    }

    .grid-table-row, .grid-table-header {
        display: grid;
        text-align: center;
        grid-template-columns: 120px 250px 80px 80px 80px 80px repeat(${props => props.numOfPhases}, 100px);
    }

    .grid-table-header {
        // ADD THIS TO GET LOCKING HEADER
        position: -webkit-sticky;
        position: sticky;
        top: 130px;
        z-index: 5;
        border-top: 3px solid black;
        // ********************************

        background: rgb(210, 200, 190); // table header color
    }

    .grid-table-row:nth-child(even) {
        background: rgb(230, 230, 220); // table row color
    }

    .grid-table-row:nth-child(odd) {
        background: white;
    }

    .grid-table-row:hover {
        background: rgb(109, 174, 141);
    }

    .grid-table-header-data, .grid-table-row-data {
        display: grid;
        align-items: center;
        padding: 3px 0;
        border: 1px solid black;
    }

    .grid-table-header-data {
        height: 100px;
        padding: 0;
    }

    .grid-table-header-data.tasks {
        .column-toggle{
            height: 14px;
            width: 14px;
            margin-top: 4px;
            justify-self: center;
            align-self: start;
            background: rgb(150, 240, 150);
            border-radius: 3px;
            border: 1px solid black;
        }

        .header-name{
            height: 55px;
            display: grid;
            align-content: center;
            align-self: center;
        }
    }

    .grid-table-row-data.split {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        height: 60px;
        padding: 0;
    }

    .grid-table-row-data.release-phase, .grid-table-header-data.release-phase {
        background: rgb(130, 130, 130);
    }

    .work-phase-projected-date,
    .work-phase-scheduled-date,
    .work-phase-completed-date {
        height: 20px;
        display: grid;
        grid-row: 1/2;
        border: 1px solid black;
        color: black;

    }

    .work-phase-scheduled-date {
        grid-row: 2/3;
    }

    .work-phase-completed-date {
        grid-row: 3/4;
    }

    .gray{background: rgb(165, 165, 165);}
    .blue{background: rgb(150, 150, 255);}
    .green{background: rgb(150, 255, 150);}
    .red{background: rgb(255, 150, 150);}
    .yellow{background: rgb(255, 255, 150);}
`;

export default EvenFlowContainer;
