import { useState, useMemo } from 'react';
import Auth from '../authentication/Auth.js';
import {MaterialReactTable} from "material-react-table";

const LotsStatistics = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);
    
    const [statistics, setStatistics] = useState([]);

    const loadData = async () => {
        setDidLoadData(true);
        const tempStatistics = await Auth.hitEndpointNew('GET', 'stats', `selector=twentyfive`);
        setStatistics(tempStatistics);
    }

    if(!didLoadData){
        loadData();
    }

    const columns = useMemo(() => [
        {
            accessorKey: 'phase',
            header: 'Phase',
            size: 180
        },
        {
            accessorKey: 'received',
            header: 'Received',
            size: 150
        },
        {
            accessorKey: 'need_measurements',
            header: 'Need Measurements',
            size: 150
        },
        {
            accessorKey: 'verification_needed',
            header: 'Verification Needed',
            size: 150
        },
        {
            accessorKey: 'need_information',
            header: 'Need Information',
            size: 150
        },
        {
            accessorKey: 'save_documents',
            header: 'Save Documents',
            size: 150
        },
        {
            accessorKey: 'processed',
            header: 'Processed',
            size: 150
        },
    ], []);

    return (
        <MaterialReactTable
            columns={columns}
            data={statistics}
            enableSorting={false}
            enableColumnActions={false}
            enableTopToolbar={false}
            enableBottomToolbar={false}
            layoutMode='semantic'
            muiTableHeadCellProps={() => {
                return {
                    align: 'center',
                    sx: {
                        padding: '0',
                        height: '35px',
                        backgroundColor: 'rgb(0, 150, 255)',
                    }
                }
            }}
            muiTableBodyCellProps={({row}) => {
                return {
                    align: 'center',
                    sx: {
                        height: '30px',
                        padding: '0',
                        backgroundColor: row.index % 2 === 0 ? 'rgb(137, 207, 240)' : 'rgb(125, 249, 255)'
                    }
                }
            }}
            muiTableBodyRowProps={({row}) => {
                return {
                    hover: false,
                }
            }}
        />
    );
}

export default LotsStatistics;