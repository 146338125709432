import { useState } from 'react';
import Auth from '../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import Select from "react-select";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CreateLot = ({displayMessageWithTimer, jobs, createLot, setCreateLot}) => {
    const [lotsAdded, setLotsAdded] = useState(0);

    const [newLot, setNewLot] = useState({
        job: null,
        number: '',
        phase: '',
        address: '',
        plan_number: '',
        active: true,
        notes: '',
    })

    const [error, setError] = useState({
        type: '',
        message: ''
    })

    const closeWithSave = async (addAnother) => {
        if(newLot.job === null){
            setError({
                type: 'NO JOB SELECTED',
                message: 'Please select a job'
            })
            return;
        }
        if(newLot.number === ''){
            setError({
                type: 'NO LOT NUMBER ENTERED',
                message: 'Please enter a lot number'
            })
            return;
        }

        const queryString = `job_id = ${newLot.job.id} and lot.number = '${newLot.number}' and lot.phase = '${newLot.phase}'`;
        const dupLots = await Auth.hitEndpointNew('GET', 'lots/index-new', `queryString=${queryString}`);
        if(dupLots.length > 0){
            setError({
                type: 'REPEAT LOT',
                message: 'Lot already exists, please enter an original lot'
            })
            return;
        }

        const dbObj = {
            job_id: newLot.job.id,
            number: newLot.number,
            phase: newLot.phase,
            address: newLot.address,
            plan_number: newLot.plan_number,
            active: newLot.active,
            notes: newLot.notes,
        };

        await Auth.hitEndpointNew('POST', 'lot', '', dbObj);

        if(addAnother){
            setLotsAdded(lotsAdded + 1);
            setNewLot({
                job: null,
                number: '',
                phase: '',
                address: '',
                plan_number: '',
                active: true,
                notes: '',
            })
            return;
        }

        let lotsAddedMessage = lotsAdded === 0 ? '1 Lot Created. Refresh filters to see added lots.' 
            : `${lotsAdded + 1} Lots Created. Refresh filters to see added lots`;
        displayMessageWithTimer(lotsAddedMessage, 'green', 5000);
        setCreateLot(false);
    }

    const closeNoSave = () => {
        displayMessageWithTimer('No Lots Created', 'green', 5000);
        setCreateLot(false);
    }

    return (
        <Dialog open={createLot}>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Create Lot</DialogTitle>
            <DialogContent sx={{minWidth: '600px'}}>
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                height: '50px',
                                margin: '10px auto',
                                backgroundColor: error.type === "NO JOB SELECTED" ? 'rgb(255, 150, 150)' : 'white',
                            }
                        },
                        container: styles => {
                            return {
                                ...styles,
                                zIndex: '100'
                            }
                        }
                    }} 
                    placeholder="Select Job"
                    value={newLot.job}
                    onChange={(job) => setNewLot({...newLot, job: job})}
                    options={jobs}
                    isClearable
                    isSearchable
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                        backgroundColor: error.type === "NO LOT NUMBER ENTERED" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    label="Lot #"
                    placeholder="Enter Lot #"
                    value={newLot.number}
                    onChange={(e) => setNewLot({...newLot, number: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px'
                    }}
                    label="Lot Phase"
                    placeholder="Enter Lot Phase"
                    value={newLot.phase}
                    onChange={(e) => setNewLot({...newLot, phase: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px'
                    }}
                    label="Lot Address"
                    placeholder="Enter Lot Address"
                    value={newLot.address}
                    onChange={(e) => setNewLot({...newLot, address: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px'
                    }}
                    label="Lot Builder Plan"
                    placeholder="Enter Lot Builder Plan"
                    value={newLot.plan_number}
                    onChange={(e) => setNewLot({...newLot, plan_number: e.target.value})}
                />
                <FormControlLabel 
                    control={<Checkbox 
                        defaultChecked={newLot.active} 
                        onChange={(e) => setNewLot({...newLot, active: e.target.checked})}/>} 
                    label="Active" 
                />
                {error.type === 'REPEAT LOT' &&
                <Dialog open={error.type !== ''}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 150, 150)', fontWeight: 'bold'}}>{error.type}</DialogTitle>
                    <DialogContent 
                        sx={{width: "600px", backgroundColor: 'rgb(255, 150, 150)'}}>{error.message}</DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 150, 150)'}}>
                        <Button 
                            sx={{
                                backgroundColor: 'rgb(150, 150, 255)'
                            }}
                            variant='contained'
                            onClick={() => {
                                setError({
                                    type: '',
                                    message: ''
                                })
                            }}
                        >OK</Button>
                    </DialogActions>
                </Dialog>}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => closeWithSave(false)}>Save</Button>
                <Button variant='contained' onClick={() => closeWithSave(true)}>Save And Add Another</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateLot;
