import styled from 'styled-components';

const CrewsPageContainer = styled.div`
    display: grid;
    margin: 10px 0 75px 10px;

    .employees-to-remove-box{
        display: grid;
        position: fixed;
        width: 500px;
        right: 20px;
        top: 200px;
        padding: 5px;
        line-height: 1.4;
        background: rgb(220, 220, 20);
        border: 3px solid rgb(220, 20, 20);
        border-radius: 10px;

        .employee-to-remove{
            font-weight: bold;
        }
    }

    .crews-top-boxes{
        display: grid;
        width: 500px;
        background: rgb(210, 200, 190);
        padding-bottom: 15px;
        border-bottom: 3px solid white;

        .crews-filter-header{
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            margin-bottom: 10px;
            background: rgb(58, 64, 67);
            color: white;
            border-bottom: 3px solid white;
        }
    }

    .page-header{
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        width: 980px;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        border-bottom: 3px solid white;

        .add-new-crew{
            grid-column: 2/4;
        }

        .header-title{
            grid-column: 9/13;
            justify-self: center;
            align-self: center;
            display: grid;
            font-size: 18px;
            color: white;
        }
    }

    .new-crew-box{
        display: grid;
        width: 800px;
        background: rgb(210, 200, 190);
        padding-bottom: 15px;
        border-bottom: 3px solid white;

        .new-crew-header{
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            margin-bottom: 10px;
            background: rgb(128, 128, 128);
            color: white;
            border-bottom: 3px solid white;
        }
        
        .sub-members-box{
            display: grid;

            .no-crew-members{
                display: grid;
                margin: 3px 0 3px 0;
                font-weight: normal;
                text-align: center;
                color: red;
            }

            .members-display{
                display: grid;
                grid-template-columns: repeat(20, 1fr);
                margin: 3px 0 3px 0;
                
                .member-on-crew-checkbox{
                    display: grid;
                    grid-column: 4/5;
                }

                .member-name{
                    display: grid;
                    align-self: center;
                    grid-column: 5/14;
                    text-align: left;
                }
                
                .member-position-dropdown{
                    display: grid;
                    grid-column: 14/19;
                }
            }
        }
        
        .sub-reports-box{
           display: grid;
           grid-template-columns: repeat(10, 1fr);
           margin: 5px 0px;
           z-index: 999999;
           
            .report-select{
                display: grid;
                grid-column: 4/9;
            }
        }

        .two-button-box{
            display: grid;
            justify-self: center;
            width: 300px;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .table-header{
        display: grid;
        width: 980px;
        grid-template-columns: 80px 230px 350px 200px 120px;
        background: rgb(210, 200, 190);

        // ADD THIS TO GET LOCKING HEADER
        position: -webkit-sticky;
        position: sticky;
        top: 140px;
        z-index: 5;

        .grid-table-header-data{
            display: grid;
            justify-content: center;
            padding: 3px 0;
            border-top: 2px solid black;
            border-bottom: 2px solid black;
            border-left: 1px solid black;
            border-right: 1px solid black;
        }

        .grid-table-header-data.index0{
            border-left: 2px solid black;
        }

        .grid-table-header-data.index4{
            border-right: 2px solid black;
        }
    }

    .grid-table-row{
        display: grid;
        width: 980px;
        grid-template-columns: 80px 230px 350px 200px 120px;

        .grid-table-row-data{
            display: grid;
            justify-content: center;
            align-items: center;
            padding: 3px 0;
            border: 1px solid black;

            .display-members-dropdown{
                width: 330px;
            }
        }

        .grid-table-row-data.first{ border-top: 2px solid black; }
        .grid-table-row-data.last{ border-bottom: 2px solid black; }
        .grid-table-row-data.far-left{ border-left: 2px solid black; }
        .grid-table-row-data.far-right{ border-right: 2px solid black; }

        :nth-child(even) { 
            background: rgb(230, 230, 220); 

            .drop-down-members{
                background: rgb(230, 230, 220);
            }
        }
    }

    .grid-table-row.in-active{
        background: rgb(180, 180, 180);

        .drop-down-members{
            background: rgb(180, 180, 180);
        }
    }

    .grid-table-row-data.left-align{
        display: grid;
        justify-content: start;
        padding-left: 5px;
    }

    .edit-crew-box{
        display: grid;
        width: 800px;
        background: rgb(210, 200, 190);
        padding-bottom: 15px;
        border-top: 3px solid white;
        border-bottom: 3px solid white;

        .edit-crew-header{
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            margin-bottom: 10px;
            background: rgb(128, 128, 128);
            color: white;
            border-bottom: 3px solid white;
        }
        
        .sub-members-box{
            display: grid;

            .no-crew-members{
                display: grid;
                margin: 3px 0 3px 0;
                font-weight: normal;
                text-align: center;
                color: red;
            }

            .members-display{
                display: grid;
                grid-template-columns: repeat(20, 1fr);
                margin: 3px 0 3px 0;
                
                .member-on-crew-checkbox{
                    display: grid;
                    grid-column: 4/5;
                }

                .member-name{
                    display: grid;
                    align-self: center;
                    grid-column: 5/14;
                    text-align: left;
                }
                
                .member-position-dropdown{
                    display: grid;
                    grid-column: 14/19;
                }
            }
        }

        .sub-reports-box{
           display: grid;
           grid-template-columns: repeat(10, 1fr);
           margin: 5px 0px;
            
            .report-select{
                display: grid;
                grid-column: 4/9;
            }
        }
            
        .two-button-box{
            display: grid;
            justify-self: center;
            width: 300px;
            grid-template-columns: repeat(2, 1fr);
        }

        .three-button-box{
            display: grid;
            justify-self: center;
            width: 450px;
            grid-template-columns: repeat(3, 1fr);
        }
    }
`;

export default CrewsPageContainer;