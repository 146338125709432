import styled from 'styled-components';

const  ScheduleUpdateContainer = styled.div`
    display: grid;
    width: 400px;
    margin: 10px 0 0 10px;


   td{
        min-width: 100px;
   }



   .tab-wrap {
      transition: 0.3s box-shadow ease;
      border-radius: $tab-wrap-border-radius;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      list-style: none;
      background-color: #fff;
      margin: 40px 0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      &:hover {
        box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
      }
    }

    .tab {
      display: none;
      @for $i from 1 through $max-tab-count {
        &:checked:nth-of-type(#{$i}) ~ .tab__content:nth-of-type(#{$i}) {
          opacity: 1;
          transition: 0.5s opacity ease-in, 0.8s transform ease;
          position: relative;
          top: 0;
          z-index: 100;
          transform: translateY(0px);
          text-shadow: 0 0 0;
        }
      }
      &:first-of-type:not(:last-of-type) + label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:not(:first-of-type):not(:last-of-type) + label {
        border-radius: 0;
      }
      &:last-of-type:not(:first-of-type) + label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:checked + label {
        background-color: #fff;
        box-shadow: 0 -1px 0 #fff inset;
        cursor: default;
        &:hover {
          box-shadow: 0 -1px 0 #fff inset;
          background-color: #fff;
        }
      }
      + label {
        box-shadow: 0 -1px 0 #eee inset;
        border-radius: $tab-wrap-border-radius $tab-wrap-border-radius 0 0;
        cursor: pointer;
        display: block;
        text-decoration: none;
        color: #333;
        flex-grow: 3;
        text-align: center;
        background-color: #f2f2f2;
        user-select: none;
        text-align: center;
        transition: 0.3s background-color ease, 0.3s box-shadow ease;
        height: 50px;
        box-sizing: border-box;
        padding: 15px;
        &:hover {
          background-color: #f9f9f9;
          box-shadow: 0 1px 0 #f4f4f4 inset;
        }
      }
      &__content {
        padding: 10px 25px;
        background-color: transparent;
        position: absolute;
        width: 100%;
        z-index: -1;
        opacity: 0;
        left: 0;
        transform: translateY(-3px);
        border-radius: $tab-wrap-border-radius;
      }
    }

   .tab-active{
        border-top-right-radius: 30;
        border-bottom-right-radius: 30;
   }

   .tab-inactive{

   }

    .filter-box{
        display: grid;
        // grid-template-rows: repeat(5, 1fr);
        grid-template-rows: 40px;
        width: 600px;
        background: rgb(210, 200, 190);
        border-bottom: 3px solid white;

        .filter-date{
            display: grid;
            align-items: center;
            width: 200px;
            grid-column: 1/3;
            grid-template-columns: 300px, 30px, 300px;

            .job-filter{grid-column: 1/2; width: 300px;}
            .lot-filter{grid-column: 2/3; width: 300px;}
        }

        .filter-super{
            display: grid;
            grid-column: 3/4;
            width: 300px;
            grid-template-columns: 300px, 30px, 300px;
            z-index: 2;
        }
    }
`;

export default ScheduleUpdateContainer;
