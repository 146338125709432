

import PopupOkContainer from './styles/PopupOkContainer';

const PopupOk = (props) => {
    return (
        <PopupOkContainer color={props.color}>
            <div className="popupok-box">
                <p className="popupok-message">{props.message}</p>
                <div className="popupok-ok-cancel">
                    <button type="button" className="popupok-button" value="ok" onClick={(e) => props.handlePopup(e)}>Ok</button>
                    <button type="button" className="popupok-button" value="cancel" onClick={(e) => props.handlePopup(e)}>Cancel</button>
                </div>
            </div>
        </PopupOkContainer>
    );
}

export const PopupOkSuccessColor = 'rgb(30, 180, 30)';
export const PopupOkFailureColor = 'rgb(255, 50, 50)';
export const PopupOkWarningColor = 'rgb(230, 230, 20)';

export default PopupOk;