import Auth from "./Auth";


class Can {

    static isAdmin(permissions){
        let group = permissions.filter(x => x.permission !== undefined && (x.permission.name === 'userPermissions' || x.permission.name === 'users' || x.permission.name === 'messaging' ))
        return group.length > 0

    }

    static isAuthorizedRead(permissions, permissionName) {
        const permission = permissions.find(p => p.permission.name === permissionName);
        if (permission === undefined) {
            return false;
        }
        return permission.read;
    }

    static isAuthorized = async (permissionName, rw) => {
        let permissions = await Auth.getPermissions()
        const permission = permissions.find(p => p.permission.name === permissionName);
        if(permission === undefined){
            return false;
        }

        if(this.isAdmin(permissions)){
            return true
        }

        if(rw === "READ"){
            return permission.read;
        }
        if(rw === "WRITE"){
            return permission.write;
        }
        return false; // Catch All
    }
}

export default Can;