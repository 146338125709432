
import styled from 'styled-components';


const PagesContainer = styled.div`
    position: fixed;
    bottom: 30px;
    left: 50px;
    display: grid;
    grid-template-columns: 30px 100px 30px;
    background:  rgb(58, 64, 67);
    padding: 5px;

    .page-down {
        grid-column: 1/2;
        color: white;
        font-weight: bold;
        justify-self: center;
        align-self: center;
        cursor: pointer;
    }

    .page-info {
        grid-column: 2/3;
        justify-self: center;
        color: white;

        .current-page-input {
            width: 30px;
        }
    }

    .page-up {
        grid-column: 3/4;
        color: white;
        font-weight: bold;
        justify-self: center;
        align-self: center;
        cursor: pointer;
    }
`

class PagesData{
    createPagesData(){
        return {
            currentPage: 1,
            itemsPerPage: 50
        }
    }

    getCurrentPageData(pagesData, dataArr){
        return dataArr.slice(pagesData.currentPage * pagesData.itemsPerPage - pagesData.itemsPerPage, pagesData.currentPage * pagesData.itemsPerPage);
    }

    pageBack(pagesData){
        pagesData.currentPage--;
        if(pagesData.currentPage < 1){
            pagesData.currentPage = 1;
        }
        return pagesData.currentPage;
    }

    pageForward(pagesData, contentLength){
        pagesData.currentPage++;
        if(contentLength + pagesData.itemsPerPage < pagesData.currentPage * pagesData.itemsPerPage){
            pagesData.currentPage--;
        }
        return pagesData.currentPage;
    }

    handleInputPageNumber(pageNumber, pagesData, contentLength){
        // Check for non integer or no input
        if(isNaN(Number.parseInt(pageNumber)) || pageNumber === ''){
            return;
        }
        let page = Number.parseInt(pageNumber);
        if(page < 1){
            page = 1;
        }
        else if(page > Math.ceil(contentLength / pagesData.itemsPerPage)){
            page = Math.ceil(contentLength / pagesData.itemsPerPage);
        }
        return page;
    }
}

// TEMPLATE
// <Pages contentLength="Overall length of data to display"
//        pagesData="A PagesData obj created from the PagesData.createPagesData"
//        pageBack="Function to update the components pagesData.currentPage object decrement"
//        pageForward="Function to update the components pagesData.currentPage object increment"
//        handleInputPageNumber="Function to update the components pagesData.currentPage object text input"
// END

export const Pages = (props) => {
    return (
        <PagesContainer>
            <div className='page-down' onClick={(e) => props.pageBack()}>{'< '}</div>
            <div className='page-info'>
                <input id='id-current-page-input' className="current-page-input" type='number' value={props.pagesData.currentPage} onClick={() => document.getElementById('id-current-page-input').select()} onChange={(e) => props.handleInputPageNumber(e.target.value)}/> of {Math.ceil(props.contentLength / props.pagesData.itemsPerPage)}
            </div>
            <div className='page-up' onClick={(e) => props.pageForward()}>{' >'}</div>
        </PagesContainer>
    );
}

const pagesData = new PagesData();
export default pagesData;

