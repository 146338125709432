import Auth from "../components/authentication/Auth";

class PhaseTasks {

    static async loadPhaseTasks(queryObj) {

        let data = [];
        let dbData;
        if (queryObj) {
            const jsonString = JSON.stringify(queryObj);
            dbData = await Auth.hitEndpointNew("GET", "phase-tasks", `jsonString=${jsonString}`);
        } else {
            dbData = await Auth.hitEndpointNew("GET", "phase-tasks", ``);
        }
        data = [...data, ...dbData];

        return data;
    }

    static async saveNewPhaseTask(phaseTask) {
        const result = await Auth.hitEndpointNew("POST", "phase-tasks", "", phaseTask);
        phaseTask.id = result.id;

        return phaseTask;
    }

    static async updatePhaseTask(phaseTask, user) {
        await Auth.hitEndpointNew("PATCH", "phase-tasks", "", phaseTask);
        return phaseTask;
    }

    static async deletePhaseTask(phaseTask) {
        await Auth.hitEndpointNew("DELETE", "phase-tasks", "", phaseTask);
        return phaseTask;
    }

    static createNewPhaseTask(user){
        return {
            phase_id: "",
            reporting_phase_id: "",
            name: "",
            description: "",
            code: "",
            order_by: "",
            type: "",
            style: "",
            owners_index: "",
            offsets: ""
        };
    }

    static async checkPhaseTaskForErrors(phaseTask){
        if(phaseTask.field_id === ''){return "ERROR_NO_PHASE";}

        // Check for duplicate phaseTasks
        const jsonString = JSON.stringify({name: phaseTask.name});
        let dupData = await Auth.hitEndpointNew("GET", "phase-tasks", `selector=nine&jsonString=${jsonString}`);
        if(phaseTask.id){
            dupData = dupData.filter(dataToFilter => dataToFilter.id.toString() !== phaseTask.id.toString());
        }
        if(dupData.length > 0){return "ERROR_DUPLICATE_PHASE";}

        return "SUCCESS";
    }

    static async getSelectedPhaseTaskIds(tasks){
        let phaseTasks = []
        if (tasks !== undefined && tasks !== null && tasks.length > 0) {
            tasks.forEach(s => {
                phaseTasks.push(s.value)
            })
        }
        return phaseTasks
    }
}

export default PhaseTasks;
