import styled from 'styled-components';

const SiteMaintenanceContainer = styled.div`
    display: grid;

    .drag-n-drop-box{
        width: 800px;
        margin: 20px auto 0;

        .drag-n-drop-header{
            display: grid;
            justify-content: center;
            align-content: center;
            padding: 10px 0;
            background: rgb(210, 200, 190);
            color: white;
            font-size: 20px;
        }

        .drop-area{
            display: grid;
            justify-content: center;
            align-content: center;
            border: 2px solid rgb(210, 200, 190);
            height: 200px;
            font-size: 30px;
        }
    }

    .submit {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        align-items: center;
        margin: 20px 0;
    }

    .button {
        justify-self: center;
        width: 120px;
        height: 30px;
        background: rgb(240, 240, 240);
        border: 1px solid rgb(120, 120, 120);
        border-radius: 3px;
        display: grid;
        justify-content: center;
        align-items: center;
        cursor: default;

        :hover {
            background: rgb(230, 230, 230);
        }
    }
`;

export default SiteMaintenanceContainer;