import styled from 'styled-components';

const MainHeaderContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 140px;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(5, 1fr);
    z-index: 50;

    .main-header-logo{
        grid-column: 1/4;
        grid-row: 1/6;
        display: grid;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        background: white;

        img {
            width: 197px; // Keep this ratio at 1.97 to height
            height: 100px;
        }
    }

     .desktop-nav {
        display: grid;
        grid-column: 4/21;
        grid-row: 1/6;
     }
     
     .mobile-nav {
        display: none;
     }
     
    .title-name-box{
        grid-column: 6/21;
        grid-row: 1/5;
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33%, rgba(4,115,58,1) 100%);
        display: grid;
        grid-template-columns: repeat(15, 1fr);
        grid-template-rows: repeat(2, 1fr);

        .main-header-title{
            grid-column: 1/10;
            grid-row: 1/3;
            display: grid;
            justify-content: center;
            align-content: center;
            font-size: 40px;
            font-weight: bold;
        }

        .dev-build{
            color: yellow;
        }

        .main-header-user-name{
            grid-column: 12/16;
            grid-row: 1/2;
            align-self: center;
            font-size: 20px;
            color: white;
        }
        
        .main-header-user-buttons-div{
            grid-column: 12/16;
            grid-row: 2/3;
            display: grid;
            justify-content: start;
            align-items: center;
            width: 100%;

            .main-header-user-buttons{
                grid-row: 1/2;
                width: 50px;
                height: 22px;
                cursor: pointer;
                background: black;
                color: white;
                border-radius: 3px;

                :hover{
                    background: rgb(40, 40, 40);
                }
            }

            .logout{
                margin-right: 5px;
            }

            .change-password{
                margin-left: 5px;
            }
        }
    }

    .main-header-drop-down{
        grid-column: 6/21;
        grid-row: 5/6;
        background: rgb(58, 64, 67);
        display: grid;
        grid-template-columns: repeat(15, 1fr);

        .main-header-drop-down-label{
            grid-column: 1/6;
            display: grid;
            align-content: center;
            justify-content: end;
            padding-right: 5px;
            color: white;
        }

        .main-header-drop-down-select{
            grid-column: 6/16;
            align-self: center;
            display: grid;
            width: 200px;
            height: 20px;
        }
    }


    @media screen and (max-width: 1080px) {
       position: static;
       width: 100vw;
       height: 200px;
       display: grid;
       grid-template-columns: repeat(20, 1fr);
       grid-template-rows: repeat(5, 1fr);
        
        .desktop-nav {
          display: none;
       }
     
      .main-header-logo{
            grid-column: 1/21;
            grid-row: 1/4;
    
            img {
                width: 197px; // Keep this ratio at 1.97 to height
                height: 100px;
            }
        }
    
        .title-name-box{
            grid-column: 1/21;
            grid-row: 4/5;
            background: white;
            grid-template-columns: repeat(20, 1fr);
            border-top: 2px solid rgb(4,115,58);
    
            .main-header-title{
                display: none;
            }
    
            .username-logout-box{
                grid-column: 1/21;
                display: grid;
                grid-template-columns: repeat(20, 1fr);
                grid-template-rows: 1fr;
    
                .main-header-user-name{
                    grid-column: 6/9;
                    grid-row: 2/5;
                    font-size: 20px;
                    color: black;
                }
    
                .main-header-logout-button{
                    grid-column: 11/18;
                    grid-row: 1/2;
                    width: 130px;
                    height: 35px;
                }
            }
        }
    
     .mobile-nav {
        display: grid;
        grid-column: 1/21;
        grid-row: 4/5;
     }
        .main-header-drop-down{
            grid-column: 1/21;
            grid-row: 5/6;
            margin-bottom: 10px;
            grid-template-columns: repeat(15, 1fr);

            .main-header-drop-down-label{
                grid-column: 1/8;
                display: grid;
                align-content: center;
                justify-content: end;
                padding-right: 5px;
                color: white;
            }
    
            .main-header-drop-down-select{
                grid-column: 8/16;
                align-self: center;
                display: grid;
                width: 150px;
                height: 20px;
            }
        }
    }
 `

 export const Spacer = styled.div`
    position: static;
    display: grid;
    width: 100%;
    height: 140px;

    @media screen and (max-width: 1080px) {
        height: 200px;
    }
 `

 export default MainHeaderContainer;