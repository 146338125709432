import styled from 'styled-components';


const EWAOldContainer = styled.div`
    display: grid;
    margin: 10px 0 175px 10px;
    box-sizing: border-box;


    .ewa-top-boxes{
        display: grid;
        grid-template-columns: 600px 1fr;
        grid-gap: 10px;
        width: 900px;
        border-bottom: 10px solid white;

        .ewa-filter{
            display: grid;
            background: rgb(210, 200, 190);

            .ewa-filter-header{
                display: grid;
                align-items: center;
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                height: 28px;
                background: rgb(58, 64, 67);
                color: white;
                border-bottom: 3px solid white;
            }

            .ewa-filter-options{
                display: grid;
                padding: 10px 0;

                .job-lot-filter-box,
                .company-employee-filter-box,
                .date-range-filter-box{
                    display: grid;
                    grid-template-columns: repeat(20, 1fr);
                    margin: 3px 0;
                    
                    .job-filter-select,
                    .company-filter-select,
                    .start-date-filter{
                        display: grid;
                        grid-column: 1/11;
                    }

                    .lot-filter-select,
                    .employee-filter-select,
                    .end-date-filter{
                        display: grid;
                        grid-column: 11/21;
                    }
                }

                .approval-status-filter-box,
                .completion-status-filter-box{
                    display: grid;
                    margin: 3px 0;
                }
            }
        }

        .ewa-sort{
            display: grid;
            grid-template-rows: 28px 100px;
            background: rgb(210, 200, 190);


            .ewa-sort-header{
                display: grid;
                align-items: center;
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                height: 28px;
                background: rgb(58, 64, 67);
                color: white;
                border-bottom: 3px solid white;
            }

            .ewa-sort-options{
                display: grid;
                padding: 10px 0;
            }
        }
    }

    .table-header{
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        grid-template-rows: 1fr;
        width: 2645px;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        border-bottom: 3px solid white;
        margin-right: 10px;

        .add-new-ewa {
            grid-row: 1/2;
            grid-column: 1/3;
            display: grid;
            justify-content: center;
            align-items: center;

            .add-new-ewa-button {
                width: 120px;
                height: 20px;
                background: rgb(240, 240, 240);
                border: 1px solid rgb(120, 120, 120);
                border-radius: 3px;
                display: grid;
                justify-content: center;
                align-items: center;
                cursor: default;

                :hover {
                    background: rgb(230, 230, 230);
                }
            }
        }

        .print-ewas {
            grid-row: 1/2;
            grid-column: 3/5;
            display: grid;
            justify-content: center;
            align-items: center;

            .print-ewas-button {
                width: 120px;
                height: 20px;
                background: rgb(240, 240, 240);
                border: 1px solid rgb(120, 120, 120);
                border-radius: 3px;
                display: grid;
                justify-content: center;
                align-items: center;
                cursor: default;

                :hover {
                    background: rgb(230, 230, 230);
                }
            }
        }

        .export-to-xlsx {
            grid-row: 1/2;
            grid-column: 5/7;
            display: grid;
            justify-content: center;
            align-items: center;

            .export-to-xlsx-button {
                width: 120px;
                height: 20px;
                background: rgb(240, 240, 240);
                border: 1px solid rgb(120, 120, 120);
                border-radius: 3px;
                display: grid;
                justify-content: center;
                align-items: center;
                cursor: default;

                :hover {
                    background: rgb(230, 230, 230);
                }
            }
        }

        .header-title{
            grid-row: 1/2;
            grid-column: 9/13;
            justify-self: center;
            align-self: center;
            display: grid;
            font-size: 18px;
            color: white;
        }
    }

    .new-ewa-box{
        display: grid;
        width: 1965px;
        background: rgb(210, 200, 190);
        margin-bottom: 3px;
        border: 3px solid rgb(58, 64, 67);

        .new-ewa-header{
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            background: rgb(128, 128, 128);
            color: white;
        }

        .new-ewa-table-header{
            display: grid;
            width: 1960px;
            grid-template-columns: 50px 150px 150px 150px 150px 500px 130px 130px 150px 150px 250px;
            background: rgb(210, 200, 190);
    
            .new-ewa-table-header-data{
                display: grid;
                justify-content: center;
                padding: 3px 0;
                border-top: 2px solid black;
                border-bottom: 2px solid black;
                border-left: 1px solid black;
                border-right: 1px solid black;
            }
    
            .grid-table-header-data.index0{
                border-left: 2px solid black;
            }
    
            .grid-table-header-data.index6{
                border-right: 2px solid black;
            }
        }

        .new-ewa-table-row{
            display: grid;
            width: 1960px;
            grid-template-columns: 50px 150px 150px 150px 150px 500px 130px 130px 150px 150px 250px;
            background: white;
    
            .new-ewa-row-data{
                display: grid;
                justify-content: center;
                align-items: center;
                border: 1px solid black;
                height: 31px;

                .new-ewa-job-drop-down{ width: 130px; }
                .new-ewa-lot-drop-down{ width: 130px; }
                .new-ewa-phase-drop-down{ width: 130px; }
                .new-ewa-phase-code-drop-down{ width: 130px; }
                .new-ewa-job-description{ width: 480px; }
                .new-ewa-job-internal-estimate-number{ width: 110px; }
                .new-ewa-job-builder-po-number{ width: 110px; }
                .new-ewa-scheduled-date{ width: 130px; }
                .new-ewa-payout{ width: 130px; }
                .new-ewa-employee-select{ width: 230px; }

                .new-ewa-scheduled-date-picker{
                    display: grid;
                    margin: 0 auto;
                    background: inherit;
                    border: none;
                    width: 80%;
                    height: 100%;
                    text-align: center;
                    cursor: pointer;
                    font: inherit;

                    border: 1px solid black;
                    
                    .react-datepicker-popper{
                        z-index: 7;
                    }
                }
            }

            .new-ewa-row-data.error{
                background: red;
            }
        }

        .new-ewa-table-row.last{ border-bottom: 1px solid black; }

        .add-new-ewa-line-box{
            display: grid;
            width: 50px;
            padding: 2px;
        }

        .two-button-box{
            display: grid;
            justify-self: center;
            width: 100%;
            padding: 2px 0;
            grid-template-columns: repeat(2, 1fr);
            background: rgb(128, 128, 128);
            border-top: 2px solid black;

            .submit-new-ewa{
                justify-self: end;
                margin-right: 20px;
            }

            .cancel-add-new-ewa{
                justify-self: start;
                margin-left: 20px;
            }
        }
    }

    .grid-table-header{
        display: grid;
        width: 2645px;
        grid-template-columns: 40px 250px 60px 90px 100px 95px 550px 
        110px 110px 90px 110px 80px 300px 240px 120px 300px ;
        background: rgb(210, 200, 190);

        // ADD THIS TO GET LOCKING HEADER
        position: -webkit-sticky;
        position: sticky;
        top: 140px;
        z-index: 5;

        .grid-table-header-data{
            display: grid;
            justify-content: center;
            padding: 3px 0;
            border-top: 2px solid black;
            border-bottom: 2px solid black;
            border-left: 1px solid black;
            border-right: 1px solid black;
        }

        .grid-table-header-data.index0{
            border-left: 2px solid black;
        }

        .grid-table-header-data.index13{
            border-right: 2px solid black;
        }
    }

    .grid-table-row{
        display: grid;
        width: 2645px;
        grid-template-columns: 40px 250px 60px 90px 100px 95px 550px 
        110px 110px 90px 110px 80px 300px 240px 120px 300px ;

        .grid-table-row-data{
            display: grid;
            justify-content: center;
            align-items: center;
            // padding: 3px 0;
            border: 1px solid black;
            height: 30px;

            .approve-decline-box{
                display: grid;
                width: 220px;
                align-items: center;
                grid-template-columns: 1fr 1fr;

                .approve-ewa{
                    grid-column: 1/2;
                }

                .decline-ewa{
                    grid-column: 2/3;
                }
            }

            .completed-box{
                display: grid;
                justify-content: center;
                align-items: center;
            }
        }

        .gray-out{
            width: 100%;
            background: rgb(120, 120, 120);
        }

        .red{
            width: 100%;
            background: red;
        }

        .grid-table-row-data.left-align{
            display: grid;
            justify-content: start;
            padding-left: 5px;
        }

        .grid-table-row-data.first{ border-top: 2px solid black; }
        .grid-table-row-data.last{ border-bottom: 2px solid black; }
        .grid-table-row-data.far-left{ border-left: 2px solid black; }
        .grid-table-row-data.far-right{ border-right: 2px solid black; }

        :nth-child(even){background: rgb(230, 230, 220);}

        .payout-display{
            grid-template-columns: 15px 75px;

            .payout-display-prefix{
                margin-left: 2px;
            }

            .payout-display-payout{
                justify-self: right;
                margin-right: 2px;
            }
        }
    }

    .grid-table-row:hover{
        background: rgb(109, 174, 141);
    }

    .edit-ewa-box{
        display: grid;
        width: 800px;
        background: rgb(210, 200, 190);
        padding-bottom: 15px;
        border-bottom: 3px solid white;

        .edit-ewa-header{
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            margin-bottom: 10px;
            background: rgb(128, 128, 128);
            color: white;
            border-bottom: 3px solid white;
        }

        .edit-ewa-job-description,
        .edit-ewa-lot-number,
        .edit-ewa-lot-phase{
            display: grid;
            grid-template-columns: repeat(10, 1fr);
            margin: 3px 0;

            .label{
                display: grid;
                grid-column: 1/4;
                padding-right: 3px;
                text-align: right;
            }

            .value{
                display: grid;
                grid-column: 4/8;
                padding-left: 3px;
            }
        }

        .two-button-box{
            display: grid;
            justify-self: center;
            width: 300px;
            grid-template-columns: repeat(2, 1fr);
        }

        .three-button-box{
            display: grid;
            justify-self: center;
            width: 450px;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .pages {
        position: fixed;
        bottom: 30px;
        left: 50px;
        display: grid;
        grid-template-columns: 30px 100px 30px;
        background:  rgb(58, 64, 67);
        padding: 5px;

        .page-down {
            grid-column: 1/2;
            color: white;
            font-weight: bold;
            justify-self: center;
            align-self: center;
            cursor: pointer;
        }

        .page-info {
            grid-column: 2/3;
            justify-self: center;
            color: white;

            .current-page-input {
                width: 30px;
            }
        }

        .page-up {
            grid-column: 3/4;
            color: white;
            font-weight: bold;
            justify-self: center;
            align-self: center;
            cursor: pointer;
        }
    }
`;

export default EWAOldContainer;