import {routes} from '../env'
import Config from '../config'
// Import URL's
//export const buildType = 'PROD'; // DEV PROD
export const adminKey = "MaintenanceAdmin";
export const version = "0.0.1";
export const capSiteKey = routes.REACT_APP_CAP_SITE_KEY;

export const urlMaps = new Map();
urlMaps["BUILDERS"]                 = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_BUILDERS              : routes.REACT_APP_PROD_URL_BUILDERS;
urlMaps["BUILDER"]                  = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_BUILDER               : routes.REACT_APP_PROD_URL_BUILDER;
urlMaps["JOB_TYPES"]                = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_JOB_TYPES             : routes.REACT_APP_PROD_URL_JOB_TYPES;
urlMaps["COLUMNS"]                  = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_COLUMNS               : routes.REACT_APP_PROD_URL_COLUMNS;
urlMaps["JOBS"]                     = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_JOBS                  : routes.REACT_APP_PROD_URL_JOBS;
urlMaps["LOT"]                      = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_LOT                   : routes.REACT_APP_PROD_URL_LOT;
urlMaps["LOTS"]                     = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_LOTS                  : routes.REACT_APP_PROD_URL_LOTS;
urlMaps["LOTS_BY_JOB"]              = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_LOTS_BY_JOB           : routes.REACT_APP_PROD_URL_LOTS_BY_JOB;
urlMaps["MEASUREMENTS"]             = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_MEASUREMENTS          : routes.REACT_APP_PROD_URL_MEASUREMENTS;
urlMaps["QA"]                       = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_QA                    : routes.REACT_APP_PROD_URL_QA;
urlMaps["USER"]                     = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_USER                  : routes.REACT_APP_PROD_URL_USER;
urlMaps["USERS"]                    = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_USERS                 : routes.REACT_APP_PROD_URL_USERS;
urlMaps["USER_ROLES"]               = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_USER_ROLES            : routes.REACT_APP_PROD_URL_USER_ROLES;
urlMaps["USER_POSITIONS"]           = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_USER_POSITIONS        : routes.REACT_APP_PROD_URL_USER_POSITIONS;
urlMaps["USER_FIELDS"]              = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_USER_FIELDS           : routes.REACT_APP_PROD_URL_USER_FIELDS;
urlMaps["USER_PERMISSIONS"]         = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_USER_PERMISSIONS      : routes.REACT_APP_PROD_URL_USER_PERMISSIONS;
urlMaps["LOGIN"]                    = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_LOGIN                 : routes.REACT_APP_PROD_URL_LOGIN;
urlMaps["AUTHENTICATE"]             = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_AUTHENTICATE          : routes.REACT_APP_PROD_URL_AUTHENTICATE;
urlMaps["DATA"]                     = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_DATA                  : routes.REACT_APP_PROD_URL_DATA;
urlMaps["STATS"]                    = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_STATS                 : routes.REACT_APP_PROD_URL_STATS;
urlMaps["APP_STATE"]                = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_APPSTATE              : routes.REACT_APP_PROD_URL_APPSTATE;
urlMaps["MESSAGES"]                 = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_MESSAGES              : routes.REACT_APP_PROD_URL_MESSAGES;
urlMaps["SCHEDULES"]                = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_SCHEDULES             : routes.REACT_APP_PROD_URL_SCHEDULES;
urlMaps["EMPLOYEES"]                = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_CREWMEMBERS           : routes.REACT_APP_PROD_URL_CREWMEMBERS;
urlMaps["CREWS"]                    = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_CREWS                 : routes.REACT_APP_PROD_URL_CREWS;
urlMaps["STARTS_STATUS"]            = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_STARTSSTATUS          : routes.REACT_APP_PROD_URL_STARTSSTATUS;
urlMaps["PHASE"]                    = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_PHASE                 : routes.REACT_APP_PROD_URL_PHASE;
urlMaps["PROSPECTIVE_EMPLOYEES"]    = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_PROSPECTIVEEMPLOYEE   : routes.REACT_APP_PROD_URL_PROSPECTIVEEMPLOYEE;
urlMaps["EWA"]                      = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_EWA                   : routes.REACT_APP_PROD_URL_EWA;
urlMaps["EWAS"]                     = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_EWAS                  : routes.REACT_APP_PROD_URL_EWAS;
urlMaps["EWA_CODES"]                = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_EWA_CODES             : routes.REACT_APP_PROD_URL_EWA_CODES;
urlMaps["PHASES"]                   = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_PHASES                : routes.REACT_APP_PROD_URL_PHASES;
urlMaps["PHASE_TASKS"]              = Config.BUILD_TYPE() === 'DEV' ? routes.REACT_APP_DEV_URL_PHASE_TASKS           : routes.REACT_APP_PROD_URL_PHASE_TASKS;
