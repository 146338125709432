import { useState } from 'react';
import Auth from '../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';

const CreateBuilder = ({displayMessageWithTimer, updateBuildersArray, 
                        builders, createBuilder, setCreateBuilder}) => {

    const [newBuilder, setNewBuilder] = useState({
        active: true,
        name: '',
    });

    const [error, setError] = useState({
        type: '',
        message: ''
    });

    const closeWithSave = async (addAnother) => {
        if(newBuilder.name === ''){
            setError({
                type: 'NAME ERROR',
                message: "Please enter builder's name"
            })
            return;
        }

        if(builders.find(b => b.name === newBuilder.name)){
            setError({
                type: 'NAME ERROR',
                message: 'Builder already exists, please enter a new builder'
            })
            return;
        }

        const dbObj = {
            active: newBuilder.active,
            created_at: dayjs(new Date()).format('YYYY-MM-DD'),
            created_by: await Auth.getUserId(),
            modified_at: dayjs(new Date()).format('YYYY-MM-DD'),
            modified_by: await Auth.getUserId(),
            name: newBuilder.name,
        };
        await Auth.hitEndpointNew('POST', 'builders', '', dbObj);

        await updateBuildersArray();
        displayMessageWithTimer('New Builder Created', 'green', 5000);
        setCreateBuilder(false);
    }

    const closeNoSave = () => {
        displayMessageWithTimer('No Builder Created', 'red', 5000);
        setCreateBuilder(false);
    }

    return (
        <Dialog open={createBuilder}>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Create Builder</DialogTitle>
            <DialogContent sx={{minWidth: '600px'}}>
                <TextField
                    sx={{
                        width: '100%',
                        margin: '10px auto',
                        backgroundColor: error.type === "NAME ERROR" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    label="Builder Name:"
                    placeholder="Enter Builder Name"
                    value={newBuilder.name}
                    onChange={(e) => setNewBuilder({...newBuilder, name: e.target.value})}
                />
                <FormControlLabel 
                    control={<Checkbox 
                        defaultChecked={newBuilder.active} 
                        onChange={(e) => setNewBuilder({...newBuilder, active: e.target.checked})}/>} 
                    label="Active" 
                />
                {error.type === 'NAME ERROR' &&
                <Dialog open={error.type !== ''}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 150, 150)', fontWeight: 'bold'}}>{error.type}</DialogTitle>
                    <DialogContent 
                        sx={{width: "600px", backgroundColor: 'rgb(255, 150, 150)'}}>{error.message}</DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 150, 150)'}}>
                        <Button 
                            sx={{
                                backgroundColor: 'rgb(150, 150, 255)'
                            }}
                            variant='contained'
                            onClick={() => {
                                setError({
                                    type: '',
                                    message: ''
                                })
                            }}
                        >OK</Button>
                    </DialogActions>
                </Dialog>}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={closeWithSave}>Save</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateBuilder;