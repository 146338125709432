
import {useNavigate} from 'react-router';
import Links from "./Links";

const Sidebar = ({showControls}) => {
    const navigate = useNavigate();
    const quickLinks = Links.quickLinks
    const specialtyLinks = Links.specialtyLinks

    return (
        <div className='links-box'>
            {showControls &&
                <div className='quick-link-box'>
                    <div className='quick-link-header'>Quick Links</div>
                    <div className='quick-link-body'>
                        {quickLinks.map((link, index) => {
                            return (
                                <div key={index} className='quick-link-link'
                                     onClick={(e) => navigate(link.navigation)}>{link.display}</div>
                            )
                        })}
                    </div>
                </div>}
            {showControls &&
                <div className='specialty-link-box'>
                    <div className='specialty-link-header'>Specialty Links</div>
                    <div className='specialty-link-body'>
                        {specialtyLinks.map((link, index) => {
                            return (
                                <div key={index} className='specialty-link-link'
                                     onClick={(e) => navigate(link.navigation)}>{link.display}</div>
                            )
                        })}
                    </div>
                </div>}
        </div>
    )
};

export default Sidebar;