import { useState } from 'react';
import dayjs from 'dayjs';
import Auth from '../authentication/Auth.js';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button  from "@mui/material/Button";
import Select from "react-select";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Switch } from '@mui/material';

const LotsFilter = ({displayMessageWithTimer, jobs, phases, phaseStatus, setLots}) => {
    const [filters, setFilters] = useState({
        job: null,
        phase: null,
        phaseStatus: null,
        startDate: null,
        endDate: null,
        includeDeleted: false,
        byBlueStake: false,
    })

    const [loading, setLoading] = useState({
        isLoading: false,
        buttonText: 'Load Lots'
    })

    const applyFilters = async () => {
        if(loading.isLoading){
            return;
        }

        if( filters.job === null  && filters.phase === null  && filters.phaseStatus === null){
            displayMessageWithTimer('No Filters Selected', 'red', 5000);
            return;
        }
        if(filters.phase !== null && filters.phaseStatus === null){
            displayMessageWithTimer('Phase With No Phase Status', 'red', 5000);
            return;
        }

        setLoading({
            isLoading: true,
            buttonText: 'Fetching Lots...'
        })

        let queryString = '';
        if(!filters.includeDeleted){
            queryString += `active=1 and `;
        }
        if(filters.job !== null){
            queryString += `job_id=${filters.job.value} and `;
        }
        if(filters.phase !== null){
            queryString += `lot_phase.phase_id=${filters.phase.value} and `
        }
        if(filters.phaseStatus !== null && filters.phaseStatus.value === "Not Released"){
            queryString += 'lot_phase.release_date is null and '
        }
        if(filters.phaseStatus !== null && filters.phaseStatus.value === "Released"){
            if(filters.startDate !== null){
                queryString += `lot_phase.release_date>'${dayjs(new Date(filters.startDate)).format('YYYY-MM-DD')}' and `
            }
            if(filters.endDate !== null){
                queryString += `lot_phase.release_date<='${dayjs(new Date(filters.endDate)).format('YYYY-MM-DD')}' and `
            }
            if(filters.startDate === null && filters.endDate === null){
                queryString += 'lot_phase.release_date is not null and '
            }
        }
        if(filters.phaseStatus !== null && filters.phaseStatus.value !== "Not Released" && filters.phaseStatus.value !== "Released"){
            queryString += `lot_phase.starts_status='${filters.phaseStatus.value}' and `;
        }

        // Trim 'and' off end of whereStr
        if(queryString !== ''){
            queryString = queryString.slice(0, queryString.length - 5);
        }

        let tempLots = await Auth.hitEndpointNew('GET', `lots/index-new`, `queryString=${queryString}`);
        if(tempLots.length === 0){
            displayMessageWithTimer('No Lots Match Filter Parameters', 'red', 5000);
        }
        if(filters.byBlueStake){
            const withoutBluestake = tempLots.filter(l => l.bluestake.length === 0);
            const withBluestake = tempLots.filter(l => l.bluestake.length > 0);
            withoutBluestake.sort((l1, l2) => {
                if(parseInt(l1.job.number) === parseInt(l2.job.number)){
                    return parseInt(l1.number) < parseInt(l2.number) ? -1 : 1;
                }
                return parseInt(l1.job.number) < parseInt(l2.job.number) ? -1 : 1;
            });
            withBluestake.sort((l1, l2) => {
                if(parseInt(l1.job.number) === parseInt(l2.job.number)){
                    return parseInt(l1.number) < parseInt(l2.number) ? -1 : 1;
                }
                return parseInt(l1.job.number) < parseInt(l2.job.number) ? -1 : 1;
            });
            tempLots = [...withoutBluestake, ...withBluestake];
        }
        else{
            tempLots.sort((l1, l2) => {
                if(parseInt(l1.job.number) === parseInt(l2.job.number)){
                    return parseInt(l1.number) < parseInt(l2.number) ? -1 : 1;
                }
                return parseInt(l1.job.number) < parseInt(l2.job.number) ? -1 : 1;
            });
        }

        setLots(tempLots);
        setLoading({
            isLoading: false,
            buttonText: 'Load Lots'
        })
    }

    return (
        <Grid 
            sx={{
                display: 'grid',
                width: 600, 
                border: '2px solid rgb(220, 220, 220)',
                borderRadius: '5px',
                zIndex: '49'
            }}
        >
            <Grid
                sx={{
                    // gridColumn: '1/11',
                    fontSize: '30px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    margin: '10px auto 0'
                }}
            >
                Lot Filters
            </Grid>
            <Select 
                styles={{
                    control: styles => {
                        return {
                            ...styles,
                            width: '500px',
                            margin: '10px auto',
                        }
                    }
                }} 
                placeholder="Select A Job"
                value={filters.job}
                onChange={(job) => setFilters({...filters, job: job})}
                options={jobs}
                isClearable
                isSearchable
            />
            <Grid
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                }}
            >
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                width: '250px',
                                margin: '10px auto 0',
                            }
                        }
                    }} 
                    placeholder="Select A Phase"
                    value={filters.phase}
                    onChange={(phase) => setFilters({...filters, phase: phase})}
                    options={phases}
                    isClearable
                    isSearchable
                />
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                width: '250px',
                                margin: '10px auto 0',
                            }
                        }
                    }} 
                    placeholder="Select Phase Status"
                    value={filters.phaseStatus}
                    onChange={(phaseStatus) => setFilters({...filters, phaseStatus: phaseStatus})}
                    options={phaseStatus}
                    isClearable
                    isSearchable
                />
            </Grid>
            <FormControlLabel 
                    sx={{
                        width: '90%',
                        justifySelf: 'end',
                    }}
                    control={<Checkbox id={"by-bludestake"}
                        defaultChecked={filters.byBlueStake} 
                        onChange={(e) => setFilters({...filters, byBlueStake: e.target.checked})}
                    />} 
                    label="By Bluestake" 
                />
            <Grid
                sx={{
                    display: 'grid',
                    justifyContent: 'center',
                    gridTemplateColumns: '1fr 1fr',
                    margin: '0 auto'
                }}
            >
                <Grid
                    sx={{
                        display: 'grid',
                        marginRight: '10px',
                    }}
                >
                    Start Date
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => setFilters({...filters, startDate: date})}
                            value={filters.startDate}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid
                    sx={{
                        display: 'grid',
                        marginLeft: '10px',
                    }}
                >
                    End Date
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => setFilters({...filters, endDate: date})}
                            value={filters.endDate}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid
                sx={{
                    display: 'grid'
                }}
            >
                <Box
                    sx={{
                        justifySelf: 'end'
                    }}
                >
                    <FormControlLabel 
                        control={<Switch id={"include=deleted-lots"}
                            checked={filters.includeDeleted} 
                            onChange={(e) => setFilters({...filters, includeDeleted: e.target.checked})}/>} 
                        label="Included Deleted Lots" 
                    />
                    <Button 
                        sx={{
                            justifySelf: 'end',
                            margin: '10px 15px 10px 0',
                            width: '180px',
                        }}
                        variant='contained' 
                        onClick={applyFilters}
                    >Update Lots</Button>
                </Box>
            </Grid>
        </Grid>
    );
}

export default LotsFilter;