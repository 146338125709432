import styled from 'styled-components';

const EditUserContainer = styled.div`
    display: grid;
    margin: 10px 0 100px 10px;

    .edit-user-title {
        display: grid;
        width: 604px;
        background: rgb(58, 64, 67);
        border-bottom: 2px solid white;
        text-align: center;
        font-size: 18px;
        padding: 3px 0;
        color: white;
    }

    .sub-header {
        width: 604px;
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(128, 128, 128);
        color: white;
        border-bottom: 2px solid white;
    }

    .select-user-dropdown{
        width: 604px;
        background: rgb(210, 200, 190);
        display: grid;
        padding: 15px 0;
        border-bottom: 2px solid white;
    }

    .user-data-input-box {
        width: 604px;
        background: rgb(210, 200, 190);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, 1fr);
        padding: 10px 0;
        border-bottom: 2px solid white;

        .button-box {
            display: grid;
            // justify-content: center;
            // align-items: center;
            grid-template-columns: 1fr 1fr;
            background: rgb(210, 200, 190);
            padding: 8px 0 10px;

            .update-user{
                justify-self: end;
                margin-right: 20px;
            }

            .cancel-update-user{
                justify-self: start;
                margin-left: 20px;
            }
        }
    }

    .read-write-label{
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        margin: 10px 0 5px;

        .read-label{
            display: grid;
            grid-column: 5/6;
        }

        .write-label{
            display: grid;
            grid-column: 6/7;
        }
    }

    .permissions-display{
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        align-items: center;

        .group-permission{
            display: grid;
            justify-self: end;
            margin-right: 20px;
            grid-column: 1/5;
        }

        .read{
            display: grid;
            grid-column: 5/6;
        }

        .write{
            display: grid;
            grid-column: 6/7;
        }
    }
`;

export default EditUserContainer;