import {useMemo, useState} from "react";
import {darken, lighten} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {createTheme} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {Autocomplete} from "@mui/material";

const DetailNew = ({selectedTasks, selectedLots, suppliers}) => {

    const handleSaveCell = (e, newValue, cell, row, detailId) => {
        if(row.original.detail === undefined){
            row.original.detail = []
        }
        let existing = row.original.detail.filter(x => x.phase_task_detail_id === detailId)
        if((existing === undefined || existing.length === 0) && newValue !== "") {
            row.original.detail.push({
                phase_task_detail_id: detailId,
                schedule_value: newValue,
            })
        }
        else{
            if(newValue === "") {
                row.original.detail.splice(row.original.detail.findIndex(i => i.phase_task_detail_id === existing.phase_task_detail_id), 1)
            }
            else{
                existing.schedule_value = newValue
            }
        }
    }

    const gridColumns = useMemo(
        () => {
            let columns = [
                {
                    accessorKey: 'number',
                    header: 'Lot',
                    size: 20,
                    enableEditing: false,
                },
                {
                    accessorKey: 'phase',
                    header: 'Phase',
                    size: 20,
                    enableEditing: false,
                }
            ]
            for (let t = 0; t < selectedTasks.length; t++) {
                for (let d = 0; d < selectedTasks[t].detail.length; d++) {
                    if(selectedTasks[t].detail[d].type.type === 'dropdown'){
                        columns.push(
                            {
                                accessorKey: selectedTasks[t].detail[d].type.key,
                                header: selectedTasks[t].detail[d].type.name,
                                size: 50,
                                enableEditing: true,
                                grow: true,
                                Edit: ({ cell, row }) => (
                                    <Autocomplete
                                       // disablePortal
                                        id={`${selectedTasks[t].detail[d].type.key}-options`}
                                        key={`${selectedTasks[t].detail[d].type.key}-options-${row.index}`}
                                        options={suppliers.filter(s => s.type.id === selectedTasks[t].detail[d].type.supplier_type_id)}
                                        sx={{ width: 200, zIndex: 10000000 }}
                                        onChange={(e, newValue) => handleSaveCell(e, newValue !== null ? newValue.id : null, cell, row, selectedTasks[t].detail[d].id )}
                                        renderInput={(params) => <TextField key={`${selectedTasks[t].detail[d].type.key}-options-${row.index}-input`} {...params} label="" />}
                                    />
                                ),
                            }
                        )
                    }
                    else {
                        columns.push(
                            {
                                accessorKey: selectedTasks[t].detail[d].type.key,
                                header: selectedTasks[t].detail[d].type.name,
                                size: 20,
                                enableEditing: true,
                                Edit: ({ cell, row }) => (
                                    <TextField
                                        placeholder={selectedTasks[t].detail[d].type.name}
                                        type={selectedTasks[t].detail[d].type.type}
                                        onBlur={(e) => handleSaveCell(e, e.target.value, cell, row, selectedTasks[t].detail[d].id)}
                                    ></TextField>
                                )
                            }
                        )
                    }
                }
            }
            return columns
        }
    );

    const theme = createTheme({
        palette: {
            ochre: {
                main: '#E3D026',
                light: '#E9DB5D',
                dark: '#A29415',
                contrastText: '#242105',
            },
        },
    });

    const baseBackgroundColor = theme.palette.mode === 'dark' ? 'rgba(3, 44, 43, 1)' : 'rgba(244, 255, 233, 1)'

    return (
       <div style={{height: '100%', width: '100%'}}>
            <MaterialReactTable
                id="schedule-detail-table"
                columns={gridColumns}
                data={selectedLots}
                rowCount={selectedLots.length}
                enableGrouping
                enableExpanding={false}
                enableExpandAll={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                enableSorting={false}
                enableFilters={false}
                enableHiding={false}
                enableEditing={true}
                editDisplayMode="table"
                enableColumnActions={true}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '800px'
                    }
                }}
                muiEditTextFieldProps={({cell}) => ({
                    onBlur: event => {handleSaveCell(cell, event.target.value);}
                })}
                muiTableHeadCellProps={({column}) => ({
                    sx: {
                        '& .MuiTableSortLabel-icon': {
                            color: column.getIsSorted() ? 'limegreen !important' : undefined
                        },
                        backgroundColor: '#D3D3D3'
                    }
                })}
                muiTableBodyCellProps={({cell, row}) => ({
                    sx: {
                        height: 10,
                        fontStyle: 'italic',
                        border: 'none',
                        backgroundColor:
                            row.depth === 0 ? '#D3D3D3' :
                                row.original.assigned ? 'lightgreen' :
                                    row.original.group_count % 2 === 0 ?
                                        darken(baseBackgroundColor, 0.1) :
                                        lighten(baseBackgroundColor, 0.3)
                        ,
                        borderTop: cell.row.depth === 1 ? 'solid 1pt' : row.depth === 0 ? 'solid 2pt' : 'none',
                        borderLeft: cell.column.id === "mrt-row-actions" ? 'solid 1pt' : 'none',
                        borderRight: cell.column.id === "employee" ? 'solid 1pt' : 'none',
                        paddingBottom: "1px",
                    }
                })}
                initialState={{
                    showColumnFilters: false,
                    pagination: {pageSize: 100, pageIndex: 0},
                 //   density: 'compact',
                }}
            />
        </div>
    )
}

export default DetailNew;
