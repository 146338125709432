import styled from 'styled-components';

const WarningScreensContainer = styled.div`
    display: grid;

    .logo{
        display: grid;
        justify-self: center;
        margin: 20px auto;
    }
    .maintenance-message{
        Max-width: 800px;
        margin: 20px auto;
        text-align: center;
        font-size: 20px;
        line-height: 1.5em;

        span{
            font-weight: bold;
            color: red;
        }
    }
`;

export default WarningScreensContainer;