import {useState} from 'react';
import Auth from '../authentication/Auth.js';
import Employees from '../../classes/Employees.js';
import MainHeader from '../utilities/MainHeader.js';
import EmployeesPageContainer from './styles/EmployeesPageContainer.js';
import { Button } from '../utilities/Button.js';
import { TextboxWithLabel, Textbox } from '../utilities/Textbox.js';
import { CheckboxWithLabel, Checkbox } from '../utilities/Checkbox.js';
import PopupOk, {PopupOkWarningColor} from '../utilities/PopupOk.js';
import Popup, {PopupSuccessColor, PopupFailureColor} from '../utilities/Popup.js';
import Utilities from '../../classes/Utilities.js';
import { createAlert } from '../utilities/MainHeaderAlert.js';

const EmployeesPage = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filterOptions, setFilterOptions] = useState({
        showInactiveEmployees: false
    })

    const [employees, setEmployees] = useState([]);
    const [newEmployee, setNewEmployee] = useState(null);
    const [employeeToEdit, setEmployeeToEdit] = useState(null);
    const [employeeToDelete, setEmployeeToDelete] = useState(null);

    const [errorFirstNameMissing, setErrorFirstNameMissing] = useState(false);
    const [errorLastNameMissing, setErrorLastNameMissing] = useState(false);
    const [errorDuplicateName, setErrorDuplicateName] = useState(false);
    const [errorDuplicateQuickBooksId, setErrorDuplicateQuickBooksId] = useState(false);
    const [errorQuickBooksIdMissing, setErrorQuickBooksIdMissing] = useState(false);
    const [errorCompanyNotSelected, setErrorCompanyNotSelected] = useState(false);
    const [errorNoSubContractorCompany, setNoSubContractorCompany] = useState(false);
    const [errorSubContractorNotChecked, setErrorSubContractorNotChecked] = useState(false);
    const [errorSubContractorWorkNotDefined, setErrorSubContractorWorkNotDefined] = useState(false);
    const [errorOnCrewCantDelete, setErrorOnCrewCantDelete] = useState(false);
    const [newEmployeeSuccess, setNewEmployeeSuccess] = useState(false);
    const [editEmployeeSuccess, setEditEmployeeSuccess] = useState(false);
    const [deleteEmployeeSuccess, setDeleteEmployeeSuccess] = useState(false);

    const canCreateEmployee = props.isAuthorized('employees', 'WRITE');
    const canEditEmployee = props.isAuthorized('employees', 'WRITE');
    const canDeleteEmployee = false;

    const applyFilters = async () => {
        const tempEmployees = Employees.sortEmployees("firstName", "ascending", (await Auth.hitEndpointNew("GET", "employees-new")));
        if(filterOptions.showInactiveEmployees){
            setEmployees(tempEmployees);
        }
        else{
            setEmployees(tempEmployees.filter(emp => emp.active === 1));
        }
    }
    
    const loadData = async () => {
        setDidLoadData(true);
        applyFilters();
    }

    if(!didLoadData){
        loadData();
    }

    const resetErrorPopups = () => {
        setErrorFirstNameMissing(false);
        setErrorLastNameMissing(false);
        setErrorDuplicateName(false);
        setErrorDuplicateQuickBooksId(false);
        setErrorQuickBooksIdMissing(false);
        setErrorCompanyNotSelected(false);
        setNoSubContractorCompany(false);
        setErrorSubContractorNotChecked(false);
        setErrorSubContractorWorkNotDefined(false);
        setErrorOnCrewCantDelete(false);
    }

    const resetSuccessPopups = () => {
        setNewEmployeeSuccess(false);
        setEditEmployeeSuccess(false);
        setDeleteEmployeeSuccess(false);
    }

    const handleEmployeeErrors = (errorCode) => {
        if(errorCode === "ERROR_NO_FIRST_NAME"){setErrorFirstNameMissing(true); return;}
        if(errorCode === "ERROR_NO_LAST_NAME"){setErrorLastNameMissing(true); return;}
        if(errorCode === "ERROR_DUPLICATE_NAME"){setErrorDuplicateName(true); return;}
        if(errorCode === "ERROR_DUPLICATE_QUICK_BOOKS_ID"){setErrorDuplicateQuickBooksId(true); return;}
        if(errorCode === "ERROR_NO_QUICKBOOKS_ID"){setErrorQuickBooksIdMissing(true); return;}
        if(errorCode === "ERROR_NO_COMPANY"){setErrorCompanyNotSelected(true); return;}
        if(errorCode === "ERROR_NO_SUB_COMPANY"){setNoSubContractorCompany(true); return;}
        if(errorCode === "ERROR_COMPANY_WITHOUT_SUB_CHECKED"){setErrorSubContractorNotChecked(true); return;}
        if(errorCode === "ERROR_SUB_WORK_NOT_DEFINED"){setErrorSubContractorWorkNotDefined(true); return;}
    }

    const saveNewEmployee = async () => {
        const result = await Employees.checkEmployeeForErrors(newEmployee);
        if(result !== "SUCCESS"){
            handleEmployeeErrors(result);
            return;
        }
        setEmployees(Employees.sortEmployees("firstName", "ascending", [...employees, await Employees.saveNewEmployee(newEmployee)]));
        setNewEmployeeSuccess(true);
        setNewEmployee(null);
    }

    const updateEmployee = async () => {
        const result = await Employees.checkEmployeeForErrors(employeeToEdit);
        if(result !== "SUCCESS"){
            handleEmployeeErrors(result);
            return;
        }

        await Employees.updateEmployee(employeeToEdit, null);
        await applyFilters();
        setEditEmployeeSuccess(true);
        setEmployeeToEdit(null);
    }

    const startDeleteEmployee = () => {
        if(employeeToEdit.crews !== ""){
            setErrorOnCrewCantDelete(true);
            return;
        }

        setEmployeeToDelete(employeeToEdit);
    }

    const deleteEmployee = async (e) => {
        if(e.target.value === "ok"){
            const tempEmployeeToDelete = {...employeeToDelete};
            tempEmployeeToDelete.active = 0;

            await Employees.updateEmployee(tempEmployeeToDelete, null);

            const tempEmployees = employees.filter(employeeToFilter => employeeToFilter.id !== tempEmployeeToDelete.id);
            setEmployees(Employees.sortEmployees("firstName", "ascending", tempEmployees));
            setDeleteEmployeeSuccess(true);
        }
        
        setEmployeeToEdit(null);
        setEmployeeToDelete(null);
    }

    const handleEmployeeToEditSetActive = async (e) => {
        const tempEmployeeToEdit = {...employeeToEdit};
        if(tempEmployeeToEdit.active){
            const crewMembers = await Auth.hitEndpointNew("GET", "crew-members", `jsonString=${JSON.stringify({employee_id: employeeToEdit.id})}`);
            if(crewMembers.length > 0){
                tempEmployeeToEdit.active = false;
                employeeToEdit.remove_from_crew = true;
                createAlert(
                    `Remove ${employeeToEdit.first_name} ${employeeToEdit.last_name} from crew ${employeeToEdit.crews}`,
                    '/crews-page',
                    [39, 49]
                )
            }
        }
        else{
            tempEmployeeToEdit.active = true;
            employeeToEdit.remove_from_crew = false;
        }

        setEmployeeToEdit({...employeeToEdit, active: !employeeToEdit.active})
    }

    const exportEmployeesToXLSX = async () => {
        console.log(employees);
        const exportData = [];

        document.getElementById('export-to-xlsx-id').innerHTML = 'Exporting...';

        for(let i = 0; i < employees.length; i++){

            exportData.push({
                'Active': employees[i].active ? 'X' : '',
                'First Name': employees[i].first_name ? employees[i].first_name : '',
                'Last Name': employees[i].last_name ? employees[i].last_name : '',
                'Alias': employees[i].alias ? employees[i].alias : '',
                'Company': employees[i].company ? employees[i].company : '',
                'Phone #': employees[i].phone_number ? employees[i].phone_number : '',
                'Email': employees[i].email ? employees[i].email : '',
                'Quickbooks ID': employees[i].qb_id ? employees[i].qb_id : '',
                'SVC': employees[i].svc ? 'X' : '',
                'SVP': employees[i].svp ? 'X' : '',
                'SVF': employees[i].svf ? 'X' : '',
                'Concrete Sub': employees[i].sub_concrete ? 'X' : '',
                'Plumbing Sub': employees[i].sub_plumbing ? 'X' : '',
                'Framing Sub': employees[i].sub_framing ? 'X' : '',
            });
        }

        Utilities.exportToXLSXFileFormat(exportData, 'Employees', './Employees.xlsx');

        document.getElementById('export-to-xlsx-id').innerHTML = 'Export To XLSX';
    }

    const columns = ['First Name', 'Last Name', 'Alias', 'Company', 'Phone #', 'Email', 'Quickbooks ID', `Crew #`];
    
    return (
        <EmployeesPageContainer>
            <MainHeader/>
            <div className='page-filter'>
                <div className='filter-header'>Employees Filter</div>
                <CheckboxWithLabel className="show-inactive-employees" name="show-inactive-employees" label="Show Inactive Employees" checked={filterOptions.showInactiveEmployees} handleCheckbox={(e) => setFilterOptions({...filterOptions, showInactiveEmployees: e.target.checked})} columns="10" labelStart="1" labelEnd="7" inputStart="7" inputEnd="10"/>
                <Button className="apply-filters" handleKeyPress={((e) => {if(e.key === 'Enter'){applyFilters()}})} handleClick={((e) => applyFilters())} buttonText="Apply Filter" width="120"/>
            </div>
            <div className='page-header'>
                {canCreateEmployee && <Button className="add-new-employee" handleClick={(async (e) => setNewEmployee(Employees.createNewEmployee(await Auth.getUserId())))} buttonText="Add Employee" buttonMargin="0 0 0 0" width="120" height="20"/>}
                <Button id="export-to-xlsx-id" className="export-to-xlsx" handleClick={((e) => exportEmployeesToXLSX())} buttonText="Export To XLSX" buttonMargin="0 0 0 0" width="120" height="20"/>
                <div className='header-title'>Employees</div>
            </div>
            {newEmployee && 
            <div className='new-employee-box'>
                <div className='new-employee-header'>Add New Employee</div>
                <CheckboxWithLabel className="is-active-checkbox" name="is-active" label="Is Active" checked={newEmployee.active} handleCheckbox={((e) => setNewEmployee({...newEmployee, active: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <TextboxWithLabel className="firstname-textbox" name="first-name" label="First Name" placeholder="First Name" value={newEmployee.first_name} handleTextbox={((e) => setNewEmployee({...newEmployee, first_name: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <TextboxWithLabel className="lastname-textbox" name="last-name" label="Last Name" placeholder="Last Name" value={newEmployee.last_name} handleTextbox={((e) => setNewEmployee({...newEmployee, last_name: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <TextboxWithLabel className="alias-textbox" name="alias" label="Alias/Nickname" placeholder="Alias/Nickname" value={newEmployee.alias} handleTextbox={((e) => setNewEmployee({...newEmployee, alias: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <TextboxWithLabel className="phone-number-textbox" inputType="tel" name="phone-number" label="Phone Number" placeholder="XXX-XXX-XXXX" value={newEmployee.phone_number} handleTextbox={((e) => setNewEmployee({...newEmployee, phone_number: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <TextboxWithLabel className="email-textbox" inputType="email" name="email" label="Email" placeholder="Email" value={newEmployee.email} handleTextbox={((e) => setNewEmployee({...newEmployee, email: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <TextboxWithLabel className="quickbooks-id-textbox" name="quickbooks-id" label="QuickBooks ID" placeholder="QuickBooks ID" value={newEmployee.qb_id} handleTextbox={((e) => setNewEmployee({...newEmployee, qb_id: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <div className='company-check-box'>
                    <CheckboxWithLabel className="is-svc-checkbox" name="is-svc" label="SVC" checked={newEmployee.svc} handleCheckbox={((e) => setNewEmployee({...newEmployee, svc: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <CheckboxWithLabel className="is-svp-checkbox" name="is-svp" label="SVP" checked={newEmployee.svp} handleCheckbox={((e) => setNewEmployee({...newEmployee, svp: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <CheckboxWithLabel className="is-svf-checkbox" name="is-svf" label="SVF" checked={newEmployee.svf} handleCheckbox={((e) => setNewEmployee({...newEmployee, svf: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <div className="sub-checkbox-and-name-box">
                        <Checkbox className="is-sub-checkbox" name="is-sub" checked={newEmployee.sub} handleCheckbox={((e) => setNewEmployee({...newEmployee, sub: e.target.checked}))} columns="4" inputStart="1" inputEnd="5"/>
                        <Textbox className="company-textbox" name="company" placeholder="Sub Contractor Name" value={newEmployee.company} handleTextbox={((e) => setNewEmployee({...newEmployee, company: e.target.value}))} columns="1" inputStart="1" inputEnd="1"/>
                    </div>
                    {newEmployee.sub &&
                    <div className='sub-company-check-box'>
                        <CheckboxWithLabel className="is-sub-concrete-checkbox" name="is-sub-concrete" label="Concrete Sub" checked={newEmployee.sub_concrete} handleCheckbox={((e) => setNewEmployee({...newEmployee, sub_concrete: e.target.checked}))} columns="20" labelStart="2" labelEnd="11" inputStart="11" inputEnd="20"/>
                        <CheckboxWithLabel className="is-sub-plumbing-checkbox" name="is-sub-plumbing" label="Plumbing Sub" checked={newEmployee.sub_plumbing} handleCheckbox={((e) => setNewEmployee({...newEmployee, sub_plumbing: e.target.checked}))} columns="20" labelStart="2" labelEnd="11" inputStart="11" inputEnd="20"/>
                        <CheckboxWithLabel className="is-sub-framing-checkbox" name="is-sub-framing" label="Framing Sub" checked={newEmployee.sub_framing} handleCheckbox={((e) => setNewEmployee({...newEmployee, sub_framing: e.target.checked}))} columns="20" labelStart="2" labelEnd="11" inputStart="11" inputEnd="20"/>
                    </div>}
                </div>
                <div className="two-button-box">
                    <Button className="submit-new-employee" handleKeyPress={((e) => {if(e.key === 'Enter'){saveNewEmployee()}})} handleClick={((e) => saveNewEmployee())} buttonText="Submit" width="120"/>
                    <Button className="cancel-add-new-employee" handleKeyPress={((e) => {if(e.key === 'Enter'){setNewEmployee(null)}})} handleClick={((e) => setNewEmployee(null))} buttonText="Cancel" width="120"/>
                </div>
            </div>}
            <div className='table-header'>
                {columns.map((column, index)=> {
                    return <div key={`${column}-${index}`} className={`grid-table-header-data index${index}`}>{column}</div>
                })}
            </div>
            {employees.map((employee, index) => {
                if(canEditEmployee && employeeToEdit && (employeeToEdit.id === employee.id)){
                    return(
                        <div key={`${employee.id}-${index}`} className='edit-employee-box'>
                            <div className='edit-employee-header'>Edit Employee</div>
                            <div className="is-active-crew-numbers-box">
                                <CheckboxWithLabel className="is-active-checkbox" name="is-active" label="Is Active" checked={employeeToEdit.active} handleCheckbox={handleEmployeeToEditSetActive} columns="7" labelStart="1" labelEnd="7" inputStart="7" inputEnd="8"/>
                                {employeeToEdit.crews !== "" && <div className="crew-numbers-display">{`On Crews: ${employeeToEdit.crews}`}</div>}
                            </div>
                            <TextboxWithLabel className="firstname-textbox" name="first-name" label="First Name" placeholder="First Name" value={employeeToEdit.first_name} handleTextbox={((e) => setEmployeeToEdit({...employeeToEdit, first_name: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                            <TextboxWithLabel className="lastname-textbox" name="last-name" label="Last Name" placeholder="Last Name" value={employeeToEdit.last_name} handleTextbox={((e) => setEmployeeToEdit({...employeeToEdit, last_name: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                            <TextboxWithLabel className="alias-textbox" name="alias" label="Alias/Nickname" placeholder="Alias/Nickname" value={employeeToEdit.alias} handleTextbox={((e) => setEmployeeToEdit({...employeeToEdit, alias: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                            <TextboxWithLabel className="phone-number-textbox" inputType="tel" name="phone-number" label="Phone Number" placeholder="XXX-XXX-XXXX" value={employeeToEdit.phone_number} handleTextbox={((e) => setEmployeeToEdit({...employeeToEdit, phone_number: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                            <TextboxWithLabel className="email-textbox" inputType="email" name="email" label="Email" placeholder="Email" value={employeeToEdit.email} handleTextbox={((e) => setEmployeeToEdit({...employeeToEdit, email: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                            <TextboxWithLabel className="quickbooks-id-textbox" name="quickbooks-id" label="QuickBooks ID" placeholder="QuickBooks ID" value={employeeToEdit.qb_id} handleTextbox={((e) => setEmployeeToEdit({...employeeToEdit, qb_id: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                            <div className='company-check-box'>
                                <CheckboxWithLabel className="is-svc-checkbox" name="is-svc" label="SVC" checked={employeeToEdit.svc} handleCheckbox={((e) => setEmployeeToEdit({...employeeToEdit, svc: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                                <CheckboxWithLabel className="is-svp-checkbox" name="is-svp" label="SVP" checked={employeeToEdit.svp} handleCheckbox={((e) => setEmployeeToEdit({...employeeToEdit, svp: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                                <CheckboxWithLabel className="is-svf-checkbox" name="is-svf" label="SVF" checked={employeeToEdit.svf} handleCheckbox={((e) => setEmployeeToEdit({...employeeToEdit, svf: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                                <div className="sub-checkbox-and-name-box">
                                    <Checkbox className="is-sub-checkbox" name="is-sub" checked={employeeToEdit.sub} handleCheckbox={((e) => setEmployeeToEdit({...employeeToEdit, sub: e.target.checked}))} columns="4" inputStart="1" inputEnd="5"/>
                                    <Textbox className="company-textbox" name="company" placeholder="Sub Contractor Name" value={employeeToEdit.company} handleTextbox={((e) => setEmployeeToEdit({...employeeToEdit, company: e.target.value}))} columns="1" inputStart="1" inputEnd="1"/>
                                </div>
                                {employeeToEdit.sub !== 0 &&
                                <div className='sub-company-check-box'>
                                    <CheckboxWithLabel className="is-sub-concrete-checkbox" name="is-sub-concrete" label="Concrete Sub" checked={employeeToEdit.sub_concrete} handleCheckbox={((e) => setEmployeeToEdit({...employeeToEdit, sub_concrete: e.target.checked}))} columns="20" labelStart="2" labelEnd="11" inputStart="11" inputEnd="20"/>
                                    <CheckboxWithLabel className="is-sub-plumbing-checkbox" name="is-sub-plumbing" label="Plumbing Sub" checked={employeeToEdit.sub_plumbing} handleCheckbox={((e) => setEmployeeToEdit({...employeeToEdit, sub_plumbing: e.target.checked}))} columns="20" labelStart="2" labelEnd="11" inputStart="11" inputEnd="20"/>
                                    <CheckboxWithLabel className="is-sub-framing-checkbox" name="is-sub-framing" label="Framing Sub" checked={employeeToEdit.sub_framing} handleCheckbox={((e) => setEmployeeToEdit({...employeeToEdit, sub_framing: e.target.checked}))} columns="20" labelStart="2" labelEnd="11" inputStart="11" inputEnd="20"/>
                                </div>}
                            </div>
                            {!canDeleteEmployee &&
                            <div className="two-button-box">
                                <Button className="submit-edit-employee" handleKeyPress={((e) => {if(e.key === 'Enter'){updateEmployee()}})} handleClick={((e) => updateEmployee())} buttonText="Submit" width="120"/>
                                <Button className="cancel-edit-employee" handleKeyPress={((e) => {if(e.key === 'Enter'){setEmployeeToEdit(null)}})} handleClick={((e) => setEmployeeToEdit(null))} buttonText="Cancel" width="120"/>
                            </div>}
                            {canDeleteEmployee &&
                            <div className="three-button-box">
                                <Button className="submit-edit-employee" handleKeyPress={((e) => {if(e.key === 'Enter'){updateEmployee()}})} handleClick={((e) => updateEmployee())} buttonText="Submit" width="120"/>
                                <Button className="cancel-edit-employee" handleKeyPress={((e) => {if(e.key === 'Enter'){setEmployeeToEdit(null)}})} handleClick={((e) => setEmployeeToEdit(null))} buttonText="Cancel" width="120"/>
                                <Button className="submit-delete-employee" handleKeyPress={((e) => {if(e.key === 'Enter'){startDeleteEmployee()}})} handleClick={((e) => startDeleteEmployee())} buttonText="Delete" width="120"/>
                            </div>}
                        </div>
                    );
                }
                const position = index === 0 ? 'first' : index === (employees.length - 1) ? 'last' : '';
                let company = '';
                if(Number.parseInt(employee.svc) === 1){company = 'SVC';}
                if(Number.parseInt(employee.svp) === 1){company = 'SVP';}
                if(Number.parseInt(employee.svf) === 1){company = 'SVF';}
                if(Number.parseInt(employee.sub) === 1){company = employee.company;}
                return (
                    <div key={`${employee.id}-${index}`} className={`grid-table-row ${employee.active ? '' : 'in-active'}`}>
                        <div className={`grid-table-row-data ${position} far-left`} onClick={(e) => setEmployeeToEdit(employee)}>{employee.first_name}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setEmployeeToEdit(employee)}>{employee.last_name}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setEmployeeToEdit(employee)}>{employee.alias}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setEmployeeToEdit(employee)}>{company}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setEmployeeToEdit(employee)}>{employee.phone_number}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setEmployeeToEdit(employee)}>{employee.email}</div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setEmployeeToEdit(employee)}>{employee.qb_id}</div>
                        <div className={`grid-table-row-data ${position} far-right`} onClick={(e) => setEmployeeToEdit(employee)}>{employee.crews}</div>
                    </div>
                )
            })}
            {errorFirstNameMissing && <Popup color={PopupFailureColor} message={'Please add a first name.'} handlePopup={resetErrorPopups}/>}
            {errorLastNameMissing && <Popup color={PopupFailureColor} message={'Please add a last name.'} handlePopup={resetErrorPopups}/>}
            {errorDuplicateName && <Popup color={PopupFailureColor} message={'Name already exists. Please add a unique first and last name.'} handlePopup={resetErrorPopups}/>}
            {errorDuplicateQuickBooksId && <Popup color={PopupFailureColor} message={'QuickBooks ID already exists. Please add a unique QuickBooks ID.'} handlePopup={resetErrorPopups}/>}
            {errorQuickBooksIdMissing && <Popup color={PopupFailureColor} message={'Please add a QuickBooks ID.'} handlePopup={resetErrorPopups}/>}
            {errorCompanyNotSelected && <Popup color={PopupFailureColor} message={'Please select company this crew member works for.'} handlePopup={resetErrorPopups}/>}
            {errorNoSubContractorCompany && <Popup color={PopupFailureColor} message={'Please enter the sub contractors name'} handlePopup={resetErrorPopups}/>}
            {errorSubContractorNotChecked && <Popup color={PopupFailureColor} message={`You have entered a sub contractor's name but have not check the sub contractor's check box. Please check the sub contractor box.`} handlePopup={resetErrorPopups}/>}
            {errorSubContractorWorkNotDefined && <Popup color={PopupFailureColor} message={`Please check the type of work the sub contractor performs. (Concrete/Plumbing/Framing).`} handlePopup={resetErrorPopups}/>}
            {errorOnCrewCantDelete && <Popup color={PopupFailureColor} message={`${employeeToEdit.first_name} ${employeeToEdit.last_name} is on the crew and cannot be deleted. Please remove ${employeeToEdit.first_name} ${employeeToEdit.last_name} from all crews before deleting.`} handlePopup={resetErrorPopups}/>}
            {employeeToDelete && <PopupOk color={PopupOkWarningColor} message={`You are deleting ${employeeToDelete.first_name} ${employeeToDelete.last_name} from the database. This action is NOT reversable. Do you wish to continue?`} handlePopup={deleteEmployee}/>}
            {newEmployeeSuccess && <Popup color={PopupSuccessColor} message={'The new employee has been saved to the database'} handlePopup={resetSuccessPopups}/>}
            {editEmployeeSuccess && <Popup color={PopupSuccessColor} message={'The employee has been updated and saved to the database'} handlePopup={resetSuccessPopups}/>}
            {deleteEmployeeSuccess && <Popup color={PopupSuccessColor} message={'The employee has been deleted from the database'} handlePopup={resetSuccessPopups}/>}
        </EmployeesPageContainer>
    );
}

export default EmployeesPage;