import { useState } from 'react';
import md5 from "md5";

import Auth from '../authentication/Auth.js';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import { Button } from '../utilities/Button.js';
import Popup, {PopupFailureColor, PopupSuccessColor, PopupWarningColor} from '../utilities/Popup.js';
import MainHeader from '../utilities/MainHeader.js';
import ChangePasswordContainer from './styles/ChangePasswordContainer.js';

const ChangePasswordUser = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [user, setUser] = useState([]);

    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        currentPasswordHash: '',
        newPassword: '',
        newPasswordConfirm: ''
    })

    const [errorIncorrectPassword, setErrorIncorrectPassword] = useState(false);
    const [errorNewPasswordsMismatch, setErrorNewPasswordsMismatch] = useState(false);
    const [errorNewPasswordLength, setErrorNewPasswordLength] = useState(false);
    const [errorNoPermissions, setErrorNoPermissions] = useState(false);
    const [success, setSuccess] = useState(false);

    const canChangePassword = props.isAuthorized('changePassword', 'WRITE');

    const loadData = async () => {
        setDidLoadData(true);

        setUser((await Auth.hitEndpoint("GET", "USER", null, null, `${await Auth.getUserId()}`))[0]);
    }

    if(!didLoadData){
        loadData();
    }

    const updatePassword = async () => {
        if(!canChangePassword){
            setErrorNoPermissions(true);
            return;
        }

        const tempPasswordData = {...passwordData};
        tempPasswordData.currentPasswordHash = md5(tempPasswordData.currentPassword);

        if(tempPasswordData.currentPasswordHash !== user.password){setErrorIncorrectPassword(true);return;}
        if(passwordData.newPassword !== passwordData.newPasswordConfirm){setErrorNewPasswordsMismatch(true);return;}
        if(passwordData.newPassword.length < 7){setErrorNewPasswordLength(true);return;}

        const tempUser = {...user};
        tempUser.password = md5(passwordData.newPassword);
        setUser({...tempUser});

        await Auth.hitEndpoint("PATCH", "USER", "", tempUser);
        setSuccess(true);
    }

    const toggleWarnings = (e) => {
        setErrorIncorrectPassword(false);
        setErrorNewPasswordsMismatch(false);
        setErrorNewPasswordLength(false);
        setErrorNoPermissions(false);
    }

    const handleSuccess = () => {
        setPasswordData({
            currentPassword: '',
            currentPasswordHash: '',
            newPassword: '',
            newPasswordConfirm: ''
        })
        setSuccess(false);
    }

    return (
        <ChangePasswordContainer>
            <MainHeader/>
            <div className='header'>Change Password</div>
            <div className='change-password-box'>
                <TextboxWithLabel className="current-password-textbox" name="current-password" label="Current Password" placeholder="Current Password" inputType="password"value={passwordData.currentPassword} handleTextbox={((e) => setPasswordData({...passwordData, currentPassword: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <TextboxWithLabel className="new-password-textbox" name="new-password" label="New Password" placeholder="New Password" inputType="password"value={passwordData.newPassword} handleTextbox={((e) => setPasswordData({...passwordData, newPassword: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <TextboxWithLabel className="new-password-confirm-textbox" name="new-password-confirm" label="Confirm Password" placeholder="Confirm Password" inputType="password"value={passwordData.newPasswordConfirm} handleTextbox={((e) => setPasswordData({...passwordData, newPasswordConfirm: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <Button className="update-password" handleKeyPress={((e) => {if(e.key === 'Enter'){updatePassword()}})} handleClick={((e) => updatePassword())} buttonText="Update Password" width="150"/>
            </div>
            {errorIncorrectPassword && <Popup color={PopupWarningColor} message={'Please enter your correct password'} handlePopup={toggleWarnings}/>}
            {errorNewPasswordLength && <Popup color={PopupWarningColor} message={'New password must have minimum of 6 characters'} handlePopup={toggleWarnings}/>}
            {errorNewPasswordsMismatch && <Popup color={PopupWarningColor} message={'New password does not match confirmation. Please make sure new password and confirm password are the same.'} handlePopup={toggleWarnings}/>}
            {errorNoPermissions && <Popup color={PopupFailureColor} message={'You do not permission to change this password. Please see you supervisor for assistance.'} handlePopup={toggleWarnings}/>}
            {success && <Popup color={PopupSuccessColor} message={`Your password has been changed.`} handlePopup={handleSuccess}/>}
        </ChangePasswordContainer>
    );
}

export default ChangePasswordUser;