import { useState, useMemo } from 'react';
import Auth from '../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {MaterialReactTable} from "material-react-table";
import Button from '@mui/material/Button';

const DeleteLot = ({displayMessageWithTimer, deleteLots, setDeleteLots, lotsToDelete, setLotsToDelete, updateLotsInLotsArray}) => {
    const [confirmDelete, setConfirmDelete] = useState(false);

    const closeWithDelete = async () => {
        for(let i = 0; i < lotsToDelete.length; i++){
            const dbObj = {
                id: lotsToDelete[i].id,
                active: false
            }
            await Auth.hitEndpointNew('PATCH', 'lot/no-processing', '', dbObj);

            lotsToDelete[i].active = false;
        }

        displayMessageWithTimer(`${lotsToDelete.length} Lot(s) Deleted. Refresh filters to remove lot(s) from table`, 'green', 5000);
        updateLotsInLotsArray(lotsToDelete);
        setLotsToDelete(null);
        setDeleteLots(false);
    }

    const closeNoDelete = () => {
        displayMessageWithTimer('No Lots Deleted', 'green', 5000);
        setLotsToDelete(null);
        setDeleteLots(false);
    }

    const columns = useMemo(() => [
        {
            accessorKey: 'job.builder.name',
            header: 'Builder',
            size: 150
        },
        {
            accessorKey: 'job.number',
            header: 'Job #',
            size: 100
        },
        {
            accessorKey: 'job.project_name',
            header: 'Project Name',
            size: 200
        },
        {
            accessorKey: 'number',
            header: 'Lot #',
            size: 100
        },
        {
            accessorKey: 'phase',
            header: 'Lot Phase',
            size: 150
        },
        {
            accessorKey: 'address',
            header: 'Address',
            size: 250
        },
    ], []);

    return (
        <Dialog open={deleteLots} maxWidth='xl'>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Lot(s) To Delete</DialogTitle>
            <DialogContent sx={{width: "1200px", maxWidth: 'xl'}}>
                <MaterialReactTable
                    columns={columns}
                    data={lotsToDelete}
                    enableSorting={false}
                    enableColumnActions={false}
                    enableTopToolbar={false}
                    enableBottomToolbar={false}
                    layoutMode='semantic'
                    muiTableHeadCellProps={() => {
                        return {
                            align: 'center',
                            sx: {
                                padding: '0',
                                height: '35px',
                                backgroundColor: 'rgb(255, 82, 82)',
                            }
                        }
                    }}
                    muiTableBodyCellProps={({row}) => {
                        return {
                            align: 'center',
                            sx: {
                                height: '30px',
                                padding: '0',
                                backgroundColor: row.index % 2 === 0 ? 'rgb(255, 186, 186)' : 'rgb(255, 123, 123)'
                            }
                        }
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return {
                            hover: false,
                        }
                    }}
                />
                {confirmDelete ?
                <Dialog open={confirmDelete}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 255, 150)', fontWeight: 'bold'}}>Confirm Delete Lot(s)</DialogTitle>
                    <DialogContent 
                        sx={{width: "600px", backgroundColor: 'rgb(255, 255, 150)'}}>Please Confirm Deleting Lot(s)</DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 255, 150)'}}>
                    <Button variant='contained' onClick={closeWithDelete}>Confirm</Button>
                    <Button variant='contained' onClick={closeNoDelete}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                : null}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => setConfirmDelete(true)}>Delete</Button>
                <Button variant='contained' onClick={closeNoDelete}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteLot;
