import styled from 'styled-components';
import Config from './config'

const AppContainer = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    font-family: Arial;
    font-size: 15px;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    // -webkit-touch-callout: none;
    // -webkit-user-select: none;
    // -khtml-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
    background-color: ${Config.BUILD_TYPE() === 'DEV' ? 'lightgrey' : ''}
`

export default AppContainer;