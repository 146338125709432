import styled from 'styled-components';

const CrewContainer = styled.div`
    .crew-filter-select{
        float: left;
        width: 44%;
    }
    .employee-filter-select{
        float: left;
        width: 44%;
    }
    .select-employee-button{
        float: left;
        width: 2%;
    }
    
    td {
        padding: 3px;
    }
    
    tr:nth-child(even) {
        background-color: #4C8BF5;
       
    }
`;

export default CrewContainer;