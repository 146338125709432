import {useEffect, useRef, useState} from 'react';
import { LoadingMessage } from '../../utilities/LoadingMessage';
import ReporterContainer from '../styles/ReporterContainer';

import dayjs from "dayjs";

const ScheduleReport = ({pdfUrl, data, date, isLoading}) => {

    const container = useRef(0);
    const [width, setWidth] = useState(0);
    useEffect(() => {setWidth(container.current.clientWidth);}, []);

    return (
        <ReporterContainer>
            {isLoading &&
                <LoadingMessage message="Creating your report, this may take several minutes..." width="600px"/>}
            {!isLoading &&
                <div>
                    <iframe src={pdfUrl} width="100%" height="1000px" />
                </div>}
        </ReporterContainer>
    );
}

export default ScheduleReport;
