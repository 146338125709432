import {List, ListItem} from "@mui/material";
import Schedules from "../../../classes/Schedules";
import SchedulePDF from "../../../classes/reports/SchedulePDF";


const Sidebar = ({reports, setTitle, selectedDate, selectedCrewIds, selectedEmployeeIds, selectedReports, setSelectedReports,
                     setReportData, userId, position, tasks, setTasks,
                     pdfUrl, setPdfUrl, isLoading, setIsLoading}) => {

    const loadSchedule = async (date, crews, employees, reportIds) => {
        setIsLoading(true)
        let report
        if(reportIds === []) {
            report = await Schedules.loadScheduleReportData(position, userId, date, [], crews, employees, null)
        }
        else{
            report = await Schedules.loadScheduleReportData(position, userId, date, [], crews, employees, reportIds)
        }
        setReportData(report)
        setTasks(report.tasks)
        setPdfUrl(await SchedulePDF.createReport(date, report))
        setIsLoading(false)
    }

    const handleReportChange = async (selection) => {
        const selectedReport = selectedReports.find(x => x.id.toString() === selection.target.id.toString())

        const report = reports.find(x => x.id.toString() === selection.target.id.toString())
        const reportId = report !== undefined ? report.id : -1
        const localReports = [...selectedReports]

        if(selectedReport !== undefined){
            const idx = localReports.findIndex(x => x.id == selectedReport.id)
            localReports.splice(idx, 1)
            setTitle('')
        }
        else {
            localReports.push(report)
            setTitle(report.display)
        }

        let reportIds = []
        setSelectedReports(localReports)
        for(let r=0; r<localReports.length; r++){
            reportIds.push(localReports[r].id)
        }

        await loadSchedule(selectedDate, selectedCrewIds, selectedEmployeeIds, reportIds)
    }

    return (
        <List style={{height: '99%', width: '90%', backgroundColor: 'white', marginLeft: '10px', }}>
            {reports.map((r, index) => {
                return (
                    <ListItem style={{cursor: 'pointer', backgroundColor: selectedReports.length > 0 && selectedReports.find(x => x.id === r.id) !== undefined ? 'yellow' : ''}} key={r.id} onClick={(event) => handleReportChange(event, index)} id={r.id} display={r.display}>{r.display}</ListItem>
                )
            })}
        </List>
    )
}

export default Sidebar;
