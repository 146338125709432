import styled from 'styled-components';

const MaintenanceScreenContainer = styled.div`
    display: grid;

    .logo{
        display: grid;
        justify-self: center;
        margin: 20px auto;
    }
    .maintenance-message{
        display: grid;
        justify-self: center;
        Max-width: 800px;
        margin: 20px 50px;
        text-align: center;
        font-size: 20px;
        line-height: 1.25em;
    }

    .login{
        display: grid;
        margin: 50px auto;
        width: 600px;
        border: 3px solid black;
        border-radius: 10px;
    
        .login-header{
            margin: 20px;
            text-align: center;
            font-size: 25px;
            font-weight: bold;
        }
    }
`;

export default MaintenanceScreenContainer;