import styled from 'styled-components';

const PageContainer = styled.div`
    display: grid;
    margin: 10px 0 75px 10px;

    .sidebar{
        background-color: #eeeeee;
    }
    .column {
        float: left;
        width: 50%;
    }
    .column-available-lots {
        float: left;
        height: 100%;
        width: 85%;
        font-weight: bolder;
    }
    .column-available-lot-buttons {
        float: left;
        width: 15%;
        margin-top: 100px;
        font-weight: bolder:
    }
    .row:after {
        content: "";
        display: table;
        clear: both;
    }

    fieldset {
        background-color: #eeeeee;
        padding: 10px 5px;
        margin-bottom: 40px;
    }
    legend {
      background-color: gray;
      color: white;
      padding: 5px 10px;
    }
    .fs-available-lots{
        height: 51vh !important;
        margin: 0 !important;
    }
    .fieldset-50 {
        float: left;
        min-height: 85px;
        width: 50% !important;
        background-color: #eeeeee;
        padding: 10px 5px;
        margin-bottom: 40px;
    }
    .available-lots-search{
        background-color: white;
        margin-top: "15px";
        width: 75%;
        padding: 5px;
    }
    .fs-schedule-table{
        overflow: auto;
        height: 100% !important;
    }
    /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      width: 20%;
      height: 34px;
      margin-top: 10px;
      margin-left: 10px;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: #00FF00;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(44px);
      -ms-transform: translateX(44px);
      transform: translateX(44px);
    }

    .ewa-task-label{
        display: grid;
        font-size: xx-small;
        text-align: right;
        margin-top: 9px;
    }
    .hide-lots-label{
        display: grid;
        font-size: xx-small;
        text-align: right;
        margin-top: -9px;
    }
    .hide-lots-label.hide{
        width: 95%;
    }
     .hide-lots-label.show{
        width: 90%;
    }

    .available-lots-select{
        width: 100%;
        height: 82%;
        padding-top: 5px;
    }
     .notes{
        width: 100%;
        height: 82%;
        padding-top: 5px;
        background-color: white;
    }
    .schedule-date-picker{
        margin: 19px 0 19px 0;
    }

    .schedule-grid{
        height: 100%;
    }

    .schedule-grid-item{
        height: 100%;
        width: 40%;
    }
    .schedule-grid-select{
        height: 92% !important;
        width: 100%;
    }
    .schedule-toolbar{
        width: 100%;
        background-color: rgb(238, 238, 238);
        height: 40px;
        vertical-align: middle;
        text-align: center;
        color: green;
        font-weight: bold;
        padding: 10px;
    }
    .schedule-toolbar-text{
        width: 55%;
        vertical-align: middle;
        text-align: center;
        font-weight: bold;
    }
    .list-container {
        height: 370px;
        background-color: white;
        display: flex;
        flex-direction: column;
        // gap: 10px;
        overflow: auto;
        cursor: pointer;
        font-weight: normal;
        font-size: small;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .checked-item,
    .not-checked-item {
        margin-left: 10px;
    }
    .selected {
        background: skyblue;
    }
    .checked-item {
        text-decoration: line-through;
    }

    .legend-tab{
        display: inline-grid;
        gap: 10px;
        width: 200px;
        height: 30px;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 5px;
        padding-top: 6px;
        cursor: pointer;
        color: whitesmoke;
        text-align: center;
    }

    ,legend-label{

    }

    .legend-tab .active{
        background-color: green;
    }
`;

export default PageContainer;
