import { useState, useMemo } from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { MaterialReactTable } from "material-react-table";
import TextField from "@mui/material/TextField";
import dayjs from 'dayjs';
import Auth from '../authentication/Auth';
import Can from '../authentication/Can';

const LotNotes = ({openLotNotes, setOpenLotNotes, lotToViewNotes, setLotToViewNotes, noteType, updatedLotReturned}) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [notes, setNotes] = useState([]);
    const [newNote, setNewNote] = useState('');

    const canCreateNote = Can.isAuthorized('lotNotes', 'WRITE');

    const noteColumns = useMemo(() => [
        {
            accessorKey: 'date',
            header: 'Date',
            size: 100
        },
        {
            accessorKey: 'user',
            header: 'User',
            size: 100
        },
        {
            accessorKey: 'type',
            header: 'Phase',
            size: 100
        },
        {
            accessorKey: 'note',
            header: 'Note',
            size: 250
        },
    ], []);

    const loadData = async () => {
        setDidLoadData(true);

        const tempNotes = [];
        if(lotToViewNotes.notes.length > 0){
            const splitNotes = lotToViewNotes.notes.split('@').filter(note => note !== '');
            for(let i = 0; i < splitNotes.length; i++){
                const note = splitNotes[i].split('^');
                const noteObj = {
                    date: dayjs(new Date(note[0])).format('MM-DD-YYYY'),
                    user: note[1],
                    type: note[2],
                    note: note[3]
                }
                tempNotes.push(noteObj);
            }
        }
        setNotes(tempNotes);
    }

    if(!didLoadData){
        loadData();
    }

    const closeWithSave = async () => {
        let notesToString = '';
        for(let i = 0; i < notes.length; i++){
            notesToString += `${notes[i].date}^${notes[i].user}^${notes[i].type}^${notes[i].note}@`;
        }
        const dbObj = {
            id: lotToViewNotes.id,
            notes: notesToString
        }
        await Auth.hitEndpointNew('PATCH', `lot/no-processing`, '', dbObj);
        
        lotToViewNotes.notes = notesToString;
        updatedLotReturned(lotToViewNotes);
        setOpenLotNotes(false);
    }

    const addNote = async () => {
        if(newNote === ''){
            return;
        }
        const tempNotes = [...notes];
        const tempNewNote = {
            date: dayjs(new Date()).format('MM-DD-YYYY'),
            user: await Auth.getFullName(),
            type: noteType,
            note: newNote,
        }
        tempNotes.push(tempNewNote);
        setNotes(tempNotes);
        setNewNote('');
    }

    const closeNoSave = () => {
        setOpenLotNotes(false);
        setLotToViewNotes(null);
    }

    return (
        <Dialog open={openLotNotes} fullWidth={true} maxWidth={'md'}>
            <DialogTitle style={{borderBottom: "2px solid black", marginBottom: '20px'}}>Lot Notes - Lot {lotToViewNotes.number}</DialogTitle>
            <DialogContent sx={{minWidth: '600px'}}>
                <MaterialReactTable
                    columns={noteColumns}
                    data={notes}
                    enableSorting={false}
                    enableColumnActions={false}
                    enableTopToolbar={false}
                    enableBottomToolbar={false}
                    layoutMode='semantic'
                    muiTableHeadCellProps={() => {
                        return {
                            align: 'center',
                            sx: {
                                padding: '0',
                                height: '35px',
                                backgroundColor: 'rgb(180, 180, 180)',
                            }
                        }
                    }}
                    muiTableBodyCellProps={({row}) => {
                        return {
                            align: 'center',
                            sx: {
                                height: '30px',
                                padding: '0',
                                backgroundColor: row.index % 2 === 0 ? 'rgb(200, 200, 200)' : 'rgb(230, 230, 230)'
                            }
                        }
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return {
                            hover: false,
                        }
                    }}
                />
                {canCreateNote &&
                <Grid
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '80% 20%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10px'
                    }}
                >
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '10px auto',
                        }}
                        placeholder="Enter Note"
                        value={newNote}
                        onChange={(e) => setNewNote(e.target.value)}
                    />
                    <Button 
                        variant='outlined' 
                        onClick={addNote}
                        sx={{
                            justifySelf: 'center',
                            width: '80%'
                        }}
                    >Add Note</Button>
                </Grid>}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={closeWithSave}>Save</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default LotNotes;