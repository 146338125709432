import { NavLink } from 'react-router-dom'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import auth from "../authentication/Auth";
import {useNavigate} from "react-router";
import './styles/navlink.css';

const SingleNavLink = ({label, route, icon, children, className, color, ...rest}) => {

    const navigate = useNavigate();

    const addedClass = "normal-style nav-navlink " + className

    const handleLogOut = (e) => {
        e.preventDefault()
        auth.logout();
        navigate('/login');
    }

    return (
        <NavLink
            // exact
            {...rest}
            to={route}
            className={addedClass}
            onClick={ label ==='Logout' ? handleLogOut : ''}
        >
            {icon != null && <FontAwesomeIcon icon={icon} style={{fontSize: '1.75em'}}/>}
            <span className={'label-style'} style={{marginTop: "5px"}}>{label}</span>
            {
                children ?
                    <div className="nav-submenu">
                        {children}
                    </div>
                    : ""
            }

        </NavLink>
    )
}

export default SingleNavLink
