
class Config {

    //Comment out or uncomment before build and deployment
    static BASE_API() {
        let url = process.env.REACT_APP_ENV === 'development' ? 'http://localhost:8000/api/v1' : process.env.REACT_APP_ENV === 'test' ? 'https://devapi.svcts.com/api/v1' : 'https://api.svcts.com/api/v1'
        return url
    }

    static BASE_UI() {
        let url = process.env.REACT_APP_ENV === 'development' ? 'http://localhost:3000' : process.env.REACT_APP_ENV === 'test' ?  'https://dev.svcts.com' : 'https://app.svcts.com'
        return url
    }

    static BUILD_TYPE() {
        let buildType = process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'test' ? 'DEV' : 'PROD'
        return buildType
    }

    static S3_INFO() {
        return {
            accessKeyId: "AKIAQN4PA7K7OGRZBFFV",
            secretAccessKey: "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",
        }
    }
}
export default Config;