
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ListItem} from "@mui/material";
import './styles/navlink.css'

const MainNavLink = ({ label, route, icon, children, className, ...rest}) => {


  const addedClass = "nav-navlink " + className

  return (
      <ListItem
         // exact
          {...rest}
          to={route}
          className={addedClass}
      >

        {
          label === "SVC?" ?
              <img width="30" src="" alt=""/>
              : ""
        }
        {icon != null &&
          <FontAwesomeIcon icon={icon} style={{fontSize: '1.75em'}}/>
        }
        <span className={"label-style"}>{label}</span>
        {
          children ?
              <div className="nav-submenu">
                {children}
              </div>
              : ""
        }

      </ListItem>
  )
}

export default MainNavLink
