import styled from 'styled-components';

const ReportsContainer = styled.div`
    display: grid;
    margin-top: 10px;

    .reports, .date-selector-box, .user-login-selector-box {
        display: grid;
        width: 600px;
        margin-left: 10px;
        border-bottom: 15px solid white;
        background: rgb(210, 200, 190);
    }

    .reports-header, .date-header, .user-login-header {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        color: white;
        border-bottom: 2px solid white;
    }

    .reports-selector {
        display: grid;
        grid-template-columns: 1fr; // Add here for additional reports
        justifiy-content: center;
        padding: 10px 0;
    }

    // Add here for additional reports
    .starts-report,
    .wip-cycle-time-report,
    .work-projection-report,
    .user-login-report{ grid-column: 1/2; }

    .submit {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        align-items: center;
        margin: 10px 0;
    }

    .button {
        justify-self: center;
        width: 240px;
        height: 40px;
        background: rgb(240, 240, 240);
        border: 1px solid rgb(120, 120, 120);
        border-radius: 3px;
        display: grid;
        justify-content: center;
        align-items: center;
        cursor: default;

        :hover {
            background: rgb(230, 230, 230);
        }
    }

    .date-selector {
        display: grid;
        grid-template-columns: 1fr;
        padding: 10px;

        .work-projection-start-date, .work-projection-end-date{
            grid-column: 1/2;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 5px 0;
        }

        .work-projection-date-picker-label{
            justify-self: right;
            align-self: center;
            padding-right: 5px;
        }
    }

    .user-login-selector {
        display: grid;
        grid-template-columns: 1fr;
        padding: 10px;

        .user-login-date-picker-label, .user-login-user-label{
            justify-self: right;
            align-self: center;
            padding-right: 5px;
        }

        .user-login-start-date, .user-login-end-date, .user-login-user{
            grid-column: 1/2;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 5px 0;
        }

        .user-login-user-drop-down-select{
            width: 250px;
        }
    }

    .submit.work-projection-dates {
        grid-column: 1/2;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        align-items: center;
        margin: 10px 0;
        
        .button {
            justify-self: center;
            width: 240px;
            height: 40px;
            background: rgb(240, 240, 240);
            border: 1px solid rgb(120, 120, 120);
            border-radius: 3px;
            display: grid;
            justify-content: center;
            align-items: center;
            cursor: default;
            
            :hover {
                background: rgb(230, 230, 230);
            }
        }
    }
    
    .loading-message-box{
        width: 900px;
        height: 200px;
        display: grid;
        grid-template-rows: repeat(10, 1fr);
        align-items: center;
        text-align: center;
        font-size: 30px;

        img{
            justify-self: center;
            grid-row: 2/7;
        }

        .loading-message {
            grid-row: 7/9;
        }
    }

    @media print{
        .hide-on-print{
            display: none;
        }
    }
`;

export default ReportsContainer;