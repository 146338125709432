import {useState} from 'react';

import Auth from "../../authentication/Auth.js";
import Schedules from "../../../classes/Schedules";
import Field from "../../../classes/Field";

import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

import Employees from "../../../classes/Employees";
import Crews from "../../../classes/Crews";
import dayjs from "dayjs";

import {Tab} from '@mui/material';
import {TabContext, TabList, TabPanel} from "@mui/lab";
import UpdatesGrid from "./UpdatesGrid";

// import {Lock} from "@mui/icons-material";
import LockList from "../../scheduling/LockList";
import MainHeader from "../../utilities/MainHeader";
import ScheduleUpdateContainer from "../styles/ScheduleUpdateContainer";
import Select, {createFilter} from "react-select";
import PhaseTasks from "../../../classes/PhaseTasks";
import Phases from "../../../classes/Phases";
import Utilities from "../../../classes/Utilities";
import Config from "../../../config";

const Updates = () => {

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [schedule, setSchedule] = useState([])

    const [locks, setLocks] = useState([])
    const [isLocked, setIsLocked] = useState(false)
    const [didLoadData, setDidLoadData] = useState(false)
    const [selectedTab, setSelectedTab] = useState("0")

    const [rowSelection, setRowSelection] = useState([]);
    const [selectedRows, setSelectedRows] = useState([])

    const [phases, setPhases] = useState([])
    const [phaseTasks, setPhaseTasks] = useState([])
    const [suppliers, setSuppliers] = useState([])

    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [employees, setEmployees] = useState([])
    const [crews, setCrews] = useState([])
    const [selectedEmployeesReassign, setSelectedEmployeesReassign] = useState([])
    const [empsToRemove, setEmpsToRemove] = useState([])


    const [userId, setUserId] = useState(null)
    const [position, setPosition] = useState(null)
    const [isLoading, setIsLoading] = useState(false);

    const [saveResponse, setSaveResponse] = useState('')

    const [supers, setSupers] = useState([])
    const [selectedSuper, setSelectedSuper] = useState(null)
    const [selectedSuperId, setSelectedSuperId] = useState(null)

    const loadData = async () => {
        if(Utilities.isLoggedOut()){
            const uri = Config.BASE_UI();
            window.location.replace(uri);
        }
        else {
            setIsLoading(true)
            setDidLoadData(true);
            const user = await Auth.getUserId()
            setUserId(user)
            const pos = await Auth.getPosition()
            setPosition(pos)
            let queryObj = {};
            if (pos !== null && pos.name === 'super') {
                queryObj['job_assignment.user_id'] = userId
            }

            setSupers(await Schedules.loadSupers(null))

            setEmployees(await Employees.loadEmployeesForDropDown(null))
            setCrews(await Crews.loadCrewsForDropdown(null))

            setPhases(await Phases.loadPhases())
            setPhaseTasks(await PhaseTasks.loadPhaseTasks(null))
            setSuppliers(await Schedules.loadSuppliers(null))
            
          //  setSelectedDate(dayjs(new Date()).format('MM/DD/YYYY'))
            let dbLocks = await Schedules.loadLockDate(new Date())
            setLocks(dbLocks)
            //setIsLocked(await Schedules.checkLocked(dbLocks))
            setIsLocked(false)
            setIsLoading(false)
            setSchedule(await Field.loadSchedule(selectedDate, queryObj))
        }
    }

    if (!didLoadData) {
        loadData();
    }

    const handleDateChanged = async date => {
        setIsLoading(true)
        let queryObj = {};
        if (position !== null && position.name === 'super') {
            queryObj['job_assignment.user_id'] = userId
        } else if (selectedSuperId !== null && selectedSuperId !== '') {
            queryObj['job_assignment.user_id'] = selectedSuperId
        }
        setSelectedDate(date)

        let dbLocks = await Schedules.loadLockDate(date)
        setLocks(dbLocks)
        //setIsLocked(await Schedules.checkLocked(dbLocks))
        setSchedule(await Field.loadSchedule(dayjs(date).format('YYYY-MM-DD'), queryObj))
        setIsLoading(false)
    }

    const handleSuperChanged = async (e) => {
        setIsLoading(true)
        setSelectedSuperId(e === null ? null : e.value)
        setSelectedSuper(e === null ? null : supers.find(x => x.id.toString() === e.value.toString()))

        let queryObj = {};
        if (e !== null) {
            queryObj['job_assignment.user_id or schedule.requested_by'] = e.value
        }
        setSchedule(await Field.loadSchedule(dayjs(selectedDate).format('YYYY-MM-DD'), queryObj))
        setIsLoading(false)
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const [matchFromStart] = useState(true);
    const [ignoreCase] = useState(true);
    const [ignoreAccents] = useState(true);
    const [trim] = useState(true);

    const filterConfig = {
        ignoreCase,
        ignoreAccents,
        trim,
        matchFrom: matchFromStart ? ('start') : ('any'),
    };

    return (
        <ScheduleUpdateContainer>
            {window.location.pathname === '/schedule-updates' && <MainHeader/>}
            <span>{saveResponse}</span>
            <div className={'filter-box'}>
                <div className={'filter-date'}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(newValue) => {
                                handleDateChanged(newValue);
                            }}
                            slotProps={{
                                textField: {
                                    helperText: '',
                                    sx: {minWidth: '120px'},
                                    variant: 'standard',
                                },
                            }}
                            defaultValue={dayjs(selectedDate)}
                            selected={selectedDate}
                        />
                    </LocalizationProvider>
                </div>
                <div className={'filter-super'}>
                    <Select
                        id="super-filter-select-id"
                        label="Select A Superintendent"
                        required
                        onChange={(e) => handleSuperChanged(e)}
                        options={supers}
                        isClearable
                        isSearchable
                        value={selectedSuper}
                        filterOption={createFilter(filterConfig)}
                        placeholder={"Select a Superintendent"}
                    />
                </div>
                {locks.length > 0 && <div className={'lock-list'}><LockList locks={locks} setLocks={setLocks}/></div>}
            </div>
            <TabContext value={selectedTab}>
                <TabList onChange={handleTabChange}>
                    <Tab className={'tab-active'} label="Scheduled Tasks" value="0"/>
                    <Tab className={'tab-inactive'} label="Completed Tasks" value="1"/>
                </TabList>
                <TabPanel value="0">
                    <UpdatesGrid
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        userId={userId}
                        position={position}
                        selectedTab={selectedTab}
                        selectedDate={selectedDate}
                        setSchedule={setSchedule}
                        schedule={schedule}
                        employees={employees}
                        setEmployees={setEmployees}
                        selectedEmployees={selectedEmployees}
                        setSelectedEmployees={setSelectedEmployees}
                        selectedEmployeesReassign={selectedEmployeesReassign}
                        setSelectedEmployeesReassign={setSelectedEmployeesReassign}
                        empsToRemove={empsToRemove}
                        setEmpsToRemove={setEmpsToRemove}
                        crews={crews}
                        setCrews={setCrews}
                        setRowSelection={setRowSelection}
                        rowSelection={rowSelection}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        phases={phases}
                        phaseTasks={phaseTasks}
                        suppliers={suppliers}
                        setSaveResponse={setSaveResponse}
                        isLocked={isLocked}
                    />
                </TabPanel>
                <TabPanel value="1">
            <UpdatesGrid
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                userId={userId}
                position={position}
                selectedTab={selectedTab}
                selectedDate={selectedDate}
                setSchedule={setSchedule}
                schedule={schedule}
                employees={employees}
                setEmployees={setEmployees}
                selectedEmployees={selectedEmployees}
                setSelectedEmployees={setSelectedEmployees}
                selectedEmployeesReassign={selectedEmployeesReassign}
                setSelectedEmployeesReassign={setSelectedEmployeesReassign}
                empsToRemove={empsToRemove}
                setEmpsToRemove={setEmpsToRemove}
                crews={crews}
                setCrews={setCrews}
                setRowSelection={setRowSelection}
                rowSelection={rowSelection}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                phases={phases}
                phaseTasks={phaseTasks}
                suppliers={suppliers}
                setSaveResponse={setSaveResponse}
                isLocked={isLocked}
            />
                </TabPanel>
            </TabContext>
        </ScheduleUpdateContainer>
    )
}

export default Updates;
