import { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import Grid from "@mui/material/Grid";
import {MaterialReactTable} from "material-react-table";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AddchartIcon from '@mui/icons-material/Addchart';
import Can from '../authentication/Can';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import XLSX from 'xlsx';

const LotsTable = ({message, lots, setCreateLot, setLoadLotsFromXLSX, 
                    setEditLot, setLotToEdit, setDeleteLots, setLotsToDelete,
                    setLotReleasesLots, setOpenBlueStake, setLotBlueStake,
                    setOpenLotNotes, setLotToViewNotes, setLotNotesType}) => {
    const [bypassPagination, setBypassPagination] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 100,
        pageIndex: 0
    })                    
    const [rowSelection, setRowSelection] = useState({});

    const canEditLot = Can.isAuthorized('lots', 'WRITE');
    const canOpenBlueStake = Can.isAuthorized('blueStake', 'READ');
    const canEditLotReleases = Can.isAuthorized('lotReleases', 'WRITE');
    const canViewLotReleases = Can.isAuthorized('lotReleases', 'READ');

    const columns = useMemo(() => [
        {
            id: 'job_number',
            accessorKey: 'job.number',
            header: 'Job #',
            enableEditing: false,
            minSize: 100,
            maxSize: 150
        },
        {
            id: 'project_name',
            accessorKey: 'job.project_name',
            header: 'Project Name',
            enableEditing: false,
            minSize: 200,
            maxSize: 250
        },
        {
            id: 'lot_number',
            accessorKey: 'number',
            header: 'Lot #',
            enableEditing: false,
            minSize: 100,
            maxSize: 150
        },
        {
            id: 'lot_phase',
            accessorKey: 'phase',
            header: 'Lot Phase',
            enableEditing: false,
            minSize: 100,
            maxSize: 150,
            // muiTableBodyCellProps: ({cell, table}) => {
            //     return {
            //         sx: {
            //             backgroundColor: 'blue',
            //             zIndex: '1000000',
            //             opacity: '1'
            //         }
            //     }
            // }
        },
        {
            accessorKey: 'address',
            header: 'Address',
            enableEditing: false,
        },
        {
            accessorKey: 'plan_number',
            header: 'Plan',
            enableEditing: false,
        },
        {
            accessorKey: 'notes',
            header: 'Notes',
            enableEditing: false,
            Cell: ({renderedCellValue}) => {
                if(renderedCellValue){
                    return (
                        <Tooltip title="View/Edit Notes">
                            <TextSnippetIcon sx={{cursor: 'pointer'}}/>
                        </Tooltip>
                    )
                }
                return (
                    <Tooltip title="Create Notes">
                        <Box sx={{
                            width: '100%', 
                            height: '40px',
                            cursor: 'pointer'}}>
                        </Box>
                    </Tooltip>
                )
            }
        },
        {
            id: 'blue_stake',
            accessorFn: (row) => {
                if(row.bluestake.length > 0){
                    let tempDate = new Date(row.bluestake[0].date);
                    for(let i = 0; i < row.bluestake.length; i++){
                        if(tempDate < new Date(row.bluestake[i].date)){
                            tempDate = new Date(row.bluestake[i].date);
                        }
                    }
                    return dayjs(tempDate).format('MM-DD-YYYY');
                }
                return '';
            },
            header: 'Blue Stake',
            enableEditing: false,
        },
        {
            id: 'rfgt',
            accessorFn: (row) => GetPhaseColumnData(row, 6),
            header: 'RFGT',
            enableEditing: false,
        },
        {
            id: 'concrete_foundation',
            accessorFn: (row) => GetPhaseColumnData(row, 1),
            header: 'Concrete Foundation',
            enableEditing: false,
        },
        {
            id: 'plumbing_soil',
            accessorFn: (row) => GetPhaseColumnData(row, 3),
            header: 'Plumbing Soil',
            enableEditing: false,
        },
        {
            id: 'plumbing_top_out',
            accessorFn: (row) => GetPhaseColumnData(row, 4),
            header: 'Plumbing Top Out',
            enableEditing: false,
        },
        {
            id: 'masonry',
            accessorFn: (row) => GetPhaseColumnData(row, 7),
            header: 'Masonry',
            enableEditing: false,
        },
        {
            id: 'gates',
            accessorFn: (row) => GetPhaseColumnData(row, 9),
            header: 'Gates',
            enableEditing: false,
        },
        {
            id: 'concrete_driveway',
            accessorFn: (row) => GetPhaseColumnData(row, 2),
            header: 'Concrete Driveway',
            enableEditing: false,
        },
        {
            id: 'paver',
            accessorFn: (row) => GetPhaseColumnData(row, 8),
            header: 'Paver',
            enableEditing: false,
        },
        {
            id: 'framing',
            accessorFn: (row) => GetPhaseColumnData(row, 10),
            header: 'Framing',
            enableEditing: false,
        },
        {
            id: 'plumbing_trim',
            accessorFn: (row) => GetPhaseColumnData(row, 5),
            header: 'Plumbing Trim',
            enableEditing: false,
        },
    ], []);

    const GetPhaseColumnData = (lot, phaseId) => {
        const phase = lot.phases.find(p => parseInt(p.phase_id) === parseInt(phaseId));
        if(phase === undefined || phase.release_date === null){
            return "";
        }
        return phase.starts_status === 'Processed' ? 
                    dayjs(new Date(phase.release_date)).format('MM-DD-YYYY')
                    : `${phase.starts_status} ${dayjs(new Date(phase.release_date)).format('MM-DD-YYYY')}`;
    }

    const exportToXLSX = () => {
        const exportData = [];
        for(let i = 0; i < lots.length; i++){
            const rfgt = lots[i].phases.find(p => parseInt(p.phase_id) === 6);
            const concreteFoundation = lots[i].phases.find(p => parseInt(p.phase_id) === 1);
            const plumbingSoil = lots[i].phases.find(p => parseInt(p.phase_id) === 3);
            const plumbingTopOut = lots[i].phases.find(p => parseInt(p.phase_id) === 4);
            const masonry = lots[i].phases.find(p => parseInt(p.phase_id) === 7);
            const gates = lots[i].phases.find(p => parseInt(p.phase_id) === 9);
            const concreteDriveway = lots[i].phases.find(p => parseInt(p.phase_id) === 2);
            const paver = lots[i].phases.find(p => parseInt(p.phase_id) === 8);
            const framing = lots[i].phases.find(p => parseInt(p.phase_id) === 10);
            const plumbingTrim = lots[i].phases.find(p => parseInt(p.phase_id) === 5);

            exportData.push({
                "Is Active":                    lots[i].active === '1' ? 'X' : '',
                "Builder":                      lots[i].job.builder.name,
                "Job #":                        lots[i].job.number,
                "Project Name":                 lots[i].job.project_name,
                "Lot #":                        lots[i].number,
                "Phase ID":                     lots[i].phase,
                "Address":                      lots[i].address,
                "Plan ID":                      lots[i].plan_number,
                "RFGT Status":                  rfgt !== undefined ? rfgt.starts_status : '',
                "RFGT Date":                    rfgt !== undefined && rfgt !== null && rfgt.release_date !== null ? 
                                                    dayjs(new Date(rfgt.release_date)).format('MM-DD-YYYY') : '',
                "Concrete Foundation Status":   concreteFoundation !== undefined ? concreteFoundation.starts_status : '',
                "Concrete Foundation Date":     concreteFoundation !== undefined && concreteFoundation !== null && concreteFoundation.release_date !== null ? 
                                                    dayjs(new Date(concreteFoundation.release_date)).format('MM-DD-YYYY') : '',
                "Plumbing Soil Status":         plumbingSoil !== undefined ? plumbingSoil.starts_status : '',
                "Plumbing Soil Date":           plumbingSoil !== undefined && plumbingSoil !== null && plumbingSoil.release_date !== null ? 
                                                    dayjs(new Date(plumbingSoil.release_date)).format('MM-DD-YYYY') : '',
                "Plumbing Top Out Status":      plumbingTopOut !== undefined ? plumbingTopOut.starts_status : '',
                "Plumbing Top Out Date":        plumbingTopOut !== undefined && plumbingTopOut !== null && plumbingTopOut.release_date !== null ? 
                                                    dayjs(new Date(plumbingTopOut.release_date)).format('MM-DD-YYYY') : '',
                "Masonry Status":               masonry !== undefined ? masonry.starts_status : '',
                "Masonry Date":                 masonry !== undefined && masonry !== null && masonry.release_date !== null ? 
                                                    dayjs(new Date(masonry.release_date)).format('MM-DD-YYYY') : '',
                "Gates Status":                 gates !== undefined ? gates.starts_status : '',
                "Gates Date":                   gates !== undefined && gates !== null && gates.release_date !== null ? 
                                                    dayjs(new Date(gates.release_date)).format('MM-DD-YYYY') : '',
                "Concrete Driveway Status":     concreteDriveway !== undefined ? concreteDriveway.starts_status : '',
                "Concrete Driveway Date":       concreteDriveway !== undefined && concreteDriveway !== null && concreteDriveway.release_date !== null ? 
                                                    dayjs(new Date(concreteDriveway.release_date)).format('MM-DD-YYYY') : '',
                "Paver Status":                 paver !== undefined ? paver.starts_status : '',
                "Paver Date":                   paver !== undefined && paver !== null && paver.release_date !== null ? 
                                                    dayjs(new Date(paver.release_date)).format('MM-DD-YYYY') : '',
                "Framing Status":               framing !== undefined ? framing.starts_status : '',
                "Framing Date":                 framing !== undefined && framing !== null && framing.release_date !== null ? 
                                                    dayjs(new Date(framing.release_date)).format('MM-DD-YYYY') : '',
                "Plumbing Trim Status":         plumbingTrim !== undefined ? plumbingTrim.starts_status : '',
                "Plumbing Trim Date":           plumbingTrim !== undefined && plumbingTrim !== null && plumbingTrim.release_date !== null ? 
                                                    dayjs(new Date(plumbingTrim.release_date)).format('MM-DD-YYYY') : '',
            });
        }
        
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Lots');
        XLSX.writeFile(workbook, './Lots.xlsx');
    }

    return (
            <MaterialReactTable
                muiTableContainerProps={{ sx: { maxWidth: '1880px', maxHeight: '600px' } }}
                columns={columns}
                data={lots}
                layoutMode='grid-no-grow'
                muiTableBodyRowProps={({row, table}) => {
                    return {
                        hover: false,
                    }
                }}
                enableColumnFilters
                enableStickyHeader
                enableColumnPinning
                onPaginationChange={(paginationState) => {
                    if(!bypassPagination){
                        setPagination(paginationState);
                    }
                    setBypassPagination(false);
                }}
                initialState={{
                    pagination: pagination,
                    columnPinning: {left: ['mrt-row-actions', 'mrt-row-select', 'job_number', 'project_name', 'lot_number', 'lot_phase', 'address']}
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        size: 130,
                        grow: false,
                    },
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <Grid>
                            <Tooltip title="Open Create Lot">
                                <IconButton onClick={() => setCreateLot(true)}>
                                    <AddBoxIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Open Drag-n-Drop Lots">
                                <IconButton onClick={() => setLoadLotsFromXLSX(true)}>
                                    <LibraryAddIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Export To XLSX">
                                <IconButton onClick={() => exportToXLSX()}>
                                    <AddchartIcon />
                                </IconButton>
                            </Tooltip>
                            <Grid>
                                <span style={{fontWeight: 'bold'}}>Status: </span>
                                <span style={{fontWeight: 'bold', color: message.color}}>{message.message}</span>
                            </Grid>

                        </Grid>
                    );
                }}
                muiTableBodyCellProps={({ cell, row, table }) => {
                    let bgColor = row.original.active === '1' ? 'white' : 'rgb(255, 150, 150)';
                    switch(cell.column.id){
                        case 'rfgt':
                            bgColor = row.original.job.attributes.find(a => parseInt(a.attribute_id) === 7) === undefined ? 'rgb(50, 50, 50)' : bgColor;
                            break;
                        case 'concrete_foundation':
                            bgColor = row.original.job.attributes.find(a => parseInt(a.attribute_id) === 1) === undefined ? 'rgb(50, 50, 50)' : bgColor;
                            break;
                        case 'plumbing_soil':
                            bgColor = row.original.job.attributes.find(a => parseInt(a.attribute_id) === 2) === undefined ? 'rgb(50, 50, 50)' : bgColor;
                            break;
                        case 'plumbing_top_out':
                            bgColor = row.original.job.attributes.find(a => parseInt(a.attribute_id) === 3) === undefined ? 'rgb(50, 50, 50)' : bgColor;
                            break;
                        case 'masonry':
                            bgColor = row.original.job.attributes.find(a => parseInt(a.attribute_id) === 9) === undefined ? 'rgb(50, 50, 50)' : bgColor;
                            break;
                        case 'gates':
                            bgColor = row.original.job.attributes.find(a => parseInt(a.attribute_id) === 12) === undefined ? 'rgb(50, 50, 50)' : bgColor;
                            break;
                        case 'concrete_driveway':
                            bgColor = row.original.job.attributes.find(a => parseInt(a.attribute_id) === 1) === undefined ? 'rgb(50, 50, 50)' : bgColor;
                            break;
                        case 'paver':
                            bgColor = row.original.job.attributes.find(a => parseInt(a.attribute_id) === 10) === undefined ? 'rgb(50, 50, 50)' : bgColor;
                            break;
                        case 'framing':
                            bgColor = row.original.job.attributes.find(a => parseInt(a.attribute_id) === 13) === undefined ? 'rgb(50, 50, 50)' : bgColor;
                            break;
                        case 'plumbing_trim':  
                            bgColor = row.original.job.attributes.find(a => parseInt(a.attribute_id) === 3) === undefined ? 'rgb(50, 50, 50)' : bgColor;
                            break;
                        default: 
                            break;
                    }
                    return {
                        sx: {
                            backgroundColor: bgColor
                        },
                        onClick: () => {
                            setPagination(table.getState().pagination);
                            setBypassPagination(true);
                            if((canViewLotReleases || canEditLotReleases) && (cell.column.id === 'rfgt' || cell.column.id === 'concrete_foundation' || cell.column.id === 'plumbing_soil' 
                                || cell.column.id === 'plumbing_top_out' || cell.column.id === 'masonry' || cell.column.id === 'gates' 
                                || cell.column.id === 'concrete_driveway' || cell.column.id === 'paver' || cell.column.id === 'framing' 
                                || cell.column.id === 'plumbing_trim')){
                                const selectedRows = table.getSelectedRowModel().rows;
                                if(selectedRows.length > 0){
                                    setLotReleasesLots(selectedRows.map(r => r.original));
                                    setRowSelection({});
                                }
                                else{
                                    setLotReleasesLots([row.original]);
                                }
                            }

                            if(cell.column.id === 'notes'){
                                setOpenLotNotes(true);
                                setLotToViewNotes(row.original);
                                setLotNotesType('Lot');
                            }

                            if(canOpenBlueStake && cell.column.id === 'blue_stake'){
                                setOpenBlueStake(true);
                                setLotBlueStake(row.original);
                            }
                        }
                    }
                }}
                positionToolbarAlertBanner='hide'
                enableRowSelection={true}
                getRowId={(row) => row.id}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection, pagination}}
                enableRowActions={true}
                renderRowActions={({ row, table }) => {
                    return (
                        <Box sx={{ display: 'flex', gap: '1rem',}}>
                            {canEditLot &&
                            <Tooltip title="Edit">
                                <IconButton onClick={async () => {
                                    setEditLot(true);
                                    setLotToEdit(row.original);
                                }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>}
                            {canEditLot &&
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => {
                                    const selectedRows = table.getSelectedRowModel().rows;
                                    if(selectedRows.length > 0){
                                        setLotsToDelete(selectedRows.map(r => r.original));
                                        setDeleteLots(true);
                                        setRowSelection({});
                                    }
                                    else{
                                        setLotsToDelete([row.original]);
                                        setDeleteLots(true);
                                    }
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>}
                        </Box>
                    )
                }}
            />
    );
}

export default LotsTable;