import Auth from "../components/authentication/Auth";
import moment from "moment";
import dayjs from "dayjs";
import Employees from "./Employees";
import Joi from "joi"

class Emails {

    static async loadEmails(queryObj) {
        let data = [];
        let dbData;
        dbData = await Auth.hitEndpointNew("GET", "emails", queryObj ? `jsonString=${JSON.stringify(queryObj)}` : '');
        data = [...data, ...dbData];

        return data;
    }

    static async save(email) {
        email.start_date = email.start_date === null || email.start_date === '' ? null : dayjs(email.start_date).format('YYYY-MM-DD')
        email.end_date = email.end_date === undefined || email.end_date === null || email.end_date === '' ? null : dayjs(email.end_date).format('YYYY-MM-DD')
        email.created_at = email.created_at === null || email.created_at === '' ? null : dayjs(email.created_at).format('YYYY-MM-DD')

        if(email.id !== undefined){
            return await Auth.hitEndpointNew("PATCH", "email", '', email);
        }
        
        return await Auth.hitEndpointNew("POST", "email", '', email);
    }

    static async loadFrequencies(queryObj) {
        let data = [];
        let dbData;
        dbData = await Auth.hitEndpointNew("GET", "email/frequencies", queryObj ? `jsonString=${JSON.stringify(queryObj)}` : '');
        data = [...data, ...dbData];

        return data;
    }

    static validateEmail(email) {
        if(email.name === ""){
            return "NO_NAME";
        }
        if(email.subject === ""){
            return "NO_SUBJECT";
        }
        if(email.body === ""){
            return "NO_BODY";
        }
        if(email.reports.length === 0){
            return "NO_REPORT";
        }
        if(email.frequency === null){
            return "NO_FREQUENCY";
        }
        if(email.start_date === undefined || email.start_date === null){
            return "NO_START_DATE";
        }
        if(email.distribution.length === 0){
            return "NO_PERSONNEL";
        }
        if(email.subRows === undefined || email.subRows.length === 0){
            return "NO_SUBS";
        }
        for(let i = 0; i < email.distribution.length; i++){
            if(email.subRows[i].email === null || email.subRows[i].email === ""){
                return "NO_EMAIL";
            }
            if(!Joi.attempt(email.subRows[i].email, Joi.string().email({tlds: {allow: false}}).trim())){
                return "BAD_EMAIL"
            }
        }
        return "SUCCESS"
    }
}

export default Emails;