import styled from 'styled-components';

const ReporterContainer = styled.div`
    display: grid;
    margin-top: 10px;

    .reports, .date-selector-box, .user-login-selector-box {
        display: grid;
        width: 600px;
        margin-left: 10px;
        border-bottom: 15px solid white;
        background: rgb(210, 200, 190);
    }

    .reports-header, .date-header, .user-login-header {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        color: white;
        border-bottom: 2px solid white;
    }

    .reports-selector {
        display: grid;
        grid-template-columns: 1fr; // Add here for additional reports
        justifiy-content: center;
        padding: 10px 0;
    }

    // Add here for additional reports
    .starts-report,
    .wip-cycle-time-report,
    .work-projection-report,
    .user-login-report{ grid-column: 1/2; }

    

    .user-login-selector {
        display: grid;
        padding: 5px 0 10px 0;
    }

    
    .loading-message-box{
        width: 900px;
        height: 200px;
        display: grid;
        grid-template-rows: repeat(10, 1fr);
        align-items: center;
        text-align: center;
        font-size: 30px;

        img{
            justify-self: center;
            grid-row: 2/7;
        }

        .loading-message {
            grid-row: 7/9;
        }
    }

    @media print{
        .hide-on-print{
            display: none;
        }
    }
`;

export default ReporterContainer;