import {useState} from "react";
import Employees from "../../../classes/Employees";
import Select, {createFilter} from "react-select";
import Schedules from "../../../classes/Schedules";

const RoutingFilter = ({schedule, phases, crews, setCrews, routeSchedule, setRouteSchedule}) => {

    const [didLoadData, setDidLoadData] = useState(false)
    const [selectedCrew, setSelectedCrew] = useState('')
    const [selectedPhase, setSelectedPhase] = useState('')

    const handlePhaseChanged = async (e) => {
        if(e===null || e===''){
            setSelectedPhase('')
        }
        else {
            setSelectedPhase(e.value)
            setSelectedCrew('')
            setRouteSchedule([])
        }
        setCrews(await Schedules.getDistinctCrews(schedule, e))
    }

    const handleCrewChanged = async (e) => {
        if(e === null){
            setSelectedCrew('')
            setRouteSchedule([])
        }
        else {
            setSelectedCrew(e)
            setRouteSchedule(await filterSchedule(e))
        }
    }

    const filterSchedule = async (crew) => {
        let filteredSchedule = []
        filteredSchedule = schedule.filter(x => x.crew.crew_id === crew.value)
        return filteredSchedule
    }

    const [matchFromStart] = useState(true);
    const [ignoreCase] = useState(true);
    const [ignoreAccents] = useState(true);
    const [trim] = useState(true);

    const filterConfig = {
        ignoreCase,
        ignoreAccents,
        trim,
        matchFrom: ('any'),
    };

    return (
        <div className={"filters"} style={{marginTop: "0px", paddingBottom: "1px"}}>
            <Select id="phase-filter-select-id"
                    className="phase"
                    value={selectedPhase.value}
                    placeholder="Select A Phase"
                    onChange={(e) => handlePhaseChanged(e)}
                    options={phases.map((phase) => {
                        return ({
                            value: phase.id,
                            label: phase.name
                        })
                    })}
                    isClearable
                    isSearchable
                    filterOption={createFilter(filterConfig)}/>
            <Select id="crew-filter-select-id"
                    className="crew"
                    value={selectedCrew}
                    placeholder="Select A Crew"
                    onChange={(e) => handleCrewChanged(e)}
                    options={crews}
                    isClearable
                    isSearchable
                    filterOption={createFilter(filterConfig)}/>
        </div>
    )
}

export default RoutingFilter;