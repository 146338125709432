import { useState, useMemo } from 'react';
import Auth from '../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { MaterialReactTable } from "material-react-table";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Can from '../authentication/Can.js';
import dayjs from 'dayjs';

const BlueStake = ({openBlueStake, setOpenBlueStake, lot, setLot, updatedLotReturned}) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [users, setUsers] = useState([]);

    const [blueStake, setBlueStake] = useState([]);
    const [newBlueStake, setNewBlueStake] = useState({
        ticket: '',
        comment: ''
    });

    const [warningNoTicketNumber, setWarningNoTicketNumber] = useState(false);

    const [pagePermissions, setPagePermissions] = useState({
        createBlueStake: false,
    })

    const blueStakeColumns = useMemo(() => [
        {
            accessorFn: (row) => row.date !== '' ? dayjs(row.date).format('MM-DD-YYYY') : '',
            header: 'Date',
            size: 100
        },
        {
            accessorFn: (row) => row.user !== undefined ? `${row.user.firstname} ${row.user.lastname}` : '',
            header: 'User',
            size: 100
        },
        {
            accessorKey: 'ticket',
            header: 'Ticket #',
            size: 100
        },
        {
            accessorKey: 'comment',
            header: 'Comment',
            size: 250
        },
    ], []);

    const loadData = async () => {
        setDidLoadData(true);

        const tempPagePermissions = {
            createBlueStake: await Can.isAuthorized('blueStake', 'WRITE')
        };
        setPagePermissions(tempPagePermissions);
        

        const tempUsers = await Auth.hitEndpointNew('GET', 'users');
        setUsers([...tempUsers]);

        setBlueStake(lot.bluestake.map(b => {
            return {
                ...b,
                date: b.date ? new Date(b.date) : '',
                user: tempUsers.find(u => parseInt(u.id) === parseInt(b.user_id)),
            }
        }).sort((b1, b2) => b1.date < b2.date ? -1 : 1));
    }

    if(!didLoadData){
        loadData();
    }

    const addBlueStake = async () => {
        if(newBlueStake.ticket === ''){
            setWarningNoTicketNumber(true);
            return;
        }
        const tempUserId = await Auth.getUserId();
        newBlueStake.lot_id = lot.id;
        newBlueStake.closed = 1;
        newBlueStake.date = new Date();
        newBlueStake.user_id = tempUserId
        newBlueStake.user = users.find(u => parseInt(u.id) === parseInt(tempUserId));
        
        blueStake.push({...newBlueStake});
        blueStake.sort((b1, b2) => b1.date < b2.date ? -1 : 1);
        setBlueStake([...blueStake]);
        
        setWarningNoTicketNumber(false);
        setNewBlueStake({
            ticket: '',
            comment: ''
        })
    }

    const closeWithSave = async () => {
        const dbObj = {
            id: lot.id,
            bluestake: blueStake.map(b => {
                b.date = dayjs(b.date).format('YYYY-MM-DD H:mm:ss');
                delete b.user;
                return b;
            })
        }

        await Auth.hitEndpointNew("PATCH", "lot/no-processing", "", dbObj);

        const tempLot = (await Auth.hitEndpointNew('GET', `lots/index-new`, `queryString=lot.id=${lot.id}`))[0];
        updatedLotReturned(tempLot);
        setOpenBlueStake(false);
        setLot(null);
    }

    const closeNoSave = () => {
        setOpenBlueStake(false);
        setLot(null);
    }

    // if(!lot){
    //     return <div></div>
    // }

    return (
        <Dialog open={openBlueStake} fullWidth={true} maxWidth={'md'}>
            <DialogTitle style={{borderBottom: "2px solid black", marginBottom: '20px'}}>Blue Stake - Lot {lot.number}</DialogTitle>
            <DialogContent sx={{minWidth: '600px'}}>
                <MaterialReactTable
                    columns={blueStakeColumns}
                    data={blueStake}
                    enableSorting={false}
                    enableColumnActions={false}
                    enableTopToolbar={false}
                    enableBottomToolbar={false}
                    layoutMode='semantic'
                    muiTableHeadCellProps={() => {
                        return {
                            align: 'center',
                            sx: {
                                padding: '0',
                                height: '35px',
                                backgroundColor: 'rgb(180, 180, 180)',
                            }
                        }
                    }}
                    muiTableBodyCellProps={({row}) => {
                        return {
                            align: 'center',
                            sx: {
                                height: '30px',
                                padding: '0',
                                backgroundColor: row.index % 2 === 0 ? 'rgb(200, 200, 200)' : 'rgb(230, 230, 230)'
                            }
                        }
                    }}
                    muiTableBodyRowProps={({row}) => {
                        return {
                            hover: false,
                        }
                    }}
                />
                {pagePermissions.createBlueStake &&
                <Grid
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '35% 35% 30%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10px'
                    }}
                >
                    <TextField
                        sx={{
                            width: '95%',
                            margin: '10px auto',
                            backgroundColor: warningNoTicketNumber ? 'rgb(255, 150, 150)' : 'white',
                        }}
                        placeholder="Enter Ticket #"
                        value={newBlueStake.ticket}
                        onChange={(e) => setNewBlueStake({...newBlueStake, ticket: e.target.value})}
                    />
                    <TextField
                        sx={{
                            width: '95%',
                            margin: '10px auto',
                        }}
                        placeholder="Enter Comment"
                        value={newBlueStake.comment}
                        onChange={(e) => setNewBlueStake({...newBlueStake, comment: e.target.value})}
                    />
                    <Button 
                        variant='outlined' 
                        onClick={addBlueStake}
                        sx={{
                            justifySelf: 'center',
                            width: '80%'
                        }}
                    >Add BlueStake</Button>
                </Grid>}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={closeWithSave}>Save</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default BlueStake;