import { useState } from 'react';
import Auth from '../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const DeleteBuilder = ({displayMessageWithTimer, updateBuildersArray, 
                        deleteBuilder, setDeleteBuilder, builderToDelete, setBuilderToDelete}) => {

    const [confirmDelete, setConfirmDelete] = useState(false);

    const closeWithDelete = async () => {
        const dbObj = {
            id: builderToDelete.id,
            active: false
        }
        await Auth.hitEndpointNew('PATCH', 'builders', '', dbObj);

        await updateBuildersArray();
        displayMessageWithTimer('Builder Deleted', 'green', 5000);
        setBuilderToDelete(null);
        setDeleteBuilder(false);
    }

    const closeNoDelete = () => {
        displayMessageWithTimer('No Builder Deleted', 'red', 5000);
        setBuilderToDelete(null);
        setDeleteBuilder(false);
    }

    return (
        <Dialog open={deleteBuilder}>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Builder To Delete</DialogTitle>
            <DialogContent sx={{width: "600px"}}>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Builder:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {builderToDelete.name}
                    </Box>
                </Box>
                {confirmDelete ?
                <Dialog open={confirmDelete}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 255, 150)', fontWeight: 'bold'}}>Confirm Delete Builder</DialogTitle>
                    <DialogContent sx={{width: "600px", backgroundColor: 'rgb(255, 255, 150)'}}>
                            Please Confirm Deleting Builder
                    </DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 255, 150)'}}>
                        <Button variant='contained' onClick={closeWithDelete}>Confirm</Button>
                        <Button variant='contained' onClick={closeNoDelete}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                : null}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => setConfirmDelete(true)}>Delete</Button>
                <Button variant='contained' onClick={closeNoDelete}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteBuilder;