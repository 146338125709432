import styled from 'styled-components';

const LoginContainer = styled.div`
    width: 400px;
    height: 600px;
    margin: 150px auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(30, 1fr);

    border: 5px solid rgb(58, 64, 67);
    border-radius: 5px;

    .login-logo {
        grid-row: 1/13;
        align-self: center;
        justify-self: center;

        img {
            width: 295px;
            height: 150px;
        }
    }

    .login-header {
        grid-row: 13/16;
        display: grid;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        background: rgb(128, 128, 128);
        color: white;
    }

    #login {
        grid-row: 16/31;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, 1fr);
        align-items: center;
        background: rgb(220, 220, 220);
    }

    .username-textbox {
        grid-row: 2/3;
    }

    .password-textbox {
        grid-row: 3/4;
    }

    .login-textbox {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr;
    }

    .login-label {
        grid-column: 1/3;
        text-align: right;
        padding-right: 5px;
        align-self: center;
    }

    .login-input {
        grid-column: 3/6;
        padding: 2px 0 2px 0;
    }

    .form-submit {
        grid-row: 5/6;
        width: 120px;
        height: 30px;
        justify-self: center;

        background: rgb(240, 240, 240);

        border: 1px solid rgb(120, 120, 120);
        border-radius: 3px;

        :hover {
            background: rgb(230, 230, 230);
        }
    }

    @media screen and (max-width: 500px) {
        width: 300px;
        height: 450px;
        margin: 100px auto;

        .login-header {
            font-size: 20px;
        }

        .login-logo {
            img {
                width: 197px;
                height: 100px;
            }
        }

        .login-label {
            font-size: 14px;
        }

        .login-input {
            padding: 1px 0 ;
        }
    }
`;

export default LoginContainer;