import dayjs from "dayjs";
import {useState} from "react";
import Split from "react-split";
import SplitPane from "../../utilities/styles/SplitPane";
import Sidebar from "./Sidebar";
import ScheduleFilters from "./ScheduleFilters";
import Reports from "../../../classes/Reports";
import ScheduleReport from "./ScheduleReport";

const ScheduleReportPage = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [didLoadData, setDidLoadData] = useState(false)
    const [userId, setUserId] = useState('')
    const [position, setPosition] = useState('')

    const [selectedCrewIds, setSelectedCrewIds] = useState([])
    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([])

    const [selectedReports, setSelectedReports] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedFields, setSelectedFields] = useState([])

    const [tasks, setTasks] = useState([])

    const [title, setTitle] = useState('')

    const [reports, setReports] = useState([])
    const [reportData, setReportData] = useState([])

    const [pdfUrl, setPdfUrl] = useState('')

    const loadData = async () => {
        setIsLoading(true)
        setDidLoadData(true);

        setReports(await Reports.loadScheduleReports())
        setIsLoading(false)
    }

    if (!didLoadData) {
        loadData();
    }

// Create Document Component
    return (
        <div>
            <ScheduleFilters
                useDate={true}
                useField={true}
                useCrew={false}
                title={"Daily"}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setSelectedCrewIds={setSelectedCrewIds}
                setSelectedEmployeeIds={setSelectedEmployeeIds}
                selectedFields={selectedFields}
                setSelectedFields={setSelectedFields}
                selectedReports={selectedReports}
                reportData={reportData}
                setReportData={setReportData}
                userId={userId}
                position={position}
                tasks={tasks}
                setTasks={setTasks}
                pdfUrl={pdfUrl}
                setPdfUrl={setPdfUrl}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
            <SplitPane>
                <Split sizes={[25, 85]} direction="horizontal" className="split">
                    <section className="pane sidebar">
                       <Sidebar
                           reports={reports}
                           setTitle={setTitle}
                           selectedDate={selectedDate}
                           selectedCrewIds={selectedCrewIds}
                           selectedEmployeeIds={selectedEmployeeIds}
                           selectedFields={selectedFields}
                           selectedReports={selectedReports}
                           setSelectedReports={setSelectedReports}
                           setReportData={setReportData}
                           userId={userId}
                           position={position}
                           tasks={tasks}
                           setTasks={setTasks}
                           pdfUrl={pdfUrl}
                           setPdfUrl={setPdfUrl}
                           isLoading={isLoading}
                           setIsLoading={setIsLoading}
                       />
                    </section>
                    <section>
                        <ScheduleReport
                            pdfUrl={pdfUrl}
                            data={reportData}
                            date={selectedDate}
                            isLoading={isLoading}
                        />
                    </section>
                </Split>
            </SplitPane>
        </div>
    )
}



export default ScheduleReportPage;
