
import styled from 'styled-components';

import waitSpinner from './../../img/Spinner-white.gif';

const loadingMessage_Margin = "5px 0 5px 0";

const LoadingMessageContainer = styled.div`
    display: grid;
    width: ${props => props.width};
    justify-content: center;
    margin: ${props => props.margin ? props.margin : loadingMessage_Margin};

    .loading-spinner{
        display: grid;
        justify-self: center;
    }

    .loading-message{
        display: grid;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: red;
    }
`

// TEMPLATE
// <LoadingMessage className="Class name of entire box" 
//                 message="message to display"
//                 width="Width of the containing box"/>
//        
// END

export const LoadingMessage = (props) => {
    return (
        <LoadingMessageContainer className={props.className} width={props.width}>
            <img className="loading-spinner" width='100' height='100' src={waitSpinner} alt='Wait Spinner'/>
            <div className='loading-message'>{props.message}</div>
        </LoadingMessageContainer>
    );
}