import {useEffect, useMemo, useState} from 'react';

import Auth from '../authentication/Auth';
import Button  from "@mui/material/Button";
import MainHeader from '../utilities/MainHeader';
import Emails from "../../classes/Emails";
import Users from "../../classes/Users";
import Employees from "../../classes/Employees";
import EmailSetupContainer from "./styles/EmailSetupContainer";
import Select, {createFilter} from "react-select";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {Clear, AddBoxSharp, EmailTwoTone, PlusOne, Drafts, GroupAdd, Edit} from "@mui/icons-material";

import {MaterialReactTable} from "material-react-table";
import {Tooltip, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Reports from "../../classes/Reports";
import { borderRadius } from '@mui/system';

const EmailSetup = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [emails, setEmails] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState({})

    const [validationError, setValidationError] = useState("");
    const [errorPersonnelWithNoEmail, setErrorPersonnelWithNoEmail] = useState("");

    const [users, setUsers] = useState([])
    const [employees, setEmployees] = useState([])

    const [frequencies, setFrequencies] = useState([])

    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([])

    const [selectedStartDate, setSelectedStartDate] = useState('')
    const [selectedEndDate, setSelectedEndDate] = useState('')

    const [emailOpen, setEmailOpen] = useState(false)
    const [distributionOpen, setDistributionOpen] = useState(false)

    const [reports, setReports] = useState([])
    const [selectedReports, setSelectedReports] = useState([])
    const [selectedReportFrequency, setSelectedReportFrequency] = useState(null)

    const gridColumns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
            },
            {
                accessorFn: (row) => new Date(row.start_date),
                id: 'start_date',
                header: 'Start Date',
                filterFn: 'lessThanOrEqualTo',
                sortingFn: 'datetime',
                Cell: ({cell}) => cell.getValue()?.toLocaleDateString(),
                Header: ({column}) => <em>{column.columnDef.header}</em>,
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(newValue) => {
                                column.setFilterValue(newValue);
                            }}
                            slotProps={{
                                textField: {
                                    helperText: '',
                                    sx: {minWidth: '120px'},
                                    variant: 'standard',
                                },
                            }}
                            value={column.getFilterValue()}
                        />
                    </LocalizationProvider>
                ),
                size: 200,
            },
            {
                accessorFn: (row) => row.end_date !== null && row.end_date !== '' ? new Date(row.end_date) : null,
                id: 'end_date',
                header: 'End Date',
                filterFn: 'lessThanOrEqualTo',
                sortingFn: 'datetime',
                Cell: ({cell}) => cell.getValue()?.toLocaleDateString(),
                Header: ({column}) => <em>{column.columnDef.header}</em>,
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(newValue) => {
                                column.setFilterValue(newValue);
                            }}
                            slotProps={{
                                textField: {
                                    helperText: '',
                                    sx: {minWidth: '120px'},
                                    variant: 'standard',
                                },
                            }}
                            value={column.getFilterValue()}
                        />
                    </LocalizationProvider>
                ),
                size: 200,
            },
        ],
        [],
    );

    const detailColumns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
                // muiTableBodyRowProps: {
                //     sx: {
                //         // backgroundColor: 'yellow',
                //         backgroundColor: x => {
                //             if(x.tableData.id % 2){
                //                 return
                //             }
                //         }
                //         borderRadius: '20px'
                //         // margin: '0 30px',
                //         // padding: '30px 0'
                //     }
                // }
            },
            {
                accessorKey: 'email',
                header: 'Email',
            },
            {
                accessorKey: 'type',
                header: '',
            },
        ],
        [],
    );

    useEffect(() => {
        const loadData = async () => {
            setDidLoadData(true);
            setEmails(await Emails.loadEmails())

            setFrequencies(await Emails.loadFrequencies());
            setReports(await Reports.loadReports())

            setUsers(await Users.loadUsers())
            setEmployees(await Employees.loadEmployeesForDropDown())
        }

        if (!didLoadData) {
            loadData();
        }
    }, []);

    const handleReportFrequencyChanged = (e) => {
        let email = {...selectedEmail}
        email.frequency = e
        setSelectedReportFrequency(e)
        setSelectedEmail(email)
    }
    const handleReportChanged = (selections) => {
        let email = {...selectedEmail}
        let reports = []
        let emailReports = [];
        for(let s=0; s < selections.length; s++) {
            reports.push({
                label: selections[s].report.display,
                value: selections[s].report.id,
                report: {
                    active: selections[s].report.active,
                    display: selections[s].report.display,
                    id: selections[s].report.id,
                    name: selections[s].report.name,
                    path: selections[s].report.path,
                    permission: selections[s].report.permission,
                }
            });
            emailReports.push({
                report_id: selections[s].report.id,
                email_id: selectedEmail.id !== undefined ? selectedEmail.id : null
            });
        }
        email.reports = reports;
        email.emailReports = emailReports;
        setSelectedReports(selections)
        setSelectedEmail(email)
    }
    const handleUserChanged = (selections) => {
        let email = {...selectedEmail}
        let distribution = handleUpdateDistributionList(email, email.distribution, selections, 'user')
     
        email.distribution = distribution.distribution
        email.subRows = distribution.subRows
        setSelectedUsers(selections)
        setSelectedEmail(email)
    }
    const handleEmployeeChanged = (selections) => {
        let email = {...selectedEmail}
        let distribution = handleUpdateDistributionList(email, email.distribution, selections, 'employee')

        email.distribution = distribution.distribution
        email.subRows = distribution.subRows
        setSelectedEmployees(selections)
        setSelectedEmail(email)
    }
    const handleUpdateDistributionList = (email, current, selections, type) =>{
        let distribution = []
        let subRows = []

        for(let d=0; d<selections.length; d++) {
            let selection = selections[d]
            let existing = undefined
            if (type === 'employee') {
                existing = current.find(x => x.employee_id === selection.id)
            } else if (type === 'user') {
                existing = current.find(x => x.user_id === selection.id)
            }

            distribution.push({
                id: existing === undefined ? null : existing.id,
                email_id: email.id === undefined ? null : email.id,
                user_id: type === 'user' ? selection.id : null,
                employee_id: type === 'employee' ? selection.id : null
            })

            subRows = addSubRows(subRows, email, selection, type)
        }
        //make sure we keep the existing ones
        for(let d=0; d<current.length; d++){
            if(type === 'user' && current[d].employee_id !== null) {
                distribution.push({
                    id: current[d].id,
                    email_id: current[d].email_id,
                    user_id: null,
                    employee_id: current[d].employee_id
                })
                subRows = addSubRows(subRows, email, current[d], type)
            }
            if(type === 'employee' && current[d].user_id !== null) {
                distribution.push({
                    id: current[d].id,
                    email_id: current[d].email_id,
                    user_id: current[d].user_id,
                    employee_id: null
                })
                subRows = addSubRows(subRows, email, current[d], type)
            }
        }
        return {
            distribution: distribution,
            subRows: subRows,
        }
    }
    const addSubRows = (subRows, email, item, type) => {
        subRows.push({
            email_id: email.id === undefined ? null : email.id,
            user_id: type === 'user' ? item.id : null,
            employee_id: type === 'employee' ? item.id : null,
            name: item.label,
            email: item.email,
            type: type,
        })
        return subRows
    }
    const handleStartDateChanged = (date) => {
        let email = {...selectedEmail}
        email.start_date = dayjs(date).format('YYYY-MM-DD')
        setSelectedStartDate(date)
        setSelectedEmail(email)
    }
    const handleEndDateChanged = (date) => {
        let email = {...selectedEmail}
        email.end_date = dayjs(date).format('YYYY-MM-DD')
        setSelectedEndDate(date)
        setSelectedEmail(email)
    }

    const handleCloseNoSave = () => {
        setValidationError("");
        setEmailOpen(false)
        setDistributionOpen(false)
        resetForm()
    }

    const handleCloseSave = async () => {
        const validateEmail = Emails.validateEmail(selectedEmail);
        if(validateEmail !== "SUCCESS"){
            console.log("ERROR: " + validateEmail);
            console.log(selectedEmail);
            setValidationError(validateEmail);
            if(validateEmail === "NO_EMAIL" || validateEmail === "BAD_EMAIL"){
                for(let i = 0; i< selectedEmail.subRows.length; i++){
                    if(selectedEmail.subRows[i].email === ""){
                        setErrorPersonnelWithNoEmail(selectedEmail.subRows[i].name);
                    }
                }
            }
            return;
        }

        selectedEmail.created_by = await Auth.getUserId();
        selectedEmail.created_at = new Date();

        await Emails.save(selectedEmail)

        setEmailOpen(false)
        setDistributionOpen(false)
        resetForm()
        setEmails(await Emails.loadEmails());
    }

    const resetForm = () => {
        setSelectedEmail({})
        setSelectedStartDate(null)
        setSelectedEndDate(null)
        setSelectedEmployees([])
        setSelectedUsers([])
        setSelectedReports([])
        setSelectedReportFrequency({})
    }

    const [matchFromStart] = useState(true);
    const [ignoreCase] = useState(true);
    const [ignoreAccents] = useState(true);
    const [trim] = useState(true);

    const filterConfig = {
        ignoreCase,
        ignoreAccents,
        trim,
        matchFrom: matchFromStart ? ('start') : ('any'),
    };

    return (
        <EmailSetupContainer>
            <MainHeader/>
            <MaterialReactTable
                id="emails-table"
                columns={gridColumns}
                data={emails}
                rowCount={emails.length}
                enableSorting
                layoutMode="grid-no-grow"
                enableColumnResizing
                enableRowActions
                renderRowActions={({table, row}) => {
                    return (
                        <Edit
                            onClick={() => {
                                let id = row.original.id
                                let email = emails.find(x => x.id === id)
                                if(email.subject === null){email.subject = ''}
                                if(email.body === null){email.body = ''}
                                setSelectedEmail(email)
                                setSelectedStartDate(new Date(email.start_date))
                                if(email.end_date !== null && email.end_date !== ''){
                                    setSelectedEndDate(new Date(email.end_date))
                                }
                                setSelectedReports(email.reports)
                                setSelectedReportFrequency(email.frequency)
                                setSelectedUsers(email.users)
                                setSelectedEmployees(email.employees)
                                setEmailOpen(true)
                            }}
                            style={{cursor: 'pointer', color: 'darkorange'}}
                        />
                    )
                }}
                renderTopToolbarCustomActions={({table}) => {
                    const handleAddNew = (table) => {
                        setSelectedReportFrequency(null)
                        setSelectedReports([])
                        let email = {
                            name: '',
                            subject: '',
                            body: '',
                            distribution: [],
                            reports: [],
                            frequency: null
                        }
                        setSelectedEmail(email)
                        setEmailOpen(true)
                    };
                    return (
                        <div style={{display: 'flex', width: "100%", justifyContent: "end"}}>
                            <Tooltip title="Add New Email">
                                <IconButton onClick={handleAddNew}>
                                    <Drafts/>
                                </IconButton>
                            </Tooltip>

                        </div>
                    );
                }}
                initialState={{
                    showColumnFilters: true,
                    pagination: {pageSize: 100, pageIndex: 0},
                    columnPinning: {
                        left: [ 'mrt-row-expand', 'mrt-row-actions'],
                    },
                    expanded: false,
                }}
                positionToolbarAlertBanner="bottom"
                renderDetailPanel={({row}) =>
                    <div>
                        <MaterialReactTable
                            id="emails-detail-table"
                            columns={detailColumns}
                            data={row.original.subRows}
                            enablePagination={false}
                            enableFullScreenToggle={false}
                            enableDensityToggle={false}
                            enableHiding={false}
                            enableRowActions
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    header: '', //change header text
                                    size: 50, //make actions column wider
                                }
                            }}
                            renderRowActions={({table, row}) => {
                                return (
                                    <Clear
                                        onClick={async () => {
                                            const tempEmails = [...emails];
                                            const index = tempEmails.findIndex(e => parseInt(e.id) === parseInt(row.original.email_id));

                                            let person;
                                            if(row.original.employee_id !== null){
                                                person = tempEmails[index].distribution.find(d => parseInt(d.employee_id) === parseInt(row.original.employee_id));
                                            }
                                            else if(row.original.user_id !== null){
                                                person = tempEmails[index].distribution.find(d => parseInt(d.user_id) === parseInt(row.original.user_id));
                                            }
                                            else{
                                                return;
                                            }

                                            Auth.hitEndpointNew("DELETE", "email/distribution", "", person);
                                            setEmails(await Emails.loadEmails());
                                        }}
                                        style={{cursor: 'pointer', color: 'darkorchid'}}
                                    />
                                )
                            }}
                            renderTopToolbarCustomActions={({table, row}) => {
                                const handleAddNew = () => {
                                    let id = table.getRowModel().rows[0].original.email_id
                                    let email = emails.find(x => x.id === id)
                                    setSelectedEmail(email)
                                    setSelectedStartDate(new Date(email.start_date))
                                    if(email.end_date !== null && email.end_date !== ''){
                                        setSelectedEndDate(new Date(email.end_date))
                                    }
                                    setDistributionOpen(true)
                                };
                                return (
                                    <div style={{display: 'flex', width: "100%", justifyContent: "end"}}>
                                        <Tooltip title="Add Someone to the Distribution List">
                                            <IconButton onClick={handleAddNew}>
                                                <GroupAdd/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                );
                            }}
                            initialState={{
                                showColumnFilters: true,
                                pagination: {pageSize: 100, pageIndex: 0},
                            }}
                        />
                    </div>
                }
            />

            <Dialog open={emailOpen || distributionOpen} onClose={handleCloseNoSave}>
                {emailOpen && <DialogTitle>Add New Email</DialogTitle>}
                {distributionOpen && <DialogTitle>Add to Distribution</DialogTitle>}
                <DialogContent>
                    {emailOpen &&
                        <div className={'subject-body'}>
                            <TextField 
                                sx={{
                                    backgroundColor: validationError === "NO_NAME" ? 'rgb(230, 100, 100)' : 'white',
                                }}
                                placeholder="Enter a Name" 
                                label="Name" 
                                value={selectedEmail.name} 
                                onChange={(e) => {
                                    let email = {...selectedEmail}
                                    email.name = e.target.value
                                    setSelectedEmail(email)
                                }}
                            />
                            <TextField 
                                sx={{
                                    backgroundColor: validationError === "NO_SUBJECT" ? 'rgb(230, 100, 100)' : 'white',
                                }}
                                placeholder="Enter Email Subject" 
                                label="Enter Email Subject" 
                                value={selectedEmail.subject} 
                                onChange={(e) => {
                                    let email = {...selectedEmail}
                                    email.subject = e.target.value
                                    setSelectedEmail(email)
                                }}
                            />
                            <TextField 
                                sx={{
                                    backgroundColor: validationError === "NO_BODY" ? 'rgb(230, 100, 100)' : 'white',
                                }}
                                placeholder="Enter Email Body" 
                                label="Enter Email Body" 
                                value={selectedEmail.body} 
                                onChange={(e) => {
                                    let email = {...selectedEmail}
                                    email.body = e.target.value
                                    setSelectedEmail(email)
                                }}
                            />
                            <div className={'report-list'}>
                                <Select
                                    styles={{
                                        control: styles => {
                                            return {
                                                ...styles,
                                                backgroundColor: validationError === "NO_REPORT" ? 'rgb(230, 100, 100)' : 'white',
                                            }
                                        },
                                        placeholder: styles => {
                                            return {
                                                ...styles,
                                                color: validationError === "NO_REPORT" ? 'black' : 'gray',
                                            }
                                        }
                                    }} 
                                    id="report-list-select-id"
                                    label="Select Report(s)"
                                    isMulti
                                    onChange={(e) => handleReportChanged(e)}
                                    options={reports.map((item, i) => ({
                                        value: item.id,
                                        label: item.display,
                                        report: item,
                                        }))}
                                    isClearable
                                    isSearchable
                                    value={selectedReports}
                                    filterOption={createFilter(filterConfig)}
                                    placeholder={"Select Report(s)"}
                                />
                            </div>
                        </div>
                    }
                    {emailOpen &&
                        <div className={'report-frequency'}>
                            <Select
                                styles={{
                                    control: styles => {
                                        return {
                                            ...styles,
                                            backgroundColor: validationError === "NO_FREQUENCY" ? 'rgb(230, 100, 100)' : 'white',
                                        }
                                    },
                                    placeholder: styles => {
                                        return {
                                            ...styles,
                                            color: validationError === "NO_FREQUENCY" ? 'black' : 'gray',
                                        }
                                    }
                                }} 
                                id="report-frequency-select-id"
                                label="Select a Frequency"
                                onChange={(e) => handleReportFrequencyChanged(e)}
                                options={frequencies.map((item, i) => ({
                                    value: item.id,
                                    label: item.display,
                                    interval: item.interval,
                                    day: item.day,
                                }))}
                                isClearable
                                isSearchable
                                value={selectedReportFrequency}
                                filterOption={createFilter(filterConfig)}
                                placeholder={"Select a Frequency"}
                            />
                        </div>
                    }
                    {emailOpen &&
                        <div className={'start-date'}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    onChange={(newValue) => {
                                        handleStartDateChanged(newValue);
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: '',
                                            sx: {minWidth: '120px', backgroundColor: validationError === "NO_START_DATE" ? 'rgb(230, 100, 100)' : 'white'},
                                            variant: 'standard',
                                        },
                                    }}
                                    defaultValue={dayjs(selectedStartDate)}
                                    selected={selectedStartDate}
                                />
                            </LocalizationProvider>
                        </div>
                    }
                    {emailOpen &&
                        <div className={'end-date'}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    onChange={(newValue) => {
                                        handleEndDateChanged(newValue);
                                    }}
                                    slotProps={{
                                        textField: {
                                            helperText: '',
                                            sx: {minWidth: '120px'},
                                            variant: 'standard',
                                        },
                                    }}
                                    defaultValue={dayjs(selectedEndDate)}
                                    selected={selectedEndDate}
                                />
                            </LocalizationProvider>
                        </div>
                    }
                    <div className={'user-list'}>
                        <Select
                            styles={{
                                control: styles => {
                                    const error = 
                                        validationError === "NO_PERSONNEL" ||
                                        validationError === "NO_SUBS" ||
                                        validationError === "NO_EMAIL" ||
                                        validationError === "BAD_EMAIL";
                                    return {
                                        ...styles,
                                        backgroundColor: error ? 'rgb(230, 100, 100)' : 'white',
                                    }
                                },
                                placeholder: styles => {
                                    const error = 
                                        validationError === "NO_PERSONNEL" ||
                                        validationError === "NO_SUBS" ||
                                        validationError === "NO_EMAIL" ||   
                                        validationError === "BAD_EMAIL";
                                    return {
                                        ...styles,
                                        color: error ? 'black' : 'gray',
                                    }
                                }
                            }} 
                            id="user-list-select-id"
                            label="Select Users"
                            required
                            isMulti
                            onChange={(e) => handleUserChanged(e)}
                            options={users.map((item, i) => ({
                                    value: item.id,
                                    label: item.firstname + ' ' + item.lastname,
                                    id: item.id,
                                    email: item.email,
                                    user: item,
                                }
                            ))}
                            isClearable
                            isSearchable
                            value={selectedUsers}
                            filterOption={createFilter(filterConfig)}
                            placeholder={"Select Users"}
                        />
                    </div>
                    <div className={'employee-list'}>
                        <Select
                            styles={{
                                control: styles => {
                                    const error = 
                                        validationError === "NO_PERSONNEL" ||
                                        validationError === "NO_SUBS" ||
                                        validationError === "NO_EMAIL" ||
                                        validationError === "BAD_EMAIL";
                                    return {
                                        ...styles,
                                        backgroundColor: error ? 'rgb(230, 100, 100)' : 'white',
                                    }
                                },
                                placeholder: styles => {
                                    const error = 
                                        validationError === "NO_PERSONNEL" ||
                                        validationError === "NO_SUBS" ||
                                        validationError === "NO_EMAIL" ||
                                        validationError === "BAD_EMAIL";
                                    return {
                                        ...styles,
                                        color: error ? 'black' : 'gray',
                                    }
                                }
                            }} 
                            id="employee-list-select-id"
                            label="Select Employees"
                            required
                            isMulti
                            onChange={(e) => handleEmployeeChanged(e)}
                            options={employees}
                            isClearable
                            isSearchable
                            value={selectedEmployees}
                            filterOption={createFilter(filterConfig)}
                            placeholder={"Select Employees"}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNoSave}>Cancel</Button>
                    <Button onClick={handleCloseSave}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={errorPersonnelWithNoEmail !== ""}>
                <DialogTitle>Email Error</DialogTitle>
                <DialogContent>
                    {`${errorPersonnelWithNoEmail} has an invalid email address. Please correct before adding to email distribution`}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setErrorPersonnelWithNoEmail("")}>OK</Button>
                </DialogActions>
            </Dialog>
        </EmailSetupContainer>
    )
};

export default EmailSetup;