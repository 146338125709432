import dayjs from "dayjs";
import Auth from "../components/authentication/Auth";

class Users {

    static async loadUsers(queryObj) {

        let jsonString = ''

        if(queryObj !== undefined && queryObj !== null) {
            jsonString = JSON.stringify(queryObj);
        }
        let dbData = await Auth.hitEndpointNew("GET", `users`, jsonString !== '' ? `jsonString=${jsonString}` : null);

        return dbData;
    }
}

export default Users;