import React, {useEffect, useState} from 'react';

import Auth from './Auth.js';

import HomeScreen from '../home-screen/HomeScreen.js';
import Can from "./Can";
// import Login from '../login/Login.js';


const AuthProvider = (props) =>  {
    const [permissions, setPermissions] = useState([]);
    const [didLoadData, setDidLoadData] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            setDidLoadData(true);
            setPermissions(await Auth.getPermissions());
        }
        
        if(!didLoadData){
            loadData();
        }
    }, []);


    // Handle page view authorization
    const isAuthorized = (permissionName, rw) => {
        const permission = permissions.find(p => p.permission.name === permissionName);
        if(permission === undefined){
            return false;
        }

        if(Can.isAdmin(permissions)){
            return true
        }

        if(rw === "READ"){
            return permission.read;
        }
        if(rw === "WRITE"){
            return permission.write;
        }

        return false; // Catch All
    }
    
    // This handles App.js page request and is always and "READ" request
    if(isAuthorized(props.permissionName, "READ") || (props.permissionName === 'admin' && Can.isAdmin(permissions))){return React.cloneElement(props.children, {isAuthorized: isAuthorized})}
    else{return React.cloneElement(<HomeScreen/>, {isAuthorized: isAuthorized})}
}


export default AuthProvider;
