// Contains Utilities class used for all utility function
import XLSX from 'xlsx';
import Auth from "../components/authentication/Auth";

class Utilities{
    // static sortLotsByJobNumbers(lots, jobs){
    //     return lots.sort((lot1, lot2) => {
    //         const job1 = jobs.find(job => job._id === lot1.jobId);
    //         const job2 = jobs.find(job => job._id === lot2.jobId);

    //         const job1Number = Number.parseInt(job1 ? job1.number : "0");
    //         const job2Number = Number.parseInt(job2 ? job2.number : "0");

    //         const lot1Number = Number.parseInt(lot1.lotNumber);
    //         const lot2Number = Number.parseInt(lot2.lotNumber);

    //         if(job1Number === job2Number){
    //             return lot1Number < lot2Number ? -1 : 1;
    //         }

    //         return job1Number < job2Number ? -1 : 1;
    //     });
    // }

    static isLoggedOut(){
        return localStorage.getItem('token') === null
    }

    static exportToXLSXFileFormat(data, sheetName, fileName){
        // Data must be an array of unpacked objects with no sub-objects or sub-arrays
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        XLSX.writeFile(workbook, fileName);
    }

    //Getting data from list tables in the db. Can be broken out to other classes if need be but for now made sense to add it here
    static async loadMeasurements(fieldId){
        let data = [];

        const dbData = await Auth.hitEndpoint("GET", "MEASUREMENTS", "", null, fieldId);

        data = [...data, ...dbData];

        return data
    }

    static async loadQuestions(type){
        let data = [];

        const dbData = await Auth.hitEndpoint("GET", "QA", "", null, type);

        data = [...data, ...dbData];

        return data
    }
}

export default Utilities;
