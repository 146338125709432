import {useState} from 'react';
import dayjs from "dayjs";

import Auth from '../../authentication/Auth.js';
import { Button } from '../../utilities/Button';
import Select from "react-select";
import Globals from '../../utilities/Globals.js';
import ReporterContainer from '../styles/ReporterContainer';

import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    viewer: {
        margin: '20px auto',
        width: '1200px',
        height: '500px',
    },
    page: {
        paddingBottom: '16mm',
        backgroundColor: 'white',
        width: '216mm',
        height: '280mm'
      },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '5mm',
        fontSize: '10mm',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '15mm',
        paddingRight: '15mm',
        top: '255mm',
        width: '100%',
        fontSize: '3mm',
    },
    reportHeader: {
        margin: '0 auto',
        padding: '2mm 0',
        width: '90%',
        fontSize: '7mm',
        textAlign: 'center',
        backgroundColor: 'rgb(217, 217, 217)',
    },
    phaseHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '3mm 0 0 20mm',
        width: '50mm',
        fontSize: '5mm',
    },
    monthHeader: {
        fontSize: '4mm',
        textAlign: 'center',
        marginTop: '3mm',
    },
    oneColumnHeader: {
        display: 'flex',
        flexDirection: 'row',
        margin: '3mm 0 0 100mm',
        fontSize: '4mm',
        column1: {
            width: '40mm',
            textAlign: 'right',
        }
    },
    oneColumnRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: '1mm 0 0 60mm',
        fontSize: '4mm',
        date: {
            width: '40mm',
            textAlign: 'right',
        },
        column1: {
            width: '40mm',
            textAlign: 'right',
        }
    },
    oneColumnTotals: {
        display: 'flex',
        flexDirection: 'row',
        width: '180mm',
        margin: '2mm 0 0 20mm',
        fontSize: '4mm',
        paddingTop: '1mm',
        paddingBottom: '1mm',
        backgroundColor: 'rgb(250, 250, 204)',
        borderBottom: '1mm solid black',
        month: {
            width: '40mm',
            textAlign: 'right',
        },
        weeks: {
            width: '40mm',
            textAlign: 'right',
        },
        column1: {
            width: '40mm',
            textAlign: 'right',
        }
    },
    twoColumnHeader: {
        display: 'flex',
        flexDirection: 'row',
        margin: '3mm 0 0 100mm',
        fontSize: '4mm',
        column1: {
            width: '40mm',
            textAlign: 'right',
        },
        column2: {
            width: '40mm',
            textAlign: 'right',
        }
    },
    twoColumnRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: '1mm 0 0 60mm',
        fontSize: '4mm',
        date: {
            width: '40mm',
            textAlign: 'right',
        },
        column1: {
            width: '40mm',
            textAlign: 'right',
        },
        column2: {
            width: '40mm',
            textAlign: 'right',
        }
    },
    twoColumnTotals: {
        display: 'flex',
        flexDirection: 'row',
        width: '180mm',
        margin: '2mm 0 0 20mm',
        fontSize: '4mm',
        paddingTop: '1mm',
        paddingBottom: '1mm',
        backgroundColor: 'rgb(250, 250, 204)',
        borderBottom: '1mm solid black',
        month: {
            width: '40mm',
            textAlign: 'right',
        },
        weeks: {
            width: '40mm',
            textAlign: 'right',
        },
        column1: {
            width: '40mm',
            textAlign: 'right',
        },
        column2: {
            width: '40mm',
            textAlign: 'right',
        }
    },
    threeColumnHeader: {
        display: 'flex',
        flexDirection: 'row',
        margin: '3mm 0 0 100mm',
        fontSize: '4mm',
        column1: {
            width: '30mm',
            textAlign: 'right',
        },
        column2: {
            width: '30mm',
            textAlign: 'right',
        },
        column3: {
            width: '30mm',
            textAlign: 'right',
        }
    },
    threeColumnRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: '1mm 0 0 60mm',
        fontSize: '4mm',
        date: {
            width: '40mm',
            textAlign: 'right',
        },
        column1: {
            width: '30mm',
            textAlign: 'right',
        },
        column2: {
            width: '30mm',
            textAlign: 'right',
        },
        column3: {
            width: '30mm',
            textAlign: 'right',
        }
    },
    threeColumnTotals: {
        display: 'flex',
        flexDirection: 'row',
        width: '180mm',
        margin: '2mm 0 0 20mm',
        fontSize: '4mm',
        paddingTop: '1mm',
        paddingBottom: '1mm',
        backgroundColor: 'rgb(250, 250, 204)',
        borderBottom: '1mm solid black',
        month: {
            width: '40mm',
            textAlign: 'right',
        },
        weeks: {
            width: '40mm',
            textAlign: 'right',
        },
        column1: {
            width: '30mm',
            textAlign: 'right',
        },
        column2: {
            width: '30mm',
            textAlign: 'right',
        },
        column3: {
            width: '30mm',
            textAlign: 'right',
        }
    },
    ytdHeader: {
        display: 'flex',
        flexDirection: 'row',
        margin: '8mm 0 3mm 0',
        fontSize: '4mm',
        company: {
            width: '35mm',
            textAlign: 'right',
        },
        phase: {
            width: '40mm',
            textAlign: 'right',
        },
        weeks: {
            width: '35mm',
            textAlign: 'right',
        },
        ytd: {
            width: '20mm',
            textAlign: 'right',
        },
        average: {
            width: '30mm',
            textAlign: 'right',
        },
        projection: {
            width: '40mm',
            textAlign: 'right',
        }
    },
    ytdRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: '1mm 0 0 45mm',
        fontSize: '4mm',
        phase: {
            width: '40mm',
            textAlign: 'right',
        },
        weeks: {
            width: '25mm',
            textAlign: 'right',
        },
        ytd: {
            width: '20mm',
            textAlign: 'right',
        },
        average: {
            width: '30mm',
            textAlign: 'right',
        },
        projection: {
            width: '40mm',
            textAlign: 'right',
        }
    },
    ytdRowNoPhase: {
        display: 'flex',
        flexDirection: 'row',
        margin: '1mm 0 0 85mm',
        fontSize: '4mm',
        weeks: {
            width: '25mm',
            textAlign: 'right',
        },
        ytd: {
            width: '20mm',
            textAlign: 'right',
        },
        average: {
            width: '30mm',
            textAlign: 'right',
        },
        projection: {
            width: '40mm',
            textAlign: 'right',
        }
    },
    companyHeaderSPPR: {
        margin: '3mm 0 0 11mm',
        fontSize: '6mm',
    },
    phaseHeaderSPPR: {
        margin: '3mm 0 0 11mm',
        width: '90%',
        fontSize: '5mm',
        paddingLeft: '9mm',
        paddingBottom: '1mm',
        borderBottom: '2pt solid black'
    },
    weekCountLineSPPR: {
        display: 'flex',
        flexDirection: 'row',
        margin: '2mm 0 0 30mm',
        fontSize: '4mm',
        week: {
            width: '26mm',
            textAlign: 'left',
        },
        date: {
            width: '90mm',
            textAlign: 'left',
        },
        count: {
            width: '13mm',
            textAlign: 'left',
        },
        number: {
            width: '15mm',
            textAlign: 'left',
        },
    },
    builderCountLineSPPR: {
        display: 'flex',
        flexDirection: 'row',
        margin: '2mm 0 0 25mm',
        fontSize: '4mm',
        count: {
            width: '25mm',
            textAlign: 'right',
            paddingRight: '10mm',
        },
        builder: {
            width: '70mm',
            textAlign: 'left',
        },
    },
    projectCountLineSPPR: {
        display: 'flex',
        flexDirection: 'row',
        margin: '2mm 0 0 60mm',
        fontSize: '4mm',
        count: {
            width: '30mm',
            textAlign: 'right',
            paddingRight: '15mm',
        },
        builder: {
            width: '80mm',
            textAlign: 'left',
        },
    },
    lineBreakSPPR: {
        width: '90%',
        margin: '3mm auto',
        borderBottom: '2pt solid black'
    }
});

const StartsReport = () => {
    const [overviewReportYearSelected, setOverviewReportYearSelected] = useState((new Date()).getFullYear());
    const overviewReportStartYear = 2014;

    const overviewReportYears = [];
    for(let i = overviewReportStartYear; i <= (new Date()).getFullYear(); i++){
        overviewReportYears.push({value: i, label: i});
    }

    const [buttonText, setButtonText] = useState('Starts Report');

    const [data, setData] = useState([
        <Page size="LETTER" style={styles.page} wrap>
            <View style={styles.header} fixed>
                <Text>Sierra Verde Companies</Text>
            </View>
            <View style={styles.footer} fixed>
                <Text>{dayjs(new Date()).format('MM-DD-YYYY')}</Text>
                <Text render={(page) => `page ${page.pageNumber} of ${page.totalPages}`} />
            </View>
        </Page>
    ]);

    const createStartsReport = async () => {
        setButtonText('Fetching Report...');

        let tempStartsReport = [];
        let tempStartsPerProject = [];
        const tempPages = [];

        const seedDate = parseInt(overviewReportYearSelected) === (new Date()).getFullYear() ? new Date() : new Date(`12-31-${overviewReportYearSelected}`);

        // Get first Sunday of the year
        const firstSunday = new Date(seedDate);
        firstSunday.setDate(1);
        firstSunday.setMonth(0);
        if (firstSunday.getDay() !== 0) {
            firstSunday.setDate(firstSunday.getDate() + (7 - firstSunday.getDay()));
        }

        // Get the first Sunday of the current date for the following month
        const lastDay = new Date(seedDate);
        lastDay.setMonth(lastDay.getMonth() + 1);
        lastDay.setDate(0);

        const months = Globals.getMonths();
        let numberOfWeeks = Math.round((lastDay - firstSunday) / (7 * 24 * 60 * 60 * 1000));

        const startsOverviewReport = await Auth.hitEndpoint("GET", "STATS", `selector=twentytwo&year=${overviewReportYearSelected}`);
        const phases = (await Auth.hitEndpoint("GET", "PHASE")).sort((p1, p2) => p1.starts_overview_report_group_by < p2.starts_overview_report_group_by ? -1 : 1);
        
        // Create Starts Overview Report
        let concreteFinished = false;
        let plumbingFinished = false;

        for (let i = 0; i < phases.length; i++) {
            tempStartsReport = [];
            if(i === 0){
                tempStartsReport.push(
                    <View style={styles.reportHeader}>
                        <Text>Starts Overview Report</Text>
                    </View>
                )
            }

            // Concrete
            if (phases[i].starts_overview_report_group_by === 1) {
                if (!concreteFinished) {
                    const weeklyFoundationData = startsOverviewReport.filter(stat => stat.phase_id === 1);
                    const weeklyDrivewayData = startsOverviewReport.filter(stat => stat.phase_id === 2);

                    tempStartsReport.push(
                        <View style={styles.phaseHeader}>
                            <Text>SVC</Text>
                            <Text>Concrete</Text>
                        </View>
                    )

                    let currentWeekDate = new Date(firstSunday);
                    currentWeekDate.setDate(currentWeekDate.getDate() - 1);
                    let outputMonth = true;

                    let totalWeeks = 0;
                    let totalYTDFoundation = 0;
                    let totalYTDDriveway = 0;

                    let weeksInMonth = 0;
                    let monthlyFoundationCount = 0;
                    let monthlyDrivewayCount = 0;

                    for (let j = 0; j < numberOfWeeks; j++) {
                        const weekNumber = j + 1;

                        currentWeekDate.setDate(currentWeekDate.getDate() + 7);
                        const currentMonth = currentWeekDate.getMonth();

                        if (outputMonth) {
                            outputMonth = false;

                            tempStartsReport.push(
                                <View style={styles.monthHeader} wrap={false}>
                                    <Text>{months[currentMonth]}</Text>
                                </View>
                            )
                            tempStartsReport.push(
                                <View style={styles.twoColumnHeader} wrap={false}>
                                    <Text style={styles.twoColumnHeader.column1}>Foundation</Text>
                                    <Text style={styles.twoColumnHeader.column2}>Driveway</Text>
                                </View>
                            )
                        }

                        const currentFoundationWeekData = weeklyFoundationData.find(data => data['WEEK(release_date)'] === weekNumber);
                        const currentDrivewayWeekData = weeklyDrivewayData.find(data => data['WEEK(release_date)'] === weekNumber);
                        const currentWeekFoundationCount = currentFoundationWeekData !== undefined ? currentFoundationWeekData.count : 0;
                        const currentWeekDrivewayCount = currentDrivewayWeekData !== undefined ? currentDrivewayWeekData.count : 0;

                        tempStartsReport.push(
                            <View style={styles.twoColumnRow} wrap={false}>
                                <Text style={styles.twoColumnRow.date}>{dayjs(currentWeekDate).format('MM-DD-YYYY')}</Text>
                                <Text style={styles.twoColumnRow.column1}>{currentWeekFoundationCount}</Text>
                                <Text style={styles.twoColumnRow.column2}>{currentWeekDrivewayCount}</Text>
                            </View>
                        )

                        totalWeeks++;
                        weeksInMonth++;

                        totalYTDFoundation += currentWeekFoundationCount;
                        totalYTDDriveway += currentWeekDrivewayCount;
                        monthlyFoundationCount += currentWeekFoundationCount;
                        monthlyDrivewayCount += currentWeekDrivewayCount;

                        const nextWeek = new Date(currentWeekDate);
                        nextWeek.setDate(nextWeek.getDate() + 7);
                        if (currentMonth !== nextWeek.getMonth()) {
                            tempStartsReport.push(
                                <View style={styles.twoColumnTotals} wrap={false}>
                                    <Text style={styles.twoColumnTotals.month}>{months[currentMonth]}</Text>
                                    <Text style={styles.twoColumnTotals.weeks}>{weeksInMonth}</Text>
                                    <Text style={styles.twoColumnTotals.column1}>{monthlyFoundationCount}</Text>
                                    <Text style={styles.twoColumnTotals.column2}>{monthlyDrivewayCount}</Text>
                                </View>
                            )

                            weeksInMonth = 0;
                            monthlyFoundationCount = 0;
                            monthlyDrivewayCount = 0;

                            outputMonth = true;
                        }
                    }

                    tempStartsReport.push(
                        <View style={styles.ytdHeader} wrap={false}>
                            <Text style={styles.ytdHeader.company}>SVC</Text>
                            <Text style={styles.ytdHeader.phase}>Concrete</Text>
                            <Text style={styles.ytdHeader.weeks}>WEEKS</Text>
                            <Text style={styles.ytdHeader.ytd}>YTD</Text>
                            <Text style={styles.ytdHeader.average}>AVERAGE</Text>
                            <Text style={styles.ytdHeader.projection}>ANNUAL PROJ</Text>
                        </View>
                    )

                    tempStartsReport.push(
                        <View style={styles.ytdRow} wrap={false}>
                            <Text style={styles.ytdRow.phase}>Foundation</Text>
                            <Text style={styles.ytdRow.weeks}>{totalWeeks}</Text>
                            <Text style={styles.ytdRow.ytd}>{totalYTDFoundation}</Text>
                            <Text style={styles.ytdRow.average}>{Number.parseInt(totalYTDFoundation / totalWeeks)}</Text>
                            <Text style={styles.ytdRow.projection}>{Number.parseInt((totalYTDFoundation / totalWeeks) * 52)}</Text>
                        </View>
                    )

                    tempStartsReport.push(
                        <View style={styles.ytdRow} wrap={false}>
                            <Text style={styles.ytdRow.phase}>Driveway</Text>
                            <Text style={styles.ytdRow.weeks}>{totalWeeks}</Text>
                            <Text style={styles.ytdRow.ytd}>{totalYTDDriveway}</Text>
                            <Text style={styles.ytdRow.average}>{Number.parseInt(totalYTDDriveway / totalWeeks)}</Text>
                            <Text style={styles.ytdRow.projection}>{Number.parseInt((totalYTDDriveway / totalWeeks) * 52)}</Text>
                        </View>
                    )
                }

                concreteFinished = true;
            }
            // Plumbing
            else if (phases[i].starts_overview_report_group_by === 4) {
                if (!plumbingFinished) {
                    const weeklySoilData = startsOverviewReport.filter(stat => stat.phase_id === 3);
                    const weeklyTopOutData = startsOverviewReport.filter(stat => stat.phase_id === 4);
                    const weeklyTrimData = startsOverviewReport.filter(stat => stat.phase_id === 5);

                    tempStartsReport.push(
                        <View style={styles.phaseHeader}>
                            <Text>SVP</Text>
                            <Text>Plumbing</Text>
                        </View>
                    )

                    let currentWeekDate = new Date(firstSunday);
                    currentWeekDate.setDate(currentWeekDate.getDate() - 1);
                    let outputMonth = true;

                    let totalWeeks = 0;
                    let totalYTDSoil = 0;
                    let totalYTDTopOut = 0;
                    let totalYTDTrim = 0;

                    let weeksInMonth = 0;
                    let monthlySoilCount = 0;
                    let monthlyTopOutCount = 0;
                    let monthlyTrimCount = 0;

                    for (let j = 0; j < numberOfWeeks; j++) {
                        const weekNumber = j + 1;

                        currentWeekDate.setDate(currentWeekDate.getDate() + 7);
                        const currentMonth = currentWeekDate.getMonth();

                        if (outputMonth) {
                            outputMonth = false;

                            tempStartsReport.push(
                                <View style={styles.monthHeader} wrap={false}>
                                    <Text>{months[currentMonth]}</Text>
                                </View>
                            )
                            tempStartsReport.push(
                                <View style={styles.threeColumnHeader} wrap={false}>
                                    <Text style={styles.threeColumnHeader.column1}>Soil</Text>
                                    <Text style={styles.threeColumnHeader.column2}>T/O</Text>
                                    <Text style={styles.threeColumnHeader.column3}>Trim</Text>
                                </View>
                            )
                        }

                        const currentSoilWeekData = weeklySoilData.find(data => data['WEEK(release_date)'] === weekNumber);
                        const currentTopOutWeekData = weeklyTopOutData.find(data => data['WEEK(release_date)'] === weekNumber);
                        const currentTrimWeekData = weeklyTrimData.find(data => data['WEEK(release_date)'] === weekNumber);
                        const currentWeekSoilCount = currentSoilWeekData !== undefined ? currentSoilWeekData.count : 0;
                        const currentWeekTopOutCount = currentTopOutWeekData !== undefined ? currentTopOutWeekData.count : 0;
                        const currentWeekTrimCount = currentTrimWeekData !== undefined ? currentTrimWeekData.count : 0;

                        tempStartsReport.push(
                            <View style={styles.threeColumnRow} wrap={false}>
                                <Text style={styles.threeColumnRow.date}>{dayjs(currentWeekDate).format('MM-DD-YYYY')}</Text>
                                <Text style={styles.threeColumnRow.column1}>{currentWeekSoilCount}</Text>
                                <Text style={styles.threeColumnRow.column2}>{currentWeekTopOutCount}</Text>
                                <Text style={styles.threeColumnRow.column2}>{currentWeekTrimCount}</Text>
                            </View>
                        )

                        totalWeeks++;
                        weeksInMonth++;

                        totalYTDSoil += currentWeekSoilCount;
                        totalYTDTopOut += currentWeekTopOutCount;
                        totalYTDTrim += currentWeekTrimCount;
                        monthlySoilCount += currentWeekSoilCount;
                        monthlyTopOutCount += currentWeekTopOutCount;
                        monthlyTrimCount += currentWeekTrimCount;

                        const nextWeek = new Date(currentWeekDate);
                        nextWeek.setDate(nextWeek.getDate() + 7);
                        if (currentMonth !== nextWeek.getMonth()) {
                            tempStartsReport.push(
                                <View style={styles.threeColumnTotals} wrap={false}>
                                    <Text style={styles.threeColumnTotals.month}>{months[currentMonth]}</Text>
                                    <Text style={styles.threeColumnTotals.weeks}>{weeksInMonth}</Text>
                                    <Text style={styles.threeColumnTotals.column1}>{monthlySoilCount}</Text>
                                    <Text style={styles.threeColumnTotals.column2}>{monthlyTopOutCount}</Text>
                                    <Text style={styles.threeColumnTotals.column2}>{monthlyTrimCount}</Text>
                                </View>
                            )

                            weeksInMonth = 0;
                            monthlySoilCount = 0;
                            monthlyTopOutCount = 0;
                            monthlyTrimCount = 0;

                            outputMonth = true;
                        }
                    }

                    tempStartsReport.push(
                        <View style={styles.ytdHeader} wrap={false}>
                            <Text style={styles.ytdHeader.company}>SVP</Text>
                            <Text style={styles.ytdHeader.phase}>Plumbing</Text>
                            <Text style={styles.ytdHeader.weeks}>WEEKS</Text>
                            <Text style={styles.ytdHeader.ytd}>YTD</Text>
                            <Text style={styles.ytdHeader.average}>AVERAGE</Text>
                            <Text style={styles.ytdHeader.projection}>ANNUAL PROJ</Text>
                        </View>
                    )

                    tempStartsReport.push(
                        <View style={styles.ytdRow} wrap={false}>
                            <Text style={styles.ytdRow.phase}>Soil</Text>
                            <Text style={styles.ytdRow.weeks}>{totalWeeks}</Text>
                            <Text style={styles.ytdRow.ytd}>{totalYTDSoil}</Text>
                            <Text style={styles.ytdRow.average}>{Number.parseInt(totalYTDSoil / totalWeeks)}</Text>
                            <Text style={styles.ytdRow.projection}>{Number.parseInt((totalYTDSoil / totalWeeks) * 52)}</Text>
                        </View>
                    )

                    tempStartsReport.push(
                        <View style={styles.ytdRow} wrap={false}>
                            <Text style={styles.ytdRow.phase}>Top Out</Text>
                            <Text style={styles.ytdRow.weeks}>{totalWeeks}</Text>
                            <Text style={styles.ytdRow.ytd}>{totalYTDTopOut}</Text>
                            <Text style={styles.ytdRow.average}>{Number.parseInt(totalYTDTopOut / totalWeeks)}</Text>
                            <Text style={styles.ytdRow.projection}>{Number.parseInt((totalYTDTopOut / totalWeeks) * 52)}</Text>
                        </View>
                    )

                    tempStartsReport.push(
                        <View style={styles.ytdRow} wrap={false}>
                            <Text style={styles.ytdRow.phase}>Trim</Text>
                            <Text style={styles.ytdRow.weeks}>{totalWeeks}</Text>
                            <Text style={styles.ytdRow.ytd}>{totalYTDTrim}</Text>
                            <Text style={styles.ytdRow.average}>{Number.parseInt(totalYTDTrim / totalWeeks)}</Text>
                            <Text style={styles.ytdRow.projection}>{Number.parseInt((totalYTDTrim / totalWeeks) * 52)}</Text>
                        </View>
                    )
                }

                plumbingFinished = true;
            }

            // All other phases
            else {
                const weeklyData = startsOverviewReport.filter(stat => stat.phase_id === phases[i].id);

                tempStartsReport.push(
                    <View style={styles.phaseHeader}>
                        <Text>{phases[i].company}</Text>
                        <Text>{phases[i].starts_overview_report_name}</Text>
                    </View>
                )

                let currentWeekDate = new Date(firstSunday);
                currentWeekDate.setDate(currentWeekDate.getDate() - 1);
                let outputMonth = true;

                let totalWeeks = 0;
                let totalYTD = 0;
                let weeksInMonth = 0;
                let monthlyCount = 0;

                for (let j = 0; j < numberOfWeeks; j++) {
                    const weekNumber = j + 1;

                    currentWeekDate.setDate(currentWeekDate.getDate() + 7);
                    const currentMonth = currentWeekDate.getMonth();

                    if (outputMonth) {
                        outputMonth = false;

                        tempStartsReport.push(
                            <View style={styles.monthHeader} wrap={false}>
                                <Text>{months[currentMonth]}</Text>
                            </View>
                        )
                    }

                    const currentWeekData = weeklyData.find(data => data['WEEK(release_date)'] === weekNumber);
                    const currentWeekCount = currentWeekData !== undefined ? currentWeekData.count : 0;

                    tempStartsReport.push(
                        <View style={styles.oneColumnRow} wrap={false}>
                            <Text style={styles.oneColumnRow.date}>{dayjs(currentWeekDate).format('MM-DD-YYYY')}</Text>
                            <Text style={styles.oneColumnRow.column1}>{currentWeekCount}</Text>
                        </View>
                    )

                    totalWeeks++;
                    weeksInMonth++;

                    totalYTD += currentWeekCount;
                    monthlyCount += currentWeekCount;

                    const nextWeek = new Date(currentWeekDate);
                    nextWeek.setDate(nextWeek.getDate() + 7);
                    if (currentMonth !== nextWeek.getMonth()) {

                        tempStartsReport.push(
                            <View style={styles.oneColumnTotals} wrap={false}>
                                <Text style={styles.oneColumnTotals.month}>{months[currentMonth]}</Text>
                                <Text style={styles.oneColumnTotals.weeks}>{weeksInMonth}</Text>
                                <Text style={styles.oneColumnTotals.column1}>{monthlyCount}</Text>
                            </View>
                        )

                        weeksInMonth = 0;
                        monthlyCount = 0;

                        outputMonth = true;
                    }
                }

                tempStartsReport.push(
                    <View style={styles.ytdHeader} wrap={false}>
                        <Text style={styles.ytdHeader.company}>{phases[i].company}</Text>
                        <Text style={styles.ytdHeader.phase}>{phases[i].starts_overview_report_name}</Text>
                        <Text style={styles.ytdHeader.weeks}>WEEKS</Text>
                        <Text style={styles.ytdHeader.ytd}>YTD</Text>
                        <Text style={styles.ytdHeader.average}>AVERAGE</Text>
                        <Text style={styles.ytdHeader.projection}>ANNUAL PROJ</Text>
                    </View>
                )

                tempStartsReport.push(
                    <View style={styles.ytdRowNoPhase} wrap={false}>
                        <Text style={styles.ytdRowNoPhase.weeks}>{totalWeeks}</Text>
                        <Text style={styles.ytdRowNoPhase.ytd}>{totalYTD}</Text>
                        <Text style={styles.ytdRowNoPhase.average}>{Number.parseInt(totalYTD / totalWeeks)}</Text>
                        <Text style={styles.ytdRowNoPhase.projection}>{Number.parseInt((totalYTD / totalWeeks) * 52)}</Text>
                    </View>
                )
            }

            if(tempStartsReport.length !== 0){
                tempPages.push(
                    <Page size="LETTER" style={styles.page} wrap>
                        <View style={styles.header} fixed>
                            <Text>Sierra Verde Companies</Text>
                        </View>
                        <View style={styles.footer} fixed>
                            <Text>{dayjs(new Date()).format('MM-DD-YYYY')}</Text>
                            <Text render={(page) => `page ${page.pageNumber} of ${page.totalPages}`} />
                        </View>
                        {tempStartsReport.map(td => td)}
                    </Page>
                )
            }
        }

        // Get Sunday 4 weeks ago
        const fourWeeksAgoSunday = new Date();
        fourWeeksAgoSunday.setDate(fourWeeksAgoSunday.getDate() - 29);
        if (fourWeeksAgoSunday.getDay() !== 0) {
            fourWeeksAgoSunday.setDate(fourWeeksAgoSunday.getDate() + (7 - fourWeeksAgoSunday.getDay()));
        }

        let data = (await Auth.hitEndpoint("GET", "STATS", `selector=twentyone&startDate=${fourWeeksAgoSunday}&endDate=${lastDay}`)).map(stat => {
            stat.release_date = new Date(stat.release_date);
            return stat;
        });
        const startsPerProjectReport = phases.map(phase => {
            return {
                company: phase.company,
                scope: phase.stat_name,
                report: data.filter(dataToFilter => dataToFilter.phase_id === phase.id)
            }
        });

        numberOfWeeks = Math.round((lastDay - fourWeeksAgoSunday) / (7 * 24 * 60 * 60 * 1000));

        // Create Starts Per Project Report
        for (let i = 0; i < startsPerProjectReport.length; i++) {
            if(i === 0){
                tempStartsPerProject.push(
                    <View style={styles.reportHeader}>
                        <Text>Starts Per Project Report</Text>
                    </View>
                )
            }

            tempStartsPerProject.push(
                <View style={styles.companyHeaderSPPR}>
                    <Text>{startsPerProjectReport[i].company}</Text>
                </View>
            )
            tempStartsPerProject.push(
                <View style={styles.phaseHeaderSPPR}>
                    <Text>{startsPerProjectReport[i].scope}</Text>
                </View>
            )

            let startDate = null;
            let endDate = new Date(fourWeeksAgoSunday);

            for (let j = 0; j < numberOfWeeks; j++) {

                startDate = new Date(endDate);
                endDate = new Date(startDate);
                endDate.setDate(endDate.getDate() + 7);

                const weeklyReports = startsPerProjectReport[i].report.filter(report => {
                    return report.release_date >= startDate && report.release_date < endDate;
                }).sort((r1, r2) => {
                    if (r1.name === r2.name) {
                        return r1.project_name < r2.project_name ? -1 : 1;
                    }
                    return r1.name < r2.name ? -1 : 1;
                });

                const currentReport = [];
                for (let k = 0; k < weeklyReports.length; k++) {
                    const currentBuilder = weeklyReports[k].name;
                    if (!currentReport.find(report => report.name === currentBuilder)) {
                        currentReport.push({name: currentBuilder, count: 0, projects: []});
                    }

                    const index = currentReport.findIndex(report => report.name === currentBuilder);
                    const currentProject = weeklyReports[k].project_name;
                    if (!currentReport[index].projects.find(project => project.name === currentProject)) {
                        currentReport[index].projects.push({name: currentProject, count: 1});
                        currentReport[index].count++;
                    } else {
                        const projectIndex = currentReport[index].projects.findIndex(project => project.name === currentProject);
                        currentReport[index].projects[projectIndex].count++;
                        currentReport[index].count++;
                    }
                }

                tempStartsPerProject.push(
                    <View style={styles.weekCountLineSPPR}>
                        <Text style={styles.weekCountLineSPPR.week}>Week Ending:</Text>
                        <Text style={styles.weekCountLineSPPR.date}>{dayjs(endDate).format('MM-DD-YYYY')}</Text>
                        <Text style={styles.weekCountLineSPPR.count}>Count:</Text>
                        <Text style={styles.weekCountLineSPPR.number}>{weeklyReports.length}</Text>
                    </View>
                )


                for (let k = 0; k < currentReport.length; k++) {
                    tempStartsPerProject.push(
                        <View style={styles.builderCountLineSPPR}>
                            <Text style={styles.builderCountLineSPPR.count}>{currentReport[k].count}</Text>
                            <Text style={styles.builderCountLineSPPR.builder}>{currentReport[k].name}</Text>
                        </View>
                    )
                
                    for (let l = 0; l < currentReport[k].projects.length; l++) {
                        tempStartsPerProject.push(
                            <View style={styles.projectCountLineSPPR}>
                                <Text style={styles.projectCountLineSPPR.count}>{currentReport[k].projects[l].count}</Text>
                                <Text style={styles.projectCountLineSPPR.builder}>{currentReport[k].projects[l].name}</Text>
                            </View>
                        )
                    }
                }

                tempStartsPerProject.push(
                    <View style={styles.lineBreakSPPR}>
                        <Text></Text>
                    </View>
                )
            }
        }

        if(tempStartsPerProject.length !== 0){
            tempPages.push(
                <Page key={Math.random()} size="LETTER" style={styles.page} wrap>
                    <View style={styles.header} fixed>
                        <Text>Sierra Verde Companies</Text>
                    </View>
                    <View style={styles.footer} fixed>
                        <Text>{dayjs(new Date()).format('MM-DD-YYYY')}</Text>
                        <Text render={(page) => `page ${page.pageNumber} of ${page.totalPages}`} />
                    </View>
                    {tempStartsPerProject.map(td => td)}
                </Page>
            )
        }

        setData(tempPages);
        setButtonText('Starts Report');
    }

    return (
        <ReporterContainer>
            <div style={{justifySelf: 'center'}}>
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                width: "300px",
                                height: '50px',
                                margin: '10px auto',
                            }
                        },
                        placeholder: styles => {
                            return {
                                ...styles,
                            }
                        }
                    }} 
                    id="overview-report-year-id"
                    className="overview-report-year"
                    placeholder="Year Of Overview Report"
                    defaultValue={{value: (new Date()).getFullYear(), label: (new Date()).getFullYear()}}
                    value={{value: overviewReportYearSelected, label: overviewReportYearSelected}}
                    onChange={(e) => setOverviewReportYearSelected(e)}
                    options={overviewReportYears}
                    isClearable
                    isSearchable
                />
                <Button 
                    className="starts-report" 
                    handleKeyPress={((e) => {
                        if (e.key === 'Enter') {
                            createStartsReport()
                        }
                    })} 
                    handleClick={((e) => createStartsReport())} 
                    buttonText={buttonText} 
                    height="40" 
                    width="300"
                />
            </div>
            <PDFViewer style={styles.viewer}>
                <Document title="Starts Report">
                    {data.map(d => d)}
                </Document>
            </PDFViewer>
        </ReporterContainer>
    );
}

export default StartsReport;
