import styled from 'styled-components';

const CreateUserContainer = styled.div`
    display: grid;
    margin: 10px 0 100px 10px;

    .create-user-title {
        display: grid;
        width: 604px;
        background: rgb(58, 64, 67);
        border-bottom: 2px solid white;
        text-align: center;
        font-size: 18px;
        padding: 3px 0;
        color: white;
    }

    .sub-header {
        width: 604px;
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(128, 128, 128);
        color: white;
        border-bottom: 2px solid white;
    }

    .user-data-input-box {
        width: 604px;
        background: rgb(210, 200, 190);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, 1fr);
        padding: 10px 0;
        border-bottom: 2px solid white;
    }

    .grid-table {
        display: grid;
        box-sizing: border-box;
        width: 604px;
        border: 2px solid black;

        .grid-table-row, .grid-table-header {
            display: grid;
            text-align: center;
            grid-template-columns: 300px 75px 75px 75px 75px;
        }
        
        .grid-table-header {
            // ADD THIS TO GET LOCKING HEADER
            position: -webkit-sticky;
            position: sticky;
            top: 140px;
            z-index: 5;
            
            background: rgb(210, 200, 190); // table header color
        }
        
        .grid-table-row:nth-child(even) {
            background: rgb(230, 230, 220); // table row color
        }
        
        .grid-table-row:nth-child(odd) {
            background: white;
        }
        
        .grid-table-row:hover {
            background: rgb(109, 174, 141);
        }
        
        .grid-table-header-data, .grid-table-row-data {
            display: grid;
            align-items: center;
            padding: 3px 0;
            border: 1px solid black;
        }
        
        .grid-table-row-data.bold {
            font-weight: bold;
            cursor: pointer;
        }
        
        .grid-table-row-data.none-bold {
            font-weight: normal;
            cursor: pointer;
        }
        
        .grid-table-row-data.not-used {
            background: rgb(0, 0, 0);
        }
        
        .grid-table-row-data.left-align {
            padding-left: 5px;
            text-align: left;
        }
        
    }
    .button-box {
        width: 604px;
        height: 40px;
        display: grid;
        justify-content: center;
        align-items: center;
        border-top: 2px solid white;
        background: rgb(210, 200, 190);
        padding: 8px 0 10px;
    }

    .read-write-label{
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        margin: 10px 0 5px;

        .read-label{
            display: grid;
            grid-column: 5/6;
        }

        .write-label{
            display: grid;
            grid-column: 6/7;
        }
    }

    .permissions-display{
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        align-items: center;

        .group-permission{
            display: grid;
            justify-self: end;
            margin-right: 20px;
            grid-column: 1/5;
        }

        .read{
            display: grid;
            grid-column: 5/6;
        }

        .write{
            display: grid;
            grid-column: 6/7;
        }
    }
`;

export default CreateUserContainer;