import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Auth from '../authentication/Auth.js';

import MainHeader from '../utilities/MainHeader';
import ProspectiveEmployeesContainer from './styles/ProspectiveEmployeesContainer';
import Globals from '../utilities/Globals';

const ProspectiveEmployees = (props) => {
    const [prospectiveEmployees, setProspectiveEmployees] = useState([]);
    const [didLoadProspectiveEmployees, setDidLoadProspectiveEmployees] = useState(false);

    const [filterOptions, setFilterOptions] = useState({
        status: 'New',
        job: {
            category: '',
            position: ''
        },
        dl: false,
        cdl: false,
        startDate: null,
        endDate: null
    });

    const canEditProspectiveEmployee = props.isAuthorized('prospectiveEmployees', 'WRITE');

    // Add for paging data
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;

    useEffect(() => {
        const loadProspectiveEmployees = async () => {
            const returnData = await Auth.hitEndpoint("GET", "PROSPECTIVE_EMPLOYEES");
            setProspectiveEmployees([...returnData]);
            setDidLoadProspectiveEmployees(true);
        }

        if(!didLoadProspectiveEmployees){
            loadProspectiveEmployees();
        }
      }, [didLoadProspectiveEmployees]);

    const updateStatus = async (e, id) => {
        // Update page data
        const tempProspectiveEmployees = [...prospectiveEmployees];
        const index = tempProspectiveEmployees.findIndex(pe => pe.id === id);
        tempProspectiveEmployees[index].status = e.target.value;
        setProspectiveEmployees([...tempProspectiveEmployees]);

        // Update database data
        await Auth.hitEndpoint("PATCH", "PROSPECTIVE_EMPLOYEES", "", {id: id, status: e.target.value})
    }
    
    const filterPosition = (e) => {
        e.preventDefault();

        const category = e.target.value.split('^')[0];
        const position = e.target.value.split('^')[1] ? e.target.value.split('^')[1] : "";

        setFilterOptions({...filterOptions, job: {
            category: category,
            position: position
        }})
    }

    const clearFilter = () => {
        setFilterOptions({
            status: '',
            job: {
                category: '',
                position: ''
            },
            dl: false,
            cdl: false,
            startDate: null,
            endDate: null
        });
    }

    const pageDown = () => {
        let page = currentPage;
        page--;
        if(page < 1){
            page = 1;
        }
        setCurrentPage(page);
    }

    const pageUp = (length) => {
        let page = currentPage;
        if(length > page * itemsPerPage){
            page++;
        }
        else{
            return;
        }
        setCurrentPage(page);
    }

    const handleSetPageNumber = (e, length) => {
        // Check for none integer or no input
        if(isNaN(Number.parseInt(e.target.value)) || e.target.value === ''){
            return;
        }
        let page = Number.parseInt(e.target.value);
        if(page < 1){
            page = 1;
        }
        else if(page > Math.ceil(length / itemsPerPage)){
            page = Math.ceil(length / itemsPerPage);
        }
        setCurrentPage(page);
    }
    
    let contentLength = 0;

    const columns = ['Status', 'First Name', 'Last Name', 'Phone Number', 'Email', 'Referred By', 'Position', 'Experience', 'Work Experience', 'DL', 'CDL', 'Comments', 'Submitted On'];
    const allPages = prospectiveEmployees.filter(em => {
        let job = JSON.parse(em.job)
        if(filterOptions.status !== ''){if(filterOptions.status !== em.status){return false;}}
        if(filterOptions.job.position !== ''){
            if(filterOptions.job.category !== job.category){return false;}
            if(filterOptions.job.position !== job.position){return false;}
        }
        if(filterOptions.dl){if(!em.has_driver_license){return false;}}
        if(filterOptions.cdl){if(!em.has_cdl){return false;}}

        if(filterOptions.startDate && filterOptions.startDate > (new Date(em.date))){return false;}
        if(filterOptions.endDate && filterOptions.endDate < (new Date(em.date))){return false;}

        return true;
    }).map((em, index) => {
        contentLength = index + 1;
        return em;
    })

    const content = allPages.slice(currentPage * itemsPerPage - itemsPerPage, currentPage * itemsPerPage);

    const exportToXLSX = async (e) => {
        document.getElementById('id-export-to-xlsx-button').innerHTML = 'Exporting...';

        const exportData = [];
        for(let i = 0; i < allPages.length; i++){

            exportData.push({
                "Status":           allPages[i].status,
                "First Name":       allPages[i].firstname,
                "Last Name":        allPages[i].lastname,
                "Phone #":          allPages[i].phone_number,
                "Email":            allPages[i].email,
                "Referred By":      allPages[i].referred_by,
                "Category":         allPages[i].job.category,
                "Position":         allPages[i].job.position,
                "Experience":       allPages[i].skill_level,
                "DL":               allPages[i].has_driver_license ? 'X' : '',
                "CDL":              allPages[i].has_cdl ? 'X' : '',
                "Employer 1":       allPages[i].work_history.employer1,
                "Employer 2":       allPages[i].work_history.employer2,
                "Employer 3":       allPages[i].work_history.employer3,
                "Comments":         allPages[i].comments,
                "Submitted On":     allPages[i].date ? Globals.formatDateToDisplay(allPages[i].date) : ''
            });
        }

        Globals.exportToXLSXFileFormat(exportData, 'Prospective Employees', './Prospective Employees.xlsx');

        document.getElementById('id-export-to-xlsx-button').innerHTML = 'Export To XLSX';
    }

    return (
        <ProspectiveEmployeesContainer>
            <MainHeader/>
            <div className='top-boxes'>
                <div className='filters'>
                    <div className='filters-header'>Filters</div>
                    <div className='filter-options'>
                        <div className='pe-status-filter'>
                            <label className="pe-status-filter-label" htmlFor='pe-status-filter-label'>Status</label>
                            <select className="pe-status-filter-select" id='pe-status-filter-select' name='pe-status-filter-select' value={filterOptions.status} onChange={(e) => setFilterOptions({...filterOptions, status: e.target.value})}>
                                <option className="pe-status-filter-option" value=''></option>
                                {Globals.getProspectiveEmployeeStatusCodes().map((statusCode, index) => {
                                    return (
                                        <option key={`${statusCode}-${index}`} className="pe-status-filter-option" value={statusCode}>{statusCode}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className='pe-position-filter'>
                            <label className="pe-position-filter-label" htmlFor='pe-position-filter-label'>Position</label>
                            <select className="pe-position-filter-select" id='pe-position-filter-select' name='pe-position-filter-select' onChange={filterPosition}>
                                <option className="pe-position-filter-option" value=''></option>
                                <option className="pe-position-filter-option" value=''>FIELD JOBS</option>
                                {Globals.getProspectiveEmployeeJobs().filter(job => job.category === 'Field').sort((j1, j2) => j1.position < j2.position ? -1 : 1).map((job, index) => {
                                    return (
                                        <option key={`${job.position}-${index}`} className="pe-position-filter-option" value={`Field^${job.position}`}>{`- ${job.position}`}</option>
                                    )
                                })}
                                <option className="pe-position-filter-option" value=''>OFFICE JOBS</option>
                                {Globals.getProspectiveEmployeeJobs().filter(job => job.category === 'Office').sort((j1, j2) => j1.position < j2.position ? -1 : 1).map((job, index)  => {
                                    return (
                                        <option key={`${job.position}-${index}`} className="pe-position-filter-option" value={`Office^${job.position}`}>{`- ${job.position}`}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className='pe-dl-check-box'>
                            <label className="pe-dl-check-box-label" htmlFor='has-dl'>Driver License</label>
                            <input className="pe-dl-check-box-input" type="checkbox" id='has-dl' name='has-dl' checked={filterOptions.dl}
                                onChange={((e) => setFilterOptions({ ...filterOptions, dl: e.target.checked }))}/>
                        </div>
                        <div className='pe-cdl-check-box'>
                            <label className="pe-cdl-check-box-label" htmlFor='has-cdl'>CDL</label>
                            <input className="pe-cdl-check-box-input" type="checkbox" id='has-cdl' name='has-cdl' checked={filterOptions.cdl}
                                onChange={((e) => setFilterOptions({ ...filterOptions, cdl: e.target.checked }))}/>
                        </div>
                        <div className='date-range-filter-box'>
                            <div className='start-date-filter'>
                                <div className="start-date-filter-label">Start Date</div>
                                <div className='start-date-filter-date'>
                                    <DatePicker enableTabLoop={false} selected={filterOptions.startDate} onChange={date => setFilterOptions({...filterOptions, startDate: date})}/>
                                </div>
                            </div>
                            <div className='end-date-filter'>
                                <div className="end-date-filter-label">End Date</div>
                                <div className='end-date-filter-date'>
                                    <DatePicker enableTabLoop={false} selected={filterOptions.endDate} onChange={date => setFilterOptions({...filterOptions, endDate: date})}/>
                                </div>
                            </div>
                        </div>
                        <div className='show-all-submit'>
                            <div tabIndex={0} className='show-all-button' onKeyDown={(e) => {if(e.key === 'Enter'){clearFilter()}}} onClick={(e) => clearFilter()}>Show All</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='table-header'>
                <div className='export-to-xlsx'>
                    <div id='id-export-to-xlsx-button' className='export-to-xlsx-button' onClick={exportToXLSX}>Export To XLSX</div>
                </div>
                <div className='header-title'>Prospective Employees</div>
            </div>
            <div className='grid-table'>
                <div className='grid-table-header'>
                    {columns.map((column, index)=> {
                        return <div key={`header-${index}`} className={`grid-table-header-data index${index}`}>{column}</div>
                    })}
                </div>
                {content.map((pe, index) => {
                    return(
                        <div key={index} className='grid-table-row'>
                            {canEditProspectiveEmployee ?
                            <div className='grid-table-row-data'>
                                <div className='status-drop-down'>
                                    <select className="status-drop-down-select" name='status-drop-down-select' value={pe.status} onChange={((e) => updateStatus(e, pe.id))}>
                                        {Globals.getProspectiveEmployeeStatusCodes().map((statusCode, index) => {
                                            return (
                                                <option key={`${statusCode}-${index}`} className="status-drop-down-option" value={statusCode}>{statusCode}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div> : <div className='grid-table-row-data'>{pe.status}</div>}
                            <div className='grid-table-row-data'>{pe.firstname}</div>
                            <div className='grid-table-row-data'>{pe.lastname}</div>
                            <div className='grid-table-row-data'>{pe.phone_number}</div>
                            <div className='grid-table-row-data'>{pe.email}</div>
                            <div className='grid-table-row-data'>{pe.referred_by}</div>
                            <div className='grid-table-row-data'>{JSON.parse(pe.job).position}</div>
                            <div className='grid-table-row-data'>{pe.skill_level}</div>
                            <div className='grid-table-row-data left-align'>
                                <div>{pe.work_history.employer1}</div>
                                <div>{pe.work_history.employer2}</div>
                                <div>{pe.work_history.employer3}</div>
                            </div>
                            <div className='grid-table-row-data'>{pe.has_driver_license ? '\u2713' : ''}</div>
                            <div className='grid-table-row-data'>{pe.has_cdl ? '\u2713' : ''}</div>
                            <div className='grid-table-row-data add-padding'>{pe.comments}</div>
                            <div className='grid-table-row-data'>{Globals.formatDateToDisplay(pe.date)}</div>
                        </div>
                    )
                })}
            </div>
            <div className='pages'>
                <div className='page-down' onClick={(e) => pageDown()}>{'< '}</div>
                <div className='page-info'>
                    <input id='id-current-page-input' className="current-page-input" type='number' value={currentPage} onClick={() => document.getElementById('id-current-page-input').select()} onChange={(e) => handleSetPageNumber(e, contentLength)}/> of {Math.ceil(contentLength / itemsPerPage)}
                </div>
                <div className='page-up' onClick={(e) => pageUp(contentLength)}>{' >'}</div>
            </div>
        </ProspectiveEmployeesContainer>
    );
}

export default ProspectiveEmployees;