import { useState } from 'react';
import Auth from '../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const EditLot = ({displayMessageWithTimer, editLot, setEditLot, lotToEdit, setLotToEdit, updateLotInLotsArray}) => {
    const [error, setError] = useState({
        type: '',
        message: ''
    })

    const closeWithSave = async (addAnother) => {
        if(lotToEdit.number === ''){
            setError({
                type: 'NO LOT NUMBER ENTERED',
                message: 'Please enter a lot number'
            })
            return;
        }

        const queryString = `lot.id != ${lotToEdit.id} and job_id = ${lotToEdit.job.id} and lot.number = '${lotToEdit.number}' and lot.phase = '${lotToEdit.phase}'`;
        const dupLots = await Auth.hitEndpointNew('GET', 'lots/index-new', `queryString=${queryString}`);
        if(dupLots.length > 0){
            setError({
                type: 'REPEAT LOT',
                message: 'Lot already exists, please enter an original lot'
            })
            return;
        }

        const dbObj = {
            id: lotToEdit.id,
            active: lotToEdit.active,
            number: lotToEdit.number,
            phase: lotToEdit.phase,
            address: lotToEdit.address,
            plan_number: lotToEdit.plan_number,
            all_tasks_multi_schedule: lotToEdit.all_tasks_multi_schedule,
            allow_schedule_no_release: lotToEdit.allow_schedule_no_release
        }
        await Auth.hitEndpointNew('PATCH', 'lot/no-processing', '', dbObj);

        displayMessageWithTimer('Lot Updated', 'green', 5000);
        updateLotInLotsArray(lotToEdit);
        setLotToEdit(null);
        setEditLot(false);
    }

    const closeNoSave = () => {
        displayMessageWithTimer('No Lots Edited', 'green', 5000);
        setLotToEdit(null);
        setEditLot(false);
    }

    return (
        <Dialog open={editLot}>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Edit Lot</DialogTitle>
            <DialogContent sx={{display: 'grid', minWidth: '600px'}}>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '15px 0 5px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Builder:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {lotToEdit.job.builder.name}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '5px 0'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Job #:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {lotToEdit.job.number}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '5px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Project Name:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {lotToEdit.job.project_name}
                    </Box>
                </Box>
                <FormControlLabel 
                    sx={{
                        width: '60%',
                        margin: '5px auto',
                    }}
                    control={<Checkbox 
                        defaultChecked={lotToEdit.active !== '0'} 
                        onChange={(e) => setLotToEdit({...lotToEdit, active: e.target.checked})}/>} 
                    label="Active" 
                />
                <Box sx={{display: 'grid'}}>
                    <TextField
                        sx={{
                            width: '60%',
                            margin: '10px auto',
                            backgroundColor: error.type === "NO LOT NUMBER ENTERED" ? 'rgb(255, 150, 150)' : 'white',
                        }}
                        label="Lot #"
                        placeholder="Enter Lot #"
                        value={lotToEdit.number}
                        onChange={(e) => setLotToEdit({...lotToEdit, number: e.target.value})}
                    />
                </Box>
                <Box sx={{display: 'grid'}}>
                    <TextField
                        sx={{
                            width: '60%',
                            margin: '10px auto'
                        }}
                        label="Phase ID"
                        placeholder="Enter Phase ID"
                        value={lotToEdit.phase}
                        onChange={(e) => setLotToEdit({...lotToEdit, phase: e.target.value})}
                    />
                </Box>
                <Box sx={{display: 'grid'}}>
                    <TextField
                        sx={{
                            width: '60%',
                            margin: '10px auto'
                        }}
                        label="Address"
                        placeholder="Enter Address"
                        value={lotToEdit.address}
                        onChange={(e) => setLotToEdit({...lotToEdit, address: e.target.value})}
                    />
                </Box>
                <Box sx={{display: 'grid'}}>
                    <TextField
                        sx={{
                            width: '60%',
                            margin: '10px auto'
                        }}
                        label="Plan ID"
                        placeholder="Enter Plan ID"
                        value={lotToEdit.plan_number}
                        onChange={(e) => setLotToEdit({...lotToEdit, plan_number: e.target.value})}
                    />
                </Box>
                <FormControlLabel 
                    sx={{
                        width: '60%',
                        margin: '5px auto',
                    }}
                    control={<Checkbox 
                        defaultChecked={lotToEdit.allow_schedule_no_release !== 0} 
                        onChange={(e) => setLotToEdit({...lotToEdit, allow_schedule_no_release: e.target.checked})}/>} 
                    label="Allow Pre-Release" 
                />
                <FormControlLabel 
                    sx={{
                        width: '60%',
                        margin: '5px auto',
                    }}
                    control={<Checkbox 
                        defaultChecked={lotToEdit.all_tasks_multi_schedule !== 0} 
                        onChange={(e) => setLotToEdit({...lotToEdit, all_tasks_multi_schedule: e.target.checked})}/>} 
                    label="Can Multi-Schedule" 
                />
                {error.type === 'REPEAT LOT' &&
                <Dialog open={error.type !== ''}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 150, 150)', fontWeight: 'bold'}}>{error.type}</DialogTitle>
                    <DialogContent 
                        sx={{width: "600px", backgroundColor: 'rgb(255, 150, 150)'}}>{error.message}</DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 150, 150)'}}>
                        <Button 
                            sx={{
                                backgroundColor: 'rgb(150, 150, 255)'
                            }}
                            variant='contained'
                            onClick={() => {
                                setError({
                                    type: '',
                                    message: ''
                                })
                            }}
                        >OK</Button>
                    </DialogActions>
                </Dialog>}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={closeWithSave}>Update</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditLot;
