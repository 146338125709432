import {useState} from "react";
import dayjs from "dayjs";
import Schedules from "../../../classes/Schedules";
import Crews from "../../../classes/Crews";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Select from "react-select";
import Employees from "../../../classes/Employees";
import { DropDownWithLabel } from "../../utilities/DropDown";
import SchedulePDF from "../../../classes/reports/SchedulePDF";
import {Button} from "../../utilities/Button";


const ScheduleFilters = ({useDate, useField, useSuper, title,
                             selectedDate, setSelectedDate,
                             selectedCrewIds, setSelectedCrewIds, selectedEmployeeIds, setSelectedEmployeeIds,
                             selectedReports, setReportData,
                             userId, position, setTasks, setPdfUrl, setIsLoading}) => {

    const [didLoadData, setDidLoadData] = useState(false)
    const [supers, setSupers] = useState([])
    const [filterFields, setFilterFields] = useState([])

    const [filterCrews, setFilterCrews] = useState([])
    const [filterEmployees, setFilterEmployees] = useState([])
    const [loadingWhat, setLoadingWhat] = useState("")

    // const [sortBy, setSortBy] = useState("");

    const [selectedCrews, setSelectedCrews] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([])
    // const sortingOptions = [
    //     {id: "Phase Task", display: "Phase Task"},
    //     {id: "Crew", display: "Crew"}
    // ];

    const loadData = async () => {
        setIsLoading(true)
        setDidLoadData(true);

        if (useField) {
            setLoadingWhat("Field")
            setFilterFields(await Schedules.loadFields(null))
        }
        if (useSuper) {
            setLoadingWhat("Supers")
            setSupers(await Schedules.loadSupers(null))
        }
        setLoadingWhat("Crews")
        setFilterCrews(await Crews.loadCrewsForDropdown(null))
        setFilterEmployees(await Employees.loadEmployeesForDropDown(null))

        setIsLoading(false)
        //TODO comment this out before deployment
        await loadSchedule(selectedDate, selectedCrewIds, selectedEmployeeIds)
        setLoadingWhat("")
    }

    if (!didLoadData) {
        loadData();
    }

    const loadSchedule = async (date, crews, employees) => {
        setIsLoading(true)
        let reportIds = []
        for(let r=0; r<selectedReports.length; r++){
            reportIds.push(selectedReports[r].id)
        }
        let report = await Schedules.loadScheduleReportData(position, userId, date, [], crews, employees, reportIds.length === 0 ? null : reportIds)
        sortReport(report);
        setReportData(report)
        setTasks(report.tasks)
        setPdfUrl(await SchedulePDF.createReport(date, report))
        setIsLoading(false)
    }

    const handleDateChanged = async date => {
        setSelectedDate(date)
        await loadSchedule(date, selectedCrewIds, selectedEmployeeIds)
    }

    const handleCrewChange = async (selections) => {
        let crews = []
        for (let i = 0; i < selections.length; i++) {
            crews.push(selections[i].value)
        }
        setSelectedCrews(selections)
        setSelectedCrewIds(crews)
        await loadSchedule(selectedDate, crews, selectedEmployeeIds)
    }

    const handleEmployeeChange = async (selections) => {
        let employees = []
        for (let i = 0; i < selections.length; i++) {
            employees.push(selections[i].value)
        }
        setSelectedEmployees(selections)
        setSelectedEmployeeIds(employees)
        await loadSchedule(selectedDate, selectedCrewIds, employees)
    }

    //TODO need to figure out a way to rework this if it is needed. Turning it off for now.
    const sortReport = (report) => {
        //
        // report.sort((d1, d2) => {
        //     switch(sortBy){
        //         case "Phase Task":
        //             // Pre-sort each data points schedule
        //             d1.data.sort((s1, s2) => {
        //                 const s1Task = report.tasks.find(t => parseInt(t.id) === parseInt(s1.phase_task_id));
        //                 const s2Task = report.tasks.find(t => parseInt(t.id) === parseInt(s2.phase_task_id));
        //                 if(!s1Task || !s2Task){
        //                     return 1;
        //                 }
        //                 return parseInt(s1Task.order_by) < parseInt(s2Task.order_by) ? -1 : 1;
        //             })
        //
        //             // Sort by first phase task scheduled
        //             const d1Task = report.tasks.find(t => parseInt(t.id) === parseInt(d1.schedule[0].phase_task_id));
        //             const d2Task = report.tasks.find(t => parseInt(t.id) === parseInt(d2.schedule[0].phase_task_id));
        //             if(!d1Task || !d2Task){
        //                 return 1;
        //             }
        //             return parseInt(d1Task.order_by) < parseInt(d2Task.order_by) ? -1 : 1;
        //         case "Crew":
        //             return parseInt(d1.crew) < parseInt(d2.crew) ? -1 : 1;
        //         default:
        //             return 1;
        //     }
        // })
    }

    // const handleSortBy = async (e) => {
    //     setSortBy(e.target.value);
    //     setDidLoadData(false);
    // }

    return (
        <fieldset style={{width: window.innerWidth, borderStyle: "solid"}}>
            <legend>Report Filters</legend>
            <div style={{width: window.innerWidth, backgroundColor: title === 'Crew' ? "lightgreen" : "lightgray"}}>
                <div
                    style={{paddingTop: 15, width: "100%", textAlign: "center", fontSize: "x-large"}}>{title} Schedule
                </div>
                <div style={{width: "75%", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "10px"}}>
                    <fieldset style={{borderStyle: "none"}}>
                        <div>
                            {useDate &&
                                <div style={{float: "left", width: "15%", overflow: "auto"}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            onChange={(newValue) => {
                                                handleDateChanged(newValue);
                                            }}
                                            slotProps={{
                                                textField: {
                                                    helperText: "",
                                                    sx: {minWidth: "120px"},
                                                    variant: "standard",
                                                },
                                            }}
                                            defaultValue={dayjs(selectedDate)}
                                            selected={selectedDate}
                                        />
                                    </LocalizationProvider>
                                    <small id="scheduleDateFilter" style={{fontSize: "x-large", color: "#686868"}}>Schedule
                                        Date</small>
                                </div>}
                                {/*{useDate &&*/}
                                {/*<div style={{float: "left", width: "40%", overflow: "auto", fontSize: "medium"}}>*/}
                                {/*    <DropDownWithLabel */}
                                {/*        className="sort-by" */}
                                {/*        id="sort-by-id" */}
                                {/*        name="sort-by" */}
                                {/*        label="Sort By" */}
                                {/*        handleDropDown={handleSortBy} */}
                                {/*        addBlankOption={true} */}
                                {/*        selectionList={sortingOptions} */}
                                {/*        columns={10} */}
                                {/*        labelStart={1} */}
                                {/*        labelEnd={4} */}
                                {/*        inputStart={4} */}
                                {/*        inputEnd={9}*/}
                                {/*    />*/}
                                {/*</div>}*/}
                                {/*<div>*/}
                                {/*    <div style={{float: "left", width: "30%"}}>*/}
                                {/*        <Select*/}
                                {/*            value={selectedCrews}*/}
                                {/*            placeholder="Filter by Crew"*/}
                                {/*            isMulti*/}
                                {/*            options={filterCrews.map((item, i) => (*/}
                                {/*                {*/}
                                {/*                    value: item.id,*/}
                                {/*                    label: item.display*/}
                                {/*                }*/}
                                {/*            ))}*/}
                                {/*            onChange={(e) => {*/}
                                {/*                handleCrewChange(e);*/}
                                {/*            }}*/}
                                {/*            styles={{width: "50%"}}*/}
                                {/*        />*/}
                                {/*        <small id="crewFilter"*/}
                                {/*               style={{fontSize: "small", color: "#686868"}}>Crew</small>*/}
                                {/*    </div>*/}
                                {/*    <div style={{float: "left", width: "30%"}}>*/}
                                {/*        <Select*/}
                                {/*            value={selectedEmployees}*/}
                                {/*            placeholder="Filter by Employee"*/}
                                {/*            isMulti*/}
                                {/*            options={filterEmployees.map((item, i) => (*/}
                                {/*                {*/}
                                {/*                    value: item.id,*/}
                                {/*                    label: item.display*/}
                                {/*                }*/}
                                {/*            ))}*/}
                                {/*            onChange={(e) => {*/}
                                {/*                handleEmployeeChange(e);*/}
                                {/*            }}*/}
                                {/*            styles={{width: "50%"}}*/}
                                {/*        />*/}
                                {/*        <small id="crewFilter" style={{fontSize: "small", color: "#686868"}}>Employee</small>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            <div>
                                <Button className="starts-report" handleKeyPress={(async (e) => {
                                if (e.key === 'Enter') {
                                    await loadSchedule(selectedDate, selectedCrewIds, selectedEmployeeIds)
                                }
                            })} handleClick={(async(e) => await loadSchedule(selectedDate, selectedCrewIds, selectedEmployeeIds))} buttonText="Run Report" height="40" width="300"/>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </fieldset>
    )
}

export default ScheduleFilters;
