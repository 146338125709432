import md5 from "md5";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import { getOptions, postOptions, putOptions, deleteOptions } from "../utilities/api";

import Auth from '../authentication/Auth.js';

import logo from './../../img/HeaderLogo.png';
import Popup, {PopupFailureColor, PopupWarningColor} from '../utilities/Popup.js';
import LoginContainer from './styles/LoginContainer.js';

const Login = (props) => {
    const [authenticateUser, setAuthenticateUser] = useState(false);
    const [userData, setUserData] = useState({
       // username: 'gflores',
       // password: 'Ant0n1na'
        username: '',
        password: ''
    });
    const [errorDB, setErrorDB] = useState(false);
    const [errorUsernameLength, setErrorUsernameLength] = useState(false);
    const [errorPasswordLength, setErrorPasswordLength] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        const authenticate = async () => {
            try {
                // Checks password and creates JWT
                const returnedData = await Auth.hitEndpoint("POST", "LOGIN", "", userData);
                if(!returnedData.success){
                    setErrorDB(true);
                    return;
                }

                // Inform Auth about successful login and send token
                Auth.login(returnedData);
                try{
                    props.setInitialTimeout();
                }
                catch(e){
                    console.log(e)
                }
                if(userData.username === 'svcfield'){
                    navigate('/daily-schedule');
                }
                else{
                   navigate("/home");
               }

            } catch (error) {
                setErrorDB(true);
                console.log(error);
            }
        }

        if(authenticateUser){
            authenticate();
            setAuthenticateUser(false);
        }
    }, [authenticateUser, userData, navigate, props]);


    const handleSubmit = (e) => {
        e.preventDefault();

        if(userData.username.length < 1){setErrorUsernameLength(true);return;}
        if(userData.password.length < 1){setErrorPasswordLength(true);return;}

        setUserData({...userData, username: userData.username.toLowerCase(), password: md5(userData.password)});
        setAuthenticateUser(true);
        
    }

    const handlePopup = (e) => {
        setErrorDB(false);
        setErrorUsernameLength(false);
        setErrorPasswordLength(false);
    }

    return (
        <LoginContainer>
            <div className="login-logo"><img src={logo} alt="SVC Logo"/></div>
            <div className='login-header'>Login</div>
            <form id="login" onSubmit={handleSubmit}>
                <input type="text" autoComplete="username" defaultValue={userData.username} hidden/>
                <div className="login-textbox username-textbox">
                    <label className="login-label" htmlFor="username">User Name</label>
                    <input className="login-input" type="username" autoComplete="username"
                           id="username" name="username" placeholder="Enter Username" value={userData.username}
                        onChange={((e) => setUserData({ ...userData, username: e.target.value }))}/>
                </div>
                <div className="login-textbox password-textbox">
                    <label className="login-label" htmlFor="password">Password</label>
                    <input className="login-input" type="password" autoComplete="password"
                           id="password" name="password" placeholder="Enter Password" value={userData.password}
                        onChange={((e) => setUserData({ ...userData, password: e.target.value }))}/>
                </div>
                <input className="form-submit" type="submit" value="Login"/>
            </form>
            {errorDB && <Popup color={PopupFailureColor} message={'Error: May have entered a wrong username and/or password'} handlePopup={handlePopup}/>}
            {errorUsernameLength && <Popup color={PopupWarningColor} message={'Please enter a username'} handlePopup={handlePopup}/>}
            {errorPasswordLength && <Popup color={PopupWarningColor} message={'Please enter a password'} handlePopup={handlePopup}/>}

        </LoginContainer>
    );
}

export default Login;

