import {useState} from 'react';
import dayjs from "dayjs";

import Auth from '../../authentication/Auth.js';
import { Button } from '../../utilities/Button';
import Select from "react-select";
import ReporterContainer from '../styles/ReporterContainer';

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    viewer: {
        margin: '20px auto',
        width: '1200px',
        height: '500px',
    },
    page: {
        paddingBottom: '17mm',
        backgroundColor: 'white',
        width: '216mm',
        height: '280mm'
      },
    companyInfo: {
        margin: '7mm auto 0',
        fontSize: '10mm',
        width: '43%',
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        borderBottom: '1mm solid black'
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '15mm',
        paddingRight: '15mm',
        top: '170mm',
        width: '100%',
        fontSize: '3mm',
    },
    docTitle: {
        margin: '3mm auto 0',
        padding: '2mm 0',
        fontSize: '8mm',
        width: '80%',
        textAlign: 'center',
        fontFamily: 'Helvetica',
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',

        columns: {
            marginLeft: '1mm',
            marginRight: '1mm',
            paddingTop: '1.5mm',
            paddingBottom: '1.5mm',
            backgroundColor: 'rgb(220, 220, 220)',
            fontFamily: 'Helvetica',
            fontSize: '3mm',
            textAlign: 'center',
        },
        date1: {
            width: '30mm'
        },
        time1: {
            width: '30mm'
        },
        date2: {
            marginLeft: '30mm',
            width: '30mm'
        },
        time2: {
            width: '30mm'
        },
        date3: {
            marginLeft: '30mm',
            width: '30mm'
        },
        time3: {
            width: '30mm'
        },
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '2pt',

        columns: {
            marginLeft: '1mm',
            marginRight: '1mm',
            paddingTop: '1.5mm',
            paddingBottom: '1.5mm',
            fontFamily: 'Helvetica',
            fontSize: '3mm',
            textAlign: 'center',
        },
        date1: {
            width: '30mm'
        },
        time1: {
            width: '30mm'
        },
        date2: {
            marginLeft: '30mm',
            width: '30mm'
        },
        time2: {
            width: '30mm'
        },
        date3: {
            marginLeft: '30mm',
            width: '30mm'
        },
        time3: {
            width: '30mm'
        },
    },
});

const UserLogin = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [users, setUsers] = useState([]);

    const [name, setName] = useState('')
    const [logins, setLogins] = useState([]);

    const [loadParameters, setLoadParameters] = useState({
        user: null,
        startDate: null,
        endDate: null,
    })

    const [buttonText, setButtonText] = useState('Create User Login Report');
    const [errorCode, setErrorCode] = useState('');

    const loadData = async () => {
        setDidLoadData(true);
        let tempUsers = await Auth.hitEndpointNew("GET", "users/logs");
        tempUsers.map (u => {
            u.value = u.id;
            u.label = `${u.firstname} ${u.lastname}`;
            return u;
        }).sort((u1, u2) => {
            if (u1.firstname === u2.firstname) {
                return u1.lastname < u2.lastname ? -1 : 1;
            }
            return u1.firstname < u2.firstname ? -1 : 1;
        });

        setUsers(tempUsers);
    }

    if(!didLoadData){
        loadData()
    }

    const createUserLoginReport = async () => {
        setButtonText('Fetching Report...');

        if(loadParameters.user === null){
            setErrorCode("ERROR_NO_USER");
            setButtonText('Create User Login Report');
            return;
        }

        setName(`${loadParameters.user.firstname} ${loadParameters.user.lastname}`);

        // Create filtered and mapped logins
        let tempLoginsRaw = loadParameters.user.log.filter(l => {
            if(loadParameters.startDate !== null && loadParameters.endDate !== null){
                if(new Date(loadParameters.startDate) > new Date(l.date) && new Date(loadParameters.endDate) <= new Date(l.date)){
                    return false;
                }
            }
            else if(loadParameters.startDate !== null){
                if(new Date(loadParameters.startDate) > new Date(l.date)){
                    return false;
                }
            }
            else if(loadParameters.endDate !== null){
                if(new Date(loadParameters.endDate) <= new Date(l.date)){
                    return false;
                }
            }

            return true;
        }).map(l => {
            const loginDate = new Date(l.date)
            return {
                date: dayjs(loginDate).format('MM-DD-YYYY'),
                time: loginDate.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', second: '2-digit'})
            }
        })

        // Format logins for pdf display
        const rowsPerCol = 22;
        let left = 0;
        const tempLogins = [];
        while(true){
            const middle = left + rowsPerCol;
            const right = middle + rowsPerCol;
            const row = {};

            if(left < tempLoginsRaw.length){
                row.date1 = tempLoginsRaw[left].date;
                row.time1 = tempLoginsRaw[left].time;
            }
            else{
                break;
            }

            if(middle < tempLoginsRaw.length){
                row.date2 = tempLoginsRaw[middle].date;
                row.time2 = tempLoginsRaw[middle].time;
            }
            else{
                row.date2 = null;
                row.time2 = null;
            }

            if(right < tempLoginsRaw.length){
                row.date3 = tempLoginsRaw[right].date;
                row.time3 = tempLoginsRaw[right].time;
            }
            else{
                row.date3 = null;
                row.time3 = null;
            }

            tempLogins.push(row);
            left++;
            if(left % rowsPerCol === 0){
                left += 2 * rowsPerCol;
            }
        }
        
        setLogins(tempLogins);
        setErrorCode('');

        setButtonText('Create User Login Report');
    }

    return (
        <ReporterContainer>
            <div style={{justifySelf: 'center'}}>
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                width: "300px",
                                height: '50px',
                                margin: '10px auto',
                                backgroundColor: errorCode === "ERROR_NO_USER" ? 'rgb(230, 100, 100)' : 'white',
                            }
                        },
                        placeholder: styles => {
                            return {
                                ...styles,
                                color: errorCode === "ERROR_NO_USER" ? 'black' : 'gray',
                            }
                        }
                    }} 
                    id="user-id"
                    className="user"
                    placeholder="Select A User"
                    value={loadParameters.user}
                    onChange={(e) => setLoadParameters({...loadParameters, user: e})}
                    options={users}
                    isClearable
                    isSearchable
                />
                <div style={{justifySelf: "center", width: "300px", marginBottom: "10px"}}>
                    <div style={{marginBottom: "5px"}}>Start Date(Optional)</div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => setLoadParameters({...loadParameters, startDate: new Date(date)})}
                            slotProps={{
                                field: { clearable: true },
                                textField: {
                                    helperText: '',
                                    sx: {width: '100%', minWidth: '120px'},
                                },
                            }}
                            value={loadParameters.startDate}
                            selected={loadParameters.startDate}
                        />
                    </LocalizationProvider>
                </div>
                <div style={{justifySelf: "center", width: "300px", marginBottom: "20px"}}>
                    <div style={{marginBottom: "5px"}}>End Date(Optional)</div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => setLoadParameters({...loadParameters, endDate: new Date(date)})}
                            slotProps={{
                                field: { clearable: true },
                                textField: {
                                    helperText: '',
                                    sx: {width: '100%', minWidth: '120px'},
                                },
                            }}
                            selected={loadParameters.endDate}
                        />
                    </LocalizationProvider>
                </div>
                <Button 
                    className="gates-report" 
                    handleKeyPress={((e) => {
                        if (e.key === 'Enter') {
                            createUserLoginReport()
                        }
                    })} 
                    handleClick={((e) => createUserLoginReport())} 
                    buttonText={buttonText} 
                    height="40" 
                    width="300"
                />
            </div>
            <PDFViewer style={styles.viewer}>
                <Document title="User Login Report">
                    <Page size="LETTER" orientation="landscape"  style={styles.page} wrap>
                        <View style={styles.companyInfo} fixed>
                            <Text>Sierra Verde Companies</Text>
                        </View>
                        <View style={styles.docTitle} fixed>
                            <Text>{`User Login Report - ${name}`}</Text>
                        </View>
                        <View style={styles.footer} fixed>
                            <Text>{dayjs(new Date()).format('MM-DD-YYYY')}</Text>
                            <Text render={(page) => `page ${page.pageNumber} of ${page.totalPages}`} />
                        </View>
                        <View style={styles.tableHeader} fixed>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.date1]}>Date</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.time1]}>Time</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.date2]}>Date</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.time2]}>Time</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.date3]}>Date</Text>
                            <Text style={[styles.tableHeader.columns, styles.tableHeader.time3]}>Time</Text>
                        </View>
                        <View wrap>
                            {logins.map((l, index) => {
                                const color = index % 2 === 0 ? 'rgb(250, 250, 204)' : 'white';
                                return (
                                    <View key={`${l.id}-${index}`} style={styles.tableRow}>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.date1]}>{l.date1}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.time1]}>{l.time1}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.date2]}>{l.date2}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.time2]}>{l.time2}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.date3]}>{l.date3}</Text>
                                        <Text style={[styles.tableRow.columns, {backgroundColor: color}, styles.tableRow.time3]}>{l.time3}</Text>
                                    </View>
                                )
                            })}
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </ReporterContainer>
    );
}

export default UserLogin;
