import { useState } from 'react';
import Auth from '../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';

const EditBuilder = ({displayMessageWithTimer, updateBuildersArray, builders,
                        editBuilder, setEditBuilder, builderToEdit, setBuilderToEdit}) => {

    const [error, setError] = useState({
        type: '',
        message: ''
    })

    const closeWithSave = async () => {
        if(builderToEdit.name === ''){
            setError({
                type: 'NAME ERROR',
                message: "Please enter builder's name"
            })
            return;
        }

        if(builders.find(b => b.name === builderToEdit.name && b.id !== builderToEdit.id)){
            setError({
                type: 'NAME ERROR',
                message: 'Builder already exists, please enter a new builder'
            })
            return;
        }

        const dbObj = {
            id: builderToEdit.id,
            active: builderToEdit.active,
            created_at: dayjs(new Date(builderToEdit.created_at)).format('YYYY-MM-DD'),
            created_by: builderToEdit.created_by,
            modified_at: dayjs(new Date()).format('YYYY-MM-DD'),
            modified_by: await Auth.getUserId(),
            name: builderToEdit.name
        }
        await Auth.hitEndpointNew('PATCH', 'builders', '', dbObj);

        await updateBuildersArray();
        displayMessageWithTimer('Builder Updated', 'green', 5000);
        setBuilderToEdit(null);
        setEditBuilder(false);
    }

    const closeNoSave = () => {
        displayMessageWithTimer('No Builder Updated', 'red', 5000);
        setBuilderToEdit(null);
        setEditBuilder(false);
    }

    return (
        <Dialog open={editBuilder}>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Edit Builder</DialogTitle>
            <DialogContent sx={{display: 'grid', minWidth: '600px'}}>
                <TextField
                    sx={{
                        width: '100%',
                        margin: '10px auto',
                        backgroundColor: error.type === "NAME ERROR" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    label="Builder Name:"
                    placeholder="Enter Bulder Name"
                    value={builderToEdit.name}
                    onChange={(e) => setBuilderToEdit({...builderToEdit, name: e.target.value})}
                />
                <FormControlLabel 
                    control={<Checkbox 
                        defaultChecked={builderToEdit.active === 1} 
                        onChange={(e) => setBuilderToEdit({...builderToEdit, active: e.target.checked})}/>} 
                    label="Active" 
                />
                {error.type === 'NAME ERROR' &&
                <Dialog open={error.type !== ''}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 150, 150)', fontWeight: 'bold'}}>{error.type}</DialogTitle>
                    <DialogContent 
                        sx={{width: "600px", backgroundColor: 'rgb(255, 150, 150)'}}>{error.message}</DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 150, 150)'}}>
                        <Button 
                            sx={{
                                backgroundColor: 'rgb(150, 150, 255)'
                            }}
                            variant='contained'
                            onClick={() => {
                                setError({
                                    type: '',
                                    message: ''
                                })
                            }}
                        >OK</Button>
                    </DialogActions>
                </Dialog>}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={closeWithSave}>Update</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditBuilder;