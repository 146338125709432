import styled from 'styled-components';

const ProspectiveEmployeesContainer = styled.div`
    display: grid;
    margin: 10px 0 50px 10px;

    .top-boxes{
        display: grid;
        grid-template-columns: 600px 1fr;
        width: 900px;
        border-bottom: 10px solid white;

        .filters{
            display: grid;
            background: rgb(210, 200, 190);

            .filters-header{
                display: grid;
                align-items: center;
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                height: 28px;
                background: rgb(58, 64, 67);
                color: white;
                border-bottom: 3px solid white;
            }

            .filter-options{
                display: grid;
                padding: 10px 0;

                .pe-status-filter,
                .pe-position-filter,
                .pe-dl-check-box,
                .pe-cdl-check-box,
                .date-range-filter-box{
                    display: grid;
                    grid-template-columns: repeat(20, 1fr);
                    margin: 3px 0;

                    .pe-status-filter-label, .pe-position-filter-label,
                    .pe-dl-check-box-label, .pe-cdl-check-box-label{
                        display: grid;
                        grid-column: 1/9;
                        padding-right: 5px;
                        justify-content: end;
                    }

                    .pe-status-filter-select, .pe-position-filter-select{
                        display: grid;
                        grid-column: 9/18;
                    }

                    .pe-dl-check-box-input, .pe-cdl-check-box-input{
                        display: grid;
                        grid-column: 9/10;
                    }

                    .start-date-filter{
                        display: grid;
                        grid-column: 1/11;
                        grid-template-columns: repeat(10, 1fr);

                        .start-date-filter-label{
                            display: grid;
                            grid-column: 1/5;
                            padding-right: 5px;
                            justify-content: end;
                        }

                        .start-date-filter-date{
                            display: grid;
                            grid-column: 5/10;
                        }
                    }

                    .end-date-filter{
                        display: grid;
                        grid-column: 11/21;
                        grid-template-columns: repeat(10, 1fr);

                        .end-date-filter-label{
                            display: grid;
                            grid-column: 1/4;
                            padding-right: 5px;
                            justify-content: end;
                        }

                        .end-date-filter-date{
                            display: grid;
                            grid-column: 4/10;
                        }
                    }
                }

                .show-all-submit{
                    display: grid;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;
        
                    .show-all-button{
                        width: 100px;
                        height: 22px;
                        background: rgb(240, 240, 240);
                        border: 1px solid rgb(120, 120, 120);
                        border-radius: 3px;
                        display: grid;
                        justify-content: center;
                        align-items: center;
                        cursor: default;
        
                        :hover {
                            background: rgb(230, 230, 230);
                        }
                    }
                }
            }
        }
    }

    .table-header {
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        grid-template-rows: 1fr;
        width: 1950px;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        border-bottom: 3px solid white;

        .export-to-xlsx{
            display: grid;
            grid-column: 1/3;
            grid-row: 1/2;
            justify-content: center;
            align-items: center;

            .export-to-xlsx-button{
                width: 130px;
                height: 22px;
                background: rgb(240, 240, 240);
                border: 1px solid rgb(120, 120, 120);
                border-radius: 3px;
                display: grid;
                justify-content: center;
                align-items: center;
                cursor: default;

                :hover {
                    background: rgb(230, 230, 230);
                }
            }
        }

        .header-title{
            display: grid;
            justify-content: center;
            grid-column: 8/14;
            padding: 3px 0;
            font-size: 18px;
            color: white;
        }
    }

    .grid-table {
        display: grid;
        width: 1950px;
        border-right: 2px solid black;   // ADD THIS TO GET LOCKING HEADER
        border-bottom: 2px solid black;  // ADD THIS TO GET LOCKING HEADER
        border-left: 2px solid black;    // ADD THIS TO GET LOCKING HEADER
    }

    .grid-table-row, .grid-table-header {
        display: grid;
        grid-template-columns: 100px 140px 180px 150px 250px 220px
                               150px 100px 210px 38px 38px 260px
                               110px;
    }

    .grid-table-header {
        display: grid;
        // ADD THIS TO GET LOCKING HEADER
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        z-index: 5;
        border-top: 3px solid black;
        // ********************************
        
        background: rgb(210, 200, 190); // table header color
    }

    .grid-table-row:nth-child(even) {
        background: rgb(230, 230, 220); // table row color
    }

    .grid-table-row:nth-child(odd) {
        background: white;
    }

    .grid-table-header-data, .grid-table-row-data {
        display: grid;
        // justify-content: center;
        // align-items: center;
        text-align: center;
        padding: 3px 0;
        border: 1px solid black;
    }

    .left-align{
        padding-left: 5px;
        text-align: left;

    }

    .add-padding{
        padding-left: 3px;
        padding-right: 3px;
    }

    .pages {
        position: fixed;
        bottom: 30px;
        left: 50px;
        display: grid;
        grid-template-columns: 30px 100px 30px;
        background:  rgb(58, 64, 67);
        padding: 5px;

        .page-down {
            grid-column: 1/2;
            color: white;
            font-weight: bold;
            justify-self: center;
            align-self: center;
            cursor: pointer;
        }

        .page-info {
            grid-column: 2/3;
            justify-self: center;
            color: white;

            .current-page-input {
                width: 30px;
            }
        }

        .page-up {
            grid-column: 3/4;
            color: white;
            font-weight: bold;
            justify-self: center;
            align-self: center;
            cursor: pointer;
        }
    }
`;

export default ProspectiveEmployeesContainer;