import styled from 'styled-components';

const UserPermissionsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 10px 0 100px 10px;
    width: 1600px;

    .permission-page{
        display: grid;
        grid-column: 1/2;
        grid-auto-rows: min-content;
        background: rgb(210, 200, 190);
        border-bottom: 2px solid white;

        .permission-page-header {
            display: grid;
            width: 800px;
            background: rgb(58, 64, 67);
            border-bottom: 2px solid white;
            text-align: center;
            font-size: 18px;
            padding: 3px 0;
            color: white;
        }
    }

    .create-permission-group{
        display: grid;
        grid-column: 1/2;
        grid-auto-rows: min-content;
        background: rgb(210, 200, 190);

        .create-permission-group-header{
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            background: rgb(128, 128, 128);
            color: white;
            border-bottom: 2px solid white;
        }
    }

    .edit-permission-group{
        display: grid;
        grid-column: 1/2;
        grid-auto-rows: min-content;
        background: rgb(210, 200, 190);

        .edit-permission-group-header{
            width: 800px;
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            background: rgb(128, 128, 128);
            color: white;
            border-bottom: 2px solid white;
        }
    }

    .compare-permission-group{
        display: grid;
        grid-column: 2/3;
        grid-auto-rows: min-content;
        margin-left: 10px;
        padding-bottom: 15px;
        background: rgb(210, 200, 190);

        .compare-permission-group-group-header{
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            background: rgb(128, 128, 128);
            color: white;
            border-bottom: 2px solid white;
        }

        .create-compare{
            margin-top: 55px;
        }

        .permission-display-create{
            margin: 3px 0;
        }

        .edit-compare{
            margin-top: 105px;
        }

        .permission-display-edit{
            margin: 3px 0;
        }
    }

    .assign-user-permission-group{
        display: grid;
        grid-column: 1/2;
        grid-auto-rows: min-content;
        background: rgb(210, 200, 190);
        padding-bottom: 15px;

        .assign-user-permission-group-header {
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            background: rgb(128, 128, 128);
            color: white;
            border-bottom: 2px solid white;
        }
    }

    .compare-user-information{
        display: grid;
        grid-column: 2/3;
        grid-auto-rows: min-content;
        margin-left: 10px;
        background: rgb(210, 200, 190);

        .assign-user-permission-group-header {
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            background: rgb(128, 128, 128);
            color: white;
            border-bottom: 2px solid white;
        }

        .compare-user-permission-group-box{
            display: grid;
            grid-template-columns: repeat(10, 1fr);
            padding: 3px 0;

            .compare-user-permission-group-label{
                display: grid;
                justify-self: end;
                padding-right: 3px;
                grid-column: 1/4;
            }

            .compare-user-permission-group{
                display: grid;
                padding-left: 5px;
                grid-column: 4/11;
            }
        }
        
        .compare-new-permission-group-box{
            display: grid;
            grid-template-columns: repeat(10, 1fr);
            padding: 3px 0;
            
            .compare-user-permission-group-label{
                display: grid;
                justify-self: end;
                padding-right: 3px;
                grid-column: 1/4;
                grid-row: 4/8;
            }

            .compare-user-permission-group{
                display: grid;
                padding-left: 5px;
                grid-column: 4/11;
            }
        }
    }

    .two-button-box{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 20px 0;

        .create-group,
        .update-group,
        .update-user-permission-group{
            display: grid;
            justify-self: right;
            margin-right: 20px;
        }

        .cancel-create,
        .cancel-update,
        .cancel-update-user-permission-group{
            display: grid;
            justify-self: left;
            margin-left: 20px;
        }
    }

    .read-write-label{
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        margin: 10px 0 5px;

        .read-label{
            display: grid;
            grid-column: 5/6;
        }

        .write-label{
            display: grid;
            grid-column: 6/7;
        }
    }

    .permissions-display{
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        align-items: center;

        .group-permission{
            display: grid;
            justify-self: end;
            margin-right: 20px;
            grid-column: 1/5;
        }

        .read{
            display: grid;
            grid-column: 5/6;
        }

        .write{
            display: grid;
            grid-column: 6/7;
        }

        .remove-permission{
            display: grid;
            justify-self: start;
            margin-left: 20px;
            grid-column: 7/11;
        }
    }
    
    .add-permission-with-description-box{
        display: grid;
        grid-template-columns: repeat(10, 1fr);

        .add-permission-name{
            display: grid;
            justify-content: end;
            grid-column: 1/4;
            padding-right: 5px;
        }

        .add-permission-box{
            display: grid;
            grid-column: 4/10;
            width: 400px;
            height: 100px;
            border: 1px solid black;
            border-radius: 3px;
            overflow-y: scroll;
            background: white;
            
            .option{
                cursor: default;
                padding: 1px 0 1px 3px;
                :hover{
                    background: rgb(110, 110, 255);
                }
            }
        }
        
        .description-box{
            position: absolute;
            top: ${props => props.mouseY}px;
            left: ${props => props.mouseX}px;
            padding: 5px;
            background: black;
            color: white;
            z-index: 10000;
            border-radius: 5px;
        }
    }
`;

export default UserPermissionsContainer;