import { useState } from 'react';
import Auth from '../authentication/Auth.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

    const DeleteJob = ({displayMessageWithTimer, updateJobsArray, 
                        deleteJob, setDeleteJob, jobToDelete, setJobToDelete}) => {
    const [confirmDelete, setConfirmDelete] = useState(false);

    const closeWithDelete = async () => {
        const dbObj = {
            id: jobToDelete.id,
            active: false
        }
        await Auth.hitEndpointNew('PATCH', 'job-new', '', dbObj);

        await updateJobsArray(jobToDelete.number);
        displayMessageWithTimer('Job Deleted', 'green', 5000);
        setJobToDelete(null);
        setDeleteJob(false);
    }

    const closeNoDelete = () => {
        displayMessageWithTimer('No Jobs Deleted', 'red', 5000);
        setJobToDelete(null);
        setDeleteJob(false);
    }

    return (
        <Dialog open={deleteJob}>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Job To Delete</DialogTitle>
            <DialogContent sx={{width: "600px"}}>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Builder:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {jobToDelete.builder.name}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Job Number:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {jobToDelete.number}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        Project Name:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {jobToDelete.project_name}
                    </Box>
                </Box>
                <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                    <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                        City:
                    </Box>
                    <Box style={{gridColumn: '5/11'}}>
                        {jobToDelete.city}
                    </Box>
                </Box>
                {confirmDelete ?
                <Dialog open={confirmDelete}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 255, 150)', fontWeight: 'bold'}}>Confirm Delete Job</DialogTitle>
                    <DialogContent sx={{width: "600px", backgroundColor: 'rgb(255, 255, 150)'}}>
                            Please Confirm Deleting Job
                    </DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 255, 150)'}}>
                        <Button variant='contained' onClick={closeWithDelete}>Confirm</Button>
                        <Button variant='contained' onClick={closeNoDelete}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                : null}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => setConfirmDelete(true)}>Delete</Button>
                <Button variant='contained' onClick={closeNoDelete}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteJob;