import { useMemo } from 'react';
import Grid from "@mui/material/Grid";
import { MaterialReactTable } from "material-react-table";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Can from '../authentication/Can';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

    const BuildersTable = ({message, builders, setCreateBuilder, setEditBuilder, setBuilderToEdit, 
                            setDeleteBuilder, setBuilderToDelete}) => {

    const canCreateBuilder = Can.isAuthorized('builders', 'WRITE');
    const canEditBuilder = Can.isAuthorized('builders', 'WRITE');
    const canDeleteBuilder = Can.isAuthorized('builders', 'WRITE');

    const columns = useMemo(() => [
        {
            id: 'name',
            accessorKey: 'name',
            header: 'Builder Name',
            enableEditing: false,
            size: 500
        }
    ], []);

    return (
        <MaterialReactTable
            muiTableContainerProps={{ sx: { maxWidth: '800px'} }}
            columns={columns}
            data={builders}
            layoutMode='grid-no-grow'
            enableColumnFilters
            enablePagination={false}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    size: 130,
                    grow: false,
                },
            }}
            renderTopToolbarCustomActions={({table}) => {
                return (
                    <Grid>
                        {canCreateBuilder &&
                        <Tooltip title="Open Create Builder">
                            <IconButton onClick={() => setCreateBuilder(true)}>
                                <AddBoxIcon />
                            </IconButton>
                        </Tooltip>}
                        <Grid>
                            <span style={{fontWeight: 'bold'}}>Status: </span>
                            <span style={{fontWeight: 'bold', color: message.color}}>{message.message}</span>
                        </Grid>

                    </Grid>
                );
            }}
            muiTableBodyCellProps={({ cell, row, table }) => {
                return {
                    sx: {
                        cursor: canEditBuilder ? 'pointer' : 'default',
                        backgroundColor: row.original.active === 1 ? 'white' : 'rgb(255, 150, 150)'
                    },
                    onClick: () => {
                        if(canEditBuilder && cell.column.id === 'name'){
                            setEditBuilder(true);
                            setBuilderToEdit(row.original);
                        }
                    }
                }
            }}
            positionToolbarAlertBanner='hide'
            enableRowActions={true}
            renderRowActions={({ row}) => {
                return (
                    <Box sx={{ display: 'flex', gap: '1rem',}}>
                        {canEditBuilder &&
                        <Tooltip title="Edit">
                            <IconButton onClick={async () => {
                                setEditBuilder(true);
                                setBuilderToEdit(row.original);
                            }}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>}
                        {canDeleteBuilder &&
                        <Tooltip title="Delete">
                            <IconButton color="error" onClick={() => {
                                setDeleteBuilder(true);
                                setBuilderToDelete(row.original);
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>}
                    </Box>
                )
            }}
        />
    );
}

export default BuildersTable;