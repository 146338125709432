import logo from '../../img/HeaderLogo.png';

import WarningScreensContainer from './styles/WarningScreensContainer';

export const WrongVersionScreen = (props) => {
    return (
        <WarningScreensContainer>
            <img className='logo' src={logo} alt="SVC Logo"/>
            <div className='maintenance-message'>
                The Field Management System(FMS) version you are currently using is out of date. 
                <span> Please close and restart your browser</span>, to run the latest version. Thank You.
            </div>    
        </WarningScreensContainer>
    )
};

export const FMSCrashScreen = (props) => {
    return (
        <WarningScreensContainer>
            <img className='logo' src={logo} alt="SVC Logo"/>
            <div className='maintenance-message'>
                The Field Management System(FMS) has experienced an unexpected error.
                <span> Please close and restart your browser.</span> If the problem persists, please submit a bug report to ericf@svcaz.com.
                Thank You.
            </div>    
        </WarningScreensContainer>
    )
};
