import {useMemo, useState} from 'react';
import {MaterialReactTable} from "material-react-table";

import Auth from '../../authentication/Auth.js';
import { Button } from '../../utilities/Button';
import Globals from '../../utilities/Globals.js';
import ReporterContainer from '../styles/ReporterContainer';

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";


const WorkProjection = () => {
    const [reportFilters, setReportFilters] = useState({
        startDate: null,
        endDate: null,
    });

    const [exportData, setExportData] = useState([]);

    const [buttonText, setButtonText] = useState('Create Work Projection Report');

    const gridColumns = useMemo(
        () => [
            {
                accessorKey: 'Builder',
                header: 'Builder',
                size: 20,
            },
            {
                accessorKey: 'Job #',
                header: 'Job #',
                size: 20,
            },
            {
                accessorKey: 'Project Name',
                header: 'Project Name',
                size: 20,
            },
            {
                accessorKey: 'Total Lots',
                header: 'Total Lots',
                size: 20,
            },
            {
                accessorKey: 'RFGT',
                header: 'RFGT',
                size: 20,
            },
            {
                accessorKey: 'Concrete Foundation',
                header: 'Concrete Foundation',
                size: 20,
            },
            {
                accessorKey: 'Plumbing Soil',
                header: 'Plumbing Soil',
                size: 20,
            },
            {
                accessorKey: 'Plumbing Top Out',
                header: 'Plumbing Top Out',
                size: 20,
            },
            {
                accessorKey: 'Masonry',
                header: 'Masonry',
                size: 20,
            },
            {
                accessorKey: 'Concrete Driveway',
                header: 'Concrete Driveway',
                size: 20,
            },
            {
                accessorKey: 'Paver',
                header: 'Paver',
                size: 20,
            },
            {
                accessorKey: 'Plumbing Trim',
                header: 'Plumbing Trim',
                size: 20,
            },
        ],
        [],
    );

    const createWorkProjectionReport = async () => {
        setButtonText('Fetching Report...');

        let phases = await Auth.hitEndpointNew("GET", "phase");
        let data = await Auth.hitEndpoint("GET", "STATS", `selector=twenty&startDate=${reportFilters.startDate}&endDate=${reportFilters.endDate}`);
        phases.sort((p1, p2) => p1.stats_order_by < p2.stats_order_by ? -1 : 1);
        data.sort((d1, d2) => d1.number < d2.number ? -1 : 1);

        const tempExportData = [];
        for(let i = 0; i < data.length; i++){
            // Get phase totals
            let tempPhases = phases.map(phase => {
                const currentPhase = data[i].phaseCounts.find(count => phase.id === count.phase_id);
                if(currentPhase !== undefined){
                    return {
                        name: phase.stat_name,
                        count: currentPhase.count
                    }
                }
                return {
                    name: phase.stat_name,
                    count: 'N/A'
                }
            });

            let finalPhases = {};
            for(let j = 0; j < tempPhases.length; j++){
                finalPhases[tempPhases[j].name] = tempPhases[j].count;
            }

            tempExportData.push({
                'Builder': data[i].name,
                'Job #': data[i].number,
                'Project Name': data[i].project_name,
                'Total Lots': data[i].total_lots,
                ...finalPhases
            });
        }

        setExportData([...tempExportData]);
        setReportFilters({
            startDate: null,
            endDate: null,
        });

        setButtonText('Create Work Projection Report');
    }

    const exportToXLSX = () => {
        Globals.exportToXLSXFileFormat(exportData, 'Work Projection', './Work Projection.xlsx');
    }

    return (
        <ReporterContainer>
            <div style={{justifySelf: 'center'}}>
                <div style={{justifySelf: "center", width: "300px", marginBottom: "10px"}}>
                    <div style={{marginBottom: "5px"}}>Start Date(Optional)</div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => setReportFilters({...reportFilters, startDate: new Date(date)})}
                            slotProps={{
                                field: { clearable: true },
                                textField: {
                                    helperText: '',
                                    sx: {width: '100%', minWidth: '120px'},
                                },
                            }}
                            selected={reportFilters.startDate}
                        />
                    </LocalizationProvider>
                </div>
                <div style={{justifySelf: "center", width: "300px", marginBottom: "20px"}}>
                    <div style={{marginBottom: "5px"}}>End Date(Optional)</div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(date) => setReportFilters({...reportFilters, endDate: new Date(date)})}
                            slotProps={{
                                field: { clearable: true },
                                textField: {
                                    helperText: '',
                                    sx: {width: '100%', minWidth: '120px'},
                                },
                            }}
                            selected={reportFilters.endDate}
                        />
                    </LocalizationProvider>
                </div>
                <Button 
                    className="gates-report" 
                    handleKeyPress={((e) => {
                        if (e.key === 'Enter') {
                            createWorkProjectionReport()
                        }
                    })} 
                    handleClick={((e) => createWorkProjectionReport())} 
                    buttonText={buttonText} 
                    height="40" 
                    width="300"
                />
            </div>
            {exportData.length > 0 ?
                <MaterialReactTable
                id="work-projection-table"
                columns={gridColumns}
                data={exportData}
                enableFilters={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableDensityToggle={false}
                initialState={{
                    pagination: {pageSize: 100, pageIndex: 0},
                    density: 'compact',
                }}
                renderTopToolbarCustomActions={() => <Button handleClick={exportToXLSX} buttonText='Export To XLSX' />}
                /> : null
            }
        </ReporterContainer>
    );
}

export default WorkProjection;
