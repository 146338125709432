import Config from './config'

const BASE_API = Config.BASE_API()
const urls = {
    REACT_APP_DEV_URL_BUILDERS: `${BASE_API}/builders`,
    REACT_APP_DEV_URL_BUILDER: `${BASE_API}/builder`,
    REACT_APP_DEV_URL_JOB_TYPES: `${BASE_API}/job/types`,
    REACT_APP_DEV_URL_JOBS: `${BASE_API}/jobs`,
    REACT_APP_DEV_URL_COLUMNS: `${BASE_API}/datatable/columns`,
    REACT_APP_DEV_URL_LOT: `${BASE_API}/lot`,
    REACT_APP_DEV_URL_LOTS: `${BASE_API}/lots`,
    REACT_APP_DEV_URL_LOTS_BY_JOB: `${BASE_API}/lots/job`,
    REACT_APP_DEV_URL_MEASUREMENTS: `${BASE_API}/measurements/field`,
    REACT_APP_DEV_URL_QA: `${BASE_API}/qa`,
    REACT_APP_DEV_URL_USER: `${BASE_API}/user`,
    REACT_APP_DEV_URL_USERS: `${BASE_API}/users`,
    REACT_APP_DEV_URL_USER_ROLES: `${BASE_API}/user/roles`,
    REACT_APP_DEV_URL_USER_POSITIONS: `${BASE_API}/user/positions`,
    REACT_APP_DEV_URL_USER_FIELDS: `${BASE_API}/user/fields`,
    REACT_APP_DEV_URL_USER_PERMISSIONS: `${BASE_API}/user/permissions`,
    REACT_APP_DEV_URL_LOGIN: `${BASE_API}/login`,
    REACT_APP_DEV_URL_AUTHENTICATE: `${BASE_API}/authenticate`,
    REACT_APP_DEV_URL_DATA: `${BASE_API}/data`,
    REACT_APP_DEV_URL_STATS: `${BASE_API}/stats`,
    REACT_APP_DEV_URL_EWA: `${BASE_API}/ewa`,
    REACT_APP_DEV_URL_EWAS: `${BASE_API}/ewas`,
    REACT_APP_DEV_URL_EWA_CODES: `${BASE_API}/ewa/codes`,
    REACT_APP_DEV_URL_PHASES: `${BASE_API}/phases`,
    REACT_APP_DEV_URL_PHASE_TASKS: `${BASE_API}/phase-tasks`,
    REACT_APP_DEV_URL_APPSTATE: `${BASE_API}/app-state`,
    REACT_APP_DEV_URL_MESSAGES: `${BASE_API}/messages`,
    REACT_APP_DEV_URL_SCHEDULES: `${BASE_API}/schedules`,
    REACT_APP_DEV_URL_CREWMEMBERS: `${BASE_API}/crew-members`,
    REACT_APP_DEV_URL_CREWS: `${BASE_API}/crews`,
    REACT_APP_DEV_URL_STARTSSTATUS: `${BASE_API}/starts-status`,
    REACT_APP_DEV_URL_PHASE: `${BASE_API}/phase`,
    REACT_APP_DEV_URL_PROSPECTIVEEMPLOYEE: `${BASE_API}/prospective-employee`,
    REACT_APP_PROD_URL_BUILDERS: `${BASE_API}/builders`,
    REACT_APP_PROD_URL_BUILDER: `${BASE_API}/builder`,
    REACT_APP_PROD_URL_JOB_TYPES: `${BASE_API}/job/types`,
    REACT_APP_PROD_URL_JOBS: `${BASE_API}/jobs`,
    REACT_APP_PROD_URL_COLUMNS: `${BASE_API}/datatable/columns`,
    REACT_APP_PROD_URL_LOT: `${BASE_API}/lot`,
    REACT_APP_PROD_URL_LOTS: `${BASE_API}/lots`,
    REACT_APP_PROD_URL_LOTS_BY_JOB: `${BASE_API}/lots/job`,
    REACT_APP_PROD_URL_MEASUREMENTS: `${BASE_API}/measurements/field`,
    REACT_APP_PROD_URL_QA: `${BASE_API}/qa`,
    REACT_APP_PROD_URL_USER: `${BASE_API}/user`,
    REACT_APP_PROD_URL_USERS: `${BASE_API}/users`,
    REACT_APP_PROD_URL_USER_ROLES: `${BASE_API}/user/roles`,
    REACT_APP_PROD_URL_USER_POSITIONS: `${BASE_API}/user/positions`,
    REACT_APP_PROD_URL_USER_FIELDS: `${BASE_API}/user/fields`,
    REACT_APP_PROD_URL_USER_PERMISSIONS: `${BASE_API}/user/permissions`,
    REACT_APP_PROD_URL_LOGIN: `${BASE_API}/login`,
    REACT_APP_PROD_URL_AUTHENTICATE: `${BASE_API}/authenticate`,
    REACT_APP_PROD_URL_DATA: `${BASE_API}/data`,
    REACT_APP_PROD_URL_STATS: `${BASE_API}/stats`,
    REACT_APP_PROD_URL_EWA: `${BASE_API}/ewa`,
    REACT_APP_PROD_URL_EWAS: `${BASE_API}/ewas`,
    REACT_APP_PROD_URL_EWA_CODES: `${BASE_API}/ewa/codes`,
    REACT_APP_PROD_URL_PHASES: `${BASE_API}/phases`,
    REACT_APP_PROD_URL_PHASE_TASKS: `${BASE_API}/phase-tasks`,
    REACT_APP_PROD_URL_APPSTATE: `${BASE_API}/app-state`,
    REACT_APP_PROD_URL_MESSAGES: `${BASE_API}/messages`,
    REACT_APP_PROD_URL_SCHEDULES: `${BASE_API}/schedules`,
    REACT_APP_PROD_URL_CREWMEMBERS: `${BASE_API}/crew-members`,
    REACT_APP_PROD_URL_CREWS: `${BASE_API}/crews`,
    REACT_APP_PROD_URL_STARTSSTATUS: `${BASE_API}/starts-status`,
    REACT_APP_PROD_URL_PHASE: `${BASE_API}/phase`,
    REACT_APP_PROD_URL_PROSPECTIVEEMPLOYEE: `${BASE_API}/prospective-employee`,
    REACT_APP_CAP_SITE_KEY: `6LdXa2YlAAAAAFsvfMqDLTEV6VEt2_cbPNK7sorW`
}

export const routes = urls