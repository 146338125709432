import { NavLink } from 'react-router-dom'
import './styles/navlink.css'
const SubNavLink = ({label, route, className, ...rest}) => {

    const addedClass = "nav-navlink normal-style " + className

    return (
        <NavLink
            {...rest}
            to={route}
            className={addedClass}
        >
            {label}
        </NavLink>
    )
}

export default SubNavLink
