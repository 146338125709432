import styled from 'styled-components';


const EWAContainer = styled.div`
    display: grid;
    margin: 10px 0 175px 10px;
    box-sizing: border-box;

    .step-one {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(10, 1fr);
        padding: 10;
        background: rgb(58, 64, 67);
        border-bottom: 3px solid white;
        width: 100%;
        
        .ewa-number{
            display: flex;
            grid-row: 2/10;
            grid-column: 1/1;
            height: 27%;
            padding-left: 5pt;
            text-align: center;
            align-items: center;
            background-color: white;
            margin-left: 5px;
        }
        .user-select{
            grid-row: 5/10;
            grid-column: 1/1;
            padding-left: 5pt;
            z-index: 2;
        }
        .phase-select{
            grid-row: 8/10;
            grid-column: 1/1;
            padding-left: 5pt;
            z-index: 2;
        }
    }
    
     .row-actions {
        display: grid;
        grid-template-columns: 55px 55px;
    }
    
    .new-lines {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(10, 1fr);
    }
    
    .job-select {
        width: 100%;
        padding: 10pt;
        padding-bottom: 20pt !important;
        z-index: 2;
    }

    .selected {
        background: skyblue;
    }

    .selected {
        background: skyblue;
    }
`;

export default EWAContainer;
