
import styled from 'styled-components';

const dropdown_Height = "22";
const dropdown_Margin = "5px 0 5px 0";

export const DropDownWithLabelContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    margin: ${props => props.margin ? props.margin : dropdown_Margin};

    .drop-down-label {
        display: grid;
        grid-column: ${props => props.labelStart}/${props => props.labelEnd};
        justify-self: right;
        align-self: center;
        padding-right: 5px;
    }

    .drop-down-select {
        display: grid;
        grid-column: ${props => props.inputStart}/${props => props.inputEnd};
        height: ${dropdown_Height}px;
    }
`

// TEMPLATE
// <DropDownWithLabel className="Class name of entire box" 
//                    id="id of this drop down box" 
//                    name="Name of input" 
//                    label="Label to display" 
//                    handleDropDown="Function that controls the selection" 
//                    addBlankOption="Adds blank option to top of list true/false"
//                    selectionList="Array of items. Options must be object with .id and .display"
//                    margins="Top right bottom left margins"
//                    columns="Number of columns in label/textbox row"
//                    labelStart="Start of label, right aligned"
//                    labelEnd="End of label, right aligned"
//                    inputStart="Start of textbox, left aligned" 
//                    inputEnd="End of textbox, left aligned"/>
// END

export const DropDownWithLabel = (props) => {
    const isDisabled = props.disabled ? props.disabled : false;
    const addBlankOption = props.addBlankOption ? props.addBlankOption : false;
    return (
        <DropDownWithLabelContainer className={props.className} margin={props.margin} columns={props.columns} labelStart={props.labelStart} labelEnd={props.labelEnd} inputStart={props.inputStart} inputEnd={props.inputEnd}>
            <label className="drop-down-label" htmlFor={props.id}>{props.label}</label>
            <select className="drop-down-select" id={props.id} name={props.name} value={props.value} onChange={props.handleDropDown} disabled={isDisabled}>
                {addBlankOption && <option className="drop-down-option"value=""></option>}
                {props.selectionList.map((item, index) => <option className="drop-down-option" key={`${item.id}-${index}`} value={item.id}>{item.display}</option>)}
            </select>
        </DropDownWithLabelContainer>
    );
}

export const DropDownContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    margin: ${props => props.margin ? props.margin : dropdown_Margin};

    .drop-down-select {
        display: grid;
        grid-column: ${props => props.inputStart}/${props => props.inputEnd};
        height: ${dropdown_Height}px;
    }
`

// TEMPLATE
// <DropDown  className="Class name of entire box" 
//            id="id of this drop down box" 
//            name="Name of input" 
//            handleDropDown="Function that controls the selection" 
//            addBlankOption="Adds blank option to top of list true/false"
//            selectionList="Array of items. Options must be object with .id and .display"
//            margins="Top right bottom left margins"
//            columns="Number of columns in label/textbox row"
//            labelStart="Start of label, right aligned"
//            labelEnd="End of label, right aligned"
//            inputStart="Start of textbox, left aligned" 
//            inputEnd="End of textbox, left aligned"/>
// END

export const DropDown = (props) => {
    const isDisabled = props.disabled ? props.disabled : false;
    const addBlankOption = props.addBlankOption ? props.addBlankOption : false;
    return (
        <DropDownContainer className={props.className} margin={props.margin} columns={props.columns} inputStart={props.inputStart} inputEnd={props.inputEnd}>
            <select className="drop-down-select" id={props.id} name={props.name} value={props.value} onChange={props.handleDropDown} disabled={isDisabled}>
                {addBlankOption && <option className="drop-down-option"value=""></option>}
                {props.selectionList.map((item, index) => <option className="drop-down-option" key={`${item.id}-${index}`} value={item.id}>{item.display}</option>)}
            </select>
        </DropDownContainer>
    );
}
