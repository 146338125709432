import dayjs from "dayjs";
import Auth from "../components/authentication/Auth";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

class Field {

    static async loadSchedule(date, queryObj) {
        let dateStr = dayjs(date).format('YYYY-MM-DD')
        let data = [];
        let dbData;
        const keys = Object.keys(queryObj)
        let jsonString = ''
        if (keys.length > 0) {
            jsonString = JSON.stringify(queryObj);
        }

        let leads = []
        let tasks = []

        dbData = await Auth.hitEndpointNew("GET", `schedule/updates/${dateStr}`, jsonString !== '' ? `jsonString=${jsonString}` : null);
        // dbData.rows.forEach(r => {
        //     if(leads.filter(x => x.id === r.crew_lead_emp_id).length === 0){
        //         leads.push({
        //             id: r.crew_lead_emp_id,
        //             name: r.crew_lead,
        //             crew: []
        //         })
        //     }
        // })
        //
        // data = {
        //     crews: leads,
        //     data: dbData
        // };


        return dbData;
    }
}

export default Field;