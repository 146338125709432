import { useState } from 'react';
import Auth from '../authentication/Auth.js';
import MainHeader from '../utilities/MainHeader.js';
import LotsFilter from './LotsFilter.js';
import LotsStatistics from './LotsStatistics.js';
import LotsTable from './LotsTable.js';
import CreateLot from './CreateLot.js';
import LoadLotsFromXLSX from './LoadLotsFromXLSX.js';
import EditLot from './EditLot.js';
import DeleteLot from './DeleteLot.js';
import BlueStake from './BlueStake.js';
import LotReleases from './LotReleases.js';
import LotNotes from './LotNotes.js';
import Box from '@mui/material/Box';

const LotsPage = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [message, setMessage] = useState({
        color: '',
        message: ''
    });

    const [jobs, setJobs] = useState([]);
    const [lots, setLots] = useState([]);

    const [phase, setPhases] = useState([]);
    const [phaseStatus, setPhaseStatus] = useState([]);

    const [createLot, setCreateLot] = useState(false);
    const [loadLotsFromXLSX, setLoadLotsFromXLSX] = useState(false);
    const [editLot, setEditLot] = useState(false);
    const [lotToEdit, setLotToEdit] = useState(null);
    const [deleteLots, setDeleteLots] = useState(false);
    const [lotsToDelete, setLotsToDelete] = useState(null);
    const [lotReleasesLots, setLotReleasesLots] = useState([]);
    const [openLotNotes, setOpenLotNotes] = useState(false);
    const [lotToViewNotes, setLotToViewNotes] = useState(null);
    const [lotNotesType, setLotNotesType] = useState('');
    const [openBlueStake, setOpenBlueStake] = useState(false);
    const [lotBlueStake, setLotBlueStake] = useState(null);

    const loadData = async () => {
        setDidLoadData(true);

        const tempJobs = await Auth.hitEndpointNew('GET', 'jobs-new');
        setJobs(tempJobs.map(j => {
            return {
                ...j,
                value: j.id,
                label: `${j.number} - ${j.builder.name} - ${j.project_name}`
            }
        }).sort((j1, j2) => {
            return parseInt(j1.number) < parseInt(j2.number) ? -1 : 1;  
        }))

        const tempPhases = await Auth.hitEndpointNew('GET', 'phase');
        setPhases(tempPhases.map(p => {
            return {
                ...p,
                value: p.id,
                label: p.stat_name
            }
        }).sort((p1, p2) => {
            return parseInt(p1.even_flow_order_by) < parseInt(p2.even_flow_order_by) ? -1 : 1;
        }))

        const tempReleaseStatus = [
            { value: 'Not Released', label: 'Not Released' },
            { value: 'Released', label: 'Released' }
        ];
        const tempStartsStatus = (await Auth.hitEndpointNew('GET', 'starts-status')).map(s => {
            return {
                value: s.display,
                label: s.display
            }
        }).sort((s1, s2) => parseInt(s1.display_order) < parseInt(s2.display_order) ? -1 : 1);
        setPhaseStatus([...tempReleaseStatus, ...tempStartsStatus]);
    }

    if(!didLoadData){
        loadData();
    }

    const updateLotInLotsArray = (lot) => {
        const index = lots.findIndex(l => parseInt(l.id) === parseInt(lot.id));
        if(index >= 0){
            lots[index] = lot;
        }
        setLots([...lots]);
    }

    const updateLotsInLotsArray = (lotArr) => {
        for(let i = 0; i < lotArr.length; i++){
            const index = lots.findIndex(l => parseInt(l.id) === parseInt(lotArr[i].id));
            if(index >= 0){
                lots[index] = lotArr[i];
            }
        }
        setLots([...lots]);
    }

    const displayMessageWithTimer = (message, textColor, milliseconds) => {
        setMessage({
            color: textColor,
            message: message
        });
        setTimeout(() => {
            setMessage({
                color: '',
                message: ''
            });
        }, milliseconds)
    }

    return (
        <Box sx={{
                margin: '10px'
        }}>
            <MainHeader/>
            <Box sx={{
                display: 'flex',
                columnGap: '10px',
                marginBottom: '10px'
            }}>
                <LotsFilter 
                    displayMessageWithTimer={displayMessageWithTimer}
                    jobs={jobs} 
                    phases={phase} 
                    phaseStatus={phaseStatus} 
                    setLots={setLots}
                />
                <LotsStatistics/>
            </Box>
            <LotsTable 
                message={message}
                lots={lots} 
                setCreateLot={setCreateLot}
                setLoadLotsFromXLSX={setLoadLotsFromXLSX}
                setEditLot={setEditLot}
                setLotToEdit={setLotToEdit}
                setDeleteLots={setDeleteLots}
                setLotsToDelete={setLotsToDelete}
                setOpenBlueStake={setOpenBlueStake}
                setLotBlueStake={setLotBlueStake}
                setLotReleasesLots={setLotReleasesLots}
                setOpenLotNotes={setOpenLotNotes}
                setLotToViewNotes={setLotToViewNotes}
                setLotNotesType={setLotNotesType}
            />
            {createLot ? 
            <CreateLot 
                displayMessageWithTimer={displayMessageWithTimer}
                jobs={jobs}
                createLot={createLot} 
                setCreateLot={setCreateLot}
            /> 
            : null}
            {loadLotsFromXLSX ? 
            <LoadLotsFromXLSX 
                displayMessageWithTimer={displayMessageWithTimer}
                jobs={jobs}
                loadLotsFromXLSX={loadLotsFromXLSX} 
                setLoadLotsFromXLSX={setLoadLotsFromXLSX}
            /> 
            : null}
            {editLot && lotToEdit ? 
            <EditLot 
                displayMessageWithTimer={displayMessageWithTimer}
                editLot={editLot} 
                setEditLot={setEditLot}
                lotToEdit={lotToEdit}
                setLotToEdit={setLotToEdit}
                updateLotInLotsArray={updateLotInLotsArray}
            /> 
            : null}
            {deleteLots && lotsToDelete ? 
            <DeleteLot 
                displayMessageWithTimer={displayMessageWithTimer}
                deleteLots={deleteLots} 
                setDeleteLots={setDeleteLots}
                lotsToDelete={lotsToDelete}
                setLotsToDelete={setLotsToDelete}
                updateLotsInLotsArray={updateLotsInLotsArray}
            /> 
            : null}
            {lotReleasesLots.length > 0 ? 
            <LotReleases 
                jobs={jobs}
                lotReleasesLots={lotReleasesLots}
                setLotReleasesLots={setLotReleasesLots}
                updateLotsInLotsArray={updateLotsInLotsArray}
                setOpenLotNotes={setOpenLotNotes}
                setLotToViewNotes={setLotToViewNotes}
                setLotNotesType={setLotNotesType}
            /> 
            : null}
            {openBlueStake && lotBlueStake ? 
            <BlueStake 
                openBlueStake={openBlueStake}
                setOpenBlueStake={setOpenBlueStake}
                lot={lotBlueStake}
                setLot={setLotBlueStake}
                updatedLotReturned={updateLotInLotsArray}
            /> 
            : null}
            {openLotNotes && lotToViewNotes ? 
            <LotNotes 
                openLotNotes={openLotNotes}
                setOpenLotNotes={setOpenLotNotes}
                lotToViewNotes={lotToViewNotes}
                setLotToViewNotes={setLotToViewNotes}
                noteType={lotNotesType}
                updatedLotReturned={updateLotInLotsArray}
            /> 
            : null}
        </Box>
    );
}

export default LotsPage;