import styled from 'styled-components';

const HoverPopupContainer = styled.div`
    display: grid;
    position: absolute;
    
    pointer-events: none;
    
    width: 1000px;
    
    z-index: 100;

    animation: fadeIn 1s ease-in;
    @keyframes fadeIn {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }
    
    .hover-popup-text{
        justify-self: end;
        // align-self: center;
        display: inline;
        position: relative;

        text-align: center;
        padding: 5px;
        border-radius: 3px;

        background: black;
        color: white;

        right: 1000px;
        top: -20px;

        :after {
            content: "";
            position: absolute;
            top: 25%;
            left: 100%;
            margin-top: 0;
            border-width: 6px;
            border-style: solid;
            border-color: transparent transparent transparent black;
         }
    }

`;

export default HoverPopupContainer;
