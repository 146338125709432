import styled from 'styled-components';

const PopupContainer = styled.div`
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 60;

    .popup-box {
        position: relative;
        width: 70%;
        margin: 0 auto;
        height: auto;
        max-height: 70vh;
        margin-top: calc(100vh - 85vh - 20px);
        background: ${props => props.color};
        border-radius: 3px;
        padding: 20px;
        border: 1px solid black;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .popup-message {
        text-align: center;
        margin: 15px auto;
    }

    .popup-close-button {
        justify-self: center;
        width: 80px;
        height: 20px;
        background: rgb(240, 240, 240);
        border: 1px solid rgb(120, 120, 120);
        border-radius: 3px;
        display: grid;
        justify-content: center;
        align-items: center;
        cursor: default;
        margin: 10px 0;

        :hover {
            background: rgb(230, 230, 230);
        }
    }
`

export default PopupContainer;