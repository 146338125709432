import { useState, useEffect } from 'react';
import Auth from '../authentication/Auth.js';
import MainHeader from '../utilities/MainHeader.js';
import BuildersFilter from './BuildersFilter.js';
import BuildersTable from './BuildersTable.js';
import CreateBuilder from './CreateBuilder.js';
import EditBuilder from './EditBuilder.js';
import DeleteBuilder from './DeleteBuilder.js';
import Box from '@mui/material/Box';

const BuildersPage = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filters, setFilters] = useState({
        includeDeleted: false,
    });

    const [message, setMessage] = useState({
        color: '',
        message: ''
    });
    
    const [builders, setBuilders] = useState([]);
    const [createBuilder, setCreateBuilder] = useState(false);
    const [editBuilder, setEditBuilder] = useState(false);
    const [builderToEdit, setBuilderToEdit] = useState(null);
    const [deleteBuilder, setDeleteBuilder] = useState(false);
    const [builderToDelete, setBuilderToDelete] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            setDidLoadData(true);

            const tempBuilders = await Auth.hitEndpointNew('GET', 'builders');
            if(filters.includeDeleted){
                setBuilders(tempBuilders.sort((b1, b2) => b1.name < b2.name ? -1 : 1));
            }
            else{
                setBuilders(tempBuilders.filter(b => b.active).sort((b1, b2) => b1.name < b2.name ? -1 : 1));
            }
        }

        if(!didLoadData){
            loadData();
        }
    }, [filters]);

    const updateBuildersArray = async () => {
        const tempBuilders = await Auth.hitEndpointNew('GET', 'builders');
        if(filters.includeDeleted){
            setBuilders(tempBuilders.sort((b1, b2) => b1.name < b2.name ? -1 : 1));
        }
        else{
            setBuilders(tempBuilders.filter(b => b.active).sort((b1, b2) => b1.name < b2.name ? -1 : 1));
        }
    }

    const displayMessageWithTimer = (message, textColor, milliseconds) => {
        setMessage({
            color: textColor,
            message: message
        });
        setTimeout(() => {
            setMessage({
                color: '',
                message: ''
            });
        }, milliseconds)
    }

    return (
        <Box sx={{
                margin: '10px',
                width: '600px'
        }}>
            <MainHeader/>
            <BuildersFilter
                filters={filters}
                setFilters={setFilters}
            />
            <BuildersTable 
                message={message}
                builders={builders}
                setCreateBuilder={setCreateBuilder}
                setEditBuilder={setEditBuilder}
                setBuilderToEdit={setBuilderToEdit}
                setDeleteBuilder={setDeleteBuilder}
                setBuilderToDelete={setBuilderToDelete}
            />
            {createBuilder ? 
            <CreateBuilder 
                displayMessageWithTimer={displayMessageWithTimer}
                updateBuildersArray={updateBuildersArray}
                builders={builders}
                createBuilder={createBuilder} 
                setCreateBuilder={setCreateBuilder}
            /> 
            : null}
            {editBuilder && builderToEdit ? 
            <EditBuilder 
                displayMessageWithTimer={displayMessageWithTimer}
                updateBuildersArray={updateBuildersArray}
                builders={builders}
                editBuilder={editBuilder} 
                setEditBuilder={setEditBuilder}
                builderToEdit={builderToEdit}
                setBuilderToEdit={setBuilderToEdit}
            /> 
            : null}
            {deleteBuilder && builderToDelete ? 
            <DeleteBuilder 
                displayMessageWithTimer={displayMessageWithTimer}
                updateBuildersArray={updateBuildersArray}
                deleteBuilder={deleteBuilder} 
                setDeleteBuilder={setDeleteBuilder}
                builderToDelete={builderToDelete}
                setBuilderToDelete={setBuilderToDelete}
            /> 
            : null}
        </Box>
    );
}

export default BuildersPage;