import { useMemo, useState} from 'react';
import dayjs from "dayjs";
import Auth from '../authentication/Auth.js';
import MainHeader from '../utilities/MainHeader.js';
import {MaterialReactTable} from "material-react-table";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button  from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import { Grid, TextField } from '@mui/material';
import { Switch } from '@mui/material';
import Select from "react-select";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const PourLog = (props) => {
    const [cursor, setCursor] = useState('default');
    const [didLoadData, setDidLoadData] = useState(false);

    const [filters, setFilters] = useState({
        job: null,
        lot: null,
        scheduledDate: null,
        completedDate: null,
        builder_invoiced: false,
        vender_invoiced: false
    });

    const [jobs, setJobs] = useState([]);
    const [lots, setLots] = useState([]);

    const [pourLogs, setPourLogs] = useState([]);
    const [editPourLog, setEditPourLog] = useState(null);

    const [error, setError] = useState({
        type: '',
        message: ''
    });

    const canEditInvoice = props.isAuthorized('pourLog', 'WRITE');

    const columns = useMemo(
        () => [
            {
                accessorKey: 'job_number',
                header: 'Job #',
                size: 150
            },
            {
                accessorKey: 'lot_number',
                header: 'Lot #',
                size: 200
            },
            {
                accessorKey: 'phase_task',
                header: 'Phase Task',
                size: 200
            },
            {
                accessorKey: 'builder',
                header: 'Builder',
                size: 250
            },
            {
                accessorKey: 'project_name',
                header: 'Project',
                size: 300
            },
            {
                accessorKey: 'yards',
                header: 'Concrete Yards',
                size: 200
            },
            {
                accessorKey: 'supplier',
                header: 'Concrete Supplier',
                size: 200
            },
            {
                accessorKey: 'pump',
                header: 'Pump',
                size: 250
            },
            {
                accessorKey: 'time',
                header: 'Time',
                size: 150
            },
            {
                accessorKey: 'order',
                header: 'Order #',
                size: 180
            },
            {
                accessorKey: 'schedule_date',
                header: 'Scheduled',
                size: 200
            },
            {
                accessorKey: 'completed_date',
                header: 'Completed',
                size: 200
            },
            {
                accessorKey: 'builder_invoice_number',
                header: 'Builder Invoice #',
                size: 250
            },
            {
                id: 'builder_billed',
                accessorFn: (row) => row.builder_billed ? 'X' : '', 
                header: 'Builder Billed',
                size: 200
            },
            {
                accessorKey: 'builder_invoice_date',
                header: 'Builder Invoice Date',
                size: 250
            },
            {
                accessorKey: 'vender_invoice_number',
                header: 'Vender Invoice #',
                size: 250
            },
            {
                accessorKey: 'vender_invoice_value',
                header: 'Vender Invoice Yards',
                size: 250
            },
            {
                id: 'vender_invoice_complete',
                accessorFn: (row) => row.vender_invoice_complete ? 'X' : '',
                header: 'Vender Invoice Complete',
                size: 250
            },
            {
                accessorKey: 'vender_invoice_date',
                header: 'Vender Invoice Date',
                size: 250
            },
        ],
        [],
    );

    const loadData = async () => {
        setDidLoadData(true);

        const tempJobs = await Auth.hitEndpointNew('GET', 'jobs-new');
        setJobs(tempJobs.map(j => {
            return {
                ...j,
                value: j.id,
                label: `${j.number} - ${j.builder.name} - ${j.project_name}`
            }
        }).sort((j1, j2) => parseInt(j1.number) < parseInt(j2.number) ? -1 : 1));
    }

    if(!didLoadData){
        loadData();
    }

    const handleUpdateTable = async () => {
        let queryStr = 'jsonString=phase_task_detail.detail_type_id = 1 and schedule.complete = 1';
        if(filters.job !== null){
            queryStr += ` and lot.job_id = ${filters.job.id}`
        }
        if(filters.lot !== null){
            queryStr += ` and lot.id = ${filters.lot.id}`
        }
        if(filters.scheduledDate != null){
            const dayMinusOne = dayjs(filters.scheduledDate).add(-1, 'day').format('YYYY-MM-DD');
            const dayPlusOne = dayjs(filters.scheduledDate).add(1, 'day').format('YYYY-MM-DD');
            queryStr += ` and schedule.schedule_date > '${dayMinusOne}' and schedule.schedule_date < '${dayPlusOne}'`
        }
        if(filters.completedDate != null){
            const dayMinusOne = dayjs(filters.completedDate).add(-1, 'day').format('YYYY-MM-DD');
            const dayPlusOne = dayjs(filters.completedDate).add(1, 'day').format('YYYY-MM-DD');
            queryStr += ` and schedule.completed_date > '${dayMinusOne}' and schedule.completed_date < '${dayPlusOne}'`
        }
        if(!filters.builder_invoiced){
            queryStr += ' and (schedule_detail.builder_billed is null or schedule_detail.builder_billed=0)';
        }
        if(!filters.vender_invoiced){
            queryStr += ' and (schedule_detail.vender_invoice_complete is null or schedule_detail.vender_invoice_complete=0)';
        }
        const data = await Auth.hitEndpointNew("GET", `schedule/detail/reconcile`, queryStr);

        const tempPourLogs = [];
        for(let i = 0; i < data.length; i++){
            const yards = data[i].detail.find(d => d.taskDetail.type.key === 'yards');
            const supplier = data[i].detail.find(d => d.taskDetail.type.key === 'supplier');
            const pump = data[i].detail.find(d => d.taskDetail.type.key === 'pump');
            const time = data[i].detail.find(d => d.taskDetail.type.key === 'time');
            const order = data[i].detail.find(d => d.taskDetail.type.key === 'order');

            tempPourLogs.push({
                        builder: data[i].lot.job.builder.name,
                        job_number: data[i].lot.job.number,
                        project_name: data[i].lot.job.project_name,
                        lot_number: data[i].lot.number,
                        phase_task: `${data[i].task.code} - ${data[i].task.description}`,
                        schedule_date: data[i].schedule_date !== null ? dayjs(new Date(data[i].schedule_date)).format('MM-DD-YYYY') : null,
                        completed_date: data[i].completed_date !== null ? dayjs(new Date(data[i].completed_date)).format('MM-DD-YYYY') : null,
                        id: data[i].id,
                        yards: yards && yards.schedule_value ? yards.schedule_value : null,
                        supplier: supplier && supplier.supplier && supplier.supplier.name ? supplier.supplier.name  : null,
                        pump: pump && pump.supplier && pump.supplier.name ? pump.supplier.name : null,
                        time: time && time.schedule_value ? time.schedule_value : null,
                        order: order && order.schedule_value ? order.schedule_value : null,
                        builder_invoice_number: yards ? yards.builder_invoice_number : null,
                        builder_billed: yards && yards.builder_billed ? true : false,
                        builder_invoice_date: yards && yards.builder_invoice_date ? dayjs(new Date(yards.builder_invoice_date)).format('MM-DD-YYYY') : null,
                        builder_invoice_by: yards ? yards.builder_invoice_by : null,
                        vender_invoice_number: yards ? yards.vender_invoice_number : null,
                        vender_invoice_value: yards ? yards.vender_invoice_value : null,
                        vender_invoice_complete: yards && yards.vender_invoice_complete ? true : false,
                        vender_invoice_date: yards && yards.vender_invoice_date ? dayjs(new Date(yards.vender_invoice_date)).format('MM-DD-YYYY') : null,
                        vender_invoice_by: yards ? yards.vender_invoice_by : null,
                        details: data[i].detail
            })
        }
        setPourLogs([...tempPourLogs]);
    }

    const handleJobSelect = async (job) => {
        if(job === null){
            setFilters({...filters, job: null, lot: null});
            return;
        }
        const tempLots = await Auth.hitEndpointNew('GET', 'lots/index-new', `queryString=lot.job_id=${job.id}`);
        setLots(tempLots.map(l => {
            return {
                ...l,
                value: l.id,
                label: `${l.number} - ${l.phase} - ${l.address}`
            }
        }));
        setFilters({...filters, job: job});
    }

    const handleCloseNoSave = () => {
        setError({
            type: '',
            message: ''
        });
        setEditPourLog(null);
    }

    const createPourLogScheduleLogEntry = (originalPourLog, newPourLog, column, action, userId) => {
        return {
            schedule_id: newPourLog.id,
            action: action,
            original_value: originalPourLog[column],
            new_value: newPourLog[column],
            action_date: dayjs(new Date()).format('YYYY-MM-DD'),
            action_by: userId
        }
    }

    const handleCloseSave = async (pourLog) => {
        // if(pourLog.dialog === 'vender'){
        //     if(pourLog.vender_invoice_complete === null || !pourLog.vender_invoice_complete){
        //         setError({type: 'INVOICE_NOT_COMPLETED', message: 'Please mark invoice as complete'});
        //         return;
        //     }
        // }
        // if(pourLog.dialog === 'builder'){
        //     if(pourLog.builder_billed === null || !pourLog.builder_billed){
        //         setError({type: 'INVOICE_NOT_COMPLETED', message: 'Please mark invoice as complete'});
        //         return;
        //     }
        // }

        const originalPourLog = pourLogs.find(pl => parseInt(pl.id) === pourLog.id);

        // Build Change Log
        const userId = await Auth.getUserId();
        const changeLog = [];
        if(originalPourLog.builder_billed !== pourLog.builder_billed){
            changeLog.push(createPourLogScheduleLogEntry(originalPourLog, pourLog, 'builder_billed', 'changed builder invoice billed', userId))
        }
        if(originalPourLog.builder_invoice_number !== pourLog.builder_invoice_number){
            changeLog.push(createPourLogScheduleLogEntry(originalPourLog, pourLog, 'builder_invoice_number', 'changed builder invoice number', userId))
        }
        if(originalPourLog.vender_invoice_complete !== pourLog.vender_invoice_complete){
            changeLog.push(createPourLogScheduleLogEntry(originalPourLog, pourLog, 'vender_invoice_complete', 'changed vender invoice complete', userId))
        }
        if(originalPourLog.vender_invoice_number !== pourLog.vender_invoice_number){
            changeLog.push(createPourLogScheduleLogEntry(originalPourLog, pourLog, 'vender_invoice_number', 'changed vender invoice number', userId))
        }
        if(originalPourLog.vender_invoice_value !== pourLog.vender_invoice_value){
            changeLog.push(createPourLogScheduleLogEntry(originalPourLog, pourLog, 'vender_invoice_value', 'changed vender invoice value', userId))
        }

        for(let i = 0; i < changeLog.length; i++){
            await Auth.hitEndpointNew('POST', 'schedule/log', '', changeLog[i]);
        }

        const dbObj = {
            id: pourLog.id,
            detail: pourLog.details.map(d => {
                if(d.taskDetail.type.key === 'yards'){
                    return {
                        id: d.id,
                        builder_billed: pourLog.builder_billed ? true : false,
                        builder_invoice_number: pourLog.builder_invoice_number,
                        builder_invoice_by: pourLog.builder_invoice_by,
                        builder_invoice_date: pourLog.builder_invoice_date !== null ? dayjs(new Date(pourLog.builder_invoice_date)).format('YYYY-MM-DD H:mm:ss') : null,
                        vender_invoice_by: pourLog.vender_invoice_by,
                        vender_invoice_complete: pourLog.vender_invoice_complete ? true : false,
                        vender_invoice_date: pourLog.vender_invoice_date !== null ? dayjs(new Date(pourLog.vender_invoice_date)).format('YYYY-MM-DD H:mm:ss') : null,
                        vender_invoice_number: pourLog.vender_invoice_number,
                        vender_invoice_value: pourLog.vender_invoice_value
                    }
                }

                return {id: d.id}
            })
        }
        await Auth.hitEndpointNew("PATCH", "schedule", "", dbObj);

        const tempPourLogs = [...pourLogs];
        const index = tempPourLogs.findIndex(pl => parseInt(pl.id) === parseInt(pourLog.id));
        // Updates current table with new data
        if(index !== -1){
            tempPourLogs[index] = pourLog;
            setPourLogs([...tempPourLogs]);
        }

        setError({
            type: '',
            message: ''
        });
        setEditPourLog(null);
    }

    return (
        <Grid
            sx={{
                display: 'grid',
                margin: '10px 0 0 10px'
            }}
        >
            <MainHeader/>
            <FormGroup sx={{ 
                display: 'grid',
                width: '400px',
                border: '2px solid rgb(220, 220, 220)',
                borderRadius: '3px',
                marginBottom: '10px'
            }}>
                <Grid sx={{
                    fontSize: '20px', 
                    textAlign: 'center', 
                    fontWeight: 'bold',
                    padding: '8px 0'}}
                >Filters</Grid>
                <Select
                    styles={{
                        container: styles => {
                            return {
                                ...styles,
                                width: '80%',
                                justifySelf: 'center',
                                margin: '5px 0',
                                zIndex: '49'
                            }
                        },
                    }}
                    placeholder="Select Job"
                    value={filters.job}
                    onChange={handleJobSelect}
                    options={jobs}
                    isClearable
                    isSearchable
                />
                <Select
                    styles={{
                        container: styles => {
                            return {
                                ...styles,
                                width: '80%',
                                justifySelf: 'center',
                                margin: '5px 0',
                                zIndex: '48'
                            }
                        },
                    }}
                    placeholder="Select Lot"
                    value={filters.lot}
                    onChange={(lot) => setFilters({...filters, lot: lot})}
                    options={lots}
                    isClearable
                    isSearchable
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => {
                            if(date === null){
                                setFilters({...filters, scheduledDate: null});
                                return;
                            }
                            setFilters({...filters, scheduledDate: new Date(date)})
                        }}
                        sx={{
                            width: '80%',
                            justifySelf: 'center',
                            margin: '6px 0'
                        }}
                        slotProps={{
                            textField: {
                                size: 'small'
                            },
                        }}
                        label="Scheduled Date"
                        selected={filters.scheduledDate}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => {
                            if(date === null){
                                setFilters({...filters, completedDate: null});
                                return;
                            }
                            setFilters({...filters, completedDate: new Date(date)})
                        }}
                        sx={{
                            width: '80%',
                            justifySelf: 'center',
                            margin: '6px 0'
                        }}
                        slotProps={{
                            textField: {
                                size: 'small'
                            },
                        }}
                        label="Completed Date"
                        selected={filters.completedDate}
                    />
                </LocalizationProvider>
                <FormControlLabel 
                    sx={{
                        justifySelf: 'center'
                    }}
                    control={<Switch 
                        checked={filters.builder_invoiced} 
                        onChange={(e) => setFilters({...filters, builder_invoiced: e.target.checked})}/>} 
                    label="Builder Invoiced" 
                />
                <FormControlLabel 
                    sx={{
                        justifySelf: 'center'
                    }}
                    control={<Switch 
                        checked={filters.vender_invoiced} 
                        onChange={(e) => setFilters({...filters, vender_invoiced: e.target.checked})}/>} 
                    label="Vender Invoiced" 
                />
                <Button 
                    sx={{
                        justifySelf: 'center',
                        width: '200px',
                        margin: '10px auto 15px'
                    }}
                    variant='contained'
                    onClick={handleUpdateTable}
                >Update Table</Button>
            </FormGroup>
            <MaterialReactTable
                id="pour-log-table"
                muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                columns={columns}
                data={pourLogs}
                enableSorting
                layoutMode="grid-no-grow"
                enableColumnResizing
                enableStickyHeader
                muiTableBodyCellProps={({ cell }) => ({
                    sx: {
                        cursor: canEditInvoice ? cursor : 'default',
                    },
                    onMouseOver: () => {
                        if(cell.column.id === 'builder_invoice_number' || cell.column.id === 'builder_billed' ||
                            cell.column.id === 'vender_invoice_number' || cell.column.id === 'vender_invoice_value' ||
                            cell.column.id === 'vender_invoice_complete'){
                            setCursor('pointer');
                        }
                        else{
                            setCursor('default');
                        }
                    },
                    onClick: () => {
                        if(!canEditInvoice){
                            return;
                        }

                        const pourLog = pourLogs.find(pl => parseInt(pl.id) === parseInt(cell.row.original.id));
                        if(pourLog !== undefined){
                            if(cell.column.id === 'builder_invoice_number' || cell.column.id === 'builder_billed'){
                                pourLog.dialog = 'builder';
                                setEditPourLog(pourLog);
                            }
                            if(cell.column.id === 'vender_invoice_number' || cell.column.id === 'vender_invoice_value' ||
                                cell.column.id === 'vender_invoice_complete'){
                                pourLog.dialog = 'vender';
                                setEditPourLog(pourLog);
                            }
                        }
                    }
                })}
                initialState={{
                    pagination: {pageSize: 100, pageIndex: 0},
                    columnPinning: {left: ['job_number', 'lot_number', 'phase_task']}
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <Grid sx={{
                            display: 'flex',
                            width: "100%",
                            justifyContent: "center",
                            fontSize: "30px",
                            fontWeight: "bold",
                            cursor: "default"
                            }}
                        >
                            Pour Log
                        </Grid>
                    );
                }}
            />
            {editPourLog !== null && 
            <EditInvoiceDialog 
                pourLog={editPourLog} 
                save={handleCloseSave} 
                noSave={handleCloseNoSave}
                error={error}
            />}
            <Dialog open={error.type !== ''}>
                <DialogTitle style={{borderBottom: "2px solid black"}}>{`ERROR: ${error.type}`}</DialogTitle>
                <DialogContent style={{textAlign: 'center', marginTop: '10px'}}>
                    {error.message}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setError({
                        type: '',
                        message: ''
                    })}>OK</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const EditInvoiceDialog = ({pourLog, save, noSave}) => {
    const [pourLogToEdit, setPourLogToEdit] = useState(pourLog);

    return (
        <Dialog open={pourLogToEdit !== null} onClose={noSave}>
            {pourLogToEdit !== null && pourLogToEdit.dialog === 'builder' ? 
            <DialogTitle style={{borderBottom: "2px solid black"}}>Pour Log Builder Invoice</DialogTitle> : null}
            {pourLogToEdit !== null && pourLogToEdit.dialog === 'vender' ?
            <DialogTitle style={{borderBottom: "2px solid black"}}>Pour Log Vender Invoice</DialogTitle> : null}
            {pourLogToEdit &&
            <DialogContent>
                <InfoBox label='Concrete Yards:' value={pourLogToEdit.yards}/>
                <InfoBox label='Concrete Supplier:' value={pourLogToEdit.supplier}/>
                <InfoBox label='Pump:' value={pourLogToEdit.pump}/>
                <InfoBox label='Time:' value={pourLogToEdit.time}/>
                <InfoBox label='Order #:' value={pourLogToEdit.order}/>
                {pourLogToEdit.dialog === 'builder' &&
                <DialogContent>
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '5px auto',
                            // backgroundColor: error.type === "NO_INVOICE_NUMBER" ? 'rgb(230, 100, 100)' : 'white',
                        }}
                        placeholder="Builder Invoice #"
                        value={pourLogToEdit.builder_invoice_number !== null ? pourLogToEdit.builder_invoice_number : ""}
                        onChange={(e) => setPourLogToEdit({...pourLogToEdit, builder_invoice_number: e.target.value})}
                    />
                    <FormControlLabel 
                        sx={{
                            justifySelf: 'center',
                            // backgroundColor: error.type === "INVOICE_NOT_COMPLETED" ? 'rgb(230, 100, 100)' : 'white',
                        }}
                        control={<Checkbox 
                            defaultChecked={pourLogToEdit.builder_billed} 
                            onChange={async (e) => {
                                if(!e.target.checked){
                                    setPourLogToEdit({...pourLogToEdit, 
                                        builder_billed: e.target.checked, 
                                        builder_invoice_number: null,
                                        builder_invoice_date: null,
                                        builder_invoice_by: null
                                    });
                                    return;
                                }
                                setPourLogToEdit({...pourLogToEdit, 
                                    builder_billed: e.target.checked, 
                                    builder_invoice_date: dayjs(new Date()).format('MM-DD-YYYY'),
                                    builder_invoice_by: await Auth.getUserId()
                                });
                            }}
                        />} 
                        label="Builder Billed" 
                    />
                </DialogContent>}
                {pourLogToEdit.dialog === 'vender' &&
                <DialogContent>
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '5px auto',
                            // backgroundColor: error.type === "NO_INVOICE_NUMBER" ? 'rgb(230, 100, 100)' : 'white',
                        }}
                        placeholder="Vender Invoice #"
                        value={pourLogToEdit.vender_invoice_number !== null ? pourLogToEdit.vender_invoice_number : ""}
                        onChange={(e) => setPourLogToEdit({...pourLogToEdit, vender_invoice_number: e.target.value})}
                    />
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '5px auto',
                            // backgroundColor: error.type === "NO_INVOICE_YARDS" ? 'rgb(230, 100, 100)' : 'white',
                        }}
                        placeholder="Vender Invoice Yards"
                        value={pourLogToEdit.vender_invoice_value !== null ? pourLogToEdit.vender_invoice_value : ""}
                        onChange={(e) => setPourLogToEdit({...pourLogToEdit, vender_invoice_value: e.target.value})}
                    />
                    <FormControlLabel 
                        sx={{
                            justifySelf: 'center',
                            // backgroundColor: error.type === "INVOICE_NOT_COMPLETED" ? 'rgb(230, 100, 100)' : 'white',
                        }}
                        control={<Checkbox 
                            defaultChecked={pourLogToEdit.vender_invoice_complete} 
                            onChange={async (e) => {
                                if(!e.target.checked){
                                    setPourLogToEdit({...pourLogToEdit, 
                                        vender_invoice_complete: e.target.checked, 
                                        vender_invoice_number: null,
                                        vender_invoice_value: null,
                                        vender_invoice_date: null,
                                        vender_invoice_by: null
                                    });
                                    return;
                                }
                                setPourLogToEdit({...pourLogToEdit, 
                                    vender_invoice_complete: e.target.checked, 
                                    vender_invoice_date: dayjs(new Date()).format('MM-DD-YYYY'),
                                    vender_invoice_by: await Auth.getUserId()
                                });
                        }}
                        />} 
                        label="Vender Invoice Complete" 
                    />
                </DialogContent>}
            </DialogContent>}
            <DialogActions>
                <Button onClick={noSave}>Cancel</Button>
                <Button onClick={() => save(pourLogToEdit)}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

const InfoBox = ({label, value}) => {
    return (
        <Grid
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(10, 1fr)',
                margin: '10px auto'
            }}
        >
            <Grid
                sx={{
                    gridColumn: '1/6',
                    display: 'grid',
                    alignContent: 'center',
                    textAlign: 'end',
                    padding: '5px',
                }}
            >{label}</Grid>
            <Grid
                sx={{
                    gridColumn: '6/10',
                    display: 'grid',
                    alignContent: 'center',
                }}
            >{value}</Grid>
        </Grid>
    )
}

export default PourLog;
