import {useState} from 'react';
import moment from "moment/moment.js";
import {useNavigate} from 'react-router';

import Auth from '../authentication/Auth.js';

import MainHeader from '../utilities/MainHeader';
import HomeScreenContainer from './styles/HomeScreenContainer';
import Links from "./Links";
import Sidebar from "./Sidebar";

const HomeScreen = (props) => {
    const navigate = useNavigate();

    const [message, setMessage] = useState(null);
    const [devMessage, setDevMessage] = useState(null);

    const [didLoadMessages, setDidLoadMessages] = useState(false);
    const [didLoadStatsData, setDidLoadStatsData] = useState(false);
    const [didLoadWeatherData, setDidLoadWeatherData] = useState(false);

    const [weather, setWeather] = useState({
        currentTemp: '',
        forecast: []
    });
    const [stats, setStats] = useState([]);

    const quickLinks = Links.quickLinks

    const specialtyLinks = Links.specialtyLinks

    const loadMessages = async () => {
        setDidLoadMessages(true);
        const result = await Auth.hitEndpointNew("GET", "messages");
        for(let i = 0; i < result.length; i++){
            if(result[i].type === 'message' && result[i].message.length > 0){
                // Checks if messages are expired
                if(result[i].expires !== undefined){
                    let date = result[i].expires
                    result[i].expires = new Date(date);
                    const today = new Date();

                    if(result[i].expires < today){// TODO update current message
                        result[i].expires = null;
                        result[i].message = '';
                        result[i].modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss');
                        await Auth.hitEndpointNew("PATCH", "message", "", result[i]);
                    }
                }
                setMessage(result[i]);
            }
            if(result[i].type === 'dev-message' && result[i].message.length > 0){
                // Checks if messages are expired
                if(result[i].expires){
                    let date = result[i].expires
                    result[i].expires = new Date(date);
                    const today = new Date();

                    if(result[i].expires < today){
                        result[i].expires = null;
                        result[i].message = '';
                        result[i].modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss');
                        await Auth.hitEndpointNew("PATCH", "message", "", result[i]);
                    }
                }
                setDevMessage(result[i]);
            }
        }
    }

    if(!didLoadMessages){
        loadMessages();
    }

    const loadStats = async () => {
        setDidLoadStatsData(true);

        let stats = await Auth.hitEndpoint("GET", "STATS", `selector=twentythree`);

        const statsHeader = stats[0]
        const statsThisMonth = stats[1]
        const statsOneMonthAgo = stats[2]
        const statsTwoMonthAgo = stats[3]
        const statsThreeMonthAgo = stats[4]
        setStats([statsHeader, statsThisMonth, statsOneMonthAgo, statsTwoMonthAgo, statsThreeMonthAgo]);
    }

    if(!didLoadStatsData){
        loadStats();
    }
    
    const loadWeather = async () => {
        const CtoF = (cel) => {
            if(cel){
                return ((cel * 9 / 5) + 32).toFixed(0);
            }
            return '--';
        }

        // Getting the weather data
        const current = await Auth.hitEndpoint("GET", "WEATHER_CURRENT");
        const forecast = await Auth.hitEndpoint("GET", "WEATHER_FORECAST");

        const daysOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        if(forecast !== undefined && forecast.length !== 0 && forecast.data !== undefined) {
            const forecastData = forecast.data.properties.periods;
            if (forecastData.length === 0) {
                setDidLoadWeatherData(true);
                setTimeout(() => setDidLoadWeatherData(false), 5000);
                return;
            }
            const weatherForecast = [];
            // High temp first in array
            if (forecastData[0].icon.includes('day')) {
                let index = 0;
                for (let i = 0; i < 7; i++) {
                    const day = new Date(forecastData[index].endTime).getDay();
                    const date = forecastData[index].endTime.split('T')[0].split('-');
                    const dailyForecast = {
                        date: `${daysOfTheWeek[day]} ${date[1]}/${date[2]}`,
                        // icon: `https://api.weather.gov/${forecastData[index].icon}`,
                        icon: `${forecastData[index].icon}`,
                        description: forecastData[index].shortForecast,
                        high: forecastData[index].temperature,
                        low: forecastData[index + 1].temperature
                    }

                    weatherForecast.push(dailyForecast);
                    index += 2;
                }
            } else {
                let index = 0;
                for (let i = 0; i < 7; i++) {
                    const day = new Date(forecastData[index + 1].endTime).getDay();
                    const date = forecastData[index + 1].endTime.split('T')[0].split('-');
                    const dailyForecast = {
                        date: `${daysOfTheWeek[day]} ${date[1]}/${date[2]}`,
                        // icon: `https://api.weather.gov/${forecastData[index + 1].icon}`,
                        icon: `${forecastData[index].icon}`,
                        description: forecastData[index + 1].shortForecast,
                        high: forecastData[index + 1].temperature,
                        low: forecastData[index].temperature
                    }

                    weatherForecast.push(dailyForecast);
                    index += 2;
                }
            }

            setWeather({
                currentTemp: CtoF(current.data.properties.temperature.value),
                forecast: weatherForecast
            });
            setDidLoadWeatherData(true);
        }
        else{
            setDidLoadWeatherData(false)
        }
    }

    if(!didLoadWeatherData){
        loadWeather();
    }

    return (
        <HomeScreenContainer>
            <MainHeader/>
            <div className='messaging'>
                {message && message.message.length > 0 &&
                <div className='main-header-message reg-message'>
                    <div className='scrolling-message'>{message.message}</div>
                </div>}
                {devMessage && devMessage.message.length > 0 &&
                <div className='main-header-message dev-message'>
                    <div className='scrolling-message'>{devMessage.message}</div>
                </div>}
            </div>
            <Sidebar showControls={true}/>
            <div className='info-box'>
                <div className='statistics'>
                    <div className='stats-header'>Statistics - Lot Releases</div>
                    <div className='stats-body'>
                        <div className='stats'>
                            {stats.map((stat, index) => {
                                return (
                                    <div key={`stat-row${index}`} className={`stat-row row-num-${index}`}>
                                        {stat.map((s, fieldIndex) => {
                                            return(
                                            <div key={`stat-field${index}-${fieldIndex}`} className='stat-row-data'>{s}</div>
                                        )})}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='weather-box'>
                    <div className='weather-header'>7 Day Forecast - Phoenix, AZ - {weather.currentTemp}&#176; F</div>
                    <div className='weather-body'>
                        {weather.forecast.map((dailyForecast, index) => {
                            return (
                                <div key={index} className='weather'>
                                    <div className='date'>{dailyForecast.date}</div>
                                    <div className='icon'><img width='50' height='50' src={dailyForecast.icon} alt='Weather Icon'/></div>
                                    <div className='description'>{dailyForecast.description}</div>
                                    <div className='temperature-box'>
                                        <div className='temperature'><span className='temp-label'>High:</span><span className='temp-value'> {dailyForecast.high}</span></div>
                                        <div className='temperature'><span className='temp-label'>Low:</span> <span className='temp-value'>{dailyForecast.low}</span></div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>    
        </HomeScreenContainer>
    )
};

export default HomeScreen;