import styled from 'styled-components';

const RoutingContainer = styled.div`
    display: grid;
    margin: 10px 0 0 10px;
   
    .filters{
        display: grid;
        width: 400px;
        background-color: white;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid rgb(200, 200, 200);
        border-radius: 5px;

        .invoiced-checkbox{
            font-size: 18px;
            margin-bottom: 10px;
        }

        .filter-button{
            border: 1px solid rgb(220, 220, 220);
        }
    }
`;

export default RoutingContainer;