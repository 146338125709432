import Auth from "../components/authentication/Auth";

class Reports {
    static async loadReports(queryObj) {

        let dbData;
        if (queryObj) {
            const jsonString = JSON.stringify(queryObj);
            dbData = await Auth.hitEndpointNew("GET", "reports", `selector=eight&jsonString=${jsonString}`);
        } else {
            dbData = await Auth.hitEndpointNew("GET", "reports", ``);
        }
        return dbData;
    }

    static async loadScheduleReports(queryObj) {

        let dbData;
        if (queryObj) {
            const jsonString = JSON.stringify(queryObj);
            dbData = await Auth.hitEndpointNew("GET", "reports/schedule", `selector=eight&jsonString=${jsonString}`);
        } else {
            dbData = await Auth.hitEndpointNew("GET", "reports/schedule", ``);
        }
        return dbData;
    }

    static async saveNewReport(report) {
        const result = await Auth.hitEndpointNew("POST", "reports", "", report);
        report.id = result.id;

        return report;
    }

    static async updateReport(report, user) {
        await Auth.hitEndpointNew("PATCH", "reports", "", report);
        return report;
    }

    static async deleteReport(report) {
        await Auth.hitEndpointNew("DELETE", "reports", "", report);
        return report;
    }

    static createNewReport(user){
        return {
            field_id: "",
            name: "",
            description: "",
            stats_order_by: "",
            stat_name: ""
        };
    }
}

export default Reports;
