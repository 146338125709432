import {useState} from 'react';
import Auth from '../authentication/Auth.js';

import Crews from '../../classes/Crews.js';
import Employees from '../../classes/Employees.js';
import MainHeader from '../utilities/MainHeader.js';
import { createAlert } from '../utilities/MainHeaderAlert.js';
import CrewsPageContainer from './styles/CrewsPageContainer.js';
import { DropDownWithLabel, DropDown } from '../utilities/DropDown.js';
import { Button } from '../utilities/Button.js';
import { CheckboxWithLabel, Checkbox } from '../utilities/Checkbox.js';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import PopupOk, { PopupOkWarningColor } from '../utilities/PopupOk.js';
import Popup, { PopupSuccessColor, PopupFailureColor } from '../utilities/Popup.js';
import Select from "react-select";
import Reports from "../../classes/Reports";

const CrewsPage = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);
    const [user, setUser] = useState(null);

    const [filterOptions, setFilterOptions] = useState({
        showInactiveCrews: false,
        scope: "",
        employee_id: "",
        company: "",
    });

    const [employeesToRemove, setEmployeesToRemove] = useState([]);

    const [employees, setEmployees] = useState([]);
    const [employeesForFilterDropDown, setEmployeesForFilterDropDown] = useState([]);
    const [employeesForNewCrewDropDown, setEmployeesForNewCrewDropDown] = useState([]);
    const [employeesForEditCrewDropDown, setEmployeesForEditCrewDropDown] = useState([]);

    const [crews, setCrews] = useState([]);
    const [newCrew, setNewCrew] = useState(null);
    const [crewToEdit, setCrewToEdit] = useState(null);
    const [crewToDelete, setCrewToDelete] = useState(null);

    const [errorNoCrewNumber, setErrorNoCrewNumber] = useState(false);
    const [errorDuplicateCrewNumber, setErrorDuplicateCrewNumber] = useState(false);
    const [errorNoScope, setErrorNoScope] = useState(false);
    const [errorCrewMemberPositionEmpty, setErrorCrewMemberPositionEmpty] = useState(false);
    const [errorNoCrewLeader, setErrorNoCrewLeader] = useState(false);
    const [errorMoreThenOneLeader, setErrorMoreThenOneLeader] = useState(false);
    const [errorNoHomeReportSelected, setErrorNoHomeReportSelected] = useState(false);

    const [newCrewSuccess, setNewCrewSuccess] = useState(false);
    const [editCrewSuccess, setEditCrewSuccess] = useState(false);
    const [deleteCrewSuccess, setDeleteCrewSuccess] = useState(false);

    const canCreateCrews = props.isAuthorized('crews', 'READ');
    const canEditCrews = props.isAuthorized('crews', 'READ');
    const canDeleteCrews = false;

    const [crewScopes, setCrewScopes] = useState([]);

    const [reports, setReports] = useState([]);
    const [selectedReports, setSelectedReports] = useState([]);

    const loadData = async () => {
        setDidLoadData(true);

        const tempUser = (await Auth.hitEndpointNew("GET", `user/${await Auth.getUserId()}`))[0]
        setUser(tempUser);

        if(tempUser.permission_group_id === 39 || tempUser.permission_group_id === 49){
            const employeesToRemove = await Auth.hitEndpointNew("GET", "employees-new", "jsonString=remove_from_crew=1");
            setEmployeesToRemove(employeesToRemove);
        }

        setEmployees(await Employees.loadEmployees(null));
        setEmployeesForFilterDropDown(await Employees.loadEmployeesForDropDown(null));

        let tempCrews = await Crews.loadCrews(null);
        if(filterOptions.showInactiveCrews){
            setCrews(Crews.sortCrews("number", "ascending", tempCrews));
        }
        else{
            setCrews(Crews.sortCrews("number", "ascending", tempCrews.filter(crew => crew.active === 1)));
        }

        let scopes = await Crews.getCrewScopes()

        setReports(await Reports.loadScheduleReports())
        setCrewScopes(scopes)
    }

    if(!didLoadData){
        loadData();
    }

    const setupCrewToEdit = async (crew) => {
        if(crew.company !== ""){
            let jsonString = "";
            if(crew.company === "SVC"){
                jsonString = JSON.stringify({concrete: 1});
            }
            if(crew.company === "SVP"){
                jsonString = JSON.stringify({plumbing: 1});
            }
            if(crew.company === "SVF"){
                jsonString = JSON.stringify({framing: 1});
            }
            let reports = []
            for(let r=0; r<crew.scheduleReports.length; r++){
                reports.push({
                    value:  crew.scheduleReports[r].report.id,
                    label:  crew.scheduleReports[r].report.display,
                    report:  crew.scheduleReports[r].report
                })
            }
            setSelectedReports(reports)
            const tempEmployeesForEditCrewDropDown = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${jsonString}`);
            setEmployeesForEditCrewDropDown(Employees.convertEmployeesToDropDown(tempEmployeesForEditCrewDropDown.filter(em => em.active)));
        }

        crew.members.forEach(member => member.onCrew = true);
        setCrewToEdit(crew);
    }

    const applyFilters = async () => {
        const queryObj = {}
        if(filterOptions.scope !== ""){ queryObj.crew_scope_id = filterOptions.scope; }
        if(filterOptions.employee_id !== ""){ queryObj["crew_member.employee_id"] = Number.parseInt(filterOptions.employee_id); }
        if(filterOptions.company !== ""){ queryObj.company = filterOptions.company; }

        let tempCrews = await Crews.loadCrews(queryObj);
        if(filterOptions.showInactiveCrews){
            setCrews(Crews.sortCrews("number", "ascending", tempCrews));
        }
        else{
            setCrews(Crews.sortCrews("number", "ascending", tempCrews.filter(crew => crew.active === 1)));
        }
    }

    const setNewCrewScopeAndCompany = async (e) => {
        const tempCrew = setCrewScopeAndCompany(e.target.value, newCrew)
        setNewCrew(tempCrew);
        if(tempCrew.company !== ""){
            let jsonString = "";
            if(tempCrew.company === "SVC"){
                jsonString = JSON.stringify({concrete: 1});
            }
            if(tempCrew.company === "SVP"){
                jsonString = JSON.stringify({plumbing: 1});
            }
            if(tempCrew.company === "SVF"){
                jsonString = JSON.stringify({framing: 1});
            }
            const tempEmployeesForNewCrewDropDown = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${jsonString}`);
            setEmployeesForNewCrewDropDown(Employees.convertEmployeesToDropDown(tempEmployeesForNewCrewDropDown.filter(em => em.active)));
        }
    }

    const resetErrorPopups = () => {
        setErrorNoCrewNumber(false);
        setErrorDuplicateCrewNumber(false);
        setErrorNoScope(false);
        setErrorCrewMemberPositionEmpty(false);
        setErrorNoCrewLeader(false);
        setErrorMoreThenOneLeader(false);
        setErrorNoHomeReportSelected(false);
    }

    const resetSuccessPopups = () => {
        setNewCrewSuccess(false);
        setEditCrewSuccess(false);
        setDeleteCrewSuccess(false);
    }

    const setEditCrewScopeAndCompany = async (e) => {
        const tempCrew = setCrewScopeAndCompany(e.target.value, crewToEdit)
        setCrewToEdit(tempCrew);
        if(tempCrew.company !== ""){
            let jsonString = "";
            if(tempCrew.company === "SVC"){
                jsonString = JSON.stringify({concrete: 1});
            }
            if(tempCrew.company === "SVP"){
                jsonString = JSON.stringify({plumbing: 1});
            }
            if(tempCrew.company === "SVF"){
                jsonString = JSON.stringify({framing: 1});
            }
            const tempEmployeesForEditCrewDropDown = await Auth.hitEndpointNew("GET", "employees-by-company", `jsonString=${jsonString}`);
            setEmployeesForEditCrewDropDown(Employees.convertEmployeesToDropDown(tempEmployeesForEditCrewDropDown.filter(em => em.active)));
        }
    }

    const handleCrewErrors = (errorCode) => {
        if(errorCode === "ERROR_NO_CREW_NUMBER"){ setErrorNoCrewNumber(true); return;}
        if(errorCode === "ERROR_DUPLICATE_CREW_NUMBER"){ setErrorDuplicateCrewNumber(true); return;}
        if(errorCode === "ERROR_NO_SCOPE"){ setErrorNoScope(true); return;}
        if(errorCode === "ERROR_CREW_MEMBER_POSITION_EMPTY"){setErrorCrewMemberPositionEmpty(true); return;}
        if(errorCode === "ERROR_NO_CREW_LEADER"){ setErrorNoCrewLeader(true); return;}
        if(errorCode === "ERROR_MORE_THAN_ONE_LEADER"){ setErrorMoreThenOneLeader(true); return;}
        if(errorCode === "ERROR_NO_HOME_REPORT_SELECTED"){ setErrorNoHomeReportSelected(true); return;}
    }

    const saveNewCrew = async () => {

        newCrew.scheduleReports = []
        for(let r=0; r<selectedReports.length; r++){
            newCrew.scheduleReports.push({
                crew_id: newCrew.id,
                schedule_report_id: selectedReports[r].value
            })
        }

        const result = await Crews.checkCrewForErrors(newCrew);
        if(result !== "SUCCESS"){
            handleCrewErrors(result);
            return;
        }

        // Update employee crewNumbers member and filter off none crew members
        const crewToSave = {...newCrew};
        for(let i = 0; i < crewToSave.members.length; i++){
            const currentEmployee = employees.find(employeeTofind => employeeTofind.id === crewToSave.members[i].employee_id);
            if(crewToSave.members[i].onCrew){
                delete currentEmployee.position;
                await Employees.updateEmployee(Employees.createEmployeeDBObject(Employees.addCrewNumber(crewToSave.number, currentEmployee)), null);
            }
            else{
                delete currentEmployee.position;
                await Employees.updateEmployee(Employees.createEmployeeDBObject(Employees.removeCrewNumber(crewToSave.number, currentEmployee)), null);
            }
            if(crewToSave.members[i].position === "Leader"){
                crewToSave.phone_number = currentEmployee.phone_number;
            }
        }
        crewToSave.members = crewToSave.members.filter(memberToFilter => memberToFilter.onCrew).map(member => {
            return member;
        });

        await Crews.saveNewCrew(Crews.createCrewDBObject(crewToSave));
        setNewCrewSuccess(true);
        setNewCrew(null);
        setEmployeesForNewCrewDropDown([]);
        await applyFilters();
    }

    const updateCrew = async () => {

        for(let r=0; r<selectedReports.length; r++){
            let existing = crewToEdit.scheduleReports.find(x => x.schedule_report_id === selectedReports[r].report.id)
            if(existing === undefined) {
                crewToEdit.scheduleReports.push({
                    crew_id: crewToEdit.id,
                    schedule_report_id: selectedReports[r].value
                })
            }
        }
        let removeReports = []
        for(let r=0; r<crewToEdit.scheduleReports.length; r++){
            let selected = selectedReports.find(x => x.report.id === crewToEdit.scheduleReports[r].schedule_report_id)
            if(selected === undefined){
                removeReports.push(crewToEdit.scheduleReports[r].id)
                crewToEdit.scheduleReports.splice(r, 1)
            }
        }

        const result = await Crews.checkCrewForErrors(crewToEdit);
        if(result !== "SUCCESS"){
            handleCrewErrors(result);
            return;
        }

        // Update employee crewNumbers member and filter off none crew members
        const crewToSave = {...crewToEdit};
        for(let i = 0; i < crewToSave.members.length; i++){
            let currentEmployee = employees.find(employeeTofind => employeeTofind.id === crewToSave.members[i].employee_id);
            if(currentEmployee === undefined){
                currentEmployee = employeesToRemove.find(emp => emp.id === crewToSave.members[i].employee_id);
            }
            if(crewToSave.members[i].onCrew){
                await Employees.updateEmployee(Employees.createEmployeeDBObject(Employees.addCrewNumber(crewToSave.number, currentEmployee)), null);
                if(crewToSave.members[i].position === "Leader"){
                    crewToSave.phone_number = currentEmployee.phone_number;
                }
                    const crewMember = {
                        crew_id: crewToSave.id,
                        employee_id: crewToSave.members[i].employee_id,
                        position: crewToSave.members[i].position
                    }
                    const jsonString = `crew_id=${crewToSave.id} and employee_id=${crewToSave.members[i].employee_id}`;
                    await Auth.hitEndpointNew("DELETE", "crew-member", `jsonString=${jsonString}`, crewToSave.members[i]);
                    await Auth.hitEndpointNew("POST", "crew-member", '', crewMember);
            }
            else{
                if(currentEmployee.remove_from_crew){
                    currentEmployee.remove_from_crew = 0;
                    setEmployeesToRemove(employeesToRemove.filter(em => em.id !== currentEmployee.id));
                }
                await Employees.updateEmployee(Employees.createEmployeeDBObject(Employees.removeCrewNumber(crewToSave.number, currentEmployee)), null);
                const jsonString = `crew_id=${crewToSave.id} and employee_id=${crewToSave.members[i].employee_id}`;
                await Auth.hitEndpointNew("DELETE", "crew-member", `jsonString=${jsonString}`, crewToSave.members[i]);
            }
        }
        crewToSave.members = crewToSave.members.filter(memberToFilter => memberToFilter.onCrew).map(member => {
            return member;
        });

        if(crewToSave.members.length === 0){
            crewToSave.phone_number = '';
        }

        await Crews.deleteCrewScheduleReport(removeReports);
        await Crews.updateCrew(Crews.createCrewDBObject(crewToSave), null);
        setEditCrewSuccess(true);
        setCrewToEdit(null);
        setEmployeesForEditCrewDropDown([]);
        await applyFilters();
    }

    const deleteCrew = async (e) => {
        if(e.target.value === "ok"){
            // Update employee crewNumbers
            const crewToSave = {...crewToDelete};
            for(let i = 0; i < crewToSave.members.length; i++){
                const currentEmployee = employees.find(employeeTofind => employeeTofind.id === crewToSave.members[i].employee_id);
                await Employees.updateEmployee(Employees.createEmployeeDBObject(Employees.removeCrewNumber(crewToSave.number, currentEmployee)), null);
            }

            crewToSave.active = 0;
            await Crews.updateCrew(Crews.createCrewDBObject(crewToSave), null);
            await applyFilters();
            setDeleteCrewSuccess(true);
        }

        setCrewToEdit(null);
        setCrewToDelete(null);
    }

    // Below is edit functions for individual crew data. ALL RETURN THE CREW OBJECT
    const setCrewScopeAndCompany = (scopeId, crew) => {
        const scopeAndCompany = crewScopes.find(scopeToFind => parseInt(scopeToFind.id) === parseInt(scopeId));
        let scopeReport = reports.find(x => x.id === scopeAndCompany.schedule_report_id)
        let sReports = [...selectedReports]
        let existing = sReports.find(x => x.report.id === scopeReport.id)
        if(existing === undefined){
            sReports.push({
                value: scopeReport.id,
                label: scopeReport.display,
                report: scopeReport
            })
        }
        setSelectedReports(sReports)
        let updatedCrew = {
            ...crew,
            crew_scope_id: scopeAndCompany === undefined ? null : scopeAndCompany.id,
            scope: scopeAndCompany === undefined ? null : scopeAndCompany,
            company: scopeAndCompany === undefined ? "" : scopeAndCompany.company
        }
        return updatedCrew
    }

    const handleSelectedReportChange = (selections) => {
        setSelectedReports(selections)
    }

    const handleCancelCrewEdit = (e) => {
        setCrewToEdit(null)
        setSelectedReports([])
    }
    const columns = ['Crew #', 'Scope', 'Members', 'Company', 'Phone #'];
    
    return (
        <CrewsPageContainer>
            <MainHeader/>
            {user !== null && (user.permission_group_id === 39 || user.permission_group_id === 49) && employeesToRemove.length > 0 ? 
            <div className='employees-to-remove-box'>
                <div>Please remove the following employees from crews:</div>
                {employeesToRemove.map(em => {
                    return (
                        <div key={`${em.id}-${em.first_name}`} className='employee-to-remove'>{`${em.first_name} ${em.last_name} - Crew ${em.crews}`}</div>
                    )
                })}
            </div> : null}
            <div className='crews-top-boxes'>
                <div className='crews-filter-box'>
                    <div className='crews-filter-header'>Filter</div>
                    <CheckboxWithLabel 
                        className="show-inactive-crews" 
                        name="show-inactive-crews" 
                        label="Show Inactive Crews" 
                        checked={filterOptions.showInactiveCrews} 
                        handleCheckbox={(e) => setFilterOptions({...filterOptions, showInactiveCrews: e.target.checked})} 
                        columns="10" 
                        labelStart="1" 
                        labelEnd="7" 
                        inputStart="7" 
                        inputEnd="10"
                    />
                    <DropDownWithLabel className="scope-filter" id="scope-filter-id" name="scope" label="Scope" handleDropDown={(e) => setFilterOptions({...filterOptions, scope: e.target.value})} addBlankOption={true} selectionList={crewScopes.map(scope => {return {id: scope.id, display: scope.name}})} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    <DropDownWithLabel className="employee-filter" id="employee-filter-id" name="employee" label="Employee" handleDropDown={(e) => setFilterOptions({...filterOptions, employee_id: e.target.value})} addBlankOption={true} selectionList={employeesForFilterDropDown} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    <DropDownWithLabel className="company-filter" id="company-filter-id" name="company" label="Company" handleDropDown={(e) => setFilterOptions({...filterOptions, company: e.target.value})} addBlankOption={true} selectionList={[{id: "SVC", display: "SVC"}, {id: "SVP", display: "SVP"}, {id: "SVF", display: "SVF"}]} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                    <Button className="filter-crews" handleKeyPress={(async (e) => {if(e.key === 'Enter'){await applyFilters()}})} handleClick={(async (e) => await applyFilters())} buttonText="Apply" width="120"/>
                </div>
            </div>
            <div className='page-header'>
                {canCreateCrews && <Button className="add-new-crew" handleClick={(async (e) => {
                    setNewCrew(await Crews.createNewCrew(null))
                    setSelectedReports([])
                })} buttonText="Add Crew" buttonMargin="0 0 0 0" width="120" height="20"/>}
                <div className='header-title'>Crews</div>
            </div>
            {newCrew && 
            <div className='new-crew-box'>
                <div className='new-crew-header'>Add New Crew</div>
                <CheckboxWithLabel className="is-active-checkbox" name="is-active" label="Is Active" checked={newCrew.active} handleCheckbox={((e) => setNewCrew({...newCrew, active: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                <TextboxWithLabel className="number-textbox" name="number" label="Crew Number" placeholder="Crew Number" value={newCrew.number} handleTextbox={((e) => setNewCrew({...newCrew, number: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="9"/>
                <DropDownWithLabel className="scope-dropdown" id="scope-dropdown-id" name="scope" label="Scope" handleDropDown={setNewCrewScopeAndCompany} addBlankOption={true} selectionList={crewScopes.map(scope => {return {id: scope.id, display: scope.name}})} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                <DropDownWithLabel className="employees-dropdown" id="employee-dropdown-id" name="employee" label="Members" handleDropDown={async (e) => setNewCrew(Crews.addCrewMember(e.target.value, {...newCrew}))} addBlankOption={true} selectionList={employeesForNewCrewDropDown} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                <div className='sub-members-box'>
                    {newCrew.members.length > 0 &&
                    newCrew.members.map((member, index) => {
                        const currentMember = employees.find(emp => emp.id === member.employee_id);
                        return (
                            <div key={`${member.employee_id}-${index}`}  className='members-display'>
                                <Checkbox className="member-on-crew-checkbox" name="member-on-crew" checked={member.onCrew} handleCheckbox={(e) => setNewCrew(Crews.removeCrewMember(member.employee_id, newCrew))} columns="2" inputStart="1" inputEnd="2"/>
                                {member.onCrew && currentMember !== undefined && currentMember.alias === null && <div className="member-name">{`${currentMember.first_name} ${currentMember.last_name}`}</div>}
                                {member.onCrew && currentMember !== undefined && currentMember.alias !== null && <div className="member-name">{`${currentMember.alias} - (${currentMember.first_name} ${currentMember.last_name})`}</div>}
                                {!member.onCrew && <div className="member-name"><s>{`${currentMember.alias} - (${currentMember.first_name} ${currentMember.last_name})`}</s></div>}
                                <DropDown className="member-position-dropdown" id="member-position-dropdown-id" name="member-position" handleDropDown={(e) => setNewCrew(Crews.setCrewMemberPosition(e.target.value, member.employee_id, newCrew))} addBlankOption={true} selectionList={Crews.getCrewMemberPositions().map(position => {return {id: position, display: position}})} columns={10} inputStart={4} inputEnd={9}/>
                            </div>
                        );
                    })}
                    {newCrew.members.length === 0 && <div className='no-crew-members'>No Crew Members Selected</div>}
                </div>
                <div className='sub-reports-box'>
                    <Select className='report-select'
                        value={selectedReports}
                        isMulti
                        options={reports.map((item, i) => {
                            return (
                                {
                                    value: item.id,
                                    label: item.display,
                                    report: item,
                                })
                        })}
                        onChange={(e) => {
                            handleSelectedReportChange(e);
                        }}
                    />
                </div>
                <div className="two-button-box">
                    <Button className="submit-new-crew" handleKeyPress={((e) => {if(e.key === 'Enter'){saveNewCrew()}})} handleClick={((e) => saveNewCrew())} buttonText="Submit" width="120"/>
                    <Button className="cancel-add-new-crew" handleKeyPress={((e) => {if(e.key === 'Enter'){
                        setSelectedReports([])
                        setNewCrew(null)
                    }})} handleClick={((e) => {
                        setNewCrew(null)
                        setSelectedReports([])
                    })} buttonText="Cancel" width="120"/>
                </div>
            </div>}
            <div className="table-header">
                {columns.map((column, index)=> {
                    return <div key={`${column}-${index}`} className={`grid-table-header-data index${index}`}>{column}</div>
                })}
            </div>
            {crews.map((crew, index) => {
                if(canEditCrews && crewToEdit && (crewToEdit.id === crew.id)){
                    return(
                        <div key={`${crew.id}-${index}`} className='edit-crew-box'>
                            <div className='edit-crew-header'>Edit Crew</div>
                            <CheckboxWithLabel className="is-active-checkbox" name="is-active" label="Is Active" checked={crewToEdit.active} handleCheckbox={((e) => setCrewToEdit({...crewToEdit, active: e.target.checked}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                            <TextboxWithLabel className="number-textbox" name="number" label="Crew Number" placeholder="Crew Number" value={crewToEdit.number} handleTextbox={((e) => setCrewToEdit({...crewToEdit, number: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                            <DropDownWithLabel className="scope-dropdown" id="scope-dropdown-id" name="scope" label="Scope" value={crewToEdit.scope === undefined || crewToEdit.scope === null ? '' : crewToEdit.scope.id} handleDropDown={setEditCrewScopeAndCompany} addBlankOption={true} selectionList={crewScopes.map(scope => {return {id: scope.id, display: scope.name}})} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={9}/>
                            <DropDownWithLabel 
                                className="employees-dropdown" 
                                id="employee-dropdown-id" 
                                name="employee" 
                                label="Members" 
                                handleDropDown={async (e) => setCrewToEdit(Crews.addCrewMember(e.target.value, {...crewToEdit}))} 
                                addBlankOption={true} 
                                selectionList={employeesForEditCrewDropDown} 
                                columns={10} 
                                labelStart={1} 
                                labelEnd={4} 
                                inputStart={4} 
                                inputEnd={9}
                            />
                            <div className='sub-members-box'>
                                {crewToEdit.members.length > 0 &&
                                crewToEdit.members.map((member, index) => {
                                    let currentMember = employees.find(emp => emp.id === member.employee_id);
                                    if(currentMember === undefined){
                                        currentMember = employeesToRemove.find(emp => emp.id === member.employee_id);
                                        if(currentMember === undefined){
                                            return null;
                                        }
                                    }
                                    
                                    return (
                                        <div key={`${member.employee_id}-${index}`}  className='members-display'>
                                            <Checkbox className="member-on-crew-checkbox" name="member-on-crew" checked={member.onCrew} handleCheckbox={(e) => setCrewToEdit(Crews.removeCrewMember(member.employee_id, crewToEdit))} columns="2" inputStart="1" inputEnd="2"/>
                                            {member.onCrew && currentMember.alias !== null && <div className="member-name">{`${currentMember.alias} - (${currentMember.first_name} ${currentMember.last_name})`}</div>}
                                            {member.onCrew && currentMember.alias === null && <div className="member-name">{`${currentMember.first_name} ${currentMember.last_name}`}</div>}
                                            {!member.onCrew && <div className="member-name"><s>{`${currentMember.alias} - (${currentMember.first_name} ${currentMember.last_name})`}</s></div>}
                                            <DropDown className="member-position-dropdown" id="member-position-dropdown-id" value={member.position} name="member-position" handleDropDown={(e) => setCrewToEdit(Crews.setCrewMemberPosition(e.target.value, member.employee_id, crewToEdit))} addBlankOption={true} selectionList={Crews.getCrewMemberPositions().map(position => {return {id: position, display: position}})} columns={10} inputStart={4} inputEnd={9}/>
                                        </div>
                                    );
                                })}
                                {crewToEdit.members.length === 0 && <div className='no-crew-members'>No Crew Members Selected</div>}
                            </div>
                            <div className='sub-reports-box'>
                                <Select className='report-select'
                                    value={selectedReports}
                                    isMulti
                                    options={reports.map((item, i) => {
                                        return (
                                            {
                                                value: item.id,
                                                label: item.display,
                                                report: item,
                                            })
                                    })}
                                    onChange={(e) => {
                                        handleSelectedReportChange(e);
                                    }}
                                />
                            </div>
                            {!canDeleteCrews &&
                            <div className="two-button-box">
                                <Button className="submit-edit-crew" handleKeyPress={((e) => {if(e.key === 'Enter'){updateCrew()}})} handleClick={((e) => updateCrew())} buttonText="Submit" width="120"/>
                                <Button className="cancel-edit-crew" handleKeyPress={((e) => {if(e.key === 'Enter'){handleCancelCrewEdit(null)}})} handleClick={((e) => handleCancelCrewEdit(e))} buttonText="Cancel" width="120"/>
                            </div>}
                            {canDeleteCrews &&
                            <div className="three-button-box">
                                <Button 
                                    className="submit-edit-crew" 
                                    handleKeyPress={((e) => {if(e.key === 'Enter'){updateCrew()}})} 
                                    handleClick={((e) => updateCrew())} 
                                    buttonText="Submit" 
                                    width="120"/>
                                <Button className="cancel-edit-crew" handleKeyPress={((e) => {if(e.key === 'Enter'){handleCancelCrewEdit(null)}})} handleClick={((e) => handleCancelCrewEdit(e))} buttonText="Cancel" width="120"/>
                                <Button className="submit-delete-crew" handleKeyPress={((e) => {if(e.key === 'Enter'){setCrewToDelete(crewToEdit)}})} handleClick={((e) => setCrewToDelete(crewToEdit))} buttonText="Delete" width="120"/>
                            </div>}
                        </div>
                    );
                }
                const position = index === 0 ? 'first' : index === (crews.length - 1) ? 'last' : '';
                const members = crew.members.map(member => {
                    let currentMember = employees.find(emp => emp.id === member.employee_id);
                    if(currentMember === undefined){
                        currentMember = employeesToRemove.find(emp => emp.id === member.employee_id);
                    }
                    if(currentMember){
                        currentMember.position = member.position;
                        return currentMember;
                    }
                    return null
                }).sort((m1, m2) => {
                    return m1.position === "Leader" ? -1 : 1;
                })
                return (
                    <div key={`${crew.id}-${index}`} className={`grid-table-row ${crew.active ? '' : 'in-active'}`}>
                        <div className={`grid-table-row-data ${position} far-left`} onClick={(e) => setupCrewToEdit(crew)}>{crew.number}</div>
                        <div className={`grid-table-row-data ${position} left-align`} onClick={(e) => setupCrewToEdit(crew)}>{crew.scope.name}</div>
                        <div className={`grid-table-row-data ${position}`}>
                            <DropDown className="display-members-dropdown" id="display-members-dropdown-id" name="display-members" handleDropDown={null} addBlankOption={false}
                                      selectionList={
                                        members.map(member => {
                                            if(member !== null && member.alias !== null && member.alias !== '') {
                                                return {
                                                    id: member.id,
                                                    display: `${member.alias} - (${member.first_name} ${member.last_name} - ${member.position})`
                                                }
                                            }
                                            else{
                                                return {
                                                    id: member !== null ? member.id : -1,
                                                    display: member !== null ? `${member.first_name} ${member.last_name} - ${member.position}` : `Error - ${crew.number} - members are null`
                                                }
                                            }
                                        })} margin="2px 0 2px 0" columns={10} inputStart={1} inputEnd={11}/>
                        </div>
                        <div className={`grid-table-row-data ${position}`} onClick={(e) => setupCrewToEdit(crew)}>{crew.company}</div>
                        <div className={`grid-table-row-data ${position} far-right`} onClick={(e) => setupCrewToEdit(crew)}>{crew.phone_number}</div>
                    </div>
                )
            })}
            {errorNoCrewNumber && <Popup color={PopupFailureColor} message={"Please add crew number."} handlePopup={resetErrorPopups}/>}
            {errorDuplicateCrewNumber && <Popup color={PopupFailureColor} message={"Please select a unique crew number"} handlePopup={resetErrorPopups}/>}
            {errorNoScope && <Popup color={PopupFailureColor} message={`Please select crew's scope.`} handlePopup={resetErrorPopups}/>}
            {errorCrewMemberPositionEmpty && <Popup color={PopupFailureColor} message={'Please set all positions for all crew members.'} handlePopup={resetErrorPopups}/>}
            {errorNoCrewLeader && <Popup color={PopupFailureColor} message={'Please designate one member as the crew leader.'} handlePopup={resetErrorPopups}/>}
            {errorMoreThenOneLeader && <Popup color={PopupFailureColor} message={'Please designate one, and only one, member as the crew leader.'} handlePopup={resetErrorPopups}/>}
            {errorNoHomeReportSelected && <Popup color={PopupFailureColor} message={'Please select at least one schedule report.'} handlePopup={resetErrorPopups}/>}
            {crewToDelete && <PopupOk color={PopupOkWarningColor} message={`You are deleting crew #${crewToDelete.number} from the database. This action is NOT reversable. Do you wish to continue?`} handlePopup={deleteCrew}/>}
            {newCrewSuccess && <Popup color={PopupSuccessColor} message={'The new crew has been saved to the database'} handlePopup={resetSuccessPopups}/>}
            {editCrewSuccess && <Popup color={PopupSuccessColor} message={'The crew has been updated and saved to the database'} handlePopup={resetSuccessPopups}/>}
            {deleteCrewSuccess && <Popup color={PopupSuccessColor} message={'The crew has been deleted from the database'} handlePopup={resetSuccessPopups}/>}
        </CrewsPageContainer>
    );
}

export default CrewsPage;