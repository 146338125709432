import {useState} from 'react';

import MainHeader from '../utilities/MainHeader';
import ConcreteMeasurements from './ConcreteMeasurements';
import PaverMeasurements from './PaverMeasurements';
import GateInformation from './GateInformation';
import TopOutTrimRelease from './TopOutTrimRelease';
import PlumbingCameraQAForm from './PlumbingCameraQAForm';
import EWAForm from './EWAForm';
import FieldFormsContainer from './styles/FieldFormsContainer';
import Updates from "./schedule/Updates";

const FieldForms = (props) => {
    const [selectedForm, setSelectedForm] = useState('');

    const canInputConcreteMeasurements = props.isAuthorized('concreteMeasurements', 'READ');
    const canInputPaverMeasurements = props.isAuthorized('paverMeasurements', 'READ');
    const canInputGateInformation = props.isAuthorized('gateInformation', 'READ');
    const canReleaseTopoutTrim = props.isAuthorized('topOutAndTrimRelease', 'READ');
    const canInputPlumbingCameraQAForm = props.isAuthorized('cameraQA', 'READ');
    const canInputEWAForm = props.isAuthorized('ewa', 'READ');
    const canUpdateSchedule = props.isAuthorized('schedule', 'READ');
    // const canRequestSchedule = props.isAuthorized('schedule', 'READ');

    return (
        <FieldFormsContainer>
            <MainHeader/>
            <div className='field-form-header'>Select Form</div>
            <div className='field-form-drop-down-box'>
                <label className="field-form-drop-down-label" htmlFor='field-form-select'>Form</label>
                <select className="field-form-drop-down-select" id='field-form-select' name='field-form-select' onChange={(e) => setSelectedForm(e.target.value)}>
                    <option className="field-form-drop-down-option" value=''></option>
                    {canInputConcreteMeasurements && <option className="field-form-drop-down-option" value='concrete-measurements'>Concrete Flatwork Measurements</option>}
                    {canInputPaverMeasurements && <option className="field-form-drop-down-option" value='paver-measurements'>Paver Measurements</option>}
                    {canInputGateInformation && <option className="field-form-drop-down-option" value='gate-information'>Gate Information</option>}
                    {canReleaseTopoutTrim && <option className="field-form-drop-down-option" value='release-top-out-trim'>Release Top Out & Trim</option>}
                    {canInputPlumbingCameraQAForm && <option className="field-form-drop-down-option" value='plumbing-camera-qa-form'>Plumbing Camera QA Form</option>}
                    {canInputEWAForm && <option className="field-form-drop-down-option" value='ewa-form'>Extra Work Authorization Form</option>}
                    {canUpdateSchedule && <option className="field-form-drop-down-option" value='schedule-updates'>Schedule Updates</option>}
                    {/* {canRequestSchedule && <option className="field-form-drop-down-option" value='schedule-request'>Schedule Request</option>} */}
                </select>
            </div>
            {selectedForm === 'concrete-measurements' && <ConcreteMeasurements isAuthorized={props.isAuthorized}/>}
            {selectedForm === 'paver-measurements' && <PaverMeasurements isAuthorized={props.isAuthorized}/>}
            {selectedForm === 'gate-information' && <GateInformation isAuthorized={props.isAuthorized}/>}
            {selectedForm === 'release-top-out-trim' && <TopOutTrimRelease isAuthorized={props.isAuthorized}/>}
            {selectedForm === 'plumbing-camera-qa-form' && <PlumbingCameraQAForm isAuthorized={props.isAuthorized}/>}
            {selectedForm === 'ewa-form' && <EWAForm isAuthorized={props.isAuthorized}/>}
            {selectedForm === 'schedule-updates' && <Updates isAuthorized={props.isAuthorized}/>}
            {/* {selectedForm === 'schedule-request' && <ScheduleRequest isAuthorized={props.isAuthorized}/>} */}
        </FieldFormsContainer>
    )
};

export default FieldForms;
