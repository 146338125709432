

import PopupContainer from './styles/PopupContainer';

const Popup = (props) => {
    return (
        <PopupContainer color={props.color}>
            <div className="popup-box">
                <p className="popup-message">{props.message}</p>
                <button type="button" className="popup-close-button" value="close" onClick={(e) => props.handlePopup(e)}>Close</button>
            </div>
        </PopupContainer>
    );
}

export const PopupSuccessColor = 'rgb(30, 180, 30)';
export const PopupFailureColor = 'rgb(255, 50, 50)';
export const PopupWarningColor = 'rgb(230, 230, 20)';

export default Popup;
