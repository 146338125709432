

export function filterConfig (matchFromStart, ignoreCase, ignoreAccents, trim) {
    return {
        ignoreCase,
        ignoreAccents,
        trim,
        matchFrom: matchFromStart ? ('start') : ('any'),
    }
}


