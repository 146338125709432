import styled from 'styled-components';

const MessagerContainer = styled.div`
    display: grid;
    width: 600px;
    margin: 10px 0 0 10px;

    .messager-header {
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        color: white;
        border-bottom: 2px solid white;
    }

    .message-text-area{
        display: grid;
        margin-bottom: 2px;
        background: rgb(210, 200, 190);

        .message-text-label{
            display: grid;
            background: rgb(128, 128, 128);
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            color: white;
            border-bottom: 2px solid white;
        }

        .message-text-input{
            margin: 10px auto 5px;
            resize: none;
        }

        .message-expires-on-date{
            display: grid;
            align-items: center;
            grid-template-columns: repeat(10, 1fr);
            margin: 3px 0;

            .expires-on-label{
                grid-column: 1/7;
                display: grid;
                padding-right: 5px;
                justify-self: end;
            }

            .message-expires-date-picker{
                grid-column: 7/11;
            }
        }

        .submit {
            display: grid;
            justify-content: center;
            align-items: center;
            padding: 5px 0 10px;
            
            .button {
                justify-self: center;
                width: 160px;
                height: 30px;
                background: rgb(240, 240, 240);
                border: 1px solid rgb(120, 120, 120);
                border-radius: 3px;
                display: grid;
                justify-content: center;
                align-items: center;
                cursor: default;
                
                :hover {
                    background: rgb(230, 230, 230);
                }
            }
        }
    }
`;

export default MessagerContainer;