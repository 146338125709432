
import { useState } from 'react';
import md5 from "md5";
import moment from 'moment/moment';

import Auth from '../authentication/Auth.js';
import { DropDownWithLabel } from '../utilities/DropDown.js';
import { TextboxWithLabel } from '../utilities/Textbox.js';
import { Button } from '../utilities/Button.js';
import { Checkbox } from '../utilities/Checkbox.js';
import MainHeader from '../utilities/MainHeader.js';
import Popup, {PopupFailureColor, PopupSuccessColor, PopupWarningColor} from '../utilities/Popup.js';
import CreateUserContainer from './styles/CreateUserContainer.js';

const CreateUser = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState(null);

    const [roles, setRoles] = useState([]);
    // const [rolePermissions, setRolePermissions] = useState([]);
    const [positions, setPositions] = useState([]);
    const [fields, setFields] = useState([]);
    const [permissionGroups, setpermissionGroups] = useState([]);

    const [errorNoFirstName, setErrorNoFirstName] = useState(false);
    const [errorNoLastName, setErrorNoLastName] = useState(false);
    const [errorNoUsername, setErrorNoUsername] = useState(false);
    const [errorPasswordLength, setErrorPasswordLength] = useState(false);
    const [errorUserAlreadyExist, setErrorUserAlreadyExist] = useState(false);
    const [errorPositionNotSet, setErrorPositionNotSet] = useState(false);
    const [errorFieldNotSet, setErrorFieldNotSet] = useState(false);
    const [errorRoleNotSet, setErrorRoleNotSet] = useState(false);
    const [errorNoPermissionGroup, setErrorNoPermissionGroup] = useState(false);
    const [success, setSuccess] = useState(false);

    const setNewUserToEmpty = async () => {
        setNewUser({
            username: '',
            password: '',
            firstname: '',
            lastname: '',
            email: '',
            active: 1,
            role_id: 0,
            position_id: 0,
            field_id: 0,
            created_at: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
            created_by: await Auth.getUserId(),
            permission_group_id: '',
            permissions: [],
        })
    }

    const loadData = async () => {
        setDidLoadData(true);

        setRoles(await Auth.hitEndpoint("GET", "USER_ROLES"));
        // setRolePermissions(await Auth.hitEndpoint("GET", "USER", null, null, "role/permissions"));
        setPositions(await Auth.hitEndpoint("GET", "USER_POSITIONS"));
        setFields(await Auth.hitEndpoint("GET", "USER_FIELDS"));
        setpermissionGroups(await Auth.hitEndpointNew("GET", 'permission/groups'));

        await setNewUserToEmpty();
        setUsers(await Auth.hitEndpoint("GET", "USERS"));
    }

    if(!didLoadData){
        loadData();
    }

    const toggleErrorPopup = () => {
        setErrorNoFirstName(false);
        setErrorNoLastName(false);
        setErrorNoUsername(false);
        setErrorPasswordLength(false);
        setErrorUserAlreadyExist(false);
        setErrorPositionNotSet(false);
        setErrorFieldNotSet(false);
        setErrorRoleNotSet(false);
        setErrorNoPermissionGroup(false);
    }

    const toggleSuccess = async () => {
        setSuccess(false);
    }

    const createUser = async (e) => {
        if(newUser.firstname === ''){setErrorNoFirstName(true);return;}
        if(newUser.lastname === ''){setErrorNoLastName(true);return;}
        if(newUser.username === ''){setErrorNoUsername(true);return;}
        if(newUser.password.length < 7){setErrorPasswordLength(true);return;}
        const isDupUserName = users.filter(userToFind => userToFind.username === newUser.username && userToFind.id !== newUser.id)
        if(isDupUserName.length > 0){setErrorUserAlreadyExist(true);return;}
        if(newUser.position_id === 0){setErrorPositionNotSet(true);return;}
        if(newUser.field_id === 0){setErrorFieldNotSet(true);return;}
        if(newUser.role_id === 0){setErrorRoleNotSet(true);return;}
        if(newUser.permission_group_id === ''){setErrorNoPermissionGroup(true);return;}

        newUser.password = md5(newUser.password);
        newUser.permissions.map(pn => {
            delete pn.permission;
            return pn;
        })

        await Auth.hitEndpoint("POST", "USER", "", newUser);
        await setNewUserToEmpty();
        setUsers(await Auth.hitEndpoint("GET", "USERS"));
        setSuccess(true);
    }

    const handleRoleSelect = (e) => {
        const tempNewUser = {...newUser};
        tempNewUser.role_id = e.target.value;

        setNewUser({...tempNewUser})
    }

    const selectPermissionGroup = async (e) => {
        if(e.target.value === ''){return;}
        const selectedPermissionGroup = permissionGroups.find(pg => parseInt(pg.id) === parseInt(e.target.value));
        const tempNewUser = {...newUser};
        tempNewUser.permission_group_id = e.target.value;
        tempNewUser.permissions = [];
        for(let i = 0; i < selectedPermissionGroup.group_permissions.length; i++){
            tempNewUser.permissions.push({
                permission_id: selectedPermissionGroup.group_permissions[i].permission_id,
                permission: selectedPermissionGroup.group_permissions[i].permission,
                read: selectedPermissionGroup.group_permissions[i].default_read,
                write: selectedPermissionGroup.group_permissions[i].default_write
            })
        }
        setNewUser(tempNewUser);
    }

    const setReadPermission = (permission, e) => {
        const tempNewUser = {...newUser};
        const index = tempNewUser.permissions.findIndex(p => parseInt(p.permission_id) === parseInt(permission.permission_id));
        tempNewUser.permissions[index].read = e.target.checked;
        setNewUser(tempNewUser);
    }

    const setWritePermission = (permission, e) => {
        const tempNewUser = {...newUser};
        const index = tempNewUser.permissions.findIndex(p => parseInt(p.permission_id) === parseInt(permission.permission_id));
        tempNewUser.permissions[index].write = e.target.checked;
        setNewUser(tempNewUser);
    }

    return (
        <CreateUserContainer>
            <MainHeader/>
            {newUser && 
            <div>
                <div className='create-user-title'>Create User</div>
                <div className='user-data-input-box'>
                    <TextboxWithLabel className="firstname-textbox" name="firstname" label="First Name" placeholder="First Name" value={newUser.firstname} handleTextbox={((e) => setNewUser({...newUser, firstname: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <TextboxWithLabel className="lastname-textbox" name="lastname" label="Last Name" placeholder="Last Name" value={newUser.lastname} handleTextbox={((e) => setNewUser({...newUser, lastname: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <TextboxWithLabel className="username-textbox" name="username" label="User Name" placeholder="User Name" value={newUser.username} handleTextbox={((e) => setNewUser({...newUser, username: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <TextboxWithLabel className="password-textbox" name="password" label="Password" placeholder="Password" value={newUser.password} handleTextbox={((e) => setNewUser({...newUser, password: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <TextboxWithLabel className="email-textbox" name="email" label="Email" placeholder="Email" value={newUser.email} handleTextbox={((e) => setNewUser({...newUser, email: e.target.value}))} columns="10" labelStart="1" labelEnd="4" inputStart="4" inputEnd="8"/>
                    <DropDownWithLabel className="select-position" id="select-position-id" name="position" label="Position" value={newUser.position_id} handleDropDown={((e) => setNewUser({...newUser, position_id: e.target.value}))} addBlankOption={true} selectionList={positions.map(position => ({id: position.id, display: position.name}))} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={8}/>
                    <DropDownWithLabel className="select-field" id="select-field-id" name="field" label="Field" value={newUser.field_id} handleDropDown={((e) => setNewUser({...newUser, field_id: e.target.value}))} addBlankOption={true} selectionList={fields.map(field => ({id: field.id, display: field.name}))} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={8}/>
                    <DropDownWithLabel className="select-role" id="select-role-id" name="role" label="Role" value={newUser.role_id} handleDropDown={handleRoleSelect} addBlankOption={true} selectionList={roles.map(role => ({id: role.id, display: role.name}))} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={8}/>
                    <DropDownWithLabel className="permission-group" name="permission-group" label="Permission Group" value={newUser.permission_group_id} handleDropDown={selectPermissionGroup} addBlankOption={true} selectionList={permissionGroups} columns={10} labelStart={1} labelEnd={4} inputStart={4} inputEnd={8}/>
                    {newUser.permission_group_id !== '' && newUser.permissions.length > 0 &&
                    <div>
                        <div className='assign-user-permission-group'>
                            <div className="read-write-label">
                                <div className="read-label">Read</div>
                                <div className="write-label">Write</div>
                            </div>
                            {newUser.permissions.map((permission, index) => {
                                return (
                                    <div key={`${permission.display}-${index}`}  className='permissions-display'>
                                        <div className="group-permission">{permission.permission.display}</div>
                                        <Checkbox className="read" name="read" checked={permission.read} handleCheckbox={(e) => setReadPermission(permission, e)} columns="2" inputStart="1" inputEnd="2"/>
                                        <Checkbox className="write" name="write" checked={permission.write} handleCheckbox={(e) => setWritePermission(permission, e)} columns="2" inputStart="1" inputEnd="2"/>
                                    </div>
                                );
                            })}
                        </div>
                    </div>}
                    <Button className="create-user" handleKeyPress={((e) => {if(e.key === 'Enter'){createUser()}})} handleClick={((e) => createUser())} buttonText="Create User" width="120"/>
                </div>
            </div>}
            {errorUserAlreadyExist && <Popup color={PopupFailureColor} message={'Username already exists. Please enter a unique username.'} handlePopup={toggleErrorPopup}/>}
            {errorNoFirstName && <Popup color={PopupWarningColor} message={`Please enter the user's first name`} handlePopup={toggleErrorPopup}/>}
            {errorNoLastName && <Popup color={PopupWarningColor} message={`Please enter the user's last name`} handlePopup={toggleErrorPopup}/>}
            {errorNoUsername && <Popup color={PopupWarningColor} message={'Please enter a username'} handlePopup={toggleErrorPopup}/>}
            {errorPasswordLength && <Popup color={PopupWarningColor} message={'Password must be at least 6 characters long'} handlePopup={toggleErrorPopup}/>}
            {errorPositionNotSet && <Popup color={PopupWarningColor} message={`Position not set. Please select the user's position`} handlePopup={toggleErrorPopup}/>}
            {errorFieldNotSet && <Popup color={PopupWarningColor} message={`Field not set. Please select the user's field`} handlePopup={toggleErrorPopup}/>}
            {errorRoleNotSet && <Popup color={PopupWarningColor} message={`Role not set. Please select the user's role`} handlePopup={toggleErrorPopup}/>}
            {errorNoPermissionGroup && <Popup color={PopupWarningColor} message={`Please select a permission group`} handlePopup={toggleErrorPopup}/>}
            {success && <Popup color={PopupSuccessColor} message={`User has been added.`} handlePopup={toggleSuccess}/>}
        </CreateUserContainer>
    );
}

export default CreateUser;
