import styled from 'styled-components';

const AdminPageContainer = styled.div`

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    row-gap: 10px;
    column-gap: 10px;
    margin-left: 10px;
    
    .admin-full{
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column: 3/12;
        grid-row: 3/12;
        width: 100%;
        min-height: auto;
    }
    
    .admin-full .admin-users{
        min-width: auto;
        text-wrap: nowrap;
        grid-column: 1/2;
    }
    .admin-full .admin-messaging{
        min-width: auto;
        text-wrap: nowrap;
        grid-column: 3/4;
    }
    
    @media screen and (max-width: 1080px) {
         grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
         
         .admin-full{
            grid-column: 2/12;
        }
        .admin-full .admin-messaging{
            grid-column: 1/2;
            grid-row: 7/8;
            margin-top: 10px;
        }
     }
`;

export default AdminPageContainer;