import Auth from "../components/authentication/Auth";
import moment from "moment/moment";

// Builder data from database goal
// {
//     id: "",
//     name: "",
//     active: false,
//     modified_by:
//     modified_at: new Date()
// }

class Builders{
    static async loadBuilders(){
        const data = await Auth.hitEndpoint("GET", "BUILDERS");
        // Convert all dates from strings to date objects
        // data.forEach(builder => {
        //
        // })
        return data;
    }

    static async loadBuildersForDropDown(){
        const data = await Builders.loadBuilders();
        // Convert all dates from strings to date objects
        // Add data members needed for drop down functionality
        data.forEach(builder => {
           // builder.id = builder.id;
            builder.display = builder.name
        })
        data.sort((b1, b2) => {
            return b1.name < b2.name ? -1 : 1;
        })

        return data.filter(builderToFilter => builderToFilter.active);
    }

    static async saveNewBuilder(builder){
        const result = await Auth.hitEndpoint("POST", "BUILDERS", "", builder);
        builder._id = result._id;
        
        return builder;
    }

    static async updateBuilder(builder, user){
        builder.modified_by = user;
        builder.modified_at = moment(new Date()).format('YYYY-MM-DD H:mm:ss');

        await Auth.hitEndpoint("PATCH", "BUILDERS", "", builder);
        
        return builder;
    }

    static async deleteBuilder(builder){
        await Auth.hitEndpoint("DELETE", "BUILDERS", "", builder);
        return builder;
    }
    
    static sortBuilders(sortBy, order, builders){
        const firstValue = order === "ascending" ? -1 : 1;
        const secondValue = -firstValue;
        return builders.sort((b1, b2) => {
            if(sortBy === "name"){
                return b1.name < b2.name ? firstValue : secondValue;
            }

            return firstValue;
        })
    }

    static createNewBuilder(user){
        return {
            active: true,
            name: "",
            modified_by: user,
            modified_at: moment(new Date()).format('YYYY-MM-DD H:mm:ss')
        };
    }

    static async checkBuilderForErrors(builder){
        if(builder.name === ''){return "ERROR_NO_NAME";}

        // Check for duplicate builder names
    //    const jsonString = JSON.stringify({name: builder.name});
        let dupData = await Auth.hitEndpoint("GET", "BUILDER", "", null, builder.name);
        if(builder.id){
            dupData = dupData.filter(dataToFilter => dataToFilter.id.toString() !== builder.id.toString());
        }
        if(dupData.length > 0){return "ERROR_DUPLICATE_NAME";}

        return "SUCCESS";
    }
}

export default Builders;