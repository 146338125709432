
import HoverPopupContainer from './styles/HoverPopupContainer';


const HoverPopup = (props) => {
    return (
        <HoverPopupContainer width={props.width}>
            <span className='hover-popup-text'>{props.text}</span>
        </HoverPopupContainer>
    );
};

export default HoverPopup;