import styled from 'styled-components';

const PopupOkContainer = styled.div`
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 60;

    .popupok-box {
        position: relative;
        width: 70%;
        margin: 0 auto;
        height: auto;
        max-height: 70vh;
        margin-top: calc(100vh - 85vh - 20px);
        background: ${props => props.color};
        border-radius: 3px;
        padding: 20px;
        border: 1px solid black;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .popupok-message {
        text-align: center;
        margin: 15px auto;
        white-space: pre-line;
    }

    .popupok-ok-cancel {
        display: flex;

        .popupok-button {
            justify-self: center;
            width: 80px;
            height: 20px;
            background: rgb(240, 240, 240);
            border: 1px solid rgb(120, 120, 120);
            border-radius: 3px;
            display: grid;
            justify-content: center;
            align-items: center;
            cursor: default;
            margin: 10px 20px;
    
            :hover {
                background: rgb(230, 230, 230);
            }
        }
    }
`

export default PopupOkContainer;