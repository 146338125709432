import Auth from "../components/authentication/Auth";

class Jobs{
    static async loadJobs(queryObj = null) {

        const limit = 50;
        let skip = 0;
        let data = [];

        let dbData;
        if (queryObj) {
            const jsonString = JSON.stringify(queryObj);
            dbData = await Auth.hitEndpoint("GET", "JOBS", `selector=eight&jsonString=${jsonString}&skip=${skip}&limit=${limit}`);
        } else {
            dbData = await Auth.hitEndpoint("GET", "JOBS", `selector=seven&skip=${skip}&limit=${limit}`);
        }
        data = [...data, ...dbData];
        if (dbData.length === 0) {

        }
        return data;
    }

    static async loadJobsForDropDown(queryObj= null){
        let data = await Jobs.loadJobs(queryObj);
        // Convert all dates from strings to date objects
        // Add data members needed for drop down functionality
        data.forEach(job => {
            job.display = `${job.number} - ${job.builder.name} - ${job.project_name}`
        })

        data.sort((j1, j2) => {
            return Number.parseInt(j1.number) < Number.parseInt(j2.number) ? -1 : 1;
        })

        return data.filter(jobToFilter => jobToFilter.active);
    }

    static async saveNewJob(job){
        const result = await Auth.hitEndpoint("POST", "JOBS", "", job);
        job.id = result.id;

        return job;
    }

    static async updateJob(job, user){
        job.edited_by.name = user;
        job.edited_by.date = new Date();

        await Auth.hitEndpoint("PATCH", "JOBS", "", job);

        return job;
    }

    static async deleteJob(job){
        await Auth.hitEndpoint("DELETE", "JOBS", "", job);
        return job;
    }

    static sortJobs(sortBy, order, jobs, builders = null){
        const firstValue = order === "ascending" ? -1 : 1;
        const secondValue = -firstValue;
        return jobs.sort((j1, j2) => {
            if(sortBy === "number"){
                return Number.parseInt(j1.number) < Number.parseInt(j2.number) ? firstValue : secondValue;
            }
            if(sortBy === "projectName"){
                return j1.project_name < j2.project_name ? firstValue : secondValue;
            }
            if(sortBy === "builder"){
                const j1B = builders.find(builderToFind => builderToFind.id.toString() === j1.builder_id.toString());
                const j2B = builders.find(builderToFind => builderToFind.id.toString() === j2.builder_id.toString());
                return j1B.name < j2B.name ? firstValue : secondValue;
            }
            if(sortBy === "city"){
                return j1.city < j2.city ? firstValue : secondValue;
            }

            return firstValue;
        })
    }

    static sortJobsNew(sortBy, order, jobs){
        const firstValue = order === "ascending" ? -1 : 1;
        const secondValue = -firstValue;
        return jobs.sort((j1, j2) => {
            if(sortBy === "number"){
                return Number.parseInt(j1.number) < Number.parseInt(j2.number) ? firstValue : secondValue;
            }
            if(sortBy === "projectName"){
                return j1.project_name < j2.project_name ? firstValue : secondValue;
            }
            if(sortBy === "builder"){
                return j1.builder.name < j2.builder.name ? firstValue : secondValue;
            }
            if(sortBy === "city"){
                return j1.city < j2.city ? firstValue : secondValue;
            }

            return firstValue;
        })
    }

    static createNewJob(user){
        return {
            builder_id: "",
            project_name: "",
            job_type_id: "",
            number: "",
            cross_streets: "",
            city: "",
            zip_code: "",
            concreteSuper: "",
            concreteGeneral: "",
            plumbingSuper: "",
            plumbingGeneral: "",
            masonrySuper: "",
            masonryGeneral: "",
            paverSuper: "",
            paverGeneral: "",
            rfgSuper: "",
            rfgGeneral: "",
            framingSuper: "",
            framingGeneral: "",
            gatesSuper: "",  
            gatesGeneral: "",
            trashSuper: "",  
            trashGeneral: "",
            hasConcrete: false,
            hasPlumbingSoil: false,
            hasPlumbingTopOut: false,
            hasPlumbingTrim: false,
            hasPlumbingGas: false,
            isPlumbingGasOpt: false,
            hasRoughFinalGrade: false,
            hasTrash: false,
            hasMasonry: false,
            hasPavers: false,
            isPaversOpt: false,
            hasGates: false,
            hasFraming: false,
            active: true,
            edited_by: {
                name: user,
                date: new Date()
            }
        };
    }

    static createNewJobNew(user){
        return {
            active: true,               // DONE
            assignments: [],            // DONE
            attributes: [],             // DONE
            builder_id: '',             // DONE
            city: '',
            cross_streets: '',
            job_type_id: '',            // DONE
            modified_at: new Date(),    // DONE
            modified_by: user,          // DONE
            number: '',                 // DONE
            project_name: '',           // DONE
            zip_code: '',
        };
    }

    static async checkJobForErrors(job){
        if(job.builder_id === ''){return "ERROR_NO_BUILDER";}
        if(job.number === ''){return "ERROR_NO_JOB_NUMBER";}
        const regex = new RegExp('[0-9][0-9][0-9][0-9]');
        if(!regex.test(job.number)){return "ERROR_INCORRECT_JOB_NUMBER_FORMAT";}

        // Check for duplicate job numbers
        const jsonString = JSON.stringify({number: job.number});
        let dupData = await Auth.hitEndpoint("GET", "JOBS", `selector=nine&jsonString=${jsonString}`);
        if(job.id){
            dupData = dupData.filter(dataToFilter => dataToFilter.id.toString() !== job.id.toString());
        }
        if(dupData.length > 0){return "ERROR_DUPLICATE_JOB_NUMBER";}

        return "SUCCESS";
    }

    static async checkJobForErrorsNew(job){
        if(job.builder_id === ''){return "ERROR_NO_BUILDER";}
        if(job.number === ''){return "ERROR_NO_JOB_NUMBER";}
        if(job.project_name === ''){return "ERROR_NO_PROJECT_NAME";}
        if(job.job_type_id === ''){return "ERROR_NO_JOB_TYPE";}
        const regex = new RegExp('[0-9][0-9][0-9][0-9]');
        if(!regex.test(job.number)){return "ERROR_INCORRECT_JOB_NUMBER_FORMAT";}

        // Check for duplicate job numbers
        const jsonString = JSON.stringify({number: job.number});
        let dupData = await Auth.hitEndpoint("GET", "JOBS", `selector=nine&jsonString=${jsonString}`);
        if(job.id){
            dupData = dupData.filter(dataToFilter => dataToFilter.id.toString() !== job.id.toString());
        }
        if(dupData.length > 0){return "ERROR_DUPLICATE_JOB_NUMBER";}

        return "SUCCESS";
    }

    // Functions that return constant values
    static async getJobCategories(){
        let types = await Auth.hitEndpoint("GET", "JOB_TYPES", );
        return types
    }

    static getScopeFilterOptions(){
        return [
            {id: "concrete", display: "Concrete"},
            {id: "plumbing", display: "Plumbing"},
            {id: "rfg/trash", display: "RFG/Trash"},
            {id: "masonry/gates", display: "Masonry/Gates"},
            {id: "pavers", display: "Pavers"},
            {id: "framing", display: "Framing"}
        ]
    }

    static getSortOptions(){
        return [
            {id: "builder", display: "Builder"},
            {id: "projectName", display: "Project Name"},
            {id: "number", display: "Job Number"},
            {id: "city", display: "City"}
        ]
    }
}

export default Jobs;
