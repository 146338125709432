import styled from 'styled-components';

const TaskAdjustmentContainer = styled.div`
    
    display: grid;
    margin: 10px 0 175px 10px;
    box-sizing: border-box;
    
     .filter-boxes{
        display: grid;
        grid-template-columns: 300px 300px;
        grid-gap: 10px;
        width: 900px;
        border-bottom: 10px solid white;
    
    
        .job-filter-select, 
        .lot-filter-select, 
        .phase-filter-select, 
        .task-filter-select{
            width:100%;
            padding: 5px 5px 5px 0px;
        }
    }
    
    .schedule-data-results{
        width: 100%;
    }

`;
export default TaskAdjustmentContainer;