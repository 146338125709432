import { useState } from 'react';
import Auth from '../authentication/Auth.js';
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";
import Select from "react-select";
import JobAttributesAndAssignments from './JobAttributesAndAssignments.js';

const CreateJob = ({displayMessageWithTimer, updateJobsArray, 
                    builders, jobTypes, generals, supers,
                    createJob, setCreateJob}) => {
    const [newJob, setNewJob] = useState({
        active: true,
        builder: null,
        number: '',
        project_name: '',
        job_type: null,
        cross_streets: '',
        city: '',
        zip_code: '',
        attributes: [],
        assignments: []
    });

    const [error, setError] = useState({
        type: '',
        message: ''
    })

    const closeWithSave = async () => {
        if(newJob.builder === null){
            setError({
                type: 'BUILDER ERROR',
                message: 'Please select a builder'
            })
            return;
        }
        if(newJob.number === ''){
            setError({
                type: 'NUMBER ERROR',
                message: 'Please enter a job number'
            })
            return;
        }
        const regex = new RegExp('[0-9][0-9][0-9][0-9]');
        if(!regex.test(newJob.number)){
            setError({
                type: 'NUMBER ERROR',
                message: `Please enter a job number in the format 'XXXX'`
            })
            return;
        }
        if(newJob.project_name === ''){
            setError({
                type: 'PROJECT NAME ERROR',
                message: 'Please enter a project name'
            })
            return;
        }
        if(newJob.job_type === null){
            setError({
                type: 'JOB TYPE ERROR',
                message: 'Please select a job type'
            })
            return;
        }
        
        const queryString = `number = '${newJob.number}'`;
        const dupJobs = await Auth.hitEndpointNew('GET', 'jobs-new', `queryStr=${queryString}`);
        if(dupJobs.length > 0){
            setError({
                type: 'REPEAT JOB',
                message: 'Job already exists, please enter an original job'
            })
            return;
        }

        const dbObj = {
            active: newJob.active,
            builder_id: newJob.builder.id,
            number: newJob.number,
            project_name: newJob.project_name,
            job_type_id: newJob.job_type.id,
            cross_streets: newJob.cross_streets,
            city: newJob.city,
            zip_code: newJob.zip_code,
            attributes: newJob.attributes.map(a => {
                return {
                    attribute_id: a.attribute_id
                }
            }),
            assignments: newJob.assignments.map(a => {
                return {
                    assignment_id: a.assignment_id,
                    user_id: a.user_id
                }
            })
        };

        await Auth.hitEndpointNew('POST', 'job-new', '', dbObj);

        await updateJobsArray(newJob.number);
        displayMessageWithTimer('1 Job Created', 'green', 5000);
        setCreateJob(false);
    }

    const closeNoSave = () => {
        displayMessageWithTimer('No Job Created', 'green', 5000);
        setCreateJob(false);
    }

    return (
        <Dialog open={createJob} maxWidth='xl'>
            <DialogTitle style={{borderBottom: "2px solid black"}}>Create Job</DialogTitle>
            <DialogContent sx={{width: '800px', paddingBottom: '220px'}}>
                <FormControlLabel 
                    sx={{
                        margin: '10px 0'
                    }}
                    control={<Checkbox 
                        defaultChecked={newJob.active} 
                        onChange={(e) => setNewJob({...newJob, active: e.target.checked})}/>} 
                    label="Active" 
                />
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                margin: '10px auto',
                                backgroundColor: error.type === "BUILDER ERROR" ? 'rgb(255, 150, 150)' : 'white',
                            }
                        },
                        container: styles => {
                            return {
                                ...styles,
                                zIndex: '100'
                            }
                        }
                    }} 
                    placeholder="Select Builder"
                    value={newJob.builder}
                    onChange={(builder) => setNewJob({...newJob, builder: builder})}
                    options={builders}
                    isClearable
                    isSearchable
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                        backgroundColor: error.type === "NUMBER ERROR" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    size='small'
                    label="Job Number"
                    placeholder="Enter Job Number"
                    value={newJob.number}
                    onChange={(e) => setNewJob({...newJob, number: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                        backgroundColor: error.type === "PROJECT NAME ERROR" ? 'rgb(255, 150, 150)' : 'white',
                    }}
                    size='small'
                    label="Project Name"
                    placeholder="Enter Project Name"
                    value={newJob.project_name}
                    onChange={(e) => setNewJob({...newJob, project_name: e.target.value})}
                />
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                margin: '0 auto 10px',
                                backgroundColor: error.type === "JOB TYPE ERROR" ? 'rgb(255, 150, 150)' : 'white',
                            }
                        },
                        container: styles => {
                            return {
                                ...styles,
                                zIndex: '99'
                            }
                        }
                    }} 
                    placeholder="Select Job Type"
                    value={newJob.job_type}
                    onChange={(jobType) => setNewJob({...newJob, job_type: jobType})}
                    options={jobTypes}
                    isClearable
                    isSearchable
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                    }}
                    size='small'
                    label="Cross Roads"
                    placeholder="Enter Cross Roads"
                    value={newJob.cross_streets}
                    onChange={(e) => setNewJob({...newJob, cross_streets: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                    }}
                    size='small'
                    label="City"
                    placeholder="Enter City"
                    value={newJob.city}
                    onChange={(e) => setNewJob({...newJob, city: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '0 auto 10px',
                    }}
                    size='small'
                    label="Zip Code"
                    placeholder="Enter Zip Code"
                    value={newJob.zip_code}
                    onChange={(e) => setNewJob({...newJob, zip_code: e.target.value})}
                />
                <Box 
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(30, 1fr)',
                        margin: '10px 0 5px',
                    }}
                >
                    <Box 
                        sx={{
                            gridColumn: '9/11',
                            textAlign: 'center',
                        }}
                    >Opt</Box>
                    <Box
                        sx={{
                            gridColumn: '11/21',
                            textAlign: 'center',
                        }}
                    >Generals</Box>
                    <Box
                        sx={{
                            gridColumn: '21/31',
                            textAlign: 'center',
                        }}
                    >Supers</Box>
                </Box>
                <JobAttributesAndAssignments
                    job={newJob}
                    setJob={setNewJob}
                    attributeName='Concrete'
                    attributeId={1}
                    generalAssignmentId={2}
                    superAssignmentId={1}
                    generals={generals}
                    supers={supers}
                />
                <JobAttributesAndAssignments
                    job={newJob}
                    setJob={setNewJob}
                    attributeName='Plumbing'
                    attributeId={2}
                    generalAssignmentId={4}
                    superAssignmentId={3}
                    generals={generals}
                    supers={supers}
                />
                <JobAttributesAndAssignments
                    job={newJob}
                    setJob={setNewJob}
                    attributeName='Plumbing-Gas'
                    attributeId={5}
                    attributeOptId={6}
                />
                <JobAttributesAndAssignments
                    job={newJob}
                    setJob={setNewJob}
                    attributeName='Masonry'
                    attributeId={9}
                    generalAssignmentId={6}
                    superAssignmentId={5}
                    generals={generals}
                    supers={supers}
                />
                <JobAttributesAndAssignments
                    job={newJob}
                    setJob={setNewJob}
                    attributeName='Gates'
                    attributeId={12}
                    generalAssignmentId={14}
                    superAssignmentId={13}
                    generals={generals}
                    supers={supers}
                />
                <JobAttributesAndAssignments
                    job={newJob}
                    setJob={setNewJob}
                    attributeName='Pavers'
                    attributeId={10}
                    attributeOptId={11}
                    generalAssignmentId={8}
                    superAssignmentId={7}
                    generals={generals}
                    supers={supers}
                />
                <JobAttributesAndAssignments
                    job={newJob}
                    setJob={setNewJob}
                    attributeName='Rough/Final Grade'
                    attributeId={7}
                    generalAssignmentId={10}
                    superAssignmentId={9}
                    generals={generals}
                    supers={supers}
                />
                <JobAttributesAndAssignments
                    job={newJob}
                    setJob={setNewJob}
                    attributeName='Trash'
                    attributeId={8}
                    generalAssignmentId={16}
                    superAssignmentId={15}
                    generals={generals}
                    supers={supers}
                />
                <JobAttributesAndAssignments
                    job={newJob}
                    setJob={setNewJob}
                    attributeName='Framing'
                    attributeId={13}
                    generalAssignmentId={12}
                    superAssignmentId={11}
                    generals={generals}
                    supers={supers}
                />
                {error.type === 'REPEAT JOB' &&
                <Dialog open={error.type !== ''}>
                    <DialogTitle sx={{backgroundColor: 'rgb(255, 150, 150)', fontWeight: 'bold'}}>{error.type}</DialogTitle>
                    <DialogContent 
                        sx={{width: "600px", backgroundColor: 'rgb(255, 150, 150)'}}>{error.message}</DialogContent>
                    <DialogActions sx={{backgroundColor: 'rgb(255, 150, 150)'}}>
                        <Button 
                            sx={{
                                backgroundColor: 'rgb(150, 150, 255)'
                            }}
                            variant='contained'
                            onClick={() => {
                                setError({
                                    type: '',
                                    message: ''
                                })
                            }}
                        >OK</Button>
                    </DialogActions>
                </Dialog>}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={closeWithSave}>Save</Button>
                <Button variant='contained' onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateJob;