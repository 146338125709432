
import styled from 'styled-components';

const button_Height = "25";
const button_BackgroundColor = "rgb(240, 240, 240)";
const button_BackgroundColorHover = "rgb(230, 230, 230)";
const button_TextColor = "rgb(0, 0, 0)";
const button_BorderColor = "rgb(120, 120, 120)";
const button_Cursor = "pointer"

const button_DisabledBackgroundColor = "rgb(120, 120, 120)";
const button_DisabledBackgroundColorHover = "rgb(120, 120, 120)";
const button_DisabledTextColor = "rgb(200, 200, 200)";
const button_DisabledBorderColor = "rgb(255, 255, 255)";
const button_DisabledCursor = "default"

const button_Margin = "5px 0 5px 0";

const ButtonContainer = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    margin: ${props => props.buttonMargin ? props.buttonMargin : button_Margin};

    .button {
        display: grid;
        justify-content: center;
        align-items: center;
        width: ${props => props.width}px;
        height: ${props => props.height ? props.height : button_Height}px;
        background: ${props => props.disableButton ? button_DisabledBackgroundColor : button_BackgroundColor};
        color: ${props => props.disableButton ? button_DisabledTextColor : button_TextColor};
        border: 1px solid ${props => props.disableButton ? button_DisabledBorderColor : button_BorderColor};
        border-radius: 3px;
        cursor: ${props => props.disableButton ? button_DisabledCursor : button_Cursor};

        :hover {
            background: ${props => props.disableButton ? button_DisabledBackgroundColorHover : button_BackgroundColorHover};
        }
    }
`

// TEMPLATE
// <Button className="Class name of entire box" 
//         handleKeyPress="Function that controls the key presses when button has focus"
//         handleClick="Function that controls the button"
//         buttonText="Text to display on button" 
//         buttonMargin="All 4 sides of margin around button"
//         width="Width of button" 
//         height="Height of button"/>
// END

export const Button = (props) => {
    const handleKeyPress = props.handleKeyPress ? props.handleKeyPress : (e) => {};
    const disableButton = props.disableButton !== undefined ? props.disableButton : false;
    return (
        <ButtonContainer className={props.className} buttonMargin={props.buttonMargin} width={props.width} height={props.height} disableButton={disableButton}>
            {disableButton && <div className="button" id={`${props.className}-id`}>{props.buttonText}</div>}
            {!disableButton && <div className="button" id={`${props.className}-id`} onKeyDown={handleKeyPress} onClick={props.handleClick}>{props.buttonText}</div>}
        </ButtonContainer>
    )
}