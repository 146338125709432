import styled from 'styled-components';

const ViewUsersContainer = styled.div`
    display: grid;
    margin-top: 10px;

    .top-boxes{
        display: grid;
        grid-template-columns: 510px 300px;

        .page-filter {
            display: grid;
            width: 500px;
            margin-left: 10px;
            padding-bottom: 10px;
            background: rgb(210, 200, 190);
            border-bottom: 15px solid white;
    
            .filter-header{
                height: 25px;
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                padding: 3px 0;
                background: rgb(58, 64, 67);
                color: white;
                border-bottom: 2px solid white;
            }
    
            .filter-body{
                display: grid;
                padding-top: 10px;
    
                .filter{
                    display: grid;
                    grid-template-columns: repeat(20, 1fr);
    
                    .select-role-label, .select-position-label, .select-field-label{
                        grid-column: 1/7;
                        text-align: right;
                        align-self: center;
                        padding: 3px 0;
                        padding-right: 5px;
                    }
    
                    .select-role, .select-position, .select-field{
                        grid-column: 7/18;
                        padding: 1px 0;
                        margin: 5px 0;
                    }
                }
    
                .clear-filter-submit {
                    display: grid;
                    margin: 10px 0 15px;
                
                    .clear-filter-button {
                        justify-self: center;
                        width: 100px;
                        height: 20px;
                        background: rgb(240, 240, 240);
                        border: 1px solid rgb(120, 120, 120);
                        border-radius: 3px;
                        display: grid;
                        justify-content: center;
                        align-items: center;
                        cursor: default;
                    
                        :hover {
                            background: rgb(230, 230, 230);
                        }
                    }
                }
            }
        }

        .page-key{
            display: grid;
            width: 300px;
            margin-left: 15px;
            margin-bottom: 15px;
            background: rgb(210, 200, 190);
            
            .key-header{
                height: 25px;
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                padding: 3px 0;
                background: rgb(58, 64, 67);
                color: white;
                border-bottom: 2px solid white;
            }

            .key{
                display: grid;
                padding-bottom: 30px;

                .key-pair{
                    display: grid;
                    grid-template-columns: repeat(20, 1fr);

                    .key-symbol{
                        justify-self: right;
                        display: grid;
                        // text-align: right;
                        align-self: center;
                        padding: 3px;
                        grid-column: 1/8;
                        font-weight: bold;
                    }

                    .key-value{
                        grid-column: 8/21;
                        padding-left: 5px;
                        align-self: center;
                    }
                }
            }
        }
    }

    

    .table-header {
        width: 919px;
        font-size: 18px;
        padding: 3px 0;
        text-align: center;
        margin: 0 1700px 0 10px;
        background: rgb(58, 64, 67);
        color: white;
        border-bottom: 3px solid white;
    }

    .table-link {
        color: rgb(0, 0, 200); // hyper-link color
        text-decoration: underline;
        cursor: pointer;
    }

    .grid-table {
        display: grid;
        box-sizing: border-box;
        width: 919px;
        margin: 0 1700px 0 10px;
        border-right: 2px solid black;   // ADD THIS TO GET LOCKING HEADER
        border-bottom: 2px solid black;  // ADD THIS TO GET LOCKING HEADER
        border-left: 2px solid black;    // ADD THIS TO GET LOCKING HEADER
    }

    .grid-table-row, .grid-table-header {
        display: grid;
        text-align: center;
        // grid-template-columns:  60px 135px 100px 110px 200px 80px 80px
        //                         80px 80px 90px 80px 80px 80px 80px 80px 80px 80px
        //                         80px 80px 80px 80px 80px 80px 80px 80px 80px 80px
        //                         80px 110px 110px 80px 80px 80px 80px 80px 80px
        //                         80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px;
        grid-template-columns:  60px 135px 100px 110px 200px 80px 80px 150px;
    }

    .grid-table-header {
        // ADD THIS TO GET LOCKING HEADER
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        z-index: 5;
        border-top: 3px solid black;
        // ********************************

        background: rgb(210, 200, 190); // table header color
    }

    // TESTING #################################
    .grid-table-row-data.fixed, .grid-table-header-data.name{
        position: sticky;
        left: 0;
    }

    .grid-table-header-data.name{
        background: rgb(210, 200, 190);
    }

    .grid-table-row-data.fixed.row-one{
        background: rgb(230, 230, 220)
    }

    .grid-table-row-data.fixed.row-two{
        background: white;
    }
    // ############################################

    .grid-table-row:nth-child(even) {
        background: rgb(230, 230, 220); // table row color
    }

    .grid-table-row:nth-child(odd) {
        background: white;
    }

    .grid-table-header-data, .grid-table-row-data {
        display: grid;
        align-items: center;
        padding: 5px 0;
        border: 1px solid black;
    }

    .grid-table-header-data {
        padding: 5px 0;
    }

    .grid-table-row-data.permissions{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 0;


        .permission-value{
            display: grid;
            align-items: center;
            height: 100%;
            font-weight: bold;
            padding: 1px;
            border: 1px solid black;
            cursor: pointer;
        }

        .permission-value.has-permission{
            background: rgb(0, 150, 0);
        }

        .permission-value.inactive-white{
            color: white;
            background: rgb(200, 200, 200);;
        }

        .permission-value.not-used{
            color: black;
            background: black;
        }
    }

    .inactive{
        color: rgb(200, 200, 200);
    }
`;

export default ViewUsersContainer