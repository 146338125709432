import {useEffect, useState} from "react";
import Auth from "../authentication/Auth";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
// import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
// import {Label, Lock} from "@mui/icons-material";
import {Lock} from "@mui/icons-material";
import dayjs from "dayjs";
import md5 from "md5";
import Popup, {PopupFailureColor, PopupWarningColor} from "../utilities/Popup";
import Schedules from "../../classes/Schedules";

const LockList = ({locks, setLocks}) => {

    const [open, setOpen] = useState(false)
    // const [username, setUsername] = useState('')
    const [fullname, setFullname] = useState('')
    // const [pwd, setPwd] = useState('')
    const [selectedLock, setSelectedLock] = useState(-1)

    const [didLoadData, setDidLoadData] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseNoSave = () => {
        setOpen(false);
    };

    // const handleCloseSave = () => {
    //     setOpen(false)
    // }

    const getUsername = async () => {
        setFullname(await Auth.getFullName())
        // let user = JSON.parse(await Auth.getUserName())
        // setUsername(user.username)
    }

    if (!didLoadData) {
        setDidLoadData(true)
        getUsername();
    }
    const handleUnlock = (id) => {
        setUserData({
            username: 'gflores',
            password: 'Ant0n1na'
        })
        setSelectedLock(id)
        setOpen(true)
    }

    const [authenticateUser, setAuthenticateUser] = useState(false);
    const [userData, setUserData] = useState({
        username: '',
        password: ''
    });
    const [errorDB, setErrorDB] = useState(false);
    const [errorUsernameLength, setErrorUsernameLength] = useState(false);
    const [errorPasswordLength, setErrorPasswordLength] = useState(false);

    useEffect(() => {
        const authenticate = async () => {
            try {
                const returnedData = await Auth.hitEndpoint("POST", "LOGIN", "", userData);
                if(returnedData.success){
                    setOpen(false);
                    let lock = locks.find(x => x.id === selectedLock)
                    lock.deleted_at = dayjs(new Date()).format('YYYY-MM-DD')
                    lock.deleted_by = returnedData.id
                    lock.locked_at = dayjs(lock.locked_at).format('YYYY-MM-DD')
                    let updatedLocks = [lock]
                    setLocks(await Schedules.updateLockDates(updatedLocks))
                    return;
                }
                else{
                    setErrorDB(true)
                }

            } catch (error) {
                setErrorDB(true);
                console.log(error);
            }
        }

        if(authenticateUser){
            authenticate();
            setAuthenticateUser(false);
        }
    }, [authenticateUser, userData, locks, selectedLock, setLocks]);


    const handleSubmit = (e) => {
       // e.preventDefault();
        if(userData.username.length < 1){setErrorUsernameLength(true);return;}
        if(userData.password.length < 1){setErrorPasswordLength(true);return;}

        setUserData({...userData, username: userData.username.toLowerCase(), password: md5(userData.password)});
        setAuthenticateUser(true);

    }

    const handlePopup = (e) => {
        setErrorDB(false);
        setErrorUsernameLength(false);
        setErrorPasswordLength(false);
    }

    return (
        <div>
            <table style={{float: "left", width: "40%"}}>
                <tbody>
                {locks.map(l => {
                    return (
                        <tr key={`locked${l.id}`}>
                            <td style={{verticalAlign: "middle", width: "5%"}}>
                                <Button value={l.id} onClick={() => {
                                    handleUnlock(l.id)
                                }}>
                                    <Lock/>
                                </Button>
                            </td>
                            <td style={{
                                verticalAlign: "middle",
                                textAlign: "right",
                                paddingRight: "5pt",
                                fontWeight: "bold"
                            }}>{l.phase.description}</td>
                            <td style={{verticalAlign: "middle", textAlign: "right"}}>locked on</td>
                            <td style={{
                                verticalAlign: "middle",
                                width: "11%",
                                fontWeight: "bold"
                            }}>{dayjs(l.locked_at).format('MM/DD/YYYY')}</td>
                            <td style={{verticalAlign: "middle", width: "5%"}}>by</td>
                            <td style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                fontWeight: "bold"
                            }}>{l.lockedBy.firstname} {l.lockedBy.lastname}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <Dialog open={open} onClose={handleClose} fullWidth={true}>
                <DialogTitle>{`Enter the password for ${fullname}`}</DialogTitle>
                <DialogContent>
                    <form id="login">
                        <input type="text" autoComplete="username" defaultValue={userData.username} hidden/>
                        <div className="login-textbox username-textbox">
                            <label className="login-label" htmlFor="username">User Name</label>
                            <input className="login-input" type="username" autoComplete="username"
                                   id="username" name="username" placeholder="Enter Username" value={userData.username}
                                   onChange={((e) => setUserData({ ...userData, username: e.target.value }))}/>
                        </div>
                        <div className="login-textbox password-textbox">
                            <label className="login-label" htmlFor="password">Password</label>
                            <input className="login-input" type="password" autoComplete="password"
                                   id="password" name="password" placeholder="Enter Password" value={userData.password}
                                   onChange={((e) => setUserData({ ...userData, password: e.target.value }))}/>
                        </div>
                    </form>
                    {errorDB && <Popup color={PopupFailureColor} message={'Error: May have entered a wrong username and/or password'} handlePopup={handlePopup}/>}
                    {errorUsernameLength && <Popup color={PopupWarningColor} message={'Please enter a username'} handlePopup={handlePopup}/>}
                    {errorPasswordLength && <Popup color={PopupWarningColor} message={'Please enter a password'} handlePopup={handlePopup}/>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNoSave}>Cancel</Button>
                    <Button onClick={handleSubmit}>Unlock</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default LockList;