import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';

const BuildersFilter = ({filters, setFilters}) => {

    return (
        <Grid 
            sx={{
                display: 'grid',
                justifyContent: 'center',
                width: 600, 
                padding: '10px 0',
                marginBottom: '10px',
                border: '2px solid rgb(220, 220, 220)',
                borderRadius: '5px',
                zIndex: '49'
            }}
        >
            <FormControlLabel 
                control={<Switch 
                    checked={filters.includeDeleted} 
                    onChange={(e) => setFilters({...filters, includeDeleted: e.target.checked})}/>} 
                label="Included Deleted Builders" 
            />
        </Grid>
    );
}

export default BuildersFilter;