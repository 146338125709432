import styled from 'styled-components';


const DailyScheduleContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    margin: 10px 0 0 10px;

    .messaging{
        width: 1110px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 10px;

        .main-header-message{
            font-size: 20px;

            :nth-child(1){grid-column: 1/2;}
            :nth-child(2){grid-column: 2/3;}
        }

        .reg-message{
            padding: 5px;
            color: red;
            background: rgb(210, 200, 190);
            border: 4px solid rgb(58, 64, 67);
        }

        .dev-message{
            padding: 5px;
            color: rgb(200, 50, 50);
            background: rgb(255, 255, 100);
            border: 4px dashed black;
        }
    }

    .daily-schedules {
        width: 600px;
        display: grid;
        background: rgb(210, 200, 190);
        margin: 0 0 10px 0;
        
        .daily-schedules-header {
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            background: rgb(58, 64, 67);
            color: white;
            border-bottom: 2px solid white;
        }
        
        .daily-schedules-container {
            display: grid;
            padding: 5px 0 7px;

            .no-docs-message{
                text-align: center;
                padding: 5px 0;
                font-size: 18px;
            }

            .name-list{
                display: grid;
                padding: 7px 0;
                font-weight: bold;
                font-size: 18px;

                .check-box{
                    display: grid;
                    grid-template-columns: repeat(10, 1fr);
                    
                    .check-box-input{
                        justify-self: end;
                        grid-column: 1/3;
                    }
                    
                    .check-box-label{
                        grid-column: 3/11;
                        padding-left: 5px;
                    }
                }
            }

            .daily-schedule-buttons{
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: 5px 0;
                
                .submit {
                    display: grid;
                    justify-content: center;
                    align-items: center;
                    padding: 5px 0;
                    
                    .button {
                        justify-self: center;
                        width: 135px;
                        height: 35px;
                        background: rgb(240, 240, 240);
                        border: 1px solid rgb(120, 120, 120);
                        border-radius: 3px;
                        display: grid;
                        justify-content: center;
                        align-items: center;
                        cursor: default;
                        
                        :hover {
                            background: rgb(230, 230, 230);
                        }
                    }
                }
            }
        }
    }

    .drop-area{
        width: 600px;
        display: grid;
        background: rgb(210, 200, 190);

        .drop-area-header {
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            background: rgb(58, 64, 67);
            color: white;
        }

        .drop-area-area{
            height: 150px;
            max-width: 1000px;
            width: 100%;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            border-left: 5px dashed rgb(128, 128, 128);
            border-bottom: 5px dashed rgb(128, 128, 128);
            border-right: 5px dashed rgb(128, 128, 128);  
        }
        
        .drop-area-area span{
            font-size: 17px;
        }
    }
`;

export default DailyScheduleContainer;