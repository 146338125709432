import styled from 'styled-components';

const EmployeesPageContainer = styled.div`
    display: grid;
    margin: 10px 0 75px 10px;

    
    .page-filter {
        display: grid;
        width: 500px;
        padding-bottom: 10px;
        background: rgb(210, 200, 190);
        border-bottom: 15px solid white;

        .filter-header{
            height: 25px;
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            background: rgb(58, 64, 67);
            color: white;
            border-bottom: 2px solid white;
        }
    }

    .page-header{
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        width: 1420px;
        padding: 3px 0;
        background: rgb(58, 64, 67);
        border-bottom: 3px solid white;

        .add-new-employee{
            grid-column: 1/4;
        }

        .export-to-xlsx{
            grid-column: 4/6;
        }

        .header-title{
            grid-column: 9/13;
            justify-self: center;
            align-self: center;
            display: grid;
            font-size: 18px;
            color: white;
        }
    }

    .new-employee-box{
        display: grid;
        width: 800px;
        background: rgb(210, 200, 190);
        padding-bottom: 15px;
        border-bottom: 3px solid white;

        .new-employee-header{
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            margin-bottom: 10px;
            background: rgb(128, 128, 128);
            color: white;
            border-bottom: 3px solid white;
        }
        
        .sub-checkbox-and-name-box{
            display: grid;
            grid-template-columns: repeat(20, 1fr);

            .is-sub-checkbox{
                display: grid;
                grid-column: 7/8;
            }

            .company-textbox{
                display: grid;
                grid-column: 8/15;
            }
        }

        .two-button-box{
            display: grid;
            justify-self: center;
            width: 300px;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .table-header{
        display: grid;
        width: 1420px;
        grid-template-columns: 180px 250px 120px 200px 120px 300px 125px 125px;
        background: rgb(210, 200, 190);

        // ADD THIS TO GET LOCKING HEADER
        position: -webkit-sticky;
        position: sticky;
        top: 140px;
        z-index: 5;

        .grid-table-header-data{
            display: grid;
            justify-content: center;
            padding: 3px 0;
            border-top: 2px solid black;
            border-bottom: 2px solid black;
            border-left: 1px solid black;
            border-right: 1px solid black;
        }

        .grid-table-header-data.index0{
            border-left: 2px solid black;
        }

        .grid-table-header-data.index7{
            border-right: 2px solid black;
        }
    }

    .grid-table-row{
        display: grid;
        width: 1420px;
        grid-template-columns: 180px 250px 120px 200px 120px 300px 125px 125px;

        .grid-table-row-data{
            display: grid;
            justify-content: center;
            padding: 3px 0;
            border: 1px solid black;
        }

        .grid-table-row-data.first{ border-top: 2px solid black; }
        .grid-table-row-data.last{ border-bottom: 2px solid black; }
        .grid-table-row-data.far-left{ border-left: 2px solid black; }
        .grid-table-row-data.far-right{ border-right: 2px solid black; }

        :nth-child(even) { background: rgb(230, 230, 220); }
    }

    .grid-table-row.in-active{
        color: rgb(225, 225, 225);
        background: rgb(180, 180, 180);
    }

    .edit-employee-box{
        display: grid;
        width: 800px;
        background: rgb(210, 200, 190);
        padding-bottom: 15px;
        border-bottom: 3px solid white;

        .edit-employee-header{
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            padding: 3px 0;
            margin-bottom: 10px;
            background: rgb(128, 128, 128);
            color: white;
            border-top: 3px solid white;
            border-bottom: 3px solid white;
        }

        .is-active-crew-numbers-box{
            display: grid;
            grid-template-columns: repeat(20, 1fr);

            .is-active-checkbox{
                display: grid;
                grid-column: 1/8;
            }

            .crew-numbers-display{
                display: grid;
                align-self: center;
                grid-column:8/21;
            }
        }

        .sub-checkbox-and-name-box{
            display: grid;
            grid-template-columns: repeat(20, 1fr);

            .is-sub-checkbox{
                display: grid;
                grid-column: 7/8;
            }

            .company-textbox{
                display: grid;
                grid-column: 8/15;
            }
        }

        .two-button-box{
            display: grid;
            justify-self: center;
            width: 300px;
            grid-template-columns: repeat(2, 1fr);
        }

        .three-button-box{
            display: grid;
            justify-self: center;
            width: 450px;
            grid-template-columns: repeat(3, 1fr);
        }
    }
`;

export default EmployeesPageContainer;